import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddUserPage from "./AddUserPage";
import EditUserPage from "./EditUserPage";
import UsersPage from "./UsersPage";
import ViewUserPage from "./ViewUserPage";

export default class UsersLayout extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/users" exact component={UsersPage} />
        <Route path="/admin/users/add" component={AddUserPage} />
        <Route path="/admin/users/:userId/edit" component={EditUserPage} />
        <Route path="/admin/users/:userId/view" component={ViewUserPage} />
        <Redirect to="/admin/users" />
      </Switch>
    );
  }
}
