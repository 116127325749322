import * as usersApi from "services/admin";
import { USER_TYPES, FORM_MODE_CREATE, FORM_MODE_EDIT, FORM_MODE_CONSULT } from "utils/constants";
import { Field, fieldsAggregatorBuilder, isEmail, isRequired, forbiddenChar } from "utils/forms";
import { t } from "utils/i18n";
import get from "lodash/get";

export default function builder({ authStore, fetchService, mode = FORM_MODE_CREATE, user = null }) {
  const { currentUser } = authStore;
  const role = currentUser.role;

  const fields = {
    photo: new Field({ disabled: mode === FORM_MODE_CONSULT }),
    role: new Field({
      initValue: USER_TYPES.ANIMATOR,
      disabled: mode === FORM_MODE_CONSULT,
    }),
    firstname: new Field({ disabled: mode === FORM_MODE_CONSULT }),
    lastname: new Field({
      validate: [isRequired],
      label: t("name"),
      disabled: mode === FORM_MODE_CONSULT,
    }),
    certified: new Field({ initValue: false, disabled: mode === FORM_MODE_CONSULT }),
    bio: new Field({ disabled: mode === FORM_MODE_CONSULT }),
    password: new Field({
      validate: [forbiddenChar(" ", "Password", "Le mot de passe ne doit pas contenir d'espace")],
      disabled: mode === FORM_MODE_CONSULT,
    }),
    weezsCount: new Field({
      initValue: 0,
      disabled: mode === FORM_MODE_CONSULT,
    }),
    subscriptionsCount: new Field({
      initValue: 0,
      disabled: mode === FORM_MODE_CONSULT,
    }),
    subscribersCount: new Field({
      initValue: 0,
      disabled: mode === FORM_MODE_CONSULT,
    }),
    location: new Field({ disabled: mode === FORM_MODE_CONSULT }),
    subscriptions: new Field({
      initValue: new Set(),
      disabled: mode === FORM_MODE_CONSULT,
    }),
  };

  fields.email = new Field({
    validate: [
      (newValue, label) => {
        if (fields.role.value === USER_TYPES.DUMMY && !newValue) {
          return {
            valid: true,
            stopValidation: true,
          };
        } else {
          return {
            valid: true,
          };
        }
      },
      isEmail,
    ],
    label: t("email"),
    disabled: mode === FORM_MODE_CONSULT,
  });

  fields.client = new Field({
    initValue: 0,
    disabled: mode === FORM_MODE_EDIT || mode === FORM_MODE_CONSULT,
    validate: [
      (newValue, label) => {
        // Required only if SUPERADMIN and not DUMMY
        if (currentUser.role === USER_TYPES.ANIMATOR || fields.role.value === USER_TYPES.DUMMY) {
          return {
            valid: true,
          };
        }

        return isRequired(newValue, label);
      },
    ],
    label: t("client"),
    computeValue: (originalValue) => {
      if (currentUser.role === USER_TYPES.ANIMATOR) {
        return currentUser.client;
      } else if (fields.role.value === USER_TYPES.DUMMY) {
        return null;
      } else {
        return originalValue;
      }
    },
  });

  fields.category = new Field({
    initValue: 0,
    disabled: mode === FORM_MODE_CONSULT,
    validate: [
      (newValue, label) => {
        // Required only if SUPERADMIN and DUMMY
        if (fields.role.value !== USER_TYPES.DUMMY) {
          return {
            valid: true,
          };
        }

        return isRequired(newValue, label);
      },
    ],
    label: "Catégorie",
    computeValue: (originalValue) => {
      if (fields.role.value !== USER_TYPES.DUMMY) {
        return null;
      } else {
        return originalValue;
      }
    },
  });

  fields.username = new Field({
    initValue: "",
    disabled: mode === FORM_MODE_EDIT || mode === FORM_MODE_CONSULT,
    validate: [
      (newValue) => {
        if (!newValue || mode === FORM_MODE_EDIT) {
          return {
            valid: true,
          };
        }

        let clientId;
        if (role === USER_TYPES.ANIMATOR) {
          clientId = get(currentUser.client, "id");
        } else if (role === USER_TYPES.SUPERADMIN && fields.role.value !== USER_TYPES.DUMMY) {
          clientId = fields.client.value || undefined;
        }
        const checkUserData = {
          username: newValue,
          clientId,
        };
        return async () => {
          // FIXME: what happen if exception ?
          let isValid = false;
          let isUnlocked = false;
          try {
            const usernameExists = await fetchService.fetch(usersApi.checkUser(checkUserData));
            isValid = !usernameExists.exists;
            isUnlocked = !usernameExists.locked;
          } catch (e) {
            console.error(e);
          }
          return {
            valid: isValid && isUnlocked,
            message: !isUnlocked ? t("lock_message") : !isValid ? t("username_not_available") : "",
          };
        };
      },
      forbiddenChar("@", "Login"),
      forbiddenChar(" ", "Login", "Le login ne doit pas contenir d'espace! "),
      isRequired,
    ],
    label: "Login",
    debounce: true,
  });

  return fieldsAggregatorBuilder({
    fields,
    data: user,
    dataMapper: (data) => ({
      ...data,
      client: data.client ? data.client.id : 0,
      subscriptions: new Set(data.followees),
    }),
    // valuesGetter: (form, fieldsNames) => {
    //   const res = defaultValuesGetter(form, fieldsNames);

    //   // Fixing client value
    //   if (currentUser.role === USER_TYPES.ANIMATOR) {
    //     res.client = currentUser.client;
    //   } else if (currentUser.role === USER_TYPES.SUPERADMIN) {
    //     if (res.role === USER_TYPES.DUMMY) {
    //       res.client = null;
    //     }
    //   }
    //   return res;
    // },
  });
}
