import React from "react";
import ReactPlayer from "react-player/lazy";
import styled from "styled-components";
import { marginVertical } from "utils/mixins";
import { isGoogleDriveUrl, adaptGoogleDriveUrl } from "utils";

const FixedRatioContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: black;
  position: relative;
  padding-top: ${(props) =>
    props.paddingTop ? props.paddingTop : "56.25%"}; /* Player ratio: 100 / (1280 / 720) */
  max-width: calc(100% - 10px);
  border-radius: 5px;
  ${marginVertical("10px")};
  overflow: hidden;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

export default class VideoPlayer extends React.Component {
  shouldComponentUpdate() {
    return false; // This component does not need to re-render.
  }
  render() {
    const { video } = this.props;
    let iFrameWidth = 480;
    if (typeof window === "object" && window.innerWidth <= 480) {
      iFrameWidth = window.innerWidth - 100;
    }
    const iFrameHeight = iFrameWidth * 0.75;
    return !isGoogleDriveUrl(video.url) ? (
      <FixedRatioContainer>
        <StyledReactPlayer controls width="100%" height="100%" url={video.url} />
      </FixedRatioContainer>
    ) : (
      <FixedRatioContainer paddingTop="0">
        <iframe
          src={adaptGoogleDriveUrl(video.url)}
          width={`${iFrameWidth}px`}
          height={`${iFrameHeight}px`}
          frameBorder="0"
          title={video.url}
        />
      </FixedRatioContainer>
    );
  }
}
