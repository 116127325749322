import React from "react";
import throttle from "lodash/throttle";

function useScrollListener(onScroll, throttleDelay = 400) {
  const scrollHandler = throttle(onScroll, throttleDelay, { leading: true, trailing: true });
  React.useLayoutEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
}

export default useScrollListener;
