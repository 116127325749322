import { LoadingContent, Modal, ErrorContent } from "components";
import { action, observable } from "mobx";
import { observer, inject } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import modalHoc from "react-modal-hoc";
import * as conversationsApi from "services/conversations";
import styled from "styled-components";
import Weez from "./Weez";
import { weezFromData } from "./WeezModel";
import { t } from "utils/i18n";

const Container = styled.div``;

@inject("fetchService")
@observer
class WeezConversationModal extends React.Component {
  @observable getConversationRequest;

  @observable conversationWeezs = observable.array([], { deep: false });

  componentDidMount() {
    this.loadConversation();
  }

  @action("LOAD_DISCUSSION")
  loadConversation = () => {
    const { conversationId, fetchService } = this.props;
    this.getConversationRequest = fromPromise(
      fetchService.fetch(conversationsApi.getConversation(conversationId))
    );
    this.getConversationRequest
      .then(
        action(weezList => {
          this.conversationWeezs.replace(
            weezList.map(data => weezFromData(data, { fetchService }))
          );
        })
      )
      .catch(e => console.error(e));
  };

  render() {
    const { onRequestClose } = this.props;
    if (!this.getConversationRequest) {
      return null;
    }

    return (
      <Modal allowScroll isOpen title={t("conversation")} onRequestClose={onRequestClose}>
        <Container>
          {this.getConversationRequest.case({
            pending: () => <LoadingContent label={t("load_conversation")} />,
            rejected: error => <ErrorContent text={t(error.code)} />,
            fulfilled: () => null,
          })}
          {this.conversationWeezs.map(weez => (
            <Weez showButtons={false} key={weez.id} weez={weez} />
          ))}
        </Container>
      </Modal>
    );
  }
}

export default modalHoc(WeezConversationModal);
