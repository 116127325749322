import React from "react";
import Downshift from "downshift";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import styledMap from "styled-map";
import Input from "./Input";

const Container = styled.div`
  position: relative;
`;

const itemBackground = styledMap({
  isActive: themeGet("colors.primary"),
  isSelected: themeGet("colors.primary"),
  default: themeGet("colors.white"),
});
const itemColor = styledMap({
  isActive: themeGet("colors.white"),
  isSelected: themeGet("colors.white"),
  default: themeGet("colors.black"),
});

const Item = styled.div`
  color: ${themeGet("colors.black")};
  font-size: 1.1rem;
  padding: 10px;
  cursor: pointer;
  display: block;
  background-color: ${itemBackground};
  color: ${itemColor};
`;

const Menu = styled.div`
  background-color: ${themeGet("colors.white")};
  border: 1px solid ${themeGet("colors.grey")};
  box-shadow: ${themeGet("shadows.default")};
  box-sizing: border-box;
  margin-top: 0px;
  max-height: 200px;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  border-radius: 4px;
`;

const InputWrapper = styled.div`
  cursor: auto;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover {
    box-shadow: 0;
  }
`;

// this is just a demo of how you'd use the getRootProps function
// normally you wouldn't need this kind of abstraction 😉
const Root = ({ innerRef, ...rest }) => <div ref={innerRef} {...rest} />;

const RenderIfItems = ({ items, render }) => {
  if (!items || !items.length) {
    return null;
  }
  return render({ items });
};

const Dropdown = ({
  alert,
  items,
  defaultItem,
  selectedItem,
  placeholder,
  label,
  onBlur,
  filter,
  disabled,
  withCloseButton,
  itemToString,
  renderItem,
  onSelect,
  error,
  ...rest
}) => (
  <Downshift
    itemToString={itemToString}
    initialSelectedItem={defaultItem}
    selectedItem={selectedItem}
    onSelect={onSelect}
    {...rest}>
    {({
      getRootProps,
      getInputProps,
      openMenu,
      getItemProps,
      isOpen,
      itemToString,
      clearSelection,
      selectedItem,
      inputValue,
      highlightedIndex,
    }) => (
      <Root {...getRootProps({ refKey: "innerRef" })}>
        <Container>
          <InputWrapper>
            <Input
              type="text"
              {...getInputProps({
                isOpen,
                placeholder,
                onBlur,
                disabled,
                error,
                onMouseUp: openMenu,
                onChange: e => {
                  if (e.target.value === "") {
                    clearSelection();
                  }
                },
              })}
            />
          </InputWrapper>
          {isOpen && (
            <RenderIfItems
              items={filter && inputValue ? filter(items, inputValue) : items}
              render={({ items }) => (
                <Menu>
                  {items.map((item, index) => (
                    <Item
                      key={index}
                      {...getItemProps({
                        item,
                        index,
                        isActive: highlightedIndex === index,
                        isSelected: selectedItem === item,
                      })}>
                      {(renderItem && renderItem(item, inputValue)) ||
                        itemToString(item, inputValue)}
                    </Item>
                  ))}
                </Menu>
              )}
            />
          )}
        </Container>
      </Root>
    )}
  </Downshift>
);

export default Dropdown;
