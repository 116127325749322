import { USER_TYPES } from "utils/constants";
import { Field, fieldsAggregatorBuilder } from "utils/forms";

export default function builder({ authStore, initialValues }) {
  const { currentUser } = authStore;
  const fields = {
    terms: new Field({ initValue: initialValues && initialValues.terms }),
  };

  fields.roles = new Field({ initValue: initialValues ? initialValues.roles : [] });

  fields.client = new Field({
    initValue: currentUser.role === USER_TYPES.SUPERADMIN ? 0 : currentUser.client.id,
    disabled: currentUser.role !== USER_TYPES.SUPERADMIN,
    computeValue: originalValue => {
      if (currentUser.role === USER_TYPES.ANIMATOR) {
        return currentUser.client.id;
      } else {
        return originalValue;
      }
    },
  });

  fields.status = new Field({ initValue: initialValues ? initialValues.status : true});
  
  return fieldsAggregatorBuilder({
    fields,
    dataMapper: data => ({
      ...data,
      client: data.client ? data.client.id : 0,
    }),
  });
}
