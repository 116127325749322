import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";
import { ifProp, lightenColor, transition } from "utils/mixins";
import Spinner from "./Spinner";

const buttonColor = styledMap({
  empty: themeGet("colors.black"),
  secondary: themeGet("colors.black"),
  full: themeGet("colors.white"),
  default: themeGet("colors.primary"),
});

const buttonBackground = styledMap({
  empty: "transparent",
  secondary: themeGet("colors.secondary"),
  full: themeGet("colors.primary"),
  default: "transparent",
});

const buttonBorder = styledMap({
  full: "none",
  empty: "none",
  secondary: "none",
  default: css`1px solid ${themeGet("colors.primary")}`,
});

const buttonHoverBackground = styledMap({
  empty: "transparent",
  secondary: lightenColor(0.1, "colors.secondary"),
  full: lightenColor(0.1, "colors.primary"),
  default: themeGet("colors.veryLightGrey"),
});

const buttonBorderRadius = styledMap({
  empty: "0px",
  admin: "4px",
  default: "18px",
});

const horizontalPadding = styledMap({
  empty: "initial",
  admin: "10px",
  default: "15px",
});

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  border: ${buttonBorder};
  outline: 0;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  line-height: ${styledMap({
    empty: "0px",
    default: "33px",
  })};
  height: ${styledMap({
    empty: "initial",
    default: "35px",
  })};
  padding-left: ${horizontalPadding};
  padding-right: ${horizontalPadding};
  color: ${buttonColor};
  background: ${buttonBackground};
  border-radius: ${buttonBorderRadius};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  ${transition("all")};
  position: relative;
  text-decoration: none;
  opacity: ${styledMap({
    invalid: 0.7,
    default: 1,
  })};
  white-space: pre;

  &:hover {
    background: ${buttonHoverBackground};
    ${ifProp(
      "empty",
      css`
        color: ${themeGet("colors.primary")};
      `
    )};
  }

  &:active {
    color: ${themeGet("colors.veryLightGrey")};
  }

  & svg {
    ${styledMap({
      empty: null,
      default: css`
        /* margin-bottom: 0.2rem; */
        margin-right: 5px;
        margin-left: -3px;
      `,
    })};
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const StyledLink = StyledButton.withComponent(Link);

const FakeButton = styled(StyledButton)`
  flex: 1;
`;

const IconButton = styled(StyledButton)`
  & svg {
    ${styledMap({
      empty: null,
      default: css`
        /* margin-bottom: 0.2rem; */
        margin-right: 0;
        margin-left: 0;
      `,
    })};
  }
`;

const Button = ({ loading, disabled, children, ...props }) => {
  if (loading) {
    disabled = true;
  }
  if (props.iconButton) {
    return (
      <IconButton {...props} disabled={disabled}>
        {children}
      </IconButton>
    );
  }
  if (props.fake) {
    return (
      <FakeButton {...props} disabled={disabled}>
        {loading && <Spinner size={20} />}
        {children}
      </FakeButton>
    );
  } else if (props.to) {
    return (
      <StyledLink {...props} disabled={disabled}>
        {children}
      </StyledLink>
    );
  } else if (props.externalUrl) {
    return (
      <a href={props.externalUrl}>
        <StyledButton type="button" {...props} disabled={disabled}>
          {loading && <Spinner size={20} />}
          {children}
        </StyledButton>
      </a>
    );
  } else {
    return (
      <StyledButton type="button" {...props} disabled={disabled}>
        {loading && <Spinner size={20} color="secondary" />}
        {children}
      </StyledButton>
    );
  }
};

export default Button;
