import { fb, isRequired, isInt, next, stop } from "utils/forms2";

export function buildNewMoodField({ t }) {
  const hashtag = fb.field("");
  return fb.map({
    mood: fb.field("", { validate: [isRequired(t("boost.errors.mood_required"))] }),
    count: fb.field(0, { validate: [isRequired(t("boost.errors.qty_required"))] }),
    multiplicator: fb.field(1),
    hashtag,
    percent: fb.field(0, {
      validate: [
        () => (hashtag.value ? next() : stop()),
        isInt(t("boost.errors.percent_format"), { min: 1, max: 100 }),
      ],
    }),
  });
}

export default function builder({ t }) {
  const form = fb.map({
    duration: fb.field(5, {
      validate: [
        isRequired(t("boost.errors.duration_required")),
        isInt(t("boost.errors.duration_required"), { min: 5 }),
      ],
    }),
    lang: fb.field("fr", {
      validate: [isRequired(t("boost.errors.lang_required"))],
      disabled: false,
    }),
    moods: fb.array([buildNewMoodField({ t })]),
    likes: fb.field(0),
  });
  return form;
}
