import React from "react";
import modalHoc from "react-modal-hoc";
import styled from "styled-components";
import { Modal, Button } from "components";
import { useTranslation } from "react-i18next";

const Content = styled.div`
  padding: 10px;
  font-size: 14px;
`;
const Title = styled.div`
  min-height: 25px;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
`;
const StyledButton = styled(Button)`
  margin: 5px;
`;

const handleCancel = (onRequestClose, onCancel) => (evt) => {
  if (onCancel) onCancel();
  onRequestClose();
};

const handleConfirm = (onRequestClose, onConfirm) => (evt) => {
  if (onConfirm) onConfirm();
  onRequestClose();
};
const ConfirmationDialog = ({ onCancel, onConfirm, onRequestClose, text, title }) => {
  const { t } = useTranslation();
  return (
    <Modal allowScroll={false} isOpen title={title} onRequestClose={onRequestClose}>
      {/* <Title>{title}</Title> */}
      <Content>{text}</Content>
      <Buttons>
        <StyledButton onClick={handleConfirm(onRequestClose, onConfirm)}>{t("ok")}</StyledButton>
        <StyledButton onClick={handleCancel(onRequestClose, onCancel)}>{t("cancel")}</StyledButton>
      </Buttons>
    </Modal>
  );
};

export default modalHoc(ConfirmationDialog);
