import React, { Component } from "react";
import styled from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";
import { transition } from "utils/mixins";
import { withTranslation } from "react-i18next";

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  white-space: normal;
`;

const CheckboxInput = styled.input.attrs({
  type: "checkbox",
})`
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 0;
`;

const SwitchContainer = styled.span`
  background-color: ${themeGet("colors.backgroundGrey")};
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  position: relative;
  vertical-align: center;
  padding: 3px;
  /* width: 200px; */
`;

const SwitchLabel = styled.span`
  /* padding-top: 7px; */
  /* padding-bottom: 8px; */
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  user-select: none;
  width: calc(100% / 2 - 3px);
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  color: ${styledMap({
    active: themeGet("colors.white"),
    default: themeGet("colors.grey"),
  })};
  transition: ${transition("color")};
  white-space: pre;
`;

const LabelBackground = styled.span`
  background-color: ${themeGet("colors.primary")};
  height: calc(100% - 6px);
  width: calc(100% / 2 - 6px);
  position: absolute;
  left: ${styledMap({
    checked: "3px",
    default: "calc(50% + 3px)",
  })};
  top: 3px;
  border-radius: 5px;
  transition: ${transition("left")};
`;

const Thumb = ({ onMouseDown, ...other }) => (
  <LabelBackground onMouseDown={onMouseDown} {...other} />
);

export class Switch extends Component {
  handleToggle = (event) => {
    if (event.pageX !== 0 && event.pageY !== 0) this.blur();
    if (!this.props.disabled && this.props.onChange) {
      this.props.onChange(event, !this.props.checked);
    }
  };

  blur() {
    this.inputNode.blur();
  }

  focus() {
    this.inputNode.focus();
  }

  setRef = (node) => {
    this.inputNode = node;
  };

  render() {
    const { t } = this.props;
    const {
      checked,
      className,
      disabled,
      onChange,
      labelChecked = t("yes"),
      labelUnchecked = t("no"),
      ...others
    } = this.props;
    return (
      <Label className={className}>
        <CheckboxInput
          {...others}
          checked={this.props.checked}
          onClick={this.handleToggle}
          readOnly
          ref={this.setRef}
        />
        <SwitchContainer disabled={disabled}>
          <Thumb disabled={this.props.disabled} checked={checked} />
          <SwitchLabel active={checked}>{labelChecked}</SwitchLabel>
          <SwitchLabel active={!checked}>{labelUnchecked}</SwitchLabel>
        </SwitchContainer>
      </Label>
    );
  }
}

export default withTranslation()(Switch);
