import { themeGet } from "@styled-system/theme-get";
import UserItem from "app/common/UserItem";
import { Button, ErrorContent, LoadingContent } from "components";
import get from "lodash/get";
import throttle from "lodash/throttle";
import { action, observable, toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { MdEdit, MdPersonAdd, MdRemoveCircle, MdVisibility } from "react-icons/md";
import * as adminApi from "services/admin";
import styled from "styled-components";
import { cancelEvent } from "utils";
import { marginVertical, resetList } from "utils/mixins";
import SearchForm from "./SearchForm";
import searchFormDataBuilder from "./searchFormDataBuilder";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AddButton = styled(Button)`
  align-self: center;
`;

const SearchFormStyled = styled(SearchForm)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SearchResults = styled.ul`
  ${resetList};
`;

const SearchResultsTitle = styled.span`
  ${marginVertical("20px")};
  font-weight: 600;
  font-size: 1.1rem;
  color: ${themeGet("colors.grey")};
`;

const ActionButtonContainer = styled.div`
  margin: 10px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

@inject("authStore", "fetchService", "viewStore", "routingStore")
@observer
class UsersPage extends React.Component {
  @observable users = observable.array([], { deep: false });
  @observable page;
  @observable searchQueryRequest;
  @observable totalResults;

  form = searchFormDataBuilder(
    (({ authStore, routingStore: { history } }) => ({
      authStore: authStore,
      // Injects value from previous search when coming back to this page.
      initialValues: history.location.state && history.location.state.searching,
    }))(this.props)
  );

  handleSubmitSearch = cancelEvent(() => {
    if (!this.form.isValid) {
      this.form.setTouched();
      return;
    }
    this.search();
    const { routingStore } = this.props;
    routingStore.history.push(routingStore.history.location.pathname, {
      ...routingStore.history.location.state,
      searching: {
        terms: this.form.values.terms,
        roles: toJS(this.form.values.roles),
        status: this.form.values.status,
      },
    });
  });

  toggleLock = (user) => {
    const { fetchService, viewStore, authStore, t } = this.props;
    const index = this.users.findIndex((item) => item.id === user.id);
    if (authStore.currentUser.id === user.id) {
      viewStore.pushNotification(t("lock_not_available"), "warning");
      return;
    }
    if (user.locked)
      fetchService
        .fetch(adminApi.unlock(user.id))
        .then(this.updateUser(index))
        .then(viewStore.pushNotification(t("unlock_confirm")));
    else
      fetchService
        .fetch(adminApi.lock(user.id))
        .then(this.updateUser(index))
        .then(viewStore.pushNotification(t("lock_confirm")));
  };

  updateUser = (index) =>
    action((user) => {
      this.users[index] = user; // replace user at index
    });

  handleScroll = throttle(
    (evt) => {
      const st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop) {
        if (document.body.scrollHeight - window.scrollY - window.innerHeight < 20) {
          this.loadMoreSearch();
        }
      } else {
      }
      lastScrollTop = st <= 0 ? 0 : st;
    },
    600,
    { leading: true, trailing: true }
  );

  _searchRequest = action((data, page) => {
    this.searchQueryRequest = fromPromise(
      this.props.fetchService.fetch(
        adminApi.searchUsers(
          {
            ...data,
            roles: Array.from(toJS(data.roles)),
            status: data.status ? "ACTIVE" : "INACTIVE",
          },
          page
        )
      )
    );
  });

  @action("SEARCH")
  search = () => {
    const data = this.form.values;
    this.page = 0;
    this._searchRequest(data, this.page);
    this.searchQueryRequest.then(
      action((values) => {
        this.users = values.data;
        this.totalResults = values.totalElements;
      })
    );
  };

  @action("SEARCH_LOAD_MORE")
  loadMoreSearch = () => {
    const data = this.form.values;
    this.page++;
    this._searchRequest(data, this.page);
    this.searchQueryRequest.then(
      action((values) => {
        this.users.push(...values.data);
        this.totalResults = values.totalElements;
      })
    );
  };

  componentDidMount() {
    // Submit form if search values present in history (display previous search)
    if (
      this.props.routingStore.history.location.state &&
      this.props.routingStore.history.location.state.searching
    ) {
      this.handleSubmitSearch(new MouseEvent("click"));
    }
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getMenuOptions = (user) => {
    const { authStore, t } = this.props;

    if (
      !get(authStore, ["currentUser", "client", "clone"], false) &&
      (get(authStore, ["currentUser", "isSuperAdmin"], false) ||
        (user.clientPath && !user.globalDummy))
    ) {
      const to = {
        pathname: `/admin/users/${user.id}/edit`,
        state: {
          searching: { terms: this.form.values.terms, roles: toJS(this.form.values.roles) },
        },
      };
      return [
        { label: t("edit"), to, icon: MdEdit },
        {
          label: user.locked ? t("unlock") : t("lock"),
          icon: MdRemoveCircle,
          onClick: this.toggleLock,
          user: user,
        },
      ];
    } else {
      const to = {
        pathname: `/admin/users/${user.id}/view`,
        state: { searching: { ...this.form.values, roles: toJS(this.form.values.roles) } },
      };
      return [{ label: t("consult"), to, icon: MdVisibility }];
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Header>
          <h1>{t("admin_user")}</h1>
          <ActionButtonContainer>
            <AddButton
              admin
              full="true"
              to="/admin/users/add"
              disabled={
                this.props.authStore.currentUser.client &&
                this.props.authStore.currentUser.client.clone
              }>
              <MdPersonAdd size={20} />
              {t("add_user")}
            </AddButton>
          </ActionButtonContainer>
        </Header>
        <SearchFormStyled
          onSubmit={this.handleSubmitSearch}
          form={this.form}
          currentUser={this.props.authStore.currentUser}
        />
        {this.users.length > 0 && (
          <Fragment>
            <SearchResultsTitle>{`${this.totalResults} ${t("found_users")} :`} </SearchResultsTitle>
            <SearchResults>
              {this.users.map((user) => (
                <UserItem key={user.id} user={user} menuOptions={this.getMenuOptions(user)} />
              ))}
            </SearchResults>
          </Fragment>
        )}
        <div>
          {this.searchQueryRequest &&
            this.searchQueryRequest.case({
              pending: () => <LoadingContent label={t("loading_users")} />,
              rejected: (error) => <ErrorContent text={t(error.code)} />,
              fulfilled: () => null,
            })}
        </div>
      </Container>
    );
  }
}

export default withTranslation()(UsersPage);
