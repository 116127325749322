import { observable, action, runInAction, computed } from "mobx";
import * as weezsApi from "services/weezs";

export default class Weez {
  id;
  text;
  author;
  sponsored;
  createdOn;
  reweez;
  delete;
  weezeOrigin;
  medias;

  @observable likesCount;
  @observable reweezsCount;
  @observable repliesCount;
  @observable conversationId;
  @observable liked;

  @computed
  get images() {
    return this.medias && this.medias.filter((media) => media && media.type === "IMAGE");
  }

  @computed
  get videos() {
    return (
      this.medias &&
      this.medias
        .filter((media) => media && media.type === "VIDEO")
        .map((media) => ({ ...media, url: media.val }))
    );
  }

  // FIXME this is where serializr is handy
  constructor(data, { fetchService }) {
    this.fetchService = fetchService;
    const { reweez, ...properties } = data;
    Object.assign(this, properties);
    if (reweez) {
      this.reweez = new Weez(reweez, { fetchService });
    }
  }

  // FIXME this is where serializr is handy
  @action("WEEZ_UPDATE")
  updateFromData(data) {
    const { reweez, ...properties } = data;
    Object.assign(this, properties);
    if (reweez) {
      if (!this.reweez) {
        this.reweez = weezFromData(reweez);
      } else {
        this.reweez.updateFromData(reweez);
      }
    }
  }

  @action("LIKE_WEEZ")
  like = () => {
    const value = !this.liked;
    this.liked = value;
    return this.fetchService
      .fetch(weezsApi.likeWeez(this.id, value))
      .then((data) => {
        this.updateFromData(data);
      })
      .catch(() => {
        runInAction(() => {
          this.liked = !value;
        });
      });
  };

  @action("REWEEZ")
  reweezIt = (newWeez) => {
    return this.fetchService
      .fetch(weezsApi.reweez(this.id, newWeez))
      .then(action(() => this.reweezsCount++));
  };

  @action("REPLY")
  reply = (newWeez) => {
    return this.fetchService
      .fetch(weezsApi.reply(this.id, newWeez))
      .then(action((data) => (this.conversationId = data.conversationId)));
  };

  @action("DELETE")
  delete = () => {
    return this.fetchService
      .fetch(weezsApi.deleteWeez(this.id))
      .then((data) => {
        this.updateFromData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export function weezFromData(data, stores) {
  return new Weez(data, stores);
}
