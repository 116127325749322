import { getServerUrl } from "utils/fetch";

export function addMedia(file, urlPath, metadata = {}) {
  return {
    url: getServerUrl(urlPath),
    type: "multipart",
    fields: {
      file,
      metadata: new Blob([JSON.stringify(metadata)], { type: "application/json" }),
    },
  };
}

export function download(urlPath) {
  return {
    url: getServerUrl(urlPath),
  };
}
