import React from "react";
import { themeGet } from "@styled-system/theme-get";
import { paddingHorizontal } from "utils/mixins";
import styled from "styled-components";
import ReactPlayer from "react-player";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import ScrollButton from "components/ScrollButton";

const SectionTitle = styled.div`
  border: 1px solid black;
  padding: 5px;
  margin: 2rem 10px 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
`;

const Intro = styled.p`
  text-decoration: azure;
`;

const title2 = props => {
  return (
    <ScrollableAnchor id={props.id}>
      <h2 className={props.className}>{props.children}</h2>
    </ScrollableAnchor>
  );
};
const title3 = props => {
  return (
    <ScrollableAnchor id={props.id}>
      <h3 className={props.className}>{props.children}</h3>
    </ScrollableAnchor>
  );
};

const H2 = styled(title2)`
  h2 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    text-indent: 0.5rem;
  }
`;
const H3 = styled(title3)`
  h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-indent: 1rem;
  }
`;

const Remarque = styled.p`
  color: #3498db;
  background-color: #ecf0f1;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  padding-inline-start: 10px;
  strong {
    line-height: 30px;
  }
`;

const Container = styled.div`
  max-width: 800px;
  width: 100%;
  background-color: ${themeGet("colors.white")};
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  ${paddingHorizontal("10px")};
`;

const Summary = styled.div`
  margin: 1rem 0;
`;
const SumTitle = styled.h2``;
const SumLinks = styled.ul`
  list-style-type: none;
`;

const ListLink = props => {
  return (
    <li className={props.className}>
      <a href={props.href}>{props.children}</a>
    </li>
  );
};

const SumLink = styled(ListLink)`
  a {
    color: #2980b9;
    text-decoration: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: ${({ width }) => (width ? width : "100%")};
`;

const HelpPage = () => {
  configureAnchors({ offset: -60, scrollDuration: 200 });
  return (
    <React.Fragment>
      <Container>
        <h1>Guide d’utilisation de Weezer</h1>
        <H2>Présentation générale</H2>
        <p>
          Les réseaux sociaux sont devenus un lieu incontournable d’échanges d’information en temps
          réel. Lors d’un événement indésirable, la crise devient aussi digitale : afflux de
          messages, réactions négatives, atteinte à l’image de votre structure. Mieux vaut s’y être
          préparé en conditions réelles.
        </p>
        <p>
          Weezer vous propose de vous immerger dans un univers semblable à celui d’un célèbre réseau
          social, permettant ainsi de s’entraîner en conditions quasiment réelles, dans un
          environnement totalement hermétique. Elle permet en exercice ou formation de réaliser une
          pression médiatique simulée (PMS) réaliste, sans prendre le risque que l’exercice ne
          déborde sur de vrais réseaux sociaux.
        </p>
        <Summary>
          <SumTitle>Sommaire</SumTitle>
          <SumLinks>
            <p>FONCTIONNALITÉS GÉNÉRALES DU JOUEUR</p>
            <SumLink href="#title1.1">1. Spécificités du profil</SumLink>
            <SumLink href="#title1.2">2. Accueil</SumLink>
            <SumLink href="#title1.3">3. Publications de Weezes</SumLink>
            <SumLink href="#title1.4">4. Gestion des abonnements</SumLink>
            <SumLink href="#title1.5">5. Interactions et notifications</SumLink>
            <SumLink href="#title1.6">6. Tendances pour vous</SumLink>
            <p>FONCTIONNALITÉS SPÉCIFIQUES DE L'ÉQUIPE ANIMATION</p>
            <SumLink href="#titleC">
              A. Mise en place de l’univers de l’exercice / de la formation
            </SumLink>
            <SumLink href="#titleA-1">&nbsp; 1. Personnalisation de l'univers</SumLink>
            <SumLink href="#titleA-2">&nbsp; 2. Création des profils d'utilisateurs</SumLink>
            <SumLink href="#titleA-3">&nbsp; 3. Gestion des abonnements</SumLink>
            <SumLink href="#titleA-4">&nbsp; 4. Gestion des profils</SumLink>
            <SumLink href="#titleB">B. Animation</SumLink>
            <SumLink href="#titleB-1">&nbsp; 1. Accueil</SumLink>
            <SumLink href="#titleB-2">
              &nbsp; 2. Publications de Weezes / Publier "en tant que"
            </SumLink>
            <SumLink href="#titleB-3">&nbsp; 3. Interactions et notifications</SumLink>
            <SumLink href="#titleB-4">&nbsp; 4. Gestion des tendances</SumLink>
            <SumLink href="#titleC">C. Fin de l'exercice : l'archivage</SumLink>
          </SumLinks>
        </Summary>
        <SectionTitle>Fonctionnalités générales du joueur</SectionTitle>
        <H2 id="title1.1">1. Spécificités du profil</H2>
        <p>
          L’équipe animation vous communiquera l’adresse de connexion à votre compte, vos
          identifiants et mots de passe en amont ou en début d’exercice.
        </p>
        <p>
          En fonction de la convention d’exercice (si les profils joueur vous sont imposés ou non),
          vous avez la possibilité d’éditer votre profil. Vous pourrez ainsi ajouter ou changer
          votre photo, spécifier votre lieu et compléter votre biographie.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_01.mp4" controls width="560px" height="315px" />
        </div>
        <H2 id="title1.2">2. Accueil</H2>
        <p>
          En cliquant sur « Accueil », vous visionnez l’univers de l’exercice : c’est ici
          qu’apparaîtront l’ensemble des Weezes publiés par l’équipe animation et le reste des
          joueurs.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_02.mp4" controls width="560px" height="315px" />
        </div>
        <H2 id="title1.3">3. Publications de Weezes</H2>
        <p>
          Vous pouvez publier des messages soit dans la boîte de dialogue « Quoi de neuf ? » soit en
          cliquant sur le bouton « Weezer » en haut à droite. Vos messages peuvent comporter 280
          caractères incluant :
        </p>
        <ul>
          <li>Des hashtags #</li>
          <li>Des émoticônes</li>
          <li>Des identifications @nomducompte</li>
          <li>Des photos en pièces jointes</li>
          <li>Des vidéos, en mettant un lien Youtube</li>
          <li>Des liens pointant vers des sites</li>
        </ul>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_03.mp4" controls width="560px" height="250px" />
        </div>
        <H2 id="title1.4">4. Gestion des abonnements</H2>
        <p>
          En fonction des choix de l’équipe animation, vous êtes abonné par défaut à certains
          profils. Afin de voir les publications d’autres profils, vous devez vous-même les
          rechercher et vous y abonner.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_03-1.mp4" controls width="560px" height="315px" />
        </div>
        <p>
          En cliquant uniquement sur « Rechercher », vous accédez à la liste complète des profils et
          vous pouvez abonner aux profils manquants :
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_17-2.mp4" controls width="560px" height="315px" />
        </div>
        <p>
          L’équipe animation peut également décider que vous voyiez par défaut l’ensemble des
          publications. Vous n’avez pas alors à vous abonner à d’autres comptes.
        </p>
        <H2 id="title1.5">5. Interactions et notifications</H2>
        <p>
          Vous pouvez interagir avec d’autres profils en répondant, reweezant ou aimant leurs
          publications.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_04.mp4" controls width="560px" height="270px" />
        </div>
        <p>Pour voir le fil de discussion, il vous suffit de cliquer sur la conversation :</p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_04-1.mp4" controls width="560px" height="270px" />
        </div>
        <p>
          En cliquant sur « Notifications », vous pouvez visionner les interactions avec votre
          profil (savoir qui vous suit) ou bien les réponses et réactions à vos publications.
          <br />
          Par exemple, vous avez publié un communiqué de presse ? Vous pouvez voir combien de fois
          il a été reweezé ou aimé et par quels profils.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_05.mp4" controls width="560px" height="270px" />
        </div>
        <H2 id={"title1.6"}>6. Tendances pour vous</H2>
        <p>
          Vous pouvez également observer les tendances du moment qui vous permettront de voir quels
          sont les sujets les plus abordés sur Weezer. Lorsque vous cliquez sur ces tendances, vous
          verrez les Weezes qui les comportent.
        </p>
        <Image src="/help/capture_06.jpg" width="600px" />
        <p>
          <strong>Bon exercice !</strong>
        </p>
        <p>
          <strong>
            Une question ou une proposition d’amélioration ? Contactez-nous :{" "}
            <a href="mailto:weezer@techlyde.com">weezer@techlyde.com</a>
          </strong>
        </p>
        <SectionTitle>Fonctionnalités spécifiques de l’équipe animation</SectionTitle>
        <Intro>
          Connectez-vous à votre univers avec les identifiants transmis par Crisalyde. En cliquant
          sur « Accueil », vous visionnez l’univers de l’exercice : c’est ici qu’apparaitront
          l’ensemble des Weezes publiés par l’équipe animation et le reste des joueurs.
          Contrairement aux joueurs, l’animateur a également accès à l’interface « Administration ».
        </Intro>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_07.mp4" controls width="560px" height="300px" />
        </div>
        <H2 id="titleA">A. Mise en place de l’univers de l’exercice / de la formation </H2>
        <p>Crisalyde vous donne accès à un univers fermé qui vous permet d’animer un exercice.</p>
        <H3 id="titleA-1">1. Personnalisation de l’univers</H3>
        <p>
          En tant qu’Administrateur, vous pouvez demander une personnalisation de l'univers à
          Crisalyde. Vous avez ainsi la possibilité de choisir les couleurs de l’interface, le logo
          ainsi que le nom de la plateforme lors de la création de votre univers. Vous pouvez
          adresser votre demande à <a href="mailto:weezer@techlyde.com">weezer@techlyde.com</a>.
        </p>
        <H3 id="titleA-2">2. Création des profils d’utilisateurs</H3>
        <p>
          Vous pouvez créer un nombre illimité de profils Weezer qui vous permettront d’animer
          chacun de vos exercices. Accédez à votre espace « Administration » et cliquez sur «
          Ajouter un utilisateur » :
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_08.mp4" controls width="560px" height="300px" />
        </div>
        <p>
          <u>Vous pouvez créer trois types d’utilisateur : </u>
          <br />
          Le nom d’utilisateur doit être unique, peu importe le type d’utilisateur. Si le nom est
          déjà utilisé, un message d’erreur apparaîtra :
        </p>
        <Image src="/help/capture_09-1.jpg" width="400px" />
        <p>
          Les noms d'utilisateur peuvent uniquement être composés de caractères alphanumériques
          (lettres de A à Z, nombres de 0 à 9) et de traits de soulignement « _ ». Vérifiez que le
          nom d'utilisateur (login) ne contient aucun symbole, tiret ni espace.
        </p>
        <p>
          Vous pouvez leur attribuer des abonnements fictifs : nombres de Weezes publiés, nombres
          d’abonnés et nombre d’abonnements. Ces chiffres vont ensuite s’incrémenter en fonction de
          l’activité du compte : il s’agit donc d’un point de départ.
        </p>
        <Image src="/help/capture_09-3.jpg" width="400px" />
        <p style={{ marginLeft: "1rem" }}>
          <i>
            <b>Joueur :</b>
          </i>{" "}
          le joueur accède à votre univers Weezer. Il voit vos diverses publications et peut y
          réagir. Il peut également publier de nouveaux Weezes.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_09.mp4" controls width="560px" height="300px" />
        </div>
        <p>
          Vous pouvez créer un compte joueur en mentionnant soit votre adresse email d’animateur,
          soit l’adresse email personnelle du joueur. Si vous n’avez pas spécifié de mot de passe,
          un mot de passe sera attribué par défaut. Les identifiants de connexion seront alors
          envoyés à cette adresse. Si vous renseignez le mot de passe, aucun email ne sera envoyé.
          Vous pouvez le modifier le mot de passe à tout moment (V. Gestion des profils).
        </p>
        <Image src="/help/capture_09-2.jpg" width="700px" />
        <Remarque>
          <strong>Remarque</strong>
          <br /> Le délai de communication des identifiants aux joueurs dépend de vos objectifs
          pédagogiques : communiquer les identifiants en avance aux joueurs leur permet de se
          familiariser avec l’interface, mais ne les leur communiquer qu’en début d’exercice peut
          vous permettre d’augmenter leur niveau de stress. Les joueurs peuvent modifier leur profil
          (photo, lieu, biographie) à tout moment. Vous pouvez les laisser personnaliser leur profil
          ou bien leur demander de ne pas le modifier. Spécifiez cette possibilité ou interdiction
          dans votre convention d’exercice.
        </Remarque>
        <p style={{ marginLeft: "1rem" }}>
          <i>
            <b>Animateur :</b>
          </i>{" "}
          utilisateur animant l’exercice. Nous vous conseillons de créer un profil indépendant pour
          chacun de vos animateurs. Votre équipe animation pourra ainsi être plus réactive au cours
          de l'exercice. L’animateur devra être abonné à tous les comptes susceptibles de publier
          (joueurs et dummies) afin de pouvoir suivre leurs publications.
        </p>
        <Remarque>
          <strong>Remarque</strong>
          <br /> Plus tôt vous envoyez les identifiants de connexion à vos animateurs, mieux ils
          pourront se familiariser avec l’interface et la tester. Cependant, afin de garantir un
          vrai suivi des publications, notamment pour le RETEX, il n’est pas possible de supprimer
          les publications individuellement. Vous pouvez en revanche archiver (V. Fin de l’exercice
          et archivage) la phase de test : l’ensemble des Weezes disparaîtront alors.
        </Remarque>
        <p style={{ marginLeft: "1rem" }}>
          <i>
            <b>Dummy / "compte d'emprunt" :</b>
          </i>{" "}
          profil fictif dont les animateurs pourront prendre le contrôle (V. Animation) afin de
          publier « en tant que ». Crisalyde met à votre disposition par défaut un certain nombre de
          comptes réalistes d’institutions et de médias. Vous pouvez les compléter par des comptes
          d’acteurs susceptibles de réagir à votre crise (consommateurs, concurrents, acteurs
          publics ou privés, personnalités médiatiques, dirigeants...). Seuls les profils animateurs
          ont la possibilité de publier « en tant que ».
        </p>
        <Remarque>
          <strong>Remarque</strong>
          <br /> N’hésitez pas à nous signaler des comptes importants manquants (grand média,
          personnage public important, grosse société…). Nous compléterons la base de données
          commune.
        </Remarque>
        <H3 id="titleA-3">3. Gestion des abonnements</H3>
        <p>
          Vous pouvez décider d’activer ou de désactiver le fonctionnement par abonnement,
          c’est-à-dire que les Weezes soient visibles ou non par tous :
        </p>
        <p>Option 1 : Weezes non visibles par tous</p>
        <p>
          Tous les profils joueurs et animateurs devront être abonné à l’ensemble des profils actifs
          afin de visualiser leurs publications :
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_09-4.mp4" controls width="560px" height="300px" />
        </div>
        <Remarque>
          <strong>Remarque</strong>
          <br /> Vous pouvez choisir de n’abonner les joueurs qu’à un certain nombre de profils et
          les inviter à vérifier s’ils sont bien abonnés aux comptes qui leur semblent les plus
          pertinents (Cf. Manuel Joueur / Gestion des abonnements).
        </Remarque>
        <p>Option 2 : Weezes visibles par tous</p>
        <p>
          Vous n’avez pas besoin que les profils joueurs et animateurs soient abonnés à l’ensemble
          des profils actifs. Ils verront tous par défaut l’ensemble des publications :
        </p>
        <Remarque>
          <strong>Remarque</strong>
          <br /> A mentionner dans votre convention d’exercice.
        </Remarque>
        <H3 id="titleA-4">4. Gestion des profils</H3>
        <p>
          Vous pouvez afficher l’ensemble de vos utilisateurs par type via l’interface « Recherche
          ». Cela vous permet également d’accéder aux profils pour les modifier.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_10.mp4" controls width="560px" height="300px" />
        </div>
        <Remarque>
          <strong>Remarque</strong>
          <br /> Les profils sont rattachés à votre univers et non pas à votre exercice. Nous vous
          conseillons de modifier le mot de passe des joueurs à la fin de chaque exercice afin de
          s’assurer qu’ils n’auront plus accès à votre univers a posteriori. Dans le cas contraire,
          ils auront toujours la possibilité de se connecter à tout moment et publier des Weezes
          dans votre univers/dans un exercice en cours. Une fonction de désactivation de compte est
          en cours de développement.
        </Remarque>
        <H2 id="titleB">B. Animation</H2>
        <H3 id="titleB-1">1. Accueil</H3>
        <p>
          En cliquant sur « Accueil », vous visionnez l’univers de l’exercice : c’est ici
          qu’apparaîtront l’ensemble des Weezes publiés par l’équipe animation et le reste des
          joueurs.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_12.mp4" controls width="560px" height="300px" />
        </div>
        <H3 id="titleB-2">2. Publications de Weezes / Publier « en tant que »</H3>
        <p>
          L’animateur a accès à l’ensemble de la base de données des profils. Il peut donc
          sélectionner un compte d’emprunt pour publier en tant que ce compte. Vous pouvez trouver
          rapidement un compte d’emprunt via la barre de recherche.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_13.mp4" controls width="560px" height="300px" />
        </div>
        <p>
          Vous pouvez publier des messages soit dans la boîte de dialogue « Quoi de neuf ? » soit en
          cliquant sur le bouton « Weezer » en haut à droite.
        </p>
        <p>Vos messages peuvent comporter 280 caractères incluant :</p>
        Des hashtags #
        <ul>
          <li>Des émoticônes</li>
          <li>Des identifications @nomducompte</li>
          <li>Des photos en pièces jointes</li>
          <li>Des vidéos en mettant leur lien Youtube</li>
          <li>Des liens pointant vers des sites</li>
        </ul>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_16.mp4" controls width="560px" height="300px" />
        </div>
        <p>
          Sponsoriser votre Weeze vous permet d’avoir plus de visibilité dans l’ensemble des
          publications. Un Weeze sponsorisé est vu par tout le monde, qu’ils soient abonnés à votre
          profil ou non. Cela permet notamment de simuler des campagnes de publicité.
        </p>
        <Remarque>
          <strong>Remarque</strong>
          <br /> Vous pouvez utiliser cette fonction pour « polluer » le flux des joueurs comme dans
          la vraie vie, par exemple en publiant des publicités qui n’ont aucun lien avec la crise.
          Cela permet également de simuler un concurrent qui tirerait partie de votre crise pour
          faire une publicité liée à cet évènement.
        </Remarque>
        <Image src="/help/capture_16.jpg" width="600px" />
        <Remarque>
          <strong>Remarque</strong>
          <br /> Vous avez publié « en tant que » et votre Weeze n’apparaît pas dans votre flux des
          publications ? Vérifiez que vous êtes bien abonné à ce profil !
        </Remarque>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_17-1.mp4" controls width="560px" height="300px" />
        </div>
        <Remarque>
          <strong>Remarque</strong>
          <br /> En cliquant uniquement sur « Rechercher », vous accédez à la liste complète des
          profils et pouvez vous abonner aux profils manquants :
        </Remarque>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_17-2.mp4" controls width="560px" height="300px" />
        </div>
        <H3 id="titleB-3">3. Interactions et notifications</H3>
        <p>
          Vous pouvez interagir avec d’autres profils en répondant ou reweezant. Il n’est par contre
          pas possible d’aimer leurs publications.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_18.mp4" controls width="560px" height="300px" />
        </div>
        <p>
          En cliquant sur « Notifications », vous pouvez visionner les interactions avec votre
          profil (savoir qui vous suit) ou bien les réponses et réactions à vos publications. Pour
          autant, cela n’est valable que pour vos publications avec votre compte administrateur.
          Vous ne verrez pas les notifications d’interactions avec les Weezes que vous aurez publié
          « en tant que ».
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_19.mp4" controls width="560px" height="300px" />
        </div>
        <H3 id="titleB-4">4. Gestion des tendances </H3>
        <p>
          Vous pouvez créer une tendance montante au cours de la simulation en publiant un nombre
          important de Weezes avec les mêmes hashtags #.
        </p>
        <H2 id="titleC">C. Fin de l'exercice : l'archivage</H2>
        <p>
          Une fois votre exercice terminé, vous pouvez l’archiver. L’ensemble des Weezes disparaîtra
          de votre univers.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_20.mp4" controls width="560px" height="300px" />
        </div>
        <p>
          Vous pouvez télécharger votre archive sous format Excel. Vous aurez ainsi l’historique des
          publications sur Weezer pour les réutiliser lors d’un prochain exercice.
        </p>
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <ReactPlayer url="/help/capture_21.mp4" controls width="560px" height="300px" />
        </div>
        <Remarque>
          <strong>Remarque</strong>
          <br /> Les profils joueurs sont rattachés à votre univers et non pas à votre exercice :
          ils ne seront pas supprimés lors de l’archivage. Nous vous conseillons de modifier le mot
          de passe des joueurs à la fin de chaque exercice afin de s’assurer qu’ils n’auront plus
          accès à votre univers a posteriori. Dans le cas contraire, ils auront toujours la
          possibilité de se connecter à tout moment et publier des Weezes dans votre univers.
        </Remarque>
        <p>
          <strong>Bonne animation !</strong>
        </p>
        <p>
          <strong>
            Une question ou une proposition d’amélioration ? Contactez-nous :{" "}
            <a href="mailto:weezer@techlyde.com">weezer@techlyde.com</a>
          </strong>
        </p>
      </Container>
      <ScrollButton scrollStepInPx="60" delayInMs="5" />
    </React.Fragment>
  );
};

export { SectionTitle, Intro, Remarque };
export default HelpPage;
