import { extendObservable } from "mobx";
import { fromPromise } from "mobx-utils";

export default function fromPromiseWithProgress(promiseWithProgress) {
  const progressMonitor = promiseWithProgress.progressMonitor;
  return extendObservable(
    fromPromise(promiseWithProgress),
    {
      get progress() {
        return (progressMonitor && progressMonitor.value) || 0;
      },
    },
    {},
    { deep: false }
  );
}
