import { useLocalStore } from "mobx-react-lite";
import useScrollListener from "./useScrollListener";

function useScrollBottomReachedListener(onBottomReached, throttleDelay = 400) {
  const store = useLocalStore(() => ({
    scrollPosition: 0,
    updateScrollPosition(scrollY) {
      store.scrollPosition = scrollY;
    },
  }));
  const handleScroll = () => {
    if (
      document.body.scrollHeight - window.scrollY - window.innerHeight < 100 &&
      store.scrollPosition < window.scrollY
    ) {
      onBottomReached && onBottomReached();
    }
    store.updateScrollPosition(window.scrollY);
  };
  useScrollListener(handleScroll, throttleDelay);
  return store;
}

export default useScrollBottomReachedListener;
