import { getServerUrl } from "utils/fetch";

/**
 *
 * @param {Object} query
 * @param {string|number} query.terms
 * @param {string|number[]} [query.roles]
 * @param {string|number} [query.client]
 * @param {number} [page=0]
 * @param {number} [size=20]
 */
export function searchUsers({ terms, roles, client, status }, page = 0, size = 20) {
  return {
    url: getServerUrl("/admin/users/search"),
    method: "POST",
    body: JSON.stringify({ terms, roles, client, page, size, status }),
  };
}

/**
 * @export
 * @param {string|number} id
 * @returns
 */
export function lock(id) {
  return {
    url: getServerUrl(`/admin/users/${id}/lock`),
    method: "POST",
  };
}

/**
 * @export
 * @param {string|number} id
 * @returns
 */
export function unlock(id) {
  return {
    url: getServerUrl(`/admin/users/${id}/unlock`),
    method: "POST",
  };
}

/**
 *
 * @export
 * @param {string|number} ids
 * @returns
 */
export function getUser(id) {
  return {
    url: getServerUrl(`/admin/users/${id}`),
    method: "GET",
  };
}

/**
 *
 * @export
 * @param {*} user
 * @returns
 */
export function createUser(user) {
  const userData = {
    photo: user.photo,
    role: user.role,
    client: user.client,
    login: user.login,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    certified: user.certified,
    bio: user.bio,
    username: user.username,
    weezsCount: user.weezsCount,
    subscribersCount: user.subscribersCount,
    subscriptionsCount: user.subscriptionsCount,
    location: user.location,
    password: user.password,
    followees: Array.from(user.subscriptions),
    category: user.category,
  };
  return {
    url: getServerUrl("/admin/users/create"),
    method: "POST",
    body: JSON.stringify(userData),
  };
}

export function updateUser(id, user) {
  const userData = {
    photo: user.photo,
    login: user.login,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    certified: user.certified,
    bio: user.bio,
    weezsCount: user.weezsCount,
    subscribersCount: user.subscribersCount,
    subscriptionsCount: user.subscriptionsCount,
    location: user.location,
    password: user.password,
    followees: Array.from(user.subscriptions),
    category: user.category,
    role: user.role,
  };
  return {
    url: getServerUrl(`/admin/users/${id}/update`),
    method: "POST",
    body: JSON.stringify(userData),
  };
}

export function checkUser({ username, clientId }) {
  if (!username) {
    return false;
  }
  return {
    url: getServerUrl("/admin/users/check", { username, client: clientId }),
    method: "GET",
  };
}

export function getCurrentExercice() {
  return {
    url: getServerUrl("/admin/exercices/current"),
    method: "GET",
  };
}

export function getArchivedExercices(pageNumber, pageSize) {
  return {
    url: getServerUrl("/admin/exercices/archived", { page: pageNumber, size: pageSize }),
    method: "GET",
  };
}

export function terminateCurrentExercice(name) {
  return {
    url: getServerUrl("/admin/exercices/terminate", { name }),
    method: "POST",
  };
}

export function fetchSettings() {
  return {
    url: getServerUrl("/admin/settings"),
    method: "GET",
  };
}

export function saveSettings({ allSponsored }) {
  return {
    url: getServerUrl("/admin/settings"),
    method: "PUT",
    body: JSON.stringify({ allSponsored }),
  };
}

/**
 * @typedef {Object} QueryOptions
 * @property {string} url
 * @property {string} method
 * @property {string} [body]
 */
