import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddSequencePage from "./AddSequencePage";
import SequencePage from "./SequencePage";
import SequenceDetailPage from "./SequenceDetailPage";
import EditSequence from "./EditSequence";

export default class SequenceLayout extends Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/sequences" exact component={SequencePage} />
        <Route path="/admin/sequences/add" component={AddSequencePage} />
        <Route path="/admin/sequences/:id/edit" component={EditSequence} />
        <Route path="/admin/sequences/:id" component={SequenceDetailPage} />
        <Redirect to="/admin/sequences" />
      </Switch>
    );
  }
}
