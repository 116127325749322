import React from "react";
import { Autocomplete } from "components";
import matchSorter from "match-sorter";
import { getClients } from "services/clients";
import { inject } from "mobx-react";

function renderClientItem(user, query) {
  return <div>{user.name}</div>;
}
@inject("fetchService")
class ClientAutocomplete extends React.Component {
  state = {
    clients: [],
  };

  componentDidMount() {
    const { fetchService } = this.props;
    fetchService
      .fetch(getClients())
      .then(clients => {
        this.setState({
          clients,
        });
      })
      .catch(error => {
        // FIXME notif ?
        console.error(error);
      });
  }

  render() {
    const { field } = this.props;
    const { clients } = this.state;
    if (!clients.length) {
      return null;
    }
    return (
      <Autocomplete
        items={clients}
        placeholder="Sélectionnez un client ..."
        itemToString={item => (item ? `${item.name}` : "")}
        renderItem={renderClientItem}
        filter={(items, query) =>
          matchSorter(items, query, {
            keys: ["name"],
            threshold: matchSorter.rankings.STARTS_WITH,
          })
        }
        selectedItem={field.value ? clients.find(client => field.value === client.id) : null}
        onSelect={item => {
          field.setValue(item ? item.id : null);
        }}
        error={field.errorMessage}
        disabled={field.disabled}
      />
    );
  }
}

export default ClientAutocomplete;
