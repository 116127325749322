import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as clientsApi from "services/clients";
import styled from "styled-components";
import { cancelEvent } from "utils";
import ClientForm from "./ClientForm";
import clientFormDataBuilder from "./clientFormDataBuilder";
import { t } from "utils/i18n";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

@inject("authStore", "routingStore", "viewStore", "fetchService")
@observer
class AddClientPage extends React.Component {
  @observable
  createClientRequest = null;

  form = clientFormDataBuilder({
    authStore: this.props.authStore,
  });

  @action
  onSubmit = cancelEvent(evt => {
    if (!this.form.isValid) {
      this.form.setTouched();
    } else {
      const { routingStore, viewStore, fetchService } = this.props;

      const data = this.form.values;

      this.createClientRequest = fromPromise(fetchService.fetch(clientsApi.createClient(data)));
      this.createClientRequest
        .then(() => {
          routingStore.history.push("/admin/clients");
          viewStore.pushNotification("Client créé avec succès.");
        })
        .catch(e => {
          // FIXME NOTNEEDED
          console.error(e);
        });
    }
  });

  render() {
    const { authStore } = this.props;
    const { currentUser } = authStore;

    return (
      <Container>
        <Helmet>
          <title>{t("add_customer_title")}</title>
        </Helmet>
        <h1>
          <Link to="/admin/clients">{t("admin_customers")}</Link> &gt; {t("create")}
        </h1>
        <ClientForm
          form={this.form}
          currentUser={currentUser}
          requestObject={this.createClientRequest}
          onSubmit={this.onSubmit}
        />
      </Container>
    );
  }
}

export default AddClientPage;
