import { themeGet } from "@styled-system/theme-get";
import { DisplayName } from "app/common";
import { Button, OnClickOutside } from "components";
import { changeLanguage } from "i18next";
import { MobXProviderContext, useObserver } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdExitToApp, MdLanguage, MdPermIdentity } from "react-icons/md";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import styled, { keyframes } from "styled-components";
import styledMap from "styled-map";
import { marginVertical, paddingHorizontal, resetList } from "utils/mixins";

const openMenuAnimation = keyframes`
  0% {
    transform:scale(0);
  }

  100% {
    transform:scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${themeGet("colors.white")};
  width: 200px;
  /* min-height: 300px; */
  top: 50px;
  right: -10px;
  padding: 0px;
  justify-content: flex-start;
  z-index: ${themeGet("zIndex.highest")};
  box-shadow: ${themeGet("shadows.default")};
  transform-origin: 100% 0%;
  animation: ${openMenuAnimation} 200ms ease-in-out;

  ul {
    ${resetList};
    width: 100%;
  }

  button,
  a {
    width: 100%;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-decoration: none;
    text-align: left;
    ${paddingHorizontal("10px")};
    font-weight: 600;
    font-size: 1rem;
    color: ${themeGet("colors.veryStrongGrey")};

    &:hover {
      background-color: ${themeGet("colors.backgroundGrey")};
      color: ${themeGet("colors.primary")};
    }

    svg {
      margin-right: 10px;
    }
  }
`;

const Separator = styled.hr`
  border: none;
  height: 1px;
  background-color: ${themeGet("colors.veryLightGrey")};
  width: 100%;
  ${marginVertical("10px")};
  box-shadow: ${themeGet("shadows.default")};
`;

const StyledDisplayName = styled(DisplayName)`
  margin: 10px;
  margin-bottom: 0px;
`;

const Username = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${themeGet("colors.grey")};
  margin: 10px;
`;

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-bottom: 15px solid white;
  position: absolute;
  top: -15px;
  right: 12px;
`;

const ClickableFlag = styled(Flag)`
  &:hover {
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.4);
  }
  box-shadow: ${styledMap`
    selected: 0px 0px 2px 2px rgba(52, 152, 219, 0.4);
    default: unset;
  `};
`;

const UserMenu = ({ visible, closeMenu }) => {
  const { authStore } = React.useContext(MobXProviderContext);
  const { t, i18n } = useTranslation();
  return useObserver(() => (
    <OnClickOutside action={closeMenu}>
      <Container>
        <ArrowUp />
        <StyledDisplayName user={authStore.currentUser} size="default" />
        <Username>@{authStore.currentUser.username}</Username>
        <Separator />
        <ul>
          <li>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                padding: "0 10px",
                color: "#555555",
                lineHeight: "40px",
                cursor: "pointer",
              }}>
              <MdLanguage size={25} />
              <ClickableFlag
                code={"fr"}
                width="24"
                onClick={() => {
                  i18n.changeLanguage("fr");
                  changeLanguage("fr");
                }}
                selected={i18n.language === "fr"}
              />
              |
              <ClickableFlag
                code={"gb"}
                width="24"
                onClick={() => i18n.changeLanguage("en")}
                selected={i18n.language === "en"}
              />
            </div>
          </li>
          {!authStore.currentUser.isSuperAdmin && (
            <li>
              <Link
                onClick={closeMenu}
                to={{
                  pathname: `/${authStore.currentUser.username}`,
                  search: `?id=${authStore.currentUser.id}`,
                }}>
                <MdPermIdentity size={25} />
                {t("profile.title")}
              </Link>
            </li>
          )}
          <li>
            <Button
              empty
              onClick={() => {
                closeMenu();
                authStore.logout();
              }}>
              <MdExitToApp size={25} />
              {t("logout")}
            </Button>
          </li>
        </ul>
      </Container>
    </OnClickOutside>
  ));
};

export default React.memo(UserMenu);
