import { themeGet } from "@styled-system/theme-get";
import { Button, FormSection, LoadingContent, Modal } from "components";
import DateFormat from "components/DateFormat";
import { get, set } from "lodash";
import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  MdAccessAlarm,
  MdAccountCircle,
  MdAddCircleOutline,
  MdDateRange,
  MdDelete,
  MdLink,
  MdLocalPolice,
  MdPerson,
  MdVpnKey,
} from "react-icons/md";
import styled from "styled-components";
import { CLONE_ACCESS_TYPES } from "utils/constants";
import { t } from "utils/i18n";
import { lightenColor } from "utils/mixins";
import { CloneCreateModals } from "./CloneCreateModals";
import { CloneDeletionModal } from "./CloneDeletionModal";
import * as clonesApi from "services/clones";

import isSameDay from "date-fns/isSameDay";
import isAfter from "date-fns/isAfter";

export const ClonesPage = inject(
  "viewStore",
  "authStore",
  "fetchService"
)(({ viewStore, authStore, fetchService }) => {
  const [rowData, setRowData] = useState([]);
  const [showCloneAccessInfo, setShowCloneAccessInfo] = useState(false); // boolean & cloneData
  const [showCloneCreationModals, setShowCloneCreationModals] = useState(false);
  const [showCloneDeletionModal, setShowCloneDeletionModal] = useState(false); // number & boolean
  const [isLoading, setLoading] = useState(false); // String & boolean
  const clientPath = get(authStore, ["currentUser", "client", "clientPath"]);

  useEffect(() => {
    setLoading("Chargement en cours");
    fetchService
      .fetch(clonesApi.getClones())
      .then(setRowData)
      .then(() => setLoading(false));
  }, []);

  const handleCreateClone = async (data) => {
    try {
      setLoading("Création en cours");
      setShowCloneCreationModals(false);
      await fetchService.fetch(clonesApi.createClone(data));
      viewStore.pushNotification("Clone créé avec succès.");
      await fetchService.fetch(clonesApi.getClones()).then(setRowData);
    } catch (e) {
      console.error(e);
      viewStore.pushNotification("Echec de la création du clone", "error", 4000);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClone = async (cloneId) => {
    try {
      setLoading("Suppression en cours");
      setShowCloneDeletionModal(0);
      await fetchService.fetch(clonesApi.deleteClone(cloneId));
      viewStore.pushNotification("Clone supprimé avec succès.");
    } catch (e) {
      viewStore.pushNotification("Echec de la suppression");
    }
    try {
      await fetchService.fetch(clonesApi.getClones()).then(setRowData);
    } finally {
      setLoading(false);
    }
  };

  const handleClickAccessType = (selectedClone) => {
    const cloneURI = formatCloneUrl(clientPath, selectedClone.path);
    selectedClone.uri = cloneURI;
    if (selectedClone.accessType !== "LOGIN") {
      const animatorPassword = get(selectedClone, "animator.password", "");
      const animatorLink = `${cloneURI}/auth/nologin?u=${get(
        selectedClone,
        "animator.username"
      )}&pw=${Buffer.from(animatorPassword).toString("base64")}`;

      const userPassword = get(selectedClone, "user.password", "");
      const userLink = `${cloneURI}/auth/nologin?u=${get(
        selectedClone,
        "user.username"
      )}&pw=${Buffer.from(userPassword).toString("base64")}`;
      set(selectedClone, "links.animator", animatorLink);
      set(selectedClone, "links.user", userLink);
    }
    setShowCloneAccessInfo(selectedClone);
  };

  const handleClickDeleteClone = (cloneId) => {
    setShowCloneDeletionModal(cloneId);
  };

  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    viewStore.pushNotification("Lien copié avec succès.");
  };

  return (
    <Container>
      <h1>{t("clone_admin")}</h1>
      <FormSection
        ActionButtonUpper={() => (
          <ActionButtonContainer>
            <AddButton full="true" admin onClick={() => setShowCloneCreationModals(true)}>
              <MdAddCircleOutline size={20} />
              {t("clone_create")}
            </AddButton>
          </ActionButtonContainer>
        )}>
        <Table>
          <thead>
            <tr>
              <th width={25}>#</th>
              <th>
                <MdLink size={20} style={{ transform: [{ rotateY: "45deg" }] }} /> Chemin
              </th>
              <th width={130}>
                <MdDateRange size={20} /> Créé le
              </th>
              <th width={250}>
                <MdVpnKey size={20} /> Type d'accès
              </th>
              <th width={100}>
                <MdAccessAlarm size={20} /> Validité
              </th>
              <th width={100} className="right" />
            </tr>
          </thead>
          <tbody>
            {generateRows(rowData, handleClickAccessType, handleClickDeleteClone, clientPath)}
          </tbody>
        </Table>
      </FormSection>
      {showCloneAccessInfo && (
        <Modal
          isOpen={!!showCloneAccessInfo}
          onRequestClose={() => setShowCloneAccessInfo(false)}
          title={"Information de connexion"}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 10,
            }}>
            <div style={{ fontWeight: 600, padding: 5, width: 130 }}>Accès animateur:</div>
            <div style={{ paddingLeft: 10 }}>
              {showCloneAccessInfo.accessType === "LOGIN" ? (
                <span>
                  {`${get(showCloneAccessInfo, "animator.username")} // ${get(
                    showCloneAccessInfo,
                    "animator.password"
                  )}`}
                </span>
              ) : (
                <Button
                  admin
                  onClick={() => copyLink(`https://${showCloneAccessInfo.links.animator}`)}>
                  <MdLocalPolice size={18} />
                  COPIER LE LIEN
                </Button>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: 10,
            }}>
            <div style={{ fontWeight: 600, padding: 5, width: 130 }}>Accès joueur:</div>
            <div style={{ paddingLeft: 10 }}>
              {showCloneAccessInfo.accessType === "LOGIN" ? (
                <span>
                  {`${get(showCloneAccessInfo, "user.username")} // ${get(
                    showCloneAccessInfo,
                    "user.password"
                  )}`}
                </span>
              ) : (
                <Button admin onClick={() => copyLink(`https://${showCloneAccessInfo.links.user}`)}>
                  <MdPerson size={20} />
                  COPIER LE LIEN
                </Button>
              )}
            </div>
          </div>
        </Modal>
      )}

      {showCloneCreationModals && (
        <CloneCreateModals
          onClose={() => setShowCloneCreationModals(false)}
          onConfirm={(data) => handleCreateClone(data)}
          clientPath={clientPath}
        />
      )}
      {!!showCloneDeletionModal && (
        <CloneDeletionModal
          onClose={() => setShowCloneDeletionModal(0)}
          onConfirm={() => handleDeleteClone(showCloneDeletionModal)}
        />
      )}
      {!!isLoading && <LoadingContent float label={isLoading} />}
    </Container>
  );
});

export const formatCloneUrl = (clientPath, clonePath) => {
  return `${window.location.hostname}/${clientPath}/clones/${clonePath}`;
};

const generateRows = (data, onClickAccessType, onClickDelete, clientPath) => {
  if (data && data.length > 0) {
    return data.map((cloneData, index) => {
      const { id, path, createdOn, expirationDate, accessType } = cloneData;
      return (
        <tr key={id}>
          <td>{index + 1}</td>
          <td>
            <ClonePathLink
              target="_blank"
              href={`https://${formatCloneUrl(clientPath, path)}`}
              rel="noreferrer">
              {formatCloneUrl(clientPath, path)}
            </ClonePathLink>
          </td>
          <td>
            <DateFormat pattern="dd/MM/yyyy">{createdOn}</DateFormat>
          </td>
          <td>
            <AccessTypeCell onClick={() => onClickAccessType(cloneData)}>
              {accessType}
            </AccessTypeCell>
          </td>
          <td>
            <IsExpired expireOn={expirationDate}>
              <DateFormat pattern="dd/MM/yyyy">{expirationDate}</DateFormat>
            </IsExpired>
          </td>
          <td className="right">
            <Button admin iconButton onClick={() => onClickDelete(id)}>
              <MdDelete size={20} />
            </Button>
          </td>
        </tr>
      );
    });
  } else {
    return (
      <tr>
        <td colSpan={6}>
          <div>Aucun clone disponible</div>
        </td>
      </tr>
    );
  }
};

const IsExpired = ({ children, expireOn }) => {
  const now = new Date();
  const expirationDate = new Date(expireOn);
  const styles = {};
  if (isSameDay(expirationDate, now)) {
    styles.fontWeight = 600;
    styles.color = "orange";
  } else if (!isAfter(expirationDate, now)) {
    styles.fontWeight = 600;
    styles.color = "red";
  }
  return <div style={styles}>{children}</div>;
};

const AccessTypeCell = ({ children, onClick }) => {
  return (
    <AccessTypeDiv onClick={onClick}>
      {children === CLONE_ACCESS_TYPES.LOGIN ? <MdAccountCircle size={20} /> : <MdLink size={20} />}{" "}
      {t(`clone_access_${children}`)}
    </AccessTypeDiv>
  );
};

const AccessTypeDiv = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
const ClonePathLink = styled.a`
  text-decoration: none;
  color: inherit;
  :hover {
    text-decoration: underline;
    color: -webkit-link;
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AddButton = styled(Button)`
  align-self: center;
  margin-right: 30px;
`;

const ActionButtonContainer = styled.div`
  padding: 10px;
`;

export const Table = styled.table`
  font-size: 13px;
  border-collapse: collapse;
  width: 100%;
  margin-top: 2rem;
  td,
  th {
    /* border: 1px solid #ddd; */
    padding: 5px;
    max-width: 250px;
    text-align: left;
    vertical-align: center;
    &.right {
      text-align: right;
    }
  }
  th {
    color: ${themeGet("colors.veryStrongGrey")};
    font-size: 1rem;
  }
  tbody > tr:nth-child(even) {
    background-color: ${lightenColor(0.12, "colors.veryLightGrey")};
  }
`;
