import React from "react";
import { useLocalStore } from "mobx-react-lite";
import { Field } from "utils/forms";
import { MobXProviderContext } from "mobx-react";
import { fromPromise } from "mobx-utils";
import { action, runInAction } from "mobx";
import { searchUsers } from "services/search";

const useSearchStore = processResults => {
  const { fetchService } = React.useContext(MobXProviderContext);
  const store = useLocalStore(() => ({
    query: new Field(),
    allResultsLoaded: false,
    searchQueryRequest: null,
    searchPage: 0,
    terms: "",
    incrementSearchPage() {
      store.searchPage = store.searchPage + 1;
    },
    results: [],
    replaceResults(values) {
      store.results = processResults ? processResults(values) : values;
    },
    pushResults(values) {
      store.results.push(...(processResults ? processResults(values) : values));
    },
    get resultsCount() {
      return store.results.length;
    },
    loadResults(terms) {
      !store.allResultsLoaded &&
        (() => {
          if (store.searchPage > 0) {
            store.searchQueryRequest = fromPromise(
              fetchService.fetch(searchUsers(terms, store.searchPage))
            );
            store.searchQueryRequest.then(
              action(values => {
                store.pushUsers(values);
                store.allResultsLoaded = values.totalElements === store.usersCount;
              })
            );
          } else {
            store.searchQueryRequest = fromPromise(fetchService.fetch(searchUsers(terms)));
            store.searchQueryRequest.then(
              action(values => {
                store.replaceResults(values);
                store.allResultsLoaded = values.totalElements === store.resultsCount;
              })
            );
          }
        })();
    },
    loadMoreResults() {
      store.loadResults(store.terms);
    },
    resetSearch() {
      runInAction(() => {
        store.searchPage = 0;
        store.allResultsLoaded = false;
      });
    },
  }));
  return store;
};

export default useSearchStore;
