import loginBackground from "assets/login_background.jpg";
import logoChrisalyde from "assets/crisalyde-large-white.png";
import { inject } from "mobx-react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
// import { t } from "utils/i18n";
import Notifications from "app/layout/Notifications";
import { useLocation } from "react-router";
import qs from "qs";
import { LoadingContent } from "components";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  background-image: url(${loginBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const LogoImg = styled.img`
  width: 300px;
  position: absolute;
  right: 50px;
  top: 50px;
`;

const ContentContainer = styled.div`
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const LinkLoginPage = inject(
  "authStore",
  "routingStore",
  "viewStore"
)(({ authStore, routingStore, viewStore }) => {
  const location = useLocation();
  const params = qs.parse(location.search);
  const { t } = useTranslation();
  const validateClientPath = async () => {
    if (!routingStore.basename) {
      // no path means we are accessing the admin
      return;
    }
    const isClientPathValid = await authStore.validateClientPath(
      routingStore.basename,
      routingStore.isClone
    );
    if (!isClientPathValid) {
      viewStore.pushNotification(
        `Le chemin "${routingStore.basename}" n'existe pas`,
        "error",
        5000
      );
    }
  };

  useEffect(() => {
    validateClientPath();
    setTimeout(
      () =>
        authStore
          .login(
            get(params, ["?u"], ""),
            Buffer.from(get(params, ["pw"], ""), "base64").toString(),
            routingStore.clientPath,
            routingStore.clonePath
          )
          .catch(() => {
            routingStore.push("/");
            viewStore.pushNotification(`Echec de l'authentification automatique`, "error", 5000);
          }),
      400
    );
  }, []);
  return authStore.isConnected ? (
    <Redirect to="/" />
  ) : (
    <BackgroundContainer>
      <Helmet>
        <title>{t("connection_title")}</title>
      </Helmet>
      <LogoImg src={logoChrisalyde} />
      <ContentContainer>
        <LoadingContent label={t("loading")} float />
      </ContentContainer>
      <Notifications />
    </BackgroundContainer>
  );
});

export default LinkLoginPage;
