import { themeGet } from "@styled-system/theme-get";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { Button, ErrorContent, LoadingContent } from "components";
import Clickable from "components/Clickable";
import { withTranslation } from "react-i18next";
import { getSequence } from "services/sequence";
import { FORM_MODE_CONSULT } from "utils/constants";
import { paddingVertical } from "utils/mixins";
import SequenceForm from "./SequenceForm";
import sequenceFormDataBuilder from "./sequenceFormDataBuilder";
import SequenceTableDetail from "./SequenceTableDetail";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const SequenceContainer = styled.div`
  border-top: 1px solid ${themeGet("colors.lightGrey")};
  margin: 0 -15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 50px;
  & div,
  button {
    margin-bottom: 5px;
  }
`;

const SequenceContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  ${paddingVertical("20px")};

  &:last-child {
    border: none;
  }
`;

@inject("fetchService", "routingStore")
@observer
class SequenceDetailPage extends Component {
  @observable sequenceDetailsRequest;
  @observable sequence;

  constructor(props) {
    super(props);
    this.showDetailSequence();
  }

  @action("GET_SEQUENCE")
  showDetailSequence = () => {
    const { fetchService } = this.props;
    if (this.props.match.params.id)
      this.sequenceDetailsRequest = fromPromise(
        fetchService.fetch(getSequence(this.props.match.params.id))
      );
    this.sequenceDetailsRequest.then(
      action((seq) => {
        this.sequence = seq;
      })
    );
  };

  render() {
    const { routingStore, t } = this.props;
    return (
      <Container>
        <Helmet>
          <title>{t("sequence.details.title")}</title>
        </Helmet>
        <h1>
          <Clickable onClick={routingStore.history.goBack}>{t("admin_sequences")}</Clickable> &gt;{" "}
          {"Consultation"}
        </h1>
        <SequenceContainer>
          {this.sequenceDetailsRequest.case({
            pending: () => <LoadingContent label={t("loading")} float={true} />,
            rejected: (error) => <ErrorContent text={t(error.code)} />,
            fulfilled: (values) => null,
          })}
          <React.Fragment>
            <SequenceContainerItem>
              <SequenceForm
                form={sequenceFormDataBuilder({
                  sequence: this.sequence,
                  mode: FORM_MODE_CONSULT,
                  t,
                })}
              />
              <ButtonContainer>
                <Button
                  admin
                  to={`/admin/sequences/${this.sequence && this.sequence.id}/edit`}
                  full="true">
                  {t("edit")}
                </Button>
              </ButtonContainer>
            </SequenceContainerItem>
            <SequenceContainerItem>
              <SequenceTableDetail sequences={this.sequence} />
            </SequenceContainerItem>
          </React.Fragment>
        </SequenceContainer>
      </Container>
    );
  }
}

export default withTranslation()(SequenceDetailPage);
