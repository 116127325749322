export const REFRESH_SESSION_INTERVAL = 1000 * 60 * 10;
export const REFRESH_NOTIFICATIONS_INTERVAL = 1000 * 10;
export const REFRESH_FEED_INTERVAL = 1000 * 7;
export const REFRESH_TRENDS_INTERVAL = 1000 * 15;

export const USER_TYPES = {
  SUPERADMIN: "SUPER_ADMIN",
  ANIMATOR: "ANIMATOR",
  USER: "USER",
  DUMMY: "DUMMY",
  FAKE_USER: "FAKE_USER",
};

export const NOTIFICATION_TYPES = {
  REWEEZ: "REWEEZ",
  LIKE: "LIKE",
  FOLLOW: "FOLLOW",
  MENTION: "MENTION",
  REPLY: "REPLY",
};

export const FORBIDDEN_CLIENT_PATH = ["admin", "auth", "notifications", "search", "hashtag"];

export const WEEZ_MAX_LENGTH = 280;
export const WEEZ_MAX_PHOTO = 4;

export const FORM_MODE_CONSULT = "consult";
export const FORM_MODE_EDIT = "edit";
export const FORM_MODE_CREATE = "create";

export const CLONE_ACCESS_TYPES = {
  LOGIN: "LOGIN",
  URL: "URL",
};
