import { getServerUrl } from "utils/fetch";

const CLONE_API = "/admin/clones";

export function createClone({ id, path, expirationDate, createdOn, accessType }) {
  const data = {
    id,
    path,
    expirationDate,
    createdOn,
    accessType,
  };

  return {
    url: getServerUrl(`${CLONE_API}/create`),
    method: "POST",
    body: JSON.stringify(data),
  };
}

export function getClones() {
  return {
    url: getServerUrl(CLONE_API + "/"),
    method: "GET",
  };
}

export function deleteClone(cloneId) {
  return {
    url: getServerUrl(`${CLONE_API}/${cloneId}`),
    method: "DELETE",
  };
}

export function checkClonePath(clonePath) {
  return {
    url: getServerUrl(`${CLONE_API}/check_path`, { path: clonePath }),
    method: "GET",
    acceptedErrorCodes: [401],
  };
}
