import React from "react";
import styled from "styled-components";

const DroowerHeader = styled.div`
  border-bottom: 0.5px solid grey;
  padding: 10px 5px;
  /* min-height: 80px; */
`;

const Droower = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? "0" : "-100%")};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 200px;
  max-width: 90%;
  background: white;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 5px;
  box-sizing: border-box;
  transition: 0.25s ease-in-out;
  box-shadow: 2px 0px 2px 0px rgba(0, 0, 0, 0.3);
`;

const DroowerTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const DroowerLay = styled.div`
  background-color: rgba(0, 0, 0, 0.32);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.open ? "block" : "none")};
  /* pointer-events n'est supporté par IE < 11 */
  /* pointer-events: ${(props) => (props.open ? "auto" : "none")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition-property: opacity;
  transition: 0.3s ease-in-out; */
  z-index: 997;
`;

const DroowerList = styled.ul`
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
`;

const DroowerListItem = styled.li`
  display: flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: 48px;
  padding: 0 10px;
  overflow: hidden;
  transition-property: background-color;
  transition: 0.1s ease-out;
  &:hover {
    color: rgba(13, 81, 149, 1);
    background-color: rgba(13, 81, 149, 0.12);
  }
`;

const ListIcon = ({ graphic, ...otherProps }) => {
  const graphicProps = {
    ...otherProps,
  };
  return React.cloneElement(graphic, graphicProps);
};
const DroowerListIcon = styled(ListIcon)`
  margin-left: 0;
  margin-right: 15px;
  width: 24px;
  height: 24px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  fill: rgba(0, 0, 0, 0.3);
`;

export default Droower;
export { DroowerHeader, DroowerLay, DroowerTitle, DroowerList, DroowerListItem, DroowerListIcon };
