import React from "react";
import { EditorState } from "draft-js";
import data from "emoji-mart/data/twitter.json";
import Emoji from "./components/Emoji";
import EmojiSelect from "./components/EmojiSelect";
import emojiStrategy from "./emojiStrategy";
import attachImmutableEntitiesToEmojis from "./modifiers/attachImmutableEntitiesToEmojis";
import addEmoji from "./modifiers/addEmoji";

export default (config = {}) => {
  const ariaProps = {
    ariaHasPopup: "false",
    ariaExpanded: false,
    ariaOwneeID: undefined,
    ariaActiveDescendantID: undefined,
  };

  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
  };

  const { selectedEmoji = () => {} } = config;

  const pickerProps = {
    onClick: function(emoji, event) {
      const newEditorState = addEmoji(store.getEditorState(), emoji.native);
      store.setEditorState(newEditorState);

      selectedEmoji();
    },
    set: "twitter",
    data: data,
  };

  const decoratedEmojiSelect = pProps => oProps => <EmojiSelect {...pProps} {...oProps} />;

  return {
    EmojiSelect: decoratedEmojiSelect(pickerProps),

    decorators: [
      {
        strategy: emojiStrategy,
        component: Emoji,
      },
    ],

    getAccessibilityProps: () => ({
      role: "combobox",
      ariaAutoComplete: "list",
      ariaHasPopup: ariaProps.ariaHasPopup,
      ariaExpanded: ariaProps.ariaExpanded,
      ariaActiveDescendantID: ariaProps.ariaActiveDescendantID,
      ariaOwneeID: ariaProps.ariaOwneeID,
    }),

    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },

    onChange: editorState => {
      let newEditorState = attachImmutableEntitiesToEmojis(editorState);

      if (!newEditorState.getCurrentContent().equals(editorState.getCurrentContent())) {
        // Forcing the current selection ensures that it will be at it's right place.
        // This solves the issue where inserting an Emoji on OSX with Apple's Emoji
        // selector led to the right selection the data, but wrong position in
        // the contenteditable.
        newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
      }

      // if (callbacks.onChange) return callbacks.onChange(newEditorState);
      return newEditorState;
    },
  };
};
