import { Autocomplete } from "components";
import React from "react";
// import matchSorter from "match-sorter";

import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";

const LangSelect = inject("boostStore")(
  observer(function LangSelect({ value, onChange, boostStore, disabled }) {
    if (!boostStore.langs) {
      return null;
    }
    const { t, i18n } = useTranslation();
    return (
      <Autocomplete
        items={boostStore.langs}
        placeholder={t("boost.lang")}
        itemToString={(lang) => (i18n.exists(`langs.${lang}`) ? t(`langs.${lang}`) : "")}
        // filter={(items, query) =>
        //   matchSorter(items, query, {
        //     keys: [(item) => t(`langs.${item}`)],
        //     threshold: matchSorter.rankings.STARTS_WITH,
        //   })
        // }
        selectedItem={value ? boostStore.langs.find((lang) => value === lang) : ""}
        onSelect={(val, evt) => onChange(evt, val)}
        disabled={disabled}
      />
    );
  })
);

export default LangSelect;
