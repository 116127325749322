import React from "react";
import styled from "styled-components";
import { MdSearch } from "react-icons/md";
import {
  Button,
  Checkbox,
  FormSection,
  InputWrapper,
  CheckboxGroup,
  InputField,
  Switch,
} from "components";
import { ClientAutocomplete } from "app/common";
import { getInputFieldProps, getSwitchProps } from "utils/forms";
import { USER_TYPES } from "utils/constants";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

const StyledGroup = styled(CheckboxGroup)`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 20px;
  }
`;

const FormLine = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`;

@observer
class SearchForm extends React.Component {
  render() {
    const { currentUser, form, onSubmit, t } = this.props;
    return (
      <form autoComplete="off" onSubmit={onSubmit}>
        <FormSection title={t("search.title")}>
          <FormLine>
            <InputField
              label={`${t("keyword")} ${t("required")}`}
              placeholder={t("users.search_one")}
              type="text"
              field={form.terms}
            />
          </FormLine>
          {currentUser.role === USER_TYPES.SUPERADMIN && (
            <FormLine>
              <InputWrapper label={t("client")} error={form.client.errorMessage}>
                <ClientAutocomplete field={form.client} />
              </InputWrapper>
            </FormLine>
          )}
          <FormLine>
            <InputWrapper label={t("type")}>
              <StyledGroup {...getInputFieldProps(form.roles)}>
                <Checkbox label={t("animators")} value={USER_TYPES.ANIMATOR} />
                <Checkbox label={t("player")} value={USER_TYPES.USER} />
                <Checkbox label={t("dummy")} value={USER_TYPES.DUMMY} />
              </StyledGroup>
            </InputWrapper>
            <InputWrapper label={"Status"}>
              <Switch
                {...getSwitchProps(form.status)}
                labelChecked={t("users.activated")}
                labelUnchecked={t("users.deactivated")}
              />
            </InputWrapper>
          </FormLine>
          <ButtonContainer>
            <Button admin type="submit" invalid={!form.isValid} full>
              <MdSearch size={20} />
              {t("search.action")}
            </Button>
          </ButtonContainer>
        </FormSection>
      </form>
    );
  }
}

export default withTranslation()(SearchForm);
