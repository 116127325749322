import React from "react";
import { searchStoreContext } from "stores/SearchStoreProvider";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { useLocation } from "react-router";
import qs from "qs";
import throttle from "lodash/throttle";
import { UserItem } from "app/common";
import { Button } from "components";
import { cancelEvent } from "utils";
import { MobXProviderContext } from "mobx-react";
import { useHistory } from "react-router-dom";
import { PageContent } from "app/layout/MainLayout.mobile";
import SearchHeaderMobile from "app/layout/headers/SearchHeader.mobile";
import { runInAction } from "mobx";
import { useTranslation } from "react-i18next";

function SearchPageMobile() {
  const { authStore } = React.useContext(MobXProviderContext);
  const searchStore = React.useContext(searchStoreContext);
  const location = useLocation();
  const history = useHistory();

  const store = useLocalStore(() => ({
    scrollPosition: 0,
    updateScrollPosition(scrollY) {
      store.scrollPosition = scrollY;
    },
  }));

  React.useEffect(() => {
    const scrollingHandler = throttle(handleScroll, 400, { leading: true, trailing: true });
    window.addEventListener("scroll", scrollingHandler);
    return () => {
      window.removeEventListener("scroll", scrollingHandler);
    };
  }, []);

  const handleSubscribeToUser = (user) =>
    cancelEvent(() => {
      user.subscribe(!user.subscribed).then(() => {
        authStore.refreshSession();
      });
    });
  const handleScroll = () => {
    if (
      document.body.scrollHeight - window.scrollY - window.innerHeight < 100 &&
      store.scrollPosition < window.scrollY
    ) {
      searchStore.incrementSearchPage();
      searchStore.loadMoreUsers();
    }
    store.updateScrollPosition(window.scrollY);
  };

  const handleClickUser = (user) =>
    cancelEvent(() => {
      history.push({
        pathname: `/${user.username}`,
        search: `?id=${user.id}`,
      });
    });

  React.useEffect(() => {
    const terms = qs.parse(location.search.substring(1)).terms || "";
    runInAction(() => searchStore.loadUsers(terms));
  }, [location]);

  const { currentUser } = authStore;
  return (
    <React.Fragment>
      <SearchHeaderMobile user={currentUser} photo={currentUser.photo} />
      <PageContent>
        <SearchResultsView onClickUser={handleClickUser} onClickSubscribe={handleSubscribeToUser} />
      </PageContent>
    </React.Fragment>
  );
}

const SearchResultsView = ({ onClickUser, onClickSubscribe }) => {
  const { t } = useTranslation();
  const searchStore = React.useContext(searchStoreContext);
  return useObserver(() => (
    <React.Fragment>
      {searchStore.results.map((user) => (
        <UserItem
          key={user.id}
          user={user}
          displayEmail={false}
          onClick={onClickUser(user)}
          renderRight={(user) => (
            <Button full={user.subscribed} onClick={onClickSubscribe(user)}>
              {user.subscribed ? t("subscribed") : t("follow")}
            </Button>
          )}
        />
      ))}
    </React.Fragment>
  ));
};
export default SearchPageMobile;
