import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as clientsApi from "services/clients";
import styled from "styled-components";
import { cancelEvent } from "utils";
import ClientForm from "./ClientForm";
import clientFormDataBuilder from "./clientFormDataBuilder";
import { t } from "utils/i18n";
import { FORM_MODE_EDIT } from "utils/constants";
import { captureException } from "utils/logger";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

@inject("authStore", "routingStore", "viewStore", "fetchService")
@observer
class EditClientPage extends React.Component {
  @observable
  editClientRequest = null;

  @observable
  form;

  @observable
  client;

  componentDidMount() {
    const { match, fetchService } = this.props;
    const clientId = match.params.clientId;
    fetchService
      .fetch(clientsApi.getClient(clientId))
      .then(
        action((client) => {
          this.client = client;
          this.form = clientFormDataBuilder({
            authStore: this.props.authStore,
            client,
            mode: FORM_MODE_EDIT,
          });
        })
      )
      .catch((e) => {
        captureException(e);
      });
  }

  @action
  onSubmit = cancelEvent((evt) => {
    if (!this.form.isValid) {
      this.form.setTouched();
    } else {
      const { routingStore, viewStore, fetchService } = this.props;

      const data = this.form.values;

      this.editClientRequest = fromPromise(
        fetchService.fetch(clientsApi.updateClient(this.client.id, data))
      );
      this.editClientRequest
        .then(() => {
          routingStore.history.push("/admin/clients");
          viewStore.pushNotification(t("customer_updated"));
        })
        .catch((e) => {
          // FIXME NOTNEEDED
          console.error(e);
        });
    }
  });

  render() {
    const { authStore } = this.props;
    const { currentUser } = authStore;

    if (!this.client) {
      return null;
    }

    return (
      <Container>
        <Helmet>
          <title>{t("add_customer_title")}</title>
        </Helmet>
        <h1>
          <Link to="/admin/clients">{t("admin_customers")}</Link> &gt; {t("update")}
        </h1>
        <ClientForm
          form={this.form}
          mode={FORM_MODE_EDIT}
          currentUser={currentUser}
          requestObject={this.editClientRequest}
          onSubmit={this.onSubmit}
        />
      </Container>
    );
  }
}

export default EditClientPage;
