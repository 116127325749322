import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { MdSettings } from "react-icons/md";
import PlannificateurItem from "./PlannificateurItem";
import PlannificateurModel from "./PlannificateurModel";
import { Link } from "components";
import { observable, action } from "mobx";
import { withTranslation } from "react-i18next";

const Sidebar = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: white;
  width: 320px;
  height: 350px;
  padding: 0;
  margin-left: 20px;
  margin-bottom: 10px;
`;
const Side = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  overflow-y: auto;
`;
const SideTitle = styled.h2`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 10px;
  height: 50px;
  font-weight: 700;
  font-size: 1.3rem;
  color: ${themeGet("colors.grey")};
  border-bottom: 1px solid ${themeGet("colors.lightGrey")};
`;

const SequenceAdminLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: ${themeGet("colors.primary")};
  }
`;

@inject("fetchService", "webSocketService")
@observer
class Plannificateur extends React.Component {
  @observable
  plannificateurModel;

  componentDidMount() {
    this.initModel();
  }

  @action
  initModel() {
    const { fetchService, webSocketService } = this.props;
    this.plannificateurModel = new PlannificateurModel(fetchService, webSocketService);
    this.plannificateurModel.initSequences();
  }

  render() {
    const { t } = this.props;
    return (
      <Sidebar>
        <SideTitle>
          {t("scheduler.title")}
          <SequenceAdminLink to="/admin/sequences">
            <MdSettings size={25} style={{ cursor: "pointer" }} />
          </SequenceAdminLink>
        </SideTitle>
        <Side>
          {this.plannificateurModel &&
            this.plannificateurModel.sequences &&
            this.plannificateurModel.sequences.map((seq, index) => (
              <PlannificateurItem
                fetchService={this.props.fetchService}
                sequence={seq}
                key={index}
                onChangeStatus={this.plannificateurModel.updateSequenceData}
              />
            ))}
        </Side>
      </Sidebar>
    );
  }
}

export default withTranslation()(Plannificateur);
