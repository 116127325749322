import { runInAction, reaction, autorun } from "mobx";
import PrivateMessage from "./models/PrivateMessage";
import { deserialize } from "serializr";

class PrivateMessageService {
  static _instance = null;
  static getInstance() {
    if (this._instance === null) {
      this._instance = new PrivateMessageService();
    }
    return this._instance;
  }

  inject({ fetchService, webSocketService, authStore, conversationsStore }) {
    this.webSocketService = webSocketService;
    this.fetchService = fetchService;
    this.conversationsStore = conversationsStore;

    reaction(
      () =>
        this.webSocketService &&
        this.webSocketService.connected &&
        authStore.isConnected &&
        !authStore.currentUser.isSuperAdmin,
      () => {
        try {
          this.webSocketService.subscribe("/topic/privmess", async (message) => {
            const { senderId, clientId, ...messageItem } =
              await this.webSocketService.processMessage(message);
            if (this.webSocketService.checkClientMatch(clientId)) {
              runInAction(async () => {
                const message = this.deserializeMessage(messageItem.message, {
                  store: conversationsStore,
                });
                await conversationsStore.addMessageToConversation(message, senderId);
              });
            }
          });
        } catch {}
      }
    );
  }

  /**
   *
   * @param {object} message JSON values
   * @param {object} contextArgs any args to be passed to desirialization context
   */
  deserializeMessage(message, contextArgs) {
    return deserialize(PrivateMessage, message, () => {}, contextArgs);
  }

  identityAndRetrieveConversation(conversationId) {
    this.conversationsStore.identifyConversation();
  }
}

export default PrivateMessageService;
