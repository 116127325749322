import themeGet from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";

export const Divider = styled.div`
  border-top: 1px solid ${themeGet("colors.lightGrey")};
  display: flex;
  max-height: 1px;
  flex: 1 0;
  width: 100%;
`;
