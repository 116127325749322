import { FormSection, InputField, InputWrapper, TextArea } from "components";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { getInputFieldProps } from "utils/forms";

const SequenceForm = observer(({ form }) => {
  const { t } = useTranslation();
  return (
    <FormSection style={{ borderTop: 0 }}>
      <InputField
        label={t("sequence.add.form_creation.title")}
        type="text"
        field={form && form.name}
        helperPosition="below"
        labelWidth={150}
      />
      <InputField
        label={t("sequence.add.form_creation.goals")}
        type="text"
        field={form && form.goals}
        helperPosition="below"
        labelWidth={150}
      />
      <InputWrapper
        label={t("sequence.add.form_creation.description")}
        helperPosition="below"
        labelWidth={150}>
        <TextArea
          maxLength={1000}
          rows={3}
          maxRows={10}
          {...getInputFieldProps(form && form.description)}
        />
      </InputWrapper>
    </FormSection>
  );
});

export default SequenceForm;
