import React from "react";
import { WeezFeedModel } from "app/common";
import { inject, observer } from "mobx-react";
import { observable, action } from "mobx";
import * as feedApi from "services/feed";
import DesktopHomePage from "./DesktopHomePage";
import MobileHomePage from "./MobileHomePage";
import { BrowserView, MobileView } from "app/layout/responsiveness/mediaQueries";

@inject("authStore", "fetchService", "webSocketService", "viewStore")
@observer
class HomePage extends React.Component {
  @observable
  weezFeed;

  @action("HOME_INIT_FEED")
  initFeed() {
    const { fetchService, webSocketService } = this.props;
    this.weezFeed = new WeezFeedModel(feedApi.getFeed, { fetchService, webSocketService });
  }

  componentDidMount() {
    this.initFeed();
  }

  componentWillUnmount() {
    this.weezFeed && this.weezFeed.close();
  }

  render() {
    const { authStore, viewStore } = this.props;
    const currentUser = authStore.currentUser;

    return (
      <React.Fragment>
        <BrowserView>
          <DesktopHomePage currentUser={currentUser} weezeFeed={this.weezFeed} />
        </BrowserView>
        <MobileView>
          <MobileHomePage
            currentUser={currentUser}
            weezeFeed={this.weezFeed}
            toggleNewWeezeModal={viewStore.toggleNewWeezModal}
          />
        </MobileView>
      </React.Fragment>
    );
  }
}

export default HomePage;
