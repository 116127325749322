import { computed, observable } from "mobx";

export default class FieldsMap {
  @observable fields;

  initValue;

  constructor(fields, { validate } = {}) {
    this.initValue = fields;
    this.validate = validate;
    this.init(fields);
  }

  init(fields) {
    this.fields = observable.map(fields, { deep: false });
  }

  reset(pristine = false) {
    this.init(this.initValue);
    this.fields.forEach(field => field.reset(pristine));
  }

  markAsTouched() {
    this.fields.forEach(field => field.markAsTouched());
  }

  setValue(data) {
    if (!data) {
      return;
    }
    this.fields.forEach((field, name) => field.setValue(data[name], false));
  }

  get(name) {
    return this.fields.get(name);
  }

  @computed
  get _validation() {
    if (this.validate) {
      const validation = this.validate(this.fields);
      if (!validation.valid) {
        return validation;
      }
    }

    const invalidField = Array.from(this.fields.values()).find(field => !field.isValid);
    if (invalidField) {
      return invalidField._validation;
    }

    return {
      valid: true,
    };
  }

  @computed
  get isValid() {
    return this._validation.valid;
  }

  @computed
  get value() {
    const res = {};
    this.fields.forEach((field, name) => {
      res[name] = field.value;
    });
    return res;
  }
}
