import React from "react";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import { InputWrapper } from "components/form";
import OnClickOutside from "../utils/OnClickOutside";

const StylePreviewWrapper = styled.div`
  display: inline-block;
  border-radius: 2px;
  padding: 5px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
  div {
    width: 70px;
    height: 20px;
    border-radius: inherit;
    background-color: ${props => (props.color ? props.color : "#222")};
    cursor: pointer;
  }
`;

const StyledSketchPickerWrapper = styled.div`
  position: absolute;
  margin-left: 80px;
  margin-top: -70px;
  z-index: 2;
`;

export default class ColorPickerInput extends React.Component {
  state = {
    color: { hex: this.props.field.value ? this.props.field.value : "#0D5295" },
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState(state => ({ ...state, displayColorPicker: !state.displayColorPicker }));
  };

  handleClose = () => {
    this.setState(state => ({ ...state, displayColorPicker: false }));
  };

  handleChange = color => {
    this.setState(state => ({ ...state, color }));
    this.props.field.setValue(color.hex);
  };

  render() {
    const { label } = this.props;
    return (
      <InputWrapper label={label}>
        <StylePreviewWrapper onClick={this.handleClick} color={this.state.color.hex}>
          <div />
        </StylePreviewWrapper>
        {this.state.displayColorPicker && (
          <StyledSketchPickerWrapper id="popover">
            <OnClickOutside action={this.handleClose}>
              <SketchPicker color={this.state.color} onChangeComplete={this.handleChange} />
            </OnClickOutside>
          </StyledSketchPickerWrapper>
        )}
      </InputWrapper>
    );
  }
}
