import { Field, fieldsAggregatorBuilder } from "utils/forms";

export default function builder(initialValues) {
  const fields = {
    allSponsored: new Field({ initValue: initialValues ? initialValues.allSponsored : false }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
