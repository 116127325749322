import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { lightenColor } from "utils/mixins";

const StyledTab = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => (props.active ? themeGet("colors.primary") : themeGet("colors.black"))};
  background-color: ${props => (!props.active ? lightenColor(0.9, "colors.black") : "transparent")};
  cursor: pointer;
`;
function Tab({ className, children, active, ...rest }) {
  return (
    <StyledTab className={className} active={active} {...rest}>
      {children}
    </StyledTab>
  );
}

export default Tab;
