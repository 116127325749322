import { observer } from "mobx-react";
import React from "react";
import { MdRepeat, MdShop, MdExpandLess, MdExpandMore } from "react-icons/md";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import WeezContent from "./WeezContent";
import styledMap from "styled-map";
import { isMobile } from "app/layout/responsiveness/mediaQueries";
import { observable, action } from "mobx";
import { withTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => `${props.isBoostedWeeze ? 5 : 10}px ${isMobile ? 5 : 15}px`};
  border-bottom: ${styledMap({
    separation: css`1px solid ${themeGet("colors.veryLightGrey")}`,
    default: "none",
  })};
  max-width: 100%;
`;

const ContextContainer = styled.div`
  display: flex;
  color: ${themeGet("colors.strongGrey")};
  font-weight: 300;
  /* margin-top: -3px;
  margin-bottom: 5px; */
  margin-left: 40px;
  margin-right: 10px;
  align-items: center;
  justify-content: space-between;
  span {
    margin-left: 6px;
  }
`;

const SponsoredContainer = styled.div`
  display: flex;
  color: ${themeGet("colors.veryStrongGrey")};
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 65px;
  align-items: center;
  span {
    margin-left: 5px;
  }
`;

const CollapseIconWrapper = styled.div`
  cursor: pointer;
`;

const ActionTitle = styled.div`
  margin-left: -20px;
  font-weight: 600;
  font-size: 0.9rem;
`;

@observer
class Weez extends React.Component {
  @observable displayCompact = false;

  constructor(props) {
    super();
    this.displayCompact = props.weez.weezeOrigin === "BOOST";
  }

  @action("TOGGLE_DISPLAY_WEEZE_CONTENT")
  toggleDisplay = () => {
    this.displayCompact = !this.displayCompact;
  };

  render() {
    const { weez, showButtons = true, className, separation = true, onDelete, t } = this.props;
    const { author } = weez;

    let weezToShow = weez;
    let reweezToShow = null;
    let boost = weez.weezeOrigin === "BOOST";

    if (weez.reweez) {
      const weezHasText = !!weez.text;
      weezToShow = weezHasText ? weez : weez.reweez;
      reweezToShow = weezHasText ? weez.reweez : null;
    }

    return (
      <Container className={className} separation={separation} isBoostedWeeze={boost}>
        {weez.reweez && (
          <ContextContainer>
            <ActionTitle>
              <MdRepeat size={20} />
              <span>{`${author.firstname} ${author.lastname} ${t("has_reweezed")}`}</span>
            </ActionTitle>
            {boost && (
              <CollapseIconWrapper onClick={this.toggleDisplay}>
                {this.displayCompact ? <MdExpandMore size={25} /> : <MdExpandLess size={25} />}
              </CollapseIconWrapper>
            )}
          </ContextContainer>
        )}
        <WeezContent
          weez={weezToShow}
          showButtons={showButtons && !this.displayCompact}
          reweez={reweezToShow}
          displayCompact={this.displayCompact}
          onDelete={onDelete}
        />
        {weez.sponsored && (
          <SponsoredContainer>
            <MdShop size={20} />
            <span>{t("sponsored")}</span>
          </SponsoredContainer>
        )}
      </Container>
    );
  }
}

export default withTranslation()(Weez);
