import { WEEZ_MAX_LENGTH } from "utils/constants";
import {
  Field,
  FieldWithProgress,
  fieldsAggregatorBuilder,
  isRequired,
  maxLength,
} from "utils/forms";

export default function builder({ authStore }) {
  const { currentUser } = authStore;

  const textField = new Field({
    initValue: {},
    validate: [
      (newValue, label) => {
        if (imageField.value) {
          return {
            valid: true,
          };
        } else {
          return isRequired(newValue && newValue.text, label);
        }
      },
      maxLength(WEEZ_MAX_LENGTH, "text"),
    ],
  });
  const imageField = new FieldWithProgress({
    validate: [
      (newValue, label) => {
        if (textField.value) {
          return {
            valid: true,
          };
        } else {
          return isRequired(newValue, label);
        }
      },
    ],
  });

  const fields = {
    text: textField,
    image: imageField,
    author: new Field({ initValue: currentUser }),
    sponsored: new Field({ initValue: false }),
  };

  return fieldsAggregatorBuilder({ fields });
}
