import { themeGet } from "@styled-system/theme-get";
import { ProfileImage } from "app/common";
import MainHeaderMobile from "app/layout/headers/MainHeader.mobile";
import { PageContent } from "app/layout/MainLayout.mobile";
import { DesktopView, MobileView } from "app/layout/responsiveness/mediaQueries";
import get from "lodash/get";
import { action } from "mobx";
import { MobXProviderContext } from "mobx-react";
import { Observer, useLocalStore, useObserver } from "mobx-react-lite";
import React from "react";
import { useRouteMatch } from "react-router";
import { getConversation, sendMessage, updateParticipantLastRead } from "services/messages";
import styled from "styled-components";
import { lightenColor } from "utils/mixins";
import DateFormat from "./DateFormat";
import MessageInput from "./MessageInput";

function ConversationPage() {
  const [interlocutors, setInterlocutors] = React.useState([]);

  const {
    authStore,
    routingStore,
    fetchService,
    conversationsStore: cStore,
  } = React.useContext(MobXProviderContext);
  const match = useRouteMatch("/messages/conversations/:conversationId");
  // const readMode = conv.participants.filter(p => p.endpoint.id === authStore.currentUser.id)
  const store = useLocalStore(() => ({
    readMode: false,
  }));

  React.useEffect(() => {
    fetchService.fetch(getConversation(match.params.conversationId)).then(
      action((value) => {
        const conv = cStore.deserializeConversation(value);
        if (
          conv.participants.filter((p) => p.endpoint.id === get(authStore, ["currentUser", "id"]))
            .length
        ) {
          store.readMode = false;
          // cStore.conversations.set(conv.id, conv);
        } else {
          store.readMode = true;
          // cStore.otherConversations.set(conv.id, conv);
        }
        cStore.openedConversation = conv;
        // Parmis les conversations stockées, celle-ci peut être marquées comme lue
        const readConv = cStore.getConversationById(match.params.conversationId);
        if (readConv) readConv.unread = false;
        setInterlocutors(
          conv.participants.filter(
            (part) => part.identity.id !== get(authStore, ["currentUser", "id"])
          )
        );
      })
    );

    return action(() => {
      cStore.openedConversation = null;
      const conversationId = get(match.params, "conversationId");
      if (!store.readMode && !!conversationId && authStore.currentUser) {
        // We only care about conversation that belongs to the user
        const conv = cStore.getConversationById(conversationId);
        if (conv) {
          const participant = conv.participants.filter(
            (p) => p.endpoint.id === authStore.currentUser.id
          )[0];
          fetchService.fetch(updateParticipantLastRead(conv.id, get(participant, "id")));
        }
      }
    });
  }, [match.params.conversationId]);

  const onSubmitMessage = (message) => () => {
    fetchService.fetch(sendMessage(match.params.conversationId, message)).then(() => {
      const conv = cStore.openedConversation;
      fetchService.fetch(
        updateParticipantLastRead(
          conv.id,
          conv.participants.filter(
            (p) => p.endpoint.id === get(authStore, ["currentUser", "id"])
          )[0].id
        )
      );
    });
  };

  const setTitle = (conversation) => {
    return conversation && conversation.title;
  };

  const setReturnTo = () => {
    return routingStore.history.push("/messages");
  };

  return (
    <React.Fragment>
      <MobileView>
        <Observer>
          {() => (
            <MainHeaderMobile
              user={authStore.currentUser}
              title={setTitle(cStore.openedConversation)}
              returnTo={setReturnTo}
            />
          )}
        </Observer>

        <PageContent>
          <Observer>
            {() => (
              <MessagesSection
                messages={cStore.openedConversation && cStore.openedConversation.messages}
                currentUser={authStore.currentUser}
              />
            )}
          </Observer>
          <Observer>
            {() => !store.readMode && <MessageInput onSubmit={onSubmitMessage} />}
          </Observer>
        </PageContent>
      </MobileView>
      <DesktopView>
        <Observer>
          {() => (
            <HeaderDesktop>
              <AvatarContainer>
                {interlocutors.length === 1 && (
                  <ImageContainer position={0}>
                    <ProfileImage photo={interlocutors[0].identity.photo} />
                  </ImageContainer>
                )}
                {interlocutors.length > 1 &&
                  interlocutors.map((part, index) => (
                    <AvatarSplitter key={part.id} position={index}>
                      <ImageContainer position={index}>
                        <ProfileImage photo={part.identity.photo} />
                      </ImageContainer>
                    </AvatarSplitter>
                  ))}
              </AvatarContainer>
              <div>{setTitle(cStore.openedConversation)}</div>
            </HeaderDesktop>
          )}
        </Observer>

        <Observer>
          {() => (
            <MessagesSection
              messages={cStore.openedConversation && cStore.openedConversation.messages}
              currentUser={authStore.currentUser}
            />
          )}
        </Observer>
        <Observer>{() => !store.readMode && <MessageInput onSubmit={onSubmitMessage} />}</Observer>
      </DesktopView>
    </React.Fragment>
  );
}

const AvatarSplitter = styled.div`
  width: ${({ position }) => `${position * 2 + 20}px`};
  overflow: hidden;
  box-sizing: border-box;
  border-left: ${({ position }) => `${position * 2}px solid white`};
  z-index: ${({ position }) => position + 1};
`;

const ImageContainer = styled.div`
  margin-left: ${({ position }) => `-${position * 20}px`};
  width: 40px;
`;

const HeaderDesktop = styled.div`
  display: flex;
  /* flex: 1 1 0px; */
  align-items: center;
  min-height: 60px;
  height: 60px;
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
  font-weight: 800;
  font-size: 19px;
  padding: 10px;
`;

const MessagesContainer = styled.section`
  position: relative;
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  padding: 5px 1rem;
`;
const FullMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.sent ? "flex-end" : "flex-start")};
  width: 100%;
  margin-bottom: 10px;
  color: ${themeGet("colors.grey")};
  flex-shrink: 0;
`;
const MessageContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  width: fit-content;
  padding: 10px 15px;
  max-width: 90%;
`;

const MessageSentWrapper = styled(MessageContentWrapper)`
  color: ${themeGet("colors.white")};
  background-color: ${lightenColor(0.3, "colors.primary")};
  border-radius: 15px 15px 0;
  margin-right: 10px;
`;
const MessageReceivedWrapper = styled(MessageContentWrapper)`
  color: ${themeGet("colors.black")};
  background-color: ${themeGet("colors.inputBackground")};
  border-radius: 15px 15px 15px 0;
  margin-left: 10px;
`;

const MessageLineWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: "row";
  align-items: "center";
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 0px;
  border: 1px solid transparent;
  padding: 1px;
  margin-right: 10px;
`;

const MessageAvatarContainer = styled.div`
  width: 30px;
`;

const MessagesSection = ({ messages, currentUser }) => {
  const bottomRef = React.useRef(null);
  React.useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages && messages.length]);
  return useObserver(() => (
    <MessagesContainer>
      {messages &&
        !!currentUser &&
        messages.map((message) => {
          const messageSent = message.author.endpoint.id === currentUser.id;
          return (
            <FullMessageWrapper key={message.id} sent={messageSent}>
              {messageSent && (
                <div
                  style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center" }}>
                  <MessageSentWrapper>{message.content}</MessageSentWrapper>
                  <MessageAvatarContainer>
                    <ProfileImage photo={message.author.identity.photo} />
                  </MessageAvatarContainer>
                </div>
              )}
              {!messageSent && (
                <MessageLineWrapper>
                  <MessageAvatarContainer>
                    <ProfileImage photo={message.author.identity.photo} />
                  </MessageAvatarContainer>
                  <MessageReceivedWrapper>{message.content}</MessageReceivedWrapper>
                </MessageLineWrapper>
              )}
              <DateFormat pattern="dd MMM HH'h'mm">{message.createdOn}</DateFormat>
            </FullMessageWrapper>
          );
        })}
      <div style={{ float: "left", clear: "both" }} ref={bottomRef}></div>
    </MessagesContainer>
  ));
};

export default ConversationPage;
