import { themeGet } from "@styled-system/theme-get";
import { AdaptiveDateFormat, MenuButton } from "components";
import DurationFormat from "components/DurationFormat";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdDateRange, MdSchedule } from "react-icons/md";
import styled from "styled-components";
import { paddingVertical } from "utils/mixins";

const ItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  ${paddingVertical("15px")};
  border-bottom: 1px solid ${themeGet("colors.lightGrey")};
  &:last-child {
    border: none;
  }
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  /* justify-content: space-between; */
`;
const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  color: #000;
  min-width: 150px;
`;
const LastModification = styled(StyledDiv)`
  align-items: center;
  width: 400px;
  gap: 5px;
`;
const Duration = styled(StyledDiv)`
  align-items: center;
  gap: 5px;
`;
const Label = styled.span`
  color: #7f8c8d;
  font-weight: 600;
  margin-right: 10px;
`;
const Descriptif = styled.div`
  margin: 0;
  font-size: 12px;
`;

const FirstLine = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: row;
`;
const SecondLine = styled.div`
  display: flex;
  margin-top: 20px;
`;

export default function SequenceItem({ name, description, createdOn, menuOptions, duration }) {
  const { t } = useTranslation();
  return (
    <ItemContainer>
      <DescriptionContainer>
        <FirstLine>
          <Title>{name}</Title>
          <LastModification>
            <MdDateRange size={25} color="grey" />{" "}
            <Label>{`${t("sequence.last_modification_date")}: `}</Label>
            <AdaptiveDateFormat isoDate={createdOn} />
          </LastModification>
          <Duration>
            <MdSchedule size={25} color="grey" /> <Label>{`${t("sequence.duration")}: `}</Label>
            <DurationFormat duration={duration} strict={true} />
          </Duration>
        </FirstLine>
        {description && (
          <SecondLine>
            <Descriptif>{description}</Descriptif>
          </SecondLine>
        )}
      </DescriptionContainer>
      <ActionContainer>{menuOptions && <MenuButton options={menuOptions} />}</ActionContainer>
    </ItemContainer>
  );
}
