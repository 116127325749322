import { eventService } from "events/EventService";
import { action, observable } from "mobx";
import { getBoostSequences } from "services/boost";

export default class BoostSequencesModel {
  @observable sequences = observable.array([], { deep: true });

  constructor(fetchService, webSocketService) {
    this.webSocketService = webSocketService;
    this.fetchService = fetchService;
    if (this.webSocketService) {
      webSocketService.subscribe("/topic/sequences_boosts", async (message) => {
        const { clientId, ...sequenceItem } = await this.webSocketService.processMessage(message);
        if (webSocketService.checkClientMatch(clientId)) {
          this.updateSequenceData(sequenceItem);
        }
      });
    }
    // Listen to event "boost_new" and fetch all boost sequences if triggered
    eventService.on("boost_new", () => {
      this.fetchBoostSequences();
    });
  }

  @action("SEQUENCES_BOOST_FETCH")
  fetchBoostSequences() {
    this.fetchService.fetch(getBoostSequences()).then((data) => {
      this.setData(data);
    });
  }

  @action("SEQUENCES_BOOST_UPDATE")
  updateSequenceData = (data) => {
    const { id, status, publishedWeezesCount, weezesCount, elapsed, duration } = data;
    const seq = this.sequences.find((item) => item.id === id);
    if (seq) {
      seq.status = status;
      seq.publishedWeezesCount = publishedWeezesCount;
      seq.weezesCount = weezesCount;
      seq.elapsed = elapsed;
      seq.duration = duration;
    } else {
      this.sequences.unshift(data);
    }
  };

  @action("SEQUENCES_BOOST_INIT")
  init() {
    this.fetchBoostSequences();
  }

  @action("SEQUENCES_BOOST_SET")
  setData(data) {
    this.sequences = data;
  }
}
