import React from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { MdFavorite, MdFiberNew, MdPersonAdd, MdRepeat } from "react-icons/md";
import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";
import throttle from "lodash/throttle";
import { themeGet } from "@styled-system/theme-get";
import { DisplayName } from "app/common";
import Weez from "app/common/weez/Weez";
import { NOTIFICATION_TYPES } from "utils/constants";
import { t } from "utils/i18n";
import { isMobile } from "app/layout/responsiveness/mediaQueries";

const Container = styled.div`
  background-color: ${themeGet("colors.white")};
  display: flex;
  flex-direction: column;
  margin-top: ${isMobile ? 0 : "20px"};
  min-width: ${isMobile ? "50%" : "600px"};
`;

const Content = styled.div``;

const Title = styled.h1`
  font-size: ${isMobile ? "1.2rem" : "1.5rem"};
  font-weight: 600;
  margin: 0;
  padding: 10px;
  color: ${themeGet("colors.grey")};
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
`;

const ItemContainer = styled.div`
  padding: 10px;
  padding-left: 30px;
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
`;

const NotificationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const LeftIcons = styled.div`
  width: 40px;
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  color: ${themeGet("colors.grey")};
`;

const LikeIcon = styled(MdFavorite).attrs({
  size: 20,
})`
  color: red;
`;

const ReweezIcon = styled(MdRepeat).attrs({
  size: 20,
})`
  color: ${themeGet("colors.")};
`;

const FollowIcon = styled(MdPersonAdd).attrs({
  size: 20,
})`
  color: ${themeGet("colors.grey")};
`;

const StyledDisplayName = styled(DisplayName)`
  margin-right: 3px;
`;

const StyledWeez = styled(Weez)`
  margin-left: 45px;
  padding-top: 5px;
  margin-top: 10px;
  border: 1px solid ${themeGet("colors.veryLightGrey")};
  border-radius: 5px;
`;

const headerTextStyle = css`
  font-size: 1rem;
  font-weight: 400;
  color: ${themeGet("colors.primary")};
`;

const Username = styled.span`
  ${headerTextStyle};
  margin-right: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const UserLink = styled(Link)`
  text-decoration: none;
  color: ${themeGet("colors.primary")};
  &:hover span {
    color: ${themeGet("colors.primary")};
  }
`;

@inject("authStore", "notificationsStore")
@observer
class NotificationsPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = throttle(
    (evt) => {
      if (document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        this.props.notificationsStore.loadOldNotifications();
      }
    },
    300,
    { leading: true, trailing: true }
  );

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.props.authStore.currentUser.setLastNotificationRead(new Date().toISOString());
  }

  renderReweezNotif(notification, isUnread, key) {
    return (
      <ItemContainer key={key}>
        <NotificationTitle>
          <LeftIcons>
            {isUnread && <MdFiberNew size={20} />}
            <ReweezIcon />
          </LeftIcons>
          <StyledDisplayName user={notification.user} size="small" /> a reweezé votre weeze.
        </NotificationTitle>
        <StyledWeez weez={notification.weez} showBorder showButtons={false} separation={false} />
      </ItemContainer>
    );
  }

  renderLikeNotif(notification, isUnread, key) {
    return (
      <ItemContainer key={key}>
        <NotificationTitle>
          <LeftIcons>
            {isUnread && <MdFiberNew size={20} />}
            <LikeIcon />
          </LeftIcons>
          <StyledDisplayName user={notification.user} size="small" /> a liké votre weeze.
        </NotificationTitle>
        <StyledWeez weez={notification.weez} showBorder showButtons={false} separation={false} />
      </ItemContainer>
    );
  }

  renderFollowNotif({ user, followCount }, isUnread, key) {
    return (
      <ItemContainer key={key}>
        <NotificationTitle>
          <LeftIcons>
            {isUnread && <MdFiberNew size={20} />}
            <FollowIcon />
          </LeftIcons>
          <StyledDisplayName user={user} size="small" />
          {!followCount || !(followCount - 1 > 0)
            ? "vous suit"
            : `et ${followCount - 1} personnes vous suivent`}
        </NotificationTitle>
      </ItemContainer>
    );
  }

  renderMentionNotif(notification, isUnread, key) {
    return (
      <ItemContainer key={key}>
        <NotificationTitle>
          <LeftIcons>
            {isUnread && <MdFiberNew size={20} />}
            <ReweezIcon />
          </LeftIcons>
          <StyledDisplayName user={notification.user} size="small" /> vous a mentionné
        </NotificationTitle>
        <StyledWeez weez={notification.weez} showBorder showButtons={false} separation={false} />
      </ItemContainer>
    );
  }

  renderReplyNotif(notification, isUnread, key) {
    const replyTo = notification.weez.replyTo;
    return (
      <ItemContainer key={key}>
        <NotificationTitle>
          <LeftIcons>
            {isUnread && <MdFiberNew size={20} />}
            <ReweezIcon />
          </LeftIcons>
          <span>
            En réponse à{" "}
            <UserLink to={`/${replyTo.author.username}`}>
              @<Username>{`${replyTo.author.username}`}</Username>
            </UserLink>
          </span>
          {/* <StyledDisplayName user={notification.user} size="small" /> */}
        </NotificationTitle>
        <StyledWeez weez={notification.weez} showBorder showButtons={false} separation={false} />
      </ItemContainer>
    );
  }

  render() {
    const { notificationsStore } = this.props;
    const unreadCount = notificationsStore.unreadCount;
    const notifications = notificationsStore.notifications;
    return (
      <Container>
        <Helmet>
          <title>{t("notifications.title")}</title>
        </Helmet>
        <Title>{t("notifications.unread", { count: unreadCount })}</Title>
        <Content>
          {notifications.map((notification, index) => {
            const isUnread = notificationsStore.isNotificationUnread(notification);
            switch (notification.type) {
              case NOTIFICATION_TYPES.REWEEZ:
                return this.renderReweezNotif(notification, isUnread, index);
              case NOTIFICATION_TYPES.LIKE:
                return this.renderLikeNotif(notification, isUnread, index);
              case NOTIFICATION_TYPES.FOLLOW:
                return this.renderFollowNotif(notification, isUnread, index);
              case NOTIFICATION_TYPES.MENTION:
                return this.renderMentionNotif(notification, isUnread, index);
              case NOTIFICATION_TYPES.REPLY:
                return this.renderReplyNotif(notification, isUnread, index);
              default:
                return <div />;
            }
          })}
        </Content>
      </Container>
    );
  }
}

export default NotificationsPage;
