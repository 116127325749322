// import get from "lodash/get";
// import french from "i18n/french_deprecated";
import i18n from "../i18n";

// export function t(key, fallback = "") {
//   return get(french, key, fallback);
// }

const t = (key, options) => i18n.t(key, options);
const changeLanguage = (lang) => i18n.changeLanguage(lang);

export { changeLanguage, t };
