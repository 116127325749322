import React from "react";
import { MdCall } from "react-icons/md";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const ButtonContainer = styled.div`
  background-color: green;
  border: 1px solid green;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: fixed;
  right: 15px;
  top: 7px;
  z-index: ${themeGet("zIndex.higher")};
  box-shadow: ${themeGet("shadows.elevation1")};
`;

function CommunicationButton({ handleClick }) {
  return (
    <ButtonContainer onClick={handleClick}>
      <MdCall size={30} />
    </ButtonContainer>
  );
}

export default CommunicationButton;
