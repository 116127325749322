import { getServerUrl } from "utils/fetch";

export function getClients() {
  return {
    url: getServerUrl("/admin/clients"),
    method: "GET",
  };
}

export function getClient(id) {
  return {
    url: getServerUrl(`/admin/clients/${id}`),
    method: "GET",
  };
}

export function createClient(client) {
  const data = {
    name: client.clientName,
    logo: client.logo,
    endDate: client.endDate.toISOString(),
    colorMain: client.colorMain,
    clientPath: client.clientPath,
    applicationName: client.applicationName,
  };

  return {
    url: getServerUrl("/admin/clients/create"),
    method: "POST",
    body: JSON.stringify(data),
  };
}

export function updateClient(id, client) {
  const data = {
    name: client.clientName,
    logo: client.logo,
    endDate: client.endDate,
    colorMain: client.colorMain,
    clientPath: client.clientPath,
    applicationName: client.applicationName,
  };

  return {
    url: getServerUrl(`/admin/clients/${id}/update`),
    method: "POST",
    body: JSON.stringify(data),
  };
}
