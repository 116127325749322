import React from "react";
import PropTypes from "prop-types";
import { NimblePicker } from "emoji-mart/dist-es";
import styled from "styled-components";
import { MdInsertEmoticon } from "react-icons/md";

import "emoji-mart/css/emoji-mart.css";
import "vendors/emoji-mart/emoji-mart-fix.css";

export default class EmojiSelect extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    set: PropTypes.string.isRequired,
    selectButtonContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  };

  static defaultProps = {
    selectButtonContent: "☺",
  };

  // Start the selector closed
  state = {
    isOpen: false,
  };

  // When the selector is open and users click anywhere on the page,
  // the selector should close
  componentDidMount() {
    document.addEventListener("click", this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closePopover);
  }

  onClick = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  onButtonMouseUp = () => (this.state.isOpen ? this.closePopover() : this.openPopover());

  // Open the popover
  openPopover = () => {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
      });
    }
  };

  // Close the popover
  closePopover = () => {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    const { data, onClick, set } = this.props;
    return (
      <SelectorContainer onClick={this.onClick}>
        <ButtonIcon onMouseUp={this.onButtonMouseUp} size={25} />
        {this.state.isOpen && <NimblePickerStyled onClick={onClick} set={set} data={data} />}
      </SelectorContainer>
    );
  }
}

const SelectorContainer = styled.div`
  display: inline-block;
`;
const ButtonIcon = styled(MdInsertEmoticon)`
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
const NimblePickerStyled = styled(NimblePicker)`
  margin-top: 10px;
  padding: 0 0.3em;
  position: absolute !important;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
`;
