import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import ProfileImage from "../ProfileImage";
import DisplayName from "../DisplayName";

const SuggestionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2px;
  justify-content: flex-start;
`;

const SuggestionProfileImage = styled(ProfileImage)`
  width: 35px;
  margin-right: 10px;
`;

const StyledDisplayName = styled(DisplayName)`
  margin-right: 10px;
`;

const Username = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${themeGet("colors.grey")};
`;

const SuggestionEntry = props => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <SuggestionContainer>
        <SuggestionProfileImage photo={mention.photo} />
        <StyledDisplayName user={mention} size="small" />
        <Username>@{mention.username}</Username>
      </SuggestionContainer>
    </div>
  );
};

export default SuggestionEntry;
