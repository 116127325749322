import { deserialize } from "serializr";
import Conversation from "./models/Conversation";
import { observable, action, computed, reaction } from "mobx";
import { getConversation, getConversations, getAllConversations } from "services/messages";
import LiteUser from "./models/LiteUser";
import { captureException } from "utils/logger";

/**
 * Stores conversations data and contains logic...
 * Needs to be improved.
 */
// TODO rename PrivMessStore ?
class ConversationsStore {
  @observable
  conversations = new Map();

  @observable
  otherConversations = new Map();

  @observable
  users = new Map();

  @observable
  openedConversation;

  // Singleton
  constructor() {
    if (!ConversationsStore.instance) {
      return ConversationsStore.instance;
    }
    ConversationsStore.instance = this;
    return this;
  }

  inject({ fetchService, authStore }) {
    this.fetchService = fetchService;
    this.authStore = authStore;
    // We fetch user's conversations to display the unread notification
    reaction(
      () => authStore.isReady && authStore.isConnected && !authStore.currentUser.isSuperAdmin,
      () => {
        this.fetchUserConversations();
      }
    );
  }

  deserializeConversation(values) {
    return deserialize(Conversation, values, () => {}, { store: this });
  }

  /**
   * @param {string|number} conversationId
   */
  getConversationById(conversationId) {
    const id = parseInt(conversationId, 10);
    return this.conversations.get(id);
  }

  async fetchUserConversations(storeResults = true) {
    const promise = this.fetchService.fetch(getConversations()).catch((e) => captureException(e));
    if (storeResults) {
      promise.then(
        action(
          (conversations) =>
            conversations &&
            conversations
              .map((c) => this.deserializeConversation(c))
              .map((c) => this.conversations.set(c.id, c))
        )
      );
    }
    return promise;
  }

  async fetchOtherConversations(storeResults = true) {
    const promise = this.fetchService
      .fetch(getAllConversations())
      .catch((e) => captureException(e));
    if (storeResults) {
      promise.then(
        action((conversations) =>
          conversations
            .map((c) => this.deserializeConversation(c))
            .map((c) => this.otherConversations.set(c.id, c))
        )
      );
    }
    return promise;
  }

  /**
   * Fetch conversation by its id and deserialize it.
   * @param {string|number} conversationId
   * @returns
   *  The deserialized conversation
   */
  async fetchConversation(conversationId) {
    const conv = await this.fetchService.fetch(getConversation(conversationId));
    return this.deserializeConversation(conv);
  }

  /**
   * Add conversation to conversations list.
   * @param {Conversation} conversation a previously deserialized conversation
   * @returns conversations list.
   */
  @action("ADD_CONVERSATION")
  addConversation(conversation) {
    return this.conversations.set(conversation.id, conversation);
  }

  /**
   * Add conversation to other conversations list. (animators only)
   * @param {Conversation} conversation a previously deserialized conversation
   * @returns conversations list.
   */
  @action("ADD_CONVERSATION_OTHER")
  addOtherConversation(conversation) {
    return this.otherConversations.set(conversation.id, conversation);
  }

  /**
   * @param {string|number} conversationId
   */
  getConversationMessages(conversationId) {
    const conversation = this.getConversationById(conversationId);
    return conversation && conversation.messages;
  }

  @action
  clearConversations() {
    this.conversations = new Map();
    this.otherConversations = new Map();
    this.users = new Map();
    this.openedConversation = undefined;
  }

  @computed
  get sortedConversations() {
    const sorted = [...this.conversations.values()].sort(
      (c1, c2) => c2.lastModifiedOn - c1.lastModifiedOn
    );
    return sorted;
  }

  @computed
  get sortedOtherConversations() {
    const sorted = [...this.otherConversations.values()].sort(
      (c1, c2) => c2.lastModifiedOn - c1.lastModifiedOn
    );
    return sorted;
  }

  @computed
  get hasUnreadConversations() {
    const has = !!Array.from(this.conversations.values()).filter((c) => {
      return c.hasUnreadMessage;
    }).length;
    return has;
  }

  // @computed
  // get filteredConversation() {
  //   return this.sortedConversations.filter(
  //     c => c.participants.filters(p => p.endpoint.id === this.authStore.currentUser.id).length > 0
  //   );
  // }

  deserializeUser(userData, store = true) {
    const user = deserialize(LiteUser, userData, () => {});
    if (store) {
      this.users[user.id] = user;
    }
    return user;
  }

  getUserById(userId) {
    const id = parseInt(userId, 10);
    return this.users.get(id);
  }

  /**
   * add previously deserialized message to its conversation.
   * If the conversation is not stored yet it will be fetched.
   *
   * @param {PrivateMessage} message previously deserialized message
   * @returns
   *  conversation
   */
  async addMessageToConversation(message, senderId) {
    let conversation;
    const { conversationId } = message;
    const conversationOpened = !!(
      this.openedConversation && this.openedConversation.id === conversationId
    );
    if (conversationOpened) {
      this.openedConversation.addMessage(message, conversationOpened);
    }

    let newConversation = false;
    if (!this.conversations.has(parseInt(conversationId, 10))) {
      conversation = await this.fetchConversation(conversationId);
      newConversation = true;
    } else {
      conversation = this.getConversationById(conversationId);
    }
    conversation.addMessage(message, conversationOpened);
    const isEndpoint =
      senderId === this.authStore.currentUser.id ||
      !!conversation.participants.filter((p) => p.endpoint.id === this.authStore.currentUser.id)
        .length;
    if (isEndpoint) {
      if (newConversation) {
        this.addConversation(conversation);
      }
    } else if (!isEndpoint && this.authStore.currentUser.isAnimator) {
      // if user is animator we had the conversation to the animators' conversations
      // let otherConversation;
      if (!this.otherConversations.has(parseInt(conversationId, 10))) {
        // otherConversation = await this.conversationsStore.fetchConversation(conversationId);
        // otherConversation = this.conversationsStore.addOtherConversation(otherConversation);
        this.addOtherConversation(conversation);
      }
    }

    return conversation;
  }

  getConversationPerProfile(conversationId) {
    const convId = parseInt(conversationId, 10);
    if (this.conversations.has(convId)) {
      return this.getConversationById(conversationId);
    } else if (this.authStore.currentUser.isAnimator) {
      return this.otherConversations.get(convId);
    } else {
      // TODO send a notification to indicate that conversation it not available
    }
  }
}

export default ConversationsStore;
