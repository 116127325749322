import React from "react";
import { addMedia } from "services/media";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { inject } from "mobx-react";
import { t } from "utils/i18n";
import { captureException } from "utils/logger";

const Label = styled.label`
  cursor: pointer;
  &:hover {
    color: ${themeGet("colors.primary")};
  }
`;

const FileInput = styled.input.attrs({
  type: "file",
  accept: "image/jpeg, image/png",
})`
  display: none;
`;

// FIXME : deprecated in favor of UploadButton
@inject("fetchService", "viewStore")
class FilePickerButton extends React.Component {
  uploadFile = evt => {
    const {
      metadata = {},
      onChange,
      onProgress,
      fetchService,
      path = "/media/upload",
    } = this.props;
    let files = evt.target.files;
    if (files && files.length) {
      const file = files[0];
      const promiseWithProgress = fetchService.fetch(addMedia(file, path, metadata));
      onProgress(promiseWithProgress.progressMonitor);
      promiseWithProgress
        .then(data => {
          onChange(evt, data.imageId);
          onProgress();
        })
        .catch(e => {
          captureException(e);
          // FIXME t(e.code) does not point to anything, check other occurences
          this.props.viewStore.pushNotification(t(e.code), "error");
        });
    }
  };

  render() {
    const { children, className, onChange, value, ...others } = this.props;
    return (
      <Label className={className}>
        <FileInput {...others} onChange={this.uploadFile} />
        {children}
      </Label>
    );
  }
}

export default FilePickerButton;
