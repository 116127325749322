import React from "react";
import { MdCheckCircle, MdLanguage } from "react-icons/md";
import styled, { css } from "styled-components";
import { lightenColor } from "utils/mixins";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";

const DisplayNameText = styled.span`
  color: ${themeGet("colors.black")};
  display: inline-flex;
  margin: 5px;
  align-items: center;
  ${styledMap("size", {
    reduced: css`
      font-size: 0.9rem;
      font-weight: 600;
    `,
    small: css`
      font-size: 1rem;
      font-weight: 600;
    `,
    big: css`
      font-size: 1.3rem;
      font-weight: 800;
    `,
    default: css`
      font-size: 1.1rem;
      font-weight: 600;
    `,
  })};
  svg {
    position: relative;
    margin-left: 3px;
  }
  svg.exposant {
    top: -5px;
    color: ${lightenColor(0.1, "colors.primary")};
  }
`;

const Ellipsis = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
`;

const iconSizes = {
  reduced: 12,
  small: 14,
  default: 15,
  big: 20,
};

export default function DisplayName({
  user,
  className,
  size = iconSizes.default,
  showGlobalDummyIcon = false,
}) {
  const iconSize = iconSizes[size] || iconSizes.default;
  return (
    <DisplayNameText className={className} size={size}>
      <Ellipsis>
        {user.firstname} {user.lastname}
      </Ellipsis>
      {user.certified && <MdCheckCircle className="exposant" size={iconSize} />}{" "}
      {showGlobalDummyIcon && user.globalDummy && <MdLanguage size={iconSizes.big} />}
    </DisplayNameText>
  );
}
