import { action, computed, observable } from "mobx";
import { weezFromData } from "./weez/WeezModel";
import { REFRESH_FEED_INTERVAL } from "utils/constants";

export default class WeezFeedModel {
  checkWeezInterval;

  @observable endOfFeed = false;

  newElements = observable.array([], { deep: false });

  @observable isLoading;

  @observable isChecking;

  @observable error;

  @observable weezs = observable.array([], { deep: false });

  @observable count = 0;

  @computed
  get hasNewWeezs() {
    return !!(this.newElements && this.newElements.length);
  }

  constructor(feedFetcher, { fetchService, webSocketService }) {
    this.feedFetcher = feedFetcher;
    this.fetchService = fetchService;
    this.webSocketService = webSocketService;
    this.feedLoad().then(() => {
      this.checkWeezInterval = setInterval(this.checkIfNewElements, REFRESH_FEED_INTERVAL);
    });
    if (this.webSocketService) {
      webSocketService.subscribe("/topic/messages", async (message) => {
        const { clientId, senderId, weezeId, action } = await this.webSocketService.processMessage(
          message
        );

        if (
          webSocketService.checkClientMatch(clientId) &&
          !webSocketService.checkUserMatch(senderId)
        ) {
          switch (action) {
            case "LIKE":
              // this.updateWeezeLikeCount(weezeId, +1);
              break;
            case "UNLIKE":
              // this.updateWeezeLikeCount(weezeId, -1);
              break;
            case "REWEEZE":
              // this.updateWeezeReWeezesCount(weezeId, +1);
              break;
            case "DELETE":
              console.debug(
                `About to ${action} weeze with id ${weezeId} from sender with ${senderId}`
              );
              this.deleteWeeze(weezeId);
              break;
            default:
              break;
          }
        }
        // this.webSocketService.connect();
      });
    }
  }

  close = async () => {
    if (this.checkWeezInterval) {
      clearInterval(this.checkWeezInterval);
      this.checkWeezInterval = null;
    }
    // if (this.webSocketService) {
    //   await this.webSocketService.disconnect();
    // }
  };

  @action("FEED_LOAD")
  feedLoad = () => {
    if (this.isLoading || this.endOfFeed) {
      return;
    }
    const beforeWeez = this.weezs.length && this.weezs[this.weezs.length - 1];
    const beforeTimestamp = beforeWeez ? beforeWeez.createdOn : null;
    this.isLoading = true;
    this.error = null;

    return this.fetchService
      .fetch(this.feedFetcher({ before: beforeTimestamp }))
      .then(
        action((res) => {
          const items = res.data;
          // the counter is initiated once ? FIXME check if this is ok
          if (!beforeTimestamp) {
            this.count = res.count;
          }

          if (!items || !items.length) {
            this.endOfFeed = true;
          } else {
            const weezs = items.map((item) =>
              weezFromData(item, { fetchService: this.fetchService })
            );
            this.weezs.push(...weezs);
          }
          this.isLoading = false;
        })
      )
      .catch(
        action((e) => {
          this.error = e;
          this.isLoading = false;
        })
      );
  };

  @action("CHECK_NEW_WEEZS")
  checkIfNewElements = () => {
    const lastWeezFetched = this.hasNewWeezs
      ? this.newElements[0]
      : this.weezs.length && this.weezs[0];
    const afterTimestamp = lastWeezFetched ? lastWeezFetched.createdOn : null;
    this.isChecking = true;
    this.error = null;
    return this.fetchService
      .fetch(this.feedFetcher({ after: afterTimestamp }))
      .then(
        action((res) => {
          const items = res.data;
          // The counter is incremented because this is new Elements.
          this.count += res.count;
          const weezs = items.map((item) =>
            weezFromData(item, { fetchService: this.fetchService })
          );
          this.newElements.unshift(...weezs);
          this.isChecking = false;
        })
      )
      .catch(
        action((e) => {
          this.error = e;
          this.isChecking = false;
        })
      );
  };

  @action("SHOW_NEW_WEEZS")
  showNewElements = () => {
    this.weezs.unshift(...this.newElements);
    this.newElements.clear();
  };

  @action("WEEZE_DELETE")
  deleteWeeze = (weezeId) => {
    const weeze = this.weezs.find((item) => item.id === weezeId);
    if (weeze) {
      this.weezs.remove(weeze);
    }
  };

  @action("WEEZE_UPDATE_LIKES_COUNT")
  updateWeezeLikeCount = (weezeId, operand) => {
    const weeze = this.weezs.find((item) => item.id === weezeId);
    if (weeze) {
      weeze.likesCount = weeze.likesCount + operand;
    }
    return weeze;
  };

  @action("WEEZE_UPDATE_REWEEZES_COUNT")
  updateWeezeReWeezesCount = (weezeId, operand) => {
    const weeze = this.weezs.find((item) => item.id === weezeId);
    if (weeze) {
      weeze.reweezsCount = weeze.reweezsCount + operand;
    }
  };
}
