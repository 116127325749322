import * as Sentry from "@sentry/browser";
import log from "loglevel";
import config from "./config";
import { version } from "../../package.json";

const logger = log.noConflict();
logger.debug("Weezer application version:", version);
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: config.sentryUrl,
    release: version,
    environment: config.sentryEnv,
  });
}

export function captureException(err, opts) {
  Sentry.captureException(err, opts);
  logger.error(err, opts);
}
/* Not used anywhere so far */
// export function setUserContext(...opts) {
//   Raven.setUserContext(...opts);
// }

export function logBreadcrumb(...opts) {
  Sentry.addBreadcrumb(...opts);
}

export default {
  trace: logger.trace,
  debug: logger.debug,
  info: logger.info,
  warn: logger.warn,
  error: logger.error,
};
