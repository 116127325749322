import { Button } from "components";
import { Circle } from "rc-progress/lib";
import React from "react";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { imageUrl } from "utils";
import theme from "utils/theme";

const UploadedImageContainer = styled.div`
  width: 130px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: 130px;
  float: left;
  border: 5px solid;
  margin: 10px;
`;

const UploadProgress = styled(Circle)`
  width: 60px;
`;

const DeleteImageButton = styled(Button).attrs({ empty: true })`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${themeGet("zIndex.high")};
  background-color: ${themeGet("colors.white")};
  border-radius: 50%;

  &:hover {
    background-color: ${themeGet("colors.white")};
  }
`;

const ImageCoverDiv = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const UploadingImage = ({ imageId, progress, deleteImage }) => {
  if (imageId) {
    return (
      <UploadedImageContainer>
        <DeleteImageButton onClick={deleteImage}>
          <MdClose size={30} />
        </DeleteImageButton>
        <ImageCoverDiv img={imageUrl(imageId)} />
      </UploadedImageContainer>
    );
  } else if (progress) {
    return <UploadProgress percent={progress} strokeWidth="8" strokeColor={theme.colors.primary} />;
  }
  return null;
};

export default UploadingImage;
