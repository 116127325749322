import React from "react";
import { useLocalStore } from "mobx-react-lite";
import { Field } from "utils/forms";
import { MobXProviderContext } from "mobx-react";
import { fromPromise } from "mobx-utils";
import { action, runInAction } from "mobx";
import { searchUsers } from "services/search";

export const searchStoreContext = React.createContext(null);

export const SearchStoreProvider = ({ children }) => {
  const { usersStore, fetchService } = React.useContext(MobXProviderContext);
  const store = useLocalStore(() => ({
    query: new Field(),
    allResultsLoaded: false,
    searchQueryRequest: null,
    searchPage: 0,
    terms: "",
    incrementSearchPage() {
      store.searchPage = store.searchPage + 1;
    },
    users: [],
    get results() {
      return store.users;
    },
    replaceUsers(values) {
      store.users = values.data.map(value => usersStore.deserializeUser(value));
    },
    pushUsers(values) {
      store.users.push(...values.data.map(value => usersStore.deserializeUser(value)));
    },
    get usersCount() {
      return store.users.length;
    },
    loadUsers(terms) {
      !store.allResultsLoaded &&
        (() => {
          if (store.searchPage > 0) {
            store.searchQueryRequest = fromPromise(
              fetchService.fetch(searchUsers(terms, store.searchPage))
            );
            store.searchQueryRequest.then(
              action(values => {
                store.pushUsers(values);
                store.allResultsLoaded = values.totalElements === store.usersCount;
              })
            );
          } else {
            store.searchQueryRequest = fromPromise(fetchService.fetch(searchUsers(terms)));
            store.searchQueryRequest.then(
              action(values => {
                store.replaceUsers(values);
                store.allResultsLoaded = values.totalElements === store.usersCount;
              })
            );
          }
        })();
    },
    loadMoreUsers() {
      store.loadUsers(store.terms);
    },
    resetSearch() {
      runInAction(() => {
        store.searchPage = 0;
        store.allResultsLoaded = false;
      });
    },
  }));
  return <searchStoreContext.Provider value={store}>{children}</searchStoreContext.Provider>;
};

export default SearchStoreProvider;
