import { action } from "mobx";
import { fromPromise } from "mobx-utils";
import React from "react";
import * as trendsApi from "services/trends";
import { useLocalStore, useObserver } from "mobx-react-lite";
import { MobXProviderContext } from "mobx-react";
import Trends from "app/home/trends/Trends";
import { DesktopView, MobileView } from "app/layout/responsiveness/mediaQueries";
import { Button } from "components";
import styled from "styled-components";
import { PageContent } from "app/layout/MainLayout.mobile";
import SearchHeaderMobile from "app/layout/headers/SearchHeader.mobile";

const Centered = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
`;
function ExplorePageMobile({ className }) {
  const { authStore, fetchService } = React.useContext(MobXProviderContext);
  let { getTrendsRequest, trends } = useLocalStore(() => ({
    getTrendsRequest: fromPromise(fetchService.fetch(trendsApi.getTrends())),
    trends: [],
  }));
  React.useEffect(() => {
    getTrendsRequest.then(
      action(trends => {
        trends = trends || [];
      })
    );
  }, [getTrendsRequest]);

  const state = getTrendsRequest && getTrendsRequest.state;
  const isPending = !getTrendsRequest || (!trends && state === "pending");
  const isError = getTrendsRequest && state === "rejected";
  const error = isError && getTrendsRequest.value.code;

  const { currentUser } = authStore;
  return useObserver(() => (
    <React.Fragment>
      <SearchHeaderMobile user={currentUser} photo={currentUser.photo} />
      <PageContent>
        <DesktopView>
          <Centered>
            <Button>HomePage</Button>
          </Centered>
        </DesktopView>
        <MobileView>
          <Trends className={className} isPending={isPending} error={error} trends={trends} />
        </MobileView>
      </PageContent>
    </React.Fragment>
  ));
}

export default ExplorePageMobile;
