import React, { memo } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Label = styled.label`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;

  & > span {
    display: inline-block;
    min-width: 135px;
    text-align: right;
    margin-right: 20px;
    line-height: 25px;
    font-weight: 600;
    color: ${themeGet("colors.strongGrey")};
  }

  & > div {
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const ErrorText = styled.div`
  color: ${themeGet("colors.error")};
  font-weight: 600;
  margin-left: ${(props) => (props.helperPosition === "below" ? 0 : "20px")};
  margin-top: ${(props) => (props.helperPosition === "below" ? "5px" : 0)};
`;

/** helperPosition = 'below' | 'right' */
function InputWrapper({
  children,
  label,
  error,
  helperPosition = "right",
  labelWidth = "inherit",
  className,
}) {
  return (
    <Label className={className}>
      <span style={{ width: labelWidth }}>{label}</span>
      <div
        style={{
          display: "flex",
          flexDirection: helperPosition === "below" ? "column" : "row",
          alignItems: helperPosition === "below" ? "flex-start" : "center",
        }}>
        <div>{children}</div>
        {error && <ErrorText helperPosition={helperPosition}>{error}</ErrorText>}
      </div>
    </Label>
  );
}

export default memo(InputWrapper);
