import { Client as StompClient } from "@stomp/stompjs";
import get from "lodash/get";
import { action, autorun, observable } from "mobx";
import config from "utils/config";
import { captureException } from "utils/logger";

export default class WebSocketService {
  subscriptions = new Map(); // Sub that we want to subscribe to
  subscribed = new Map(); // Sub now subscribed
  client = undefined;
  @observable connected = false;

  async inject({ authStore }) {
    this.authStore = authStore;
    // create stomp client unique instance
    this.newStompClient();
  }

  newStompClient() {
    this.client = new StompClient({
      brokerURL: `${config.wsUrl}/websockets`,
      reconnectDelay: 4000,
      heartbeatIncoming: 15000,
      heartbeatOutgoing: 7000,
      debug: (str) => {},
      // debug: function(str) {
      //   console.debug(str);
      // },
    });
    // Connects (or disconnects) Websocket when user is authentified (or not)
    autorun(() => {
      if (this.authStore.isConnected && !get(this.authStore.currentUser, "isSuperAdmin", false)) {
        this.connect();
      } else {
        this.disconnect();
      }
    });
  }

  initStompClient() {
    this.client.onConnect = async (frame) => {
      // console.debug("Stomp client connected");
      this.updateConnectionStatus(true);
      // Renew subscriptions on each connect
      this.subscriptions.forEach((callback, topic) => {
        // console.debug("Subscribing to topic " + topic);
        this.subscribed.set(topic, this.client.subscribe(topic, callback));
      });
    };
    this.client.onDisconnect = (frame) => {
      // console.debug("Stomp client disconnected");
    };

    this.client.onStompError = (frame) => {
      console.error("Stomp client error:");
      console.error("Broker reported error: " + frame.headers["message"]);
      console.error("Additional details: " + frame.body);
      captureException(frame);
    };
    this.client.onWebSocketError = (event) => {
      console.error("WebSocket error:", event);
      captureException(event);
    };
    this.client.onWebSocketClose = (closeEvent) => {
      const { code, reason, wasClean } = closeEvent;
      this.subscriptions.clear();
      this.subscribed.clear();
      this.updateConnectionStatus(false);
      console.warn(
        `WebSocket connection ${wasClean ? "cleanely" : "dirtily"}  closed with code ${code} ${
          reason ? `and following reason: ${reason}` : ""
        }`
      );
    };
  }

  @action("WS_UPDATE_CONNECTION_STATUS")
  updateConnectionStatus = (isConnected) => {
    console.debug("[updateConnectionStatus]", isConnected);
    this.connected = isConnected;
  };

  async connect() {
    // console.info("WebSocketService connect()");
    if (!this.client.connected) {
      // console.debug("WebSocket connection is about to be activated");
      this.initStompClient();
      return this.client.activate();
    }
  }

  /**
   * TODO might be necessary to add an id in order to allow multiple subscriptions on same topic ?
   * Or multiple callback executed in topic's callback.
   * @param {string} topic
   * @param {*} callback
   */
  subscribe(topic, callback) {
    // console.debug("Registering topic:", topic);
    const subRegistered = this.subscriptions.has(topic);
    if (!subRegistered) {
      this.subscriptions.set(topic, callback);
    } else {
      if (this.connected) {
        try {
          if (this.subscribed.has(topic)) {
            this.subscribed.get(topic).unsubscribe();
          }
        } catch (e) {
          console.error(e);
        }
        this.subscribed.set(topic, this.client.subscribe(topic, callback));
      }
    }
  }

  async publish(message) {
    return this.client.publish(message);
  }

  async disconnect() {
    // console.debug("WebSocketService disconnect()");
    this.client.forceDisconnect();
    this.client.deactivate();
  }

  async processMessage(message) {
    let json = {};
    try {
      json = JSON.parse(message.body);
    } catch (e) {
      captureException(e);
      return;
    }
    return json;
  }

  /**
   * Check if param clientId matches currentUser client' id.
   * @param {number} clientId
   */
  checkClientMatch(clientId) {
    // console.debug(
    //   `checkClientMatch with clientId ${clientId}: ${clientId ===
    //     this.authStore.currentUser.client.id}`
    // );
    return clientId === get(this.authStore, ["currentUser", "client", "id"]);
  }

  /**
   * Check if param userId matches current user's id.
   * @param {number} clientId
   */
  checkUserMatch(userId) {
    // console.debug(
    //   `checkUserMatch with user id ${userId}: ${userId === this.authStore.currentUser.id}`
    // );
    return userId === this.authStore.currentUser.id;
  }
}
