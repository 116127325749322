import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const FormSectionContainer = styled.div`
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid ${themeGet("colors.lightGrey")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:first-of-type {
    margin-top: 0px;
  }

  h2 {
    font-weight: 700;
    font-size: 1.3rem;
    color: ${themeGet("colors.primary")};
  }

  input[type="text"],
  textarea {
    width: 300px;
  }
`;

export default function FormSection({ children, style, title, ActionButtonUpper }) {
  return (
    <FormSectionContainer style={style}>
      <div style={{display: 'flex', flexDirection: "row", alignItems:"center", justifyContent:'space-between', width: '100%'}}>
        {title && <h2>{title}</h2>}
        {ActionButtonUpper && <ActionButtonUpper/>}
      </div>
      {children}
    </FormSectionContainer>
  );
}
