import React from "react";
import styled from "styled-components";
import { LoadingContent } from "components";
import { WeezFeed, ProfileImage, DisplayName } from "app/common";
import { themeGet } from "@styled-system/theme-get";
import { marginHorizontal } from "utils/mixins";
import UserCount from "app/common/UserCount";
import NewWeez from "app/common/post/NewWeez";
import { Helmet } from "react-helmet";
import { t } from "utils/i18n";
import TrendsContainer from "./trends";
import Plannificateur from "app/common/plannificateur/Plannificateur";
import { USER_TYPES } from "utils/constants";
import BoostSequences from "app/common/boostSequences/BoostSequences";
import { media } from "utils/styles";
import { useTranslation } from "react-i18next";

const pageMaxWidth = 1200;

const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  max-width: 100%;
  ${media.large} {
    max-width: ${pageMaxWidth + "px"};
  }
  ${marginHorizontal("auto")};
`;

const LeftContainer = styled.div`
  max-width: 280px;
  min-width: 280px;
  flex: 1;
  margin-right: 20px;
`;

const RightContainer = styled.div`
  background-color: ${themeGet("colors.white")};
  min-width: 600px;
  max-width: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ProfileInfoContainer = styled.div`
  background-color: ${themeGet("colors.white")};
  position: relative;
  padding-bottom: 20px;

  &::before {
    content: "";
    height: 95px;
    background-color: ${themeGet("colors.primary")};
    display: block;
  }
`;

const ProfileInfoTop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;

const InfoProfileImage = styled(ProfileImage)`
  width: 72px;
  position: absolute;
  top: -30px;
  left: 10px;
  background-color: ${themeGet("colors.white")};
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-top: 10px;
  flex: 1;
  min-width: 0;
`;

const StyledDisplayName = styled(DisplayName)`
  margin-bottom: 3px;
  flex: 1;
  min-width: 0;
`;

const Username = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${themeGet("colors.grey")};
`;

const ProfileInfoBottom = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
`;

const StyledTrendings = styled(TrendsContainer)`
  margin-top: 20px;
`;

const SequencesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DesktopHomePage = ({ currentUser, weezeFeed }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Helmet>
        <title>{t("home")}</title>
      </Helmet>
      <LeftContainer>
        <ProfileInfoContainer>
          <ProfileInfoTop>
            <InfoProfileImage border photo={currentUser.photo} />
            <UserInfo>
              <StyledDisplayName user={currentUser} size="big" />
              <Username>@{currentUser.username}</Username>
            </UserInfo>
          </ProfileInfoTop>
          <ProfileInfoBottom>
            <UserCount leftAlign label={t("weezes")} value={currentUser.weezsCount} />
            <UserCount leftAlign label={t("subscribers")} value={currentUser.subscriptionsCount} />
            <UserCount
              leftAlign
              label={t("subscribed_plural")}
              value={currentUser.subscribersCount}
            />
          </ProfileInfoBottom>
        </ProfileInfoContainer>
        <StyledTrendings />
      </LeftContainer>
      <RightContainer>
        <NewWeez smallByDefault />
        {weezeFeed ? (
          <WeezFeed weezsFeed={weezeFeed} />
        ) : (
          <LoadingContent label={t("loading_feed")} />
        )}
      </RightContainer>
      {currentUser.role === USER_TYPES.ANIMATOR && (
        <SequencesContainer>
          <Plannificateur />
          <BoostSequences />
        </SequencesContainer>
      )}
    </Container>
  );
};

export default DesktopHomePage;
