import { getServerUrl } from "utils/fetch";

export function createConversation({ composeAs, sendTo }) {
  return {
    url: getServerUrl("/messaging/convers/"),
    method: "POST",
    body: JSON.stringify({ composeAs, sendTo }),
  };
}

export function getConversation(conversationId) {
  return {
    url: getServerUrl(`/messaging/convers/${conversationId}`),
    method: "GET",
  };
}

export function getConversations() {
  return {
    url: getServerUrl(`/messaging/convers`),
    method: "GET",
  };
}

export function getAllConversations() {
  return {
    url: getServerUrl(`/messaging/convers/all`),
    method: "GET",
  };
}

export function sendMessage(conversationId, message) {
  return {
    url: getServerUrl(`/messaging/convers/${conversationId}`),
    method: "POST",
    body: JSON.stringify({ content: message }),
  };
}

export function updateParticipantLastRead(conversationId, participantId) {
  return {
    url: getServerUrl(`/messaging/convers/${conversationId}/participants/${participantId}/read`, {
      at: new Date().toISOString(),
    }),
    method: "PATCH",
  };
}
