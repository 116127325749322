import { LangSelect } from "app/common";
import { Button, Input, InputWrapper, Modal, Select, ErrorText } from "components";
import { action, computed, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import modalHoc from "react-modal-hoc";
import { createBoost } from "services/boost";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { cancelEvent, isPending, isError, getErrorMessage } from "utils";
import { getInputFieldProps, getNumberFieldProps } from "utils/forms2";
import { t } from "utils/i18n";
import { ifProp, marginVertical, paddingVertical } from "utils/mixins";
import boostWeezeFormBuilder, { buildNewMoodField } from "./boostWeezeFormBuilder";
import InputOrned from "components/form/InputOrned";
import { useTranslation, withTranslation } from "react-i18next";
import { eventService } from "events/EventService";

const Container = styled.div`
  padding: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const MoodFormContainer = styled.div`
  margin-bottom: 10px;
  border-top: 1px solid ${themeGet("colors.lightGrey")};
  /* ${marginVertical("10px")} */
  /* ${paddingVertical("10px")} */
  /* border-top: 1px solid ${themeGet("colors.lightGrey")}; */
  /* ${ifProp(
    "background",
    css`
      background-color: #f2eab0;
    `
  )}; */
`;

const NumberInput = styled(Input)`
  width: 80px;
`;

const MoodInputCount = styled(NumberInput)`
  margin-left: 20px;
  margin-right: 5px;
  width: 70px;
`;

const MultplicatorInputCount = styled(NumberInput)`
  margin-left: 5px;
  margin-right: 5px;
  width: 60px;
`;

const NumberInputOrned = styled(InputOrned)`
  width: 80px;
`;

const HashtagInputCount = styled(NumberInputOrned)`
  margin-left: 20px;
`;

const HashtagCount = styled.span`
  color: ${themeGet("colors.grey")};
  margin-left: 10px;
`;

const AddButton = styled(Button)`
  margin: 10px;
  color: ${themeGet("colors.success")};
`;

const RemoveButton = styled(Button)`
  margin: 10px;
  color: ${themeGet("colors.error")};
`;

const TotalReweezes = styled.span`
  display: block;
  text-align: right;
  font-weight: 600;
  text-transform: uppercase;
`;

const InputWrapperRestyled = styled(InputWrapper)`
  align-items: start;
  span {
    text-align: left;
    min-width: 100px;
  }
`;

const MoodForm = observer(({ field, index, moods }) => {
  const { t } = useTranslation();
  const totalWeezesForMood = field.get("count").value * (field.get("multiplicator").value || 1);
  return (
    <MoodFormContainer>
      <InputWrapperRestyled
        helperPosition="below"
        label={`${t("boost.mood")} ${index}`}
        error={field.get("mood").errorMessage || field.get("count").errorMessage}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Select options={moods} {...getInputFieldProps(field.get("mood"))} width="200px" />
          <MoodInputCount
            placeholder="Qté"
            type="number"
            min="0"
            step="5"
            {...getNumberFieldProps(field.get("count"))}
          />
          x
          <MultplicatorInputCount
            placeholder="XL"
            type="number"
            min={0}
            {...getNumberFieldProps(field.get("multiplicator"))}
          />
          =
          <NumberInput type="number" min={0} disabled value={totalWeezesForMood} />
        </div>
      </InputWrapperRestyled>
      <InputWrapperRestyled
        helperPosition="below"
        label={`Hashtag`}
        error={field.get("hashtag").errorMessage || field.get("percent").errorMessage}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <InputOrned
            width="200px"
            ornamentStart={<div>#</div>}
            {...getInputFieldProps(field.get("hashtag"))}
          />
          <HashtagInputCount
            placeholder="Ratio"
            type="number"
            min="0"
            max="100"
            step="5"
            ornamentEnd={<div>%</div>}
            {...getNumberFieldProps(field.get("percent"))}
          />
          <HashtagCount>
            ({Math.round((field.get("count").value * field.get("percent").value) / 100)})
          </HashtagCount>
        </div>
      </InputWrapperRestyled>
    </MoodFormContainer>
  );
});

@inject("fetchService", "boostStore", "viewStore")
@observer
class BoostWeezeModal extends React.Component {
  form = boostWeezeFormBuilder({ t: this.props.t });

  @observable requestObject;

  @action("CREATE_BOOST")
  onSubmit = cancelEvent(() => {
    if (!this.form.isValid) {
      this.form.markAsTouched();
      return false;
    }
    const { fetchService, onRequestClose, viewStore } = this.props;
    this.requestObject = fromPromise(
      fetchService
        .fetch(createBoost(Object.assign(this.form.value, { weezeId: this.props.weezeId })))
        .then(eventService.emit("boost_new"))
    );
    this.requestObject
      .then(() => {
        onRequestClose();
        viewStore.pushNotification("Séquence boost lancée avec succés");
      })
      .catch((e) => {
        console.error(e);
      });
  });

  @action("RESET_BOOST")
  resetForm = () => {
    this.form.reset(true);
    this.requestObject = null;
  };

  addMood = (t) =>
    action("ADD_MOOD", () => {
      this.form.get("moods").fields.push(buildNewMoodField({ t }));
    });

  @action("REMOVE_MOOD")
  removeMood = () => {
    this.form.get("moods").fields.pop();
  };

  @computed
  get moodsOptions() {
    return this.props.boostStore.moods.map((mood) => {
      return {
        value: mood,
        label: t(`boost.moods.${mood}`),
      };
    });
  }

  render() {
    const { onRequestClose, t, i18n } = this.props;
    const totalReweezes = this.form
      .get("moods")
      .fields.reduce((count, field) => count + field.get("count").value, 0);
    return (
      <Modal allowScroll isOpen title={t("boost.config_title")} onRequestClose={onRequestClose}>
        <Container>
          <ErrorText>
            {isError(this.requestObject) && getErrorMessage(this.requestObject.value)}
          </ErrorText>
          <form autoComplete="off" onSubmit={this.onSubmit}>
            <InputWrapperRestyled
              helperPosition="below"
              label={t("boost.fields.duration_label")}
              error={this.form.get("duration").errorMessage}>
              <NumberInput type="number" {...getNumberFieldProps(this.form.get("duration"))} />
            </InputWrapperRestyled>
            <InputWrapperRestyled
              helperPosition="below"
              label={t("boost.fields.lang_label")}
              error={this.form.get("lang").errorMessage}>
              <LangSelect {...getInputFieldProps(this.form.get("lang"))} value={i18n.language} />
            </InputWrapperRestyled>
            {this.form.get("moods").fields.map((field, i) => (
              <MoodForm field={field} key={i} index={i + 1} moods={this.moodsOptions} />
            ))}

            <ButtonContainer>
              <AddButton
                empty
                onClick={this.addMood(t)}
                disabled={this.form.get("moods").fields.length >= 6}>
                <MdAddCircleOutline />
                {t("boost.buttons.mood_add")}
              </AddButton>
              <RemoveButton
                empty
                onClick={this.removeMood}
                disabled={this.form.get("moods").fields.length <= 1}>
                <MdRemoveCircleOutline />
                {t("boost.buttons.mood_remove")}
              </RemoveButton>
            </ButtonContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}>
              <InputWrapperRestyled
                helperPosition="below"
                label={t("boost.fields.likes_label")}
                error={this.form.get("likes").errorMessage}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <NumberInputOrned
                    ornamentEnd={<div>%</div>}
                    placeholder="Ratio"
                    type="number"
                    min="0"
                    max="100"
                    step="5"
                    {...getNumberFieldProps(this.form.get("likes"))}
                  />
                  <HashtagCount>
                    ({Math.round((this.form.get("likes").value * totalReweezes) / 100)})
                  </HashtagCount>
                </div>
              </InputWrapperRestyled>
              <TotalReweezes>
                {`${t("boost.fields.count_total_label")}: ${totalReweezes}`}
              </TotalReweezes>
            </div>

            <ButtonContainer>
              <Button onClick={onRequestClose} disabled={isPending(this.requestObject)}>
                {t("cancel")}
              </Button>
              <Button secondary onClick={this.resetForm} disabled={isPending(this.requestObject)}>
                {t("boost.buttons.reset")}
              </Button>
              <Button
                full
                type="submit"
                invalid={!this.form.isValid}
                loading={isPending(this.requestObject)}>
                {t("boost.buttons.launch")}
              </Button>
            </ButtonContainer>
          </form>
        </Container>
      </Modal>
    );
  }
}

export default modalHoc(withTranslation()(BoostWeezeModal));
