import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ifProp } from "utils/mixins";

const Container = styled.div`
  height: 30px;
  overflow: hidden;
  position: relative;
  width: ${props => (props.width ? props.width : "auto")};
`;

const StyledSelect = styled.select`
  padding-right: 25px;
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 30px;
  width: 100%;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  font-family: ${themeGet("defaultFont")};
  font-size: 14px;
  font-weight: 400;
  color: ${themeGet("colors.strongGrey")};
  border: 1px solid ${themeGet("colors.grey")};
  background-color: white;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: ${themeGet("colors.grey")};
  }

  & option {
    color: ${themeGet("colors.text")};
    font-family: ${themeGet("defaultFont")};
    font-size: 14px;
    font-weight: 500;
  }

  & option[disabled] {
    color: ${themeGet("colors.grey")};
    font-weight: 400;
    height: 0;
  }

  ${ifProp(
    "value",
    css`
      color: ${themeGet("colors.text")};
      font-weight: 400;
    `
  )};

  ${ifProp(
    "error",
    css`
      border-color: ${themeGet("colors.error")};
    `
  )};
`;

const IconContainer = styled.div`
  position: absolute;
  right: 2px;
  top: 5px;
  pointer-events: none;
  color: ${themeGet("colors.strongGrey")};
`;

export default class Select extends React.Component {
  render() {
    const {
      value,
      onChange,
      onBlur,
      error,
      className,
      options,
      label,
      variant = "primary",
      width,
    } = this.props;

    const optionsElements = options.map(option => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });

    return (
      <Container className={className} width={width}>
        <StyledSelect
          variant={variant}
          value={value || ""}
          onChange={evt => onChange(evt, evt.target.value)}
          onBlur={onBlur}
          error={error}>
          <option value="" disabled hidden>
            {label}
          </option>
          {optionsElements}
        </StyledSelect>
        <IconContainer>
          <MdKeyboardArrowDown size={23} />
        </IconContainer>
      </Container>
    );
  }
}
