import { getServerUrl } from "utils/fetch";

export function postWeez({ text, mentions, medias, authorId, sponsored }) {
  const data = {
    text,
    mentions,
    medias,
    authorId,
    sponsored,
  };

  const url = getServerUrl(`/weezs/post`);

  return {
    url,
    method: "POST",
    body: JSON.stringify(data),
  };
}

export function reweez(id, { text, mentions, medias, authorId, sponsored }) {
  const data = {
    text,
    mentions,
    medias,
    authorId,
    sponsored,
  };

  const url = getServerUrl(`/weezs/${id}/reweez`);

  return {
    url,
    method: "POST",
    body: JSON.stringify(data),
  };
}

export function reply(id, { text, mentions, medias, authorId }) {
  const data = {
    text,
    mentions,
    medias,
    authorId,
  };

  const url = getServerUrl(`/weezs/${id}/reply`);

  return {
    url,
    method: "POST",
    body: JSON.stringify(data),
  };
}

export function likeWeez(id, value) {
  const url = getServerUrl(`/weezs/${id}/like`);

  return {
    url,
    method: "POST",
    body: JSON.stringify(!!value),
  };
}

export function deleteWeez(id) {
  const url = getServerUrl(`/weezs/${id}/delete`);

  return {
    url,
    method: "DELETE",
  };
}

export function getWeezes(hashtag) {
  return ({ after, before }) => {
    const url = getServerUrl(`/weezs`, { hashtag, after, before });

    return {
      url,
      method: "GET",
    };
  };
}

export function getWeeze(weezeId) {
  const url = getServerUrl(`/weezs/${weezeId}`);
  return {
    url,
    method: "GET",
  };
}
