import { Button, DatepickerInput, Input, InputWrapper, Modal } from "components";
import DateFormat from "components/DateFormat";
import addDays from "date-fns/addDays";
import endOfDay from "date-fns/endOfDay";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import frLocale from "date-fns/locale/fr";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { MdAccountCircle, MdLink } from "react-icons/md";
import styled from "styled-components";
import { CLONE_ACCESS_TYPES } from "utils/constants";
import { t } from "utils/i18n";
import * as clonesApi from "services/clones";
import { inject } from "mobx-react";
import themeGet from "@styled-system/theme-get";

export const CloneCreateModals = inject("fetchService")(
  ({ onClose, onConfirm, clientPath, fetchService }) => {
    const [step, setStep] = useState(0);
    const [clonePath, setClonePath] = useState("");
    const [clonePathError, setClonePathError] = useState();
    const [accessType, setAccessType] = useState();
    const [expirationDate, setValidityDate] = useState(addDays(new Date(), 1));
    const [isConfirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

    const handleConfirmCloneCreation = () => {
      setConfirmButtonDisabled(true);
      onConfirm({
        path: clonePath,
        expirationDate: endOfDay(expirationDate).toISOString(),
        createdOn: new Date().toISOString(),
        accessType,
      });
    };

    const handleConfirmClonePath = async () => {
      try {
        const clonePathExists = await fetchService.fetch(clonesApi.checkClonePath(clonePath));
        if (clonePathExists) {
          setClonePathError("Le chemin du clone existe déjà");
        } else {
          setStep(step + 1);
        }
      } catch (e) {}
    };

    const handleValidityDateChange = (date) => {
      setValidityDate(date);
    };

    const handleClonePathChange = (evt) => {
      setClonePath(evt.target.value);
      if (/[^A-Za-z0-9_-]+/g.test(evt.target.value.normalize("NFD"))) {
        setClonePathError("Les caractères spéciaux et accentués ne sont pas autorisés");
      } else {
        setClonePathError(undefined);
      }
    };

    return (
      <>
        {step === 0 && (
          <Modal isOpen={step === 0} onRequestClose={onClose} title={"Créer un clone"}>
            <ModalContentTitle>Nommer votre clone temporaire</ModalContentTitle>
            <ActionButtons>
              {`${window.location.hostname}/${clientPath ? clientPath + "/clones/" : ""}`}
              <Input
                style={{ width: 150 }}
                value={clonePath}
                error={clonePathError}
                onChange={handleClonePathChange}
              />
            </ActionButtons>
            <ErrorMessage>{clonePathError}</ErrorMessage>
            <ActionButtons>
              <Button admin onClick={onClose}>
                Annuler
              </Button>
              <Button
                admin
                full
                onClick={handleConfirmClonePath}
                disabled={isEmpty(clonePath) || clonePathError}>
                Suivant
              </Button>
            </ActionButtons>
          </Modal>
        )}
        {step === 1 && (
          <Modal isOpen={step === 1} onRequestClose={onClose} title={"Créer un clone"}>
            <ModalContentTitle>Sélectionner le type d'authentification</ModalContentTitle>
            <ActionButtons>
              <Button
                admin
                full={accessType === CLONE_ACCESS_TYPES.URL}
                onClick={() => setAccessType(CLONE_ACCESS_TYPES.URL)}>
                <MdLink size={20} />
                {t(`clone_access_${CLONE_ACCESS_TYPES.URL}`)}
              </Button>
              <Button
                admin
                full={accessType === CLONE_ACCESS_TYPES.LOGIN}
                onClick={() => setAccessType(CLONE_ACCESS_TYPES.LOGIN)}>
                <MdAccountCircle size={20} />
                {t(`clone_access_${CLONE_ACCESS_TYPES.LOGIN}`)}
              </Button>
            </ActionButtons>
            <ModalContentText>
              {accessType === CLONE_ACCESS_TYPES.URL &&
                "Des liens d'accès seront générés automatiquement. "}
              {accessType === CLONE_ACCESS_TYPES.LOGIN &&
                "Un identifiant pour les animateurs et un identifiant pour les joueurs seront séléctionnés automatiquement. "}
            </ModalContentText>
            <ActionButtons>
              <Button admin onClick={() => setStep(step - 1)}>
                {"Précédent"}
              </Button>
              <Button admin full disabled={!accessType} onClick={() => setStep(step + 1)}>
                Suivant
              </Button>
            </ActionButtons>
          </Modal>
        )}
        {step === 2 && (
          <Modal isOpen={step === 2} onRequestClose={onClose} title={"Créer un clone"}>
            <ModalContentTitle>Sélectionner la durée de validité du clone</ModalContentTitle>
            <ModalContentText>
              Une fois la date de validité atteinte, le clone ne sera plus accessible.
            </ModalContentText>
            <ActionButtons>
              <InputWrapper label={t("clone_validity_date")}>
                <DatepickerInput
                  field={{ value: expirationDate, setValue: handleValidityDateChange }}
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 16)}
                />
              </InputWrapper>
            </ActionButtons>
            <ActionButtons>
              <Button
                admin
                onClick={() => setStep(accessType === CLONE_ACCESS_TYPES.LOGIN ? step - 1 : 0)}>
                {"Précédent"}
              </Button>
              <Button admin full disabled={!expirationDate} onClick={() => setStep(step + 1)}>
                Suivant
              </Button>
            </ActionButtons>
          </Modal>
        )}
        {step === 3 && (
          <Modal isOpen={step === 3} onRequestClose={onClose} title={"Créer un clone"}>
            <ModalContentTitle>Résumé des informations de création</ModalContentTitle>
            <ModalContentText>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <div style={{ padding: 5 }}>
                <b>Animateurs:</b> Lien ou identifiants
              </div>
              <div style={{ padding: 5 }}>
                <b>Joueurs:</b> Lien ou identifiants
              </div> */}
                <div style={{ padding: 5 }}>
                  Valide jusqu'au <DateFormat pattern="dd/MM/yyyy">{expirationDate}</DateFormat>
                  <b> ({formatDistanceToNow(endOfDay(expirationDate), { locale: frLocale })})</b>
                </div>
              </div>
            </ModalContentText>
            <ActionButtons>
              <Button admin onClick={() => setStep(step - 1)}>
                {"Précédent"}
              </Button>
              <Button
                admin
                full
                onClick={handleConfirmCloneCreation}
                disabled={isConfirmButtonDisabled}>
                Valider
              </Button>
            </ActionButtons>
          </Modal>
        )}
      </>
    );
  }
);

const ModalContentTitle = styled.div`
  display: flex;
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
`;

const ModalContentText = styled.div`
  display: flex;
  max-width: 80%;
  font-size: 13px;
  margin: 0.5rem auto;
  justify-content: center;
  min-height: 2rem;
`;
const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  > * {
    margin: 0 10px;
  }
`;
const ErrorMessage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${themeGet("colors.error")};
  font-weight: 600;
  margin-left: 20px;
  min-height: 1.2rem;
`;
