import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import ProfileImage from "app/common/ProfileImage";
import MobileDrawer from "../menus/MobileDrawer";
import { MdArrowBack } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { searchStoreContext } from "stores/SearchStoreProvider";
import SearchBar from "components/searchbar";

const Container = styled.div`
  display: flex;
  position: fixed;
  height: 65px;
  width: 100%;
  padding: 0 5px;
  align-items: center;
  background-color: ${themeGet("colors.white")};
  z-index: ${themeGet("zIndex.higher")};
  box-shadow: ${themeGet("shadows.default")};
`;

const ProfilContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
`;

const ArrowBackContainer = ProfilContainer;
const WidthLimitedSearchBar = styled(SearchBar)`
  width: 200px;
  max-width: 70%;
  margin-left: 1rem;
`;

function SearchHeader({ user, photo }) {
  const searchStore = React.useContext(searchStoreContext);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [showArrowBack, setShowArrowBack] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  React.useEffect(() => {
    if (location.pathname.includes("/search")) {
      setShowArrowBack(true);
    } else {
      setShowArrowBack(false);
    }
  }, [location]);
  const handleOpenMenu = (isOpen = true) => () => {
    setMenuOpen(true);
  };
  const handleSearchBarPressEnter = terms => {
    searchStore.resetSearch();
    history.replace(`/search?terms=${encodeURIComponent(terms)}`);
  };
  const handleClickArrowBack = () => {
    history.push("/explore");
  };
  return (
    <React.Fragment>
      <Container>
        {!showArrowBack && (
          <ProfilContainer onClick={handleOpenMenu()}>
            <ProfileImage photo={photo} border />
          </ProfilContainer>
        )}
        {showArrowBack && (
          <ArrowBackContainer>
            <MdArrowBack size={25} onClick={handleClickArrowBack} />
          </ArrowBackContainer>
        )}
        <WidthLimitedSearchBar onPressEnter={handleSearchBarPressEnter} />
      </Container>

      <MobileDrawer isOpen={menuOpen} onCloseMenu={handleOpenMenu(false)} user={user} />
    </React.Fragment>
  );
}

export default React.memo(SearchHeader);
