import { action, autorun, computed, observable, toJS } from "mobx";

export default class Field {
  @observable _value;
  @observable _interacted;
  @observable _validation;
  @observable disabled = false;
  label = "";
  opts = {};
  _isValidFn = [];

  clear() {
    this._value = undefined;
  }

  @action("FIELD_SET_DISABLED")
  setDisabled(value) {
    this.disabled = value;
  }

  @action("FIELD_MARK_AS_TOUCH")
  markAsTouch = () => {
    if (!this._interacted) {
      this._interacted = true;
    }
  };

  @computed
  get value() {
    if (this.computeValue) {
      return this.computeValue(this._value);
    }
    return this._value;
  }

  @action("SET_FIELD_VALUE")
  setValue = (val, interract = true) => {
    if (interract && !this._interacted) {
      this._interacted = true;
    }
    this._value = val;
  };

  onChange = (evt, value) => {
    if (value !== this._value) {
      this.setValue(value);
    }
  };

  @action("SET_FIELD_VALIDATION")
  setValidation(validation) {
    this._validation = validation;
  }

  @computed
  get errorMessage() {
    if (!this._interacted) {
      return "";
    }
    return this._validation.message;
  }

  @computed
  get isValid() {
    return this._validation.valid;
  }

  jsValue() {
    return toJS(this._value);
  }

  validate = async () => {
    let lastRes;
    // FIXME: better code
    // if (this.value && this.value.map) {
    //   this.value.map(() => null);
    // }

    for (let i = 0; i < this._isValidFn.length; i++) {
      const isValidFn = this._isValidFn[i];

      let res = isValidFn(this._value, this.label);
      if (typeof res === "function") {
        // Async validation must occure in a second function in order to let mobx detect used variables
        res = await res();
      }

      if (res === null || res === "undefined" || !(typeof res === "object")) {
        throw new Error("Invalid validator result");
      }
      lastRes = res;
      if (!res.valid || res.stopValidation) {
        break;
      }
    }

    if (lastRes) {
      this.setValidation(lastRes);
    }
  };

  constructor({
    initValue = "",
    validate,
    label = "",
    debounce = false,
    disabled = false,
    computeValue,
  } = {}) {
    this.disabled = disabled;
    this.label = label;
    this.computeValue = computeValue;
    this.setValue(initValue, false);
    if (validate) {
      if (validate instanceof Array) {
        this._isValidFn = validate;
      } else {
        this._isValidFn = [validate];
      }
    }

    if (this._isValidFn.length) {
      this._validation = { valid: false };
      autorun(this.validate, { delay: debounce ? 300 : 0 });
    } else {
      this._validation = { valid: true };
    }
  }
}
