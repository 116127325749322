import { ClientAutocomplete } from "app/common";
import {
  Button,
  ErrorText,
  FormSection,
  InputField,
  InputWrapper,
  RadioButton,
  RadioGroup,
  Switch,
  TextArea,
} from "components";
import { ImageDropzone } from "components/form";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { USER_TYPES, FORM_MODE_CREATE, FORM_MODE_CONSULT, FORM_MODE_EDIT } from "utils/constants";
import { getInputFieldProps, getSwitchProps } from "utils/forms";
import { t } from "utils/i18n";
import SubscriptionManagement from "./SubscriptionManagement";
import CategoryAutocomplete from "app/common/CategoryAutocomplete";

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;

  & > * {
    margin-right: 20px;
  }
`;

const StyledImageDropzone = styled(ImageDropzone)`
  align-self: center;
`;

export default observer(function UserForm({
  form,
  onSubmit,
  onCancel,
  requestObject,
  currentUser,
  mode = FORM_MODE_CREATE,
}) {
  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <FormSection title="Photo de profil">
        <StyledImageDropzone
          field={form.photo}
          metadata={{ clientId: form.values.client ? form.values.client.id : null }}
        />
      </FormSection>
      <FormSection title="Informations générales">
        <InputWrapper label={t("type") + " " + t("required")} error={form.role.errorMessage}>
          <StyledRadioGroup {...getInputFieldProps(form.role)}>
            <RadioButton label={t("animators")} value={USER_TYPES.ANIMATOR} />
            <RadioButton label={t("player")} value={USER_TYPES.USER} />
            <RadioButton
              label={t("dummy")}
              value={USER_TYPES.DUMMY}
              disabled={mode === FORM_MODE_EDIT || mode === FORM_MODE_CONSULT}
            />
          </StyledRadioGroup>
        </InputWrapper>
        {currentUser.role === USER_TYPES.SUPERADMIN && form.role.value !== USER_TYPES.DUMMY && (
          <InputWrapper label={t("client") + " " + t("required")} error={form.client.errorMessage}>
            <ClientAutocomplete field={form.client} />
          </InputWrapper>
        )}
        {(currentUser.role === USER_TYPES.SUPERADMIN || currentUser.role === USER_TYPES.ANIMATOR) &&
          form.role.value === USER_TYPES.DUMMY && (
            <InputWrapper
              label={`${t("user_form.categories.label")} ${t("required")}`}
              error={form.category.errorMessage}>
              <CategoryAutocomplete field={form.category} />
            </InputWrapper>
          )}
        <InputField
          label={`${t("user_form.login.label")} ${t("required")}`}
          type="text"
          field={form.username}
        />
        <InputField label={t("lastname") + " " + t("required")} type="text" field={form.lastname} />
        <InputField label={t("firstname")} type="text" field={form.firstname} />
        <InputField label={t("email")} type="text" field={form.email} />
        <InputWrapper label={t("officiel")}>
          <Switch {...getSwitchProps(form.certified)} />
        </InputWrapper>
        <InputField label={t("location")} type="text" field={form.location} />
        <InputWrapper label={t("bio")}>
          <TextArea maxLength={160} rows={3} maxRows={10} {...getInputFieldProps(form.bio)} />
        </InputWrapper>
        {form.role.value !== USER_TYPES.DUMMY && (
          <InputField label={t("password")} type="text" field={form.password} />
        )}
      </FormSection>
      <FormSection title={t("fake_subscriber")}>
        <InputField label={t("weezs_count")} type="number" field={form.weezsCount} />
        <InputField
          label={t("subscriptions_count")}
          type="number"
          field={form.subscriptionsCount}
        />
        <InputField label={t("subscribers_count")} type="number" field={form.subscribersCount} />
      </FormSection>
      <FormSection title={t("reel_subscriber")}>
        <SubscriptionManagement
          subscriptionsCount={form.subscriptionsCount}
          subscriptions={form.subscriptions}
          mode={mode}
        />
      </FormSection>
      {requestObject && requestObject.state === "rejected" && (
        <ErrorText>{t(requestObject.value.code)}</ErrorText>
      )}
      <ButtonContainer>
        <Button
          admin
          onClick={onCancel}
          disabled={requestObject && requestObject.state === "pending"}>
          {t("cancel")}
        </Button>
        <Button
          admin
          full
          type="submit"
          invalid={!form.isValid}
          loading={requestObject && requestObject.state === "pending"}>
          {mode === FORM_MODE_CREATE ? t("create_user") : t("save")}
        </Button>
      </ButtonContainer>
    </form>
  );
});
