import React from "react";
import AdminPage from "app/admin";
import NewWeezModal from "app/common/post/NewWeezModal";
import HomePage from "app/home";
import NotificationsPage from "app/notifications";
import ProfilePage from "app/profile";
import SearchPage from "app/search";
import { AuthorizedRoute } from "components";

import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { USER_TYPES } from "utils/constants";
import Header from "./headers/Header";
import Notifications from "./Notifications";
import HashtagPage from "app/hashtag";
import HelpPage from "app/help";
import Disclaimer from "app/disclaimer";
import MessagesRouter from "app/messages/MessagesRouter.desktop";

const Container = styled.div`
  background-color: ${themeGet("colors.backgroundGrey")};
  min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const PageContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

function MainLayoutDesktop({
  viewRoles = [],
  redirectUrl,
  newWeezModalOpened = false,
  toggleNewWeezModal = () => null,
}) {
  return (
    <Container>
      <Header />
      <PageContent>
        <Switch>
          <AuthorizedRoute
            roles={[USER_TYPES.SUPERADMIN, USER_TYPES.ANIMATOR]}
            redirectTo={redirectUrl}
            path="/admin"
            component={AdminPage}
          />
          <AuthorizedRoute
            roles={viewRoles}
            redirectTo={redirectUrl}
            path={["/"]}
            exact
            component={HomePage}
          />
          <AuthorizedRoute
            roles={viewRoles}
            redirectTo={redirectUrl}
            path="/notifications"
            component={NotificationsPage}
          />
          <AuthorizedRoute
            roles={viewRoles}
            redirectTo={redirectUrl}
            path="/search"
            component={SearchPage}
          />
          <AuthorizedRoute
            roles={viewRoles}
            redirectTo={redirectUrl}
            path="/messages"
            component={MessagesRouter}
          />
          <AuthorizedRoute
            roles={viewRoles}
            redirectTo={redirectUrl}
            path="/hashtag/:hashtag"
            component={HashtagPage}
          />
          <AuthorizedRoute
            exact
            roles={viewRoles}
            redirectTo={redirectUrl}
            path="/help"
            component={HelpPage}
          />
          <Route exact path="/disclaimer" component={Disclaimer} />
          <Route path="/explore">
            <Redirect to={"/"} />
          </Route>
          <AuthorizedRoute
            roles={viewRoles}
            redirectTo={redirectUrl}
            path="/:username"
            component={ProfilePage}
          />
          <Redirect to={redirectUrl} />
        </Switch>
      </PageContent>
      <Notifications />
      <NewWeezModal isOpen={newWeezModalOpened} onRequestClose={toggleNewWeezModal} />
    </Container>
  );
}

export default MainLayoutDesktop;
