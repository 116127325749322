import { useMediaQuery } from "react-responsive";
import { isMobile as isMobileRdd } from "react-device-detect";
export { MobileView, BrowserView } from "react-device-detect";

export function isPortrait(onChange) {
  return useMediaQuery({ query: "(orientation: portrait)" }, undefined, onChange);
}
export function isLandscape(onChange) {
  return useMediaQuery({ query: "(orientation: landscape)" }, undefined, onChange);
}
/**
 * @param {function} onChange
 */
export function isMobile(onChange) {
  // return useMediaQuery({ maxWidth: 1199 }, undefined, onChange);
  return isMobileRdd;
}
export function isNotMobile(onChange) {
  return !isMobileRdd;
  // return useMediaQuery({ minWidth: 1200 }, undefined, onChange);
}
export function isTabletOrMobileDevice() {
  return useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
}
// export function MobileView({ children, onChange }) {
//   // return isMobile(onChange) ? children : null;
// }
// export function BrowserView({ children, onChange }) {
//   // return isNotMobile(onChange) ? children : null;
// }

/**
 * Same as
 * @param {Object} props
 */
export function DesktopView({ children, onChange }) {
  return isNotMobile(onChange) ? children : null;
}
export function PortraitView({ children, onChange }) {
  return isPortrait(onChange) ? children : null;
}
export function LandscapeView({ children, onChange }) {
  return isLandscape(onChange) ? children : null;
}
