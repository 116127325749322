import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ConversationPage from "./ConversationPage";
import ConversationsPage from "./ConversationsPage";
import ComposePage from "./ComposePage";

const MessagesRouter = () => {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={`${path}/`} component={ConversationsPage} />
        <Route path={`${path}/compose`} component={ComposePage} />
        <Route path={`${path}/:id`} component={ConversationPage} />
      </Switch>
    </React.Fragment>
  );
};

export default MessagesRouter;
