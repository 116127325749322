import {  identifier, serializable } from "serializr";
import { computed } from "mobx";

class LiteUser {
  @serializable(identifier())
  id;
  @serializable
  username;
  @serializable
  firstname;
  @serializable
  lastname;
  @serializable
  photo;
  @computed
  get displayName() {
    return `${this.firstname} ${this.lastname}`.trim();
  }

  static initSchema() {
    // createModelSchema(LiteUser, {
    //   id: identifier(),
    //   username: true,
    //   firstname: primitive(),
    //   lastname: primitive(),
    //   photo: primitive(),
    // });
  }
}

export default LiteUser;
