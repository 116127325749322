import React from "react";
import { isNotMobile, isMobile } from "app/layout/responsiveness/mediaQueries";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import SearchBar from "components/searchbar";
import { cancelEvent } from "utils";
import { t } from "utils/i18n";
import useSearchStore from "./useSearchStore";
import { runInAction, action } from "mobx";
import { fromPromise } from "mobx-utils";
import { MobXProviderContext } from "mobx-react";
import { searchUsers } from "services/users";
import useScrollBottomReachedListener from "./useScrollReachedBottomListener";
import { useObserver } from "mobx-react-lite";
import { UserItem } from "app/common";
import { resetList } from "utils/mixins";

function NewConversationPage({ onPrevious, onSelect }) {
  return (
    <div>
      <NewConversationView onClickUser={onSelect} />
    </div>
  );
}

const NewConversationView = styled(NewConversationSection)`
  display: flex;
  flex-direction: column;
  height: ${isNotMobile ? "300px" : "100%"};
  max-height: 100%;
  flex: 1 1 auto;
  margin: 0 auto;
`;

const SearchBarWrapper = styled.div`
  padding: ${isMobile ? 0 : "10px"};
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
`;

const SquaredSearchBar = styled(SearchBar)`
  border: none;
  border-radius: 0;
  padding: 4px 10px;
  &:focus-within {
    background-color: ${themeGet("colors.inputBackground")};
  }
`;

const ResultsWrapper = styled.ul`
  ${resetList};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

function NewConversationSection({ className, onClickUser }) {
  const { fetchService, usersStore } = React.useContext(MobXProviderContext);
  // const processResult = values => values.data.map(value => usersStore.deserializeUser(value));
  const processResult = values => values.map(value => usersStore.deserializeUser(value));
  const store = useSearchStore(processResult);
  const loadResults = terms => {
    !store.allResultsLoaded &&
      runInAction(() => {
        if (store.searchPage > 0) {
          store.searchQueryRequest = fromPromise(
            // fetchService.fetch(searchUsersPaginated({ terms, roles: ["USER"] }, store.searchPage))
            fetchService.fetch(searchUsers(terms, ["USER", "ANIMATOR"]))
          );
          store.searchQueryRequest.then(
            action(values => {
              store.pushResults(values);
              store.allResultsLoaded = values.totalElements === store.usersCount;
            })
          );
        } else {
          store.searchQueryRequest = fromPromise(
            // fetchService.fetch(searchUsersPaginated({ terms, roles: ["USER"] }))
            fetchService.fetch(searchUsers(terms, ["USER", "ANIMATOR"]))
          );
          store.searchQueryRequest.then(
            action(values => {
              store.replaceResults(values);
              // store.allResultsLoaded = values.totalElements === store.resultsCount;
              store.allResultsLoaded = true;
            })
          );
        }
      });
  };
  const handleScroll = () => {
    store.incrementSearchPage();
    loadResults();
  };

  useScrollBottomReachedListener(handleScroll);

  const handleClickUser = user =>
    cancelEvent(() => {
      onClickUser && onClickUser(user);
    });

  const handleSearchBarPressEnter = action(terms => {
    store.resetSearch();
    store.terms = terms;
    loadResults(terms);
  });
  return useObserver(() => (
    <div className={className}>
      <SearchBarWrapper>
        <SquaredSearchBar
          rounded={false}
          placeholder={t("messages.compose.users.search.placeholder")}
          inputProps={{ autoFocus: true }}
          onPressEnter={handleSearchBarPressEnter}
        />
      </SearchBarWrapper>
      <ResultsWrapper>
        {store.resultsCount === 0 && store.allResultsLoaded && <div>Pas de résultats</div>}
        {store.resultsCount > 0 &&
          store.results.map(user => {
            return <UserItem key={user.id} user={user} onClick={handleClickUser(user)} />;
          })}
      </ResultsWrapper>
    </div>
  ));
}

export default NewConversationPage;
