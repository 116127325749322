import React from "react";
import { getInputFieldProps } from "utils/forms";
import Input from "./Input";
import InputWrapper from "./InputWrapper";
import { observer } from "mobx-react";

export default observer(function InputField({
  label,
  field,
  type,
  helperPosition,
  labelWidth,
  ...others
}) {
  return (
    <InputWrapper
      label={label}
      error={field && field.errorMessage}
      helperPosition={helperPosition}
      labelWidth={labelWidth}>
      <Input type={type} {...getInputFieldProps(field)} {...others} />
    </InputWrapper>
  );
});
