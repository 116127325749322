import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import * as trendsApi from "services/trends";
import { REFRESH_TRENDS_INTERVAL } from "utils/constants";
import Trends from "./Trends";

@inject("authStore", "fetchService")
@observer
class TrendsView extends React.Component {
  @observable
  getTrendsRequest;
  @observable
  trends = null;
  checkTrendsInterval;

  componentDidMount() {
    this.loadTrends().then(() => {
      this.checkTrendsInterval = setInterval(this.loadTrends, REFRESH_TRENDS_INTERVAL);
    });
  }

  componentWillUnmount() {
    if (this.checkTrendsInterval) {
      clearInterval(this.checkTrendsInterval);
    }
  }

  @action("LOAD_TRENDS")
  loadTrends = () => {
    this.getTrendsRequest = fromPromise(this.props.fetchService.fetch(trendsApi.getTrends()));
    return this.getTrendsRequest.then(
      action(trends => {
        this.trends = trends || [];
      })
    );
  };

  render() {
    const { className } = this.props;
    const state = this.getTrendsRequest && this.getTrendsRequest.state;
    const isPending = !this.getTrendsRequest || (!this.trends && state === "pending");
    const isError = this.getTrendsRequest && state === "rejected";
    const error = isError && this.getTrendsRequest.value.code;

    return (
      <Trends className={className} isPending={isPending} error={error} trends={this.trends} />
    );
  }
}

export default TrendsView;
