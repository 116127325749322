import { identifier, reference, custom, serializable } from "serializr"
import LiteUser from "./LiteUser"
import { observable } from "mobx"
import parseISO from "date-fns/parseISO"

export default class Participant {
  @serializable(identifier())
  id

  @serializable(
    reference(LiteUser, (uuid, callback, context) => {
      const { store } = context.parentContext.args
      let user = store.getUserById(uuid)
      if (!user) {
        user = store.deserializeUser(context.json.endpoint)
      }
      callback(null, user)
    })
  )
  endpoint

  @serializable(
    reference(LiteUser, (uuid, callback, context) => {
      // Store retrieved from parent context (PrivateMessage in most cases)
      const { store } = context.parentContext.args
      let user = store.getUserById(uuid)
      if (!user) {
        user = store.deserializeUser(context.json.identity)
      }
      callback(null, user)
    })
  )
  identity

  @observable
  @serializable(
    custom(
      (date) => date.toISOString(),
      (value, context, oldValue, callback) => {
        callback(null, value && parseISO(value))
      }
    )
  )
  lastConversationRead

  static initSchema() {
    LiteUser.initSchema()
    // createModelSchema(Participant, {
    //   id: identifier(),
    //   endpoint: reference(LiteUser, (uuid, callback, context) => {
    //     const { store } = context.parentContext.args;
    //     let user = store.getUserById(uuid);
    //     if (!user) {
    //       user = store.deserializeUser(context.json.endpoint);
    //     }
    //     // let user = usersStore.getUserById(uuid)
    //     // if (!user.id) {
    //     //   user = usersStore.deserializeUser(context.json.endpoint);
    //     // }
    //     callback(null, user);
    //   }),
    //   identity: reference(LiteUser, (uuid, callback, context) => {
    //     // let user = usersStore.getUserById(uuid);
    //     // if (!user.id) {
    //     //   user = usersStore.deserializeUser(context.json.identity);
    //     // }
    //     const { store } = context.parentContext.args;
    //     let user = store.getUserById(uuid);
    //     if (!user) {
    //       user = store.deserializeUser(context.json.identity);
    //     }
    //     callback(null, user);
    //   }),
    //   lastConversationRead: custom(
    //     date => date.toISOString(),
    //     (value, context, oldValue, callback) => {
    //       callback(null, value && parseISO(value));
    //     }
    //   ),
    // });
  }
}
