import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const Link = styled(ReactRouterLink)`
  color: ${themeGet("colors.primary")};
`;

export const ErrorText = styled.span`
  color: ${themeGet("colors.error")};
  font-weight: 600;
`;
