import { observable, action, computed } from "mobx";
import { list, identifier, object, serializable, custom } from "serializr";
import Participant from "./Participant";
import PrivateMessage from "./PrivateMessage";
// import { parse } from "date-fns"
import parseISO from "date-fns/parseISO";

export default class Conversation {
  @serializable(identifier())
  id;

  @observable
  @serializable(list(object(PrivateMessage)))
  messages = [];

  @observable
  @serializable(list(object(Participant)))
  participants;

  @serializable
  title;

  @observable
  @serializable(object(PrivateMessage))
  lastMessage;

  @observable
  @serializable(
    custom(
      (date) => date.toISOString(),
      (value, context, oldValue, callback) => {
        callback(null, value && parseISO(value));
      }
    )
  )
  createdOn;

  @observable
  @serializable
  unread; // boolean

  @observable
  @serializable(
    custom(
      (date) => date.toISOString(),
      (value, context, oldValue, callback) => {
        callback(null, value && parseISO(value));
      }
    )
  )
  lastModifiedOn;

  @computed
  get hasUnreadMessage() {
    return this.unread;
  }

  @action("CONV_MESSAGE_ADD")
  addMessage(message, isCurrentlyOpened = false) {
    this.messages.push(message);
    this.lastMessage = message;
    this.lastModifiedOn = parseISO(new Date().toISOString());
    if (!isCurrentlyOpened) {
      this.unread = true;
    }
  }
}
