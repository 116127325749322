import React from "react";
import { MdCancel, MdAdd, MdRemove, MdSearch } from "react-icons/md";
import { action, observable } from "mobx";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import { Field, getInputFieldProps } from "utils/forms";
import { resetList } from "utils/mixins";
import { t } from "utils/i18n";
import * as adminApi from "services/admin";
import { DisplayName, ProfileImage, withInfiniteScroll } from "app/common";

import { Button, Input } from "components";
import ClickableIcon from "./ClickableIcon";
import { FORM_MODE_CONSULT } from "utils/constants";

@inject("fetchService")
@observer
class SubscriptionManagement extends React.Component {
  @observable
  query = new Field();
  @observable
  getUsersRequest = null;
  @observable
  displayForm;
  userMap; // (id, [user, subscribed])
  @observable
  totalFoundElements;
  currentPage;
  totalPages;
  pageSize = 8;
  @observable
  searchLoading;
  constructor(props) {
    super(props);
    this.initialize(props);
    props.subscriptions.value.forEach((user) => this.userMap.set(user.id, [user, true]));
  }

  _addSearchResultsToUserMap = (values) => {
    values.forEach((value) =>
      this.userMap.has(value.id) ? null : this.userMap.set(value.id, [value, false])
    );
  };

  @action
  initialize = () => {
    this.userMap = observable.map(new Map(), { deep: false });
    this.displayForm = true;
    this.totalFoundElements = 0;
    this.currentPage = 0;
    this.totalPages = 0;
    this.searchLoading = false;
    this.getUsersRequest = null;
    this.query = new Field();
  };

  @action
  onSearch = (e) => {
    e.stopPropagation(); // Avoid subsmitting embedding form
    e.preventDefault(); // Avoid submitting current form
    this.currentPage = 0;
    const { fetchService } = this.props;
    this.searchLoading = true;
    fetchService
      .fetch(adminApi.searchUsers({ terms: this.query.value }, this.currentPage, this.pageSize))
      .then(
        action((res) => {
          this._addSearchResultsToUserMap(res.data);
          this.searchLoading = false;
          this.displayForm = false;
          this.totalFoundElements = res.totalElements;
        })
      );
  };

  @action
  loadMoreResults = (e) => {
    const { fetchService } = this.props;
    this.searchLoading = true;
    fetchService
      .fetch(adminApi.searchUsers({ terms: this.query.value }, this.currentPage, this.pageSize))
      .then(
        action((res) => {
          this._addSearchResultsToUserMap(res.data);
          this.searchLoading = false;
          this.currentPage += 1;
        })
      );
  };

  handleSubscribe = (user) =>
    action((evt) => {
      evt.stopPropagation();
      this.userMap.set(user.id, [user, true]);
      this.props.subscriptions.value.add(user);
      // Increment subscriptions count
      this.props.subscriptionsCount.setValue(parseInt(this.props.subscriptionsCount.value, 10) + 1);
    });

  handleUnsubscribe = (user) =>
    action((evt) => {
      evt.stopPropagation();
      this.userMap.set(user.id, [user, false]);
      this.props.subscriptions.value.delete(user);
      // Decrement subscriptions count
      if (this.props.subscriptionsCount.value > 0) {
        this.props.subscriptionsCount.setValue(
          parseInt(this.props.subscriptionsCount.value, 10) - 1
        );
      }
    });

  @action
  handleGoBack = (e) => {
    e.stopPropagation();
    this.query.setValue("");
    this.displayForm = true;
  };

  componentWillUnmount() {
    this.initialize(this.props);
  }

  render() {
    return (
      <Container>
        <BlocStyled title={t("search.title")}>
          {this.displayForm && (
            <SearchForm onSubmit={this.onSearch}>
              <SearchInput
                placeholder={t("search.users.placeholder")}
                {...getInputFieldProps(this.query)}
                disabled={this.props.mode === FORM_MODE_CONSULT}
              />
              <Button admin type="submit" disabled={this.props.mode === FORM_MODE_CONSULT} full>
                <MdSearch size={20} />
              </Button>
            </SearchForm>
          )}
          {!this.displayForm && (
            <React.Fragment>
              <ResultListWithInfinite
                isLoading={this.searchLoading}
                list={Array.from(this.userMap)}
                loadMore={this.loadMoreResults}
                onClick={this.handleSubscribe}
              />
              <ActionsContainer>
                <ClickableIcon icon={MdCancel} onClick={this.handleGoBack} size={30} />
              </ActionsContainer>
            </React.Fragment>
          )}
        </BlocStyled>
        <BlocStyled title="Abonnements">
          <SearchResults>
            {Array.from(this.userMap)
              .filter(([id, [user, subscribed]]) => subscribed)
              .map(([id, [user, subscribed]]) => (
                <ResultItemStyled
                  icon={MdRemove}
                  key={user.id}
                  user={user}
                  onClick={this.handleUnsubscribe(user)}
                />
              ))}
          </SearchResults>
        </BlocStyled>
      </Container>
    );
  }
}

// const withLoading = Component => props => (
//   <div>
//     <Component {...props} />
//     <div className="interactions">{props.isLoading && <span>Loading...</span>}</div>
//   </div>
// );

const ResultList = ({ list, onClick }) => {
  return (
    <SearchResults>
      {list
        .filter(([id, [user, subscribed]]) => !subscribed)
        .map(([id, [user, subscribed]]) => (
          <ResultItemStyled icon={MdAdd} key={user.id} user={user} onClick={onClick(user)} />
        ))}
    </SearchResults>
  );
};

const ResultListWithInfinite = withInfiniteScroll(ResultList);

class ResultItem extends React.PureComponent {
  render() {
    const { className, user, onClick, icon: Icon } = this.props;
    return (
      <div className={className}>
        <div>
          <ClickableIcon icon={Icon} onClick={onClick} size={20} />
        </div>
        <div>
          <ProfileImage photo={user.photo} />
        </div>
        <div>
          <DisplayName user={user} />
          <span>@{user.username}</span>
        </div>
      </div>
    );
  }
}

const ResultItemStyled = styled(ResultItem)`
  display: flex;
  margin: 10px;
  user-select: none;
  & > div:first-child {
    color: #333;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  & > div:nth-child(2) {
    min-width: 40px;
    margin-right: 10px;
  }
  & > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const Bloc = ({ className, title, children }) => (
  <div className={className}>
    <div>{title}</div>
    <div>{children}</div>
  </div>
);
const BlocStyled = styled(Bloc)`
  padding: 10px;
  margin: 0 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 2px;
  & > div:first-child {
    color: #333;
    display: flex;
    justify-content: flex-start;
  }
  overflow-x: hidden;
`;
const SearchForm = styled.form`
  margin-top: 20px;
  display: flex;
`;

const SearchInput = styled(Input)`
  flex: 1;
  margin-right: 10px;
`;

const SearchResults = styled.ul`
  ${resetList};
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ActionsContainer = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default SubscriptionManagement;
