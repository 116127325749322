import React from "react";
import { Autocomplete } from "components";
import matchSorter from "match-sorter";
import { getCategories } from "services/categories";
import { inject } from "mobx-react";
import { t } from "utils/i18n";

function renderCategorieItem(categorie, query) {
  return <div>{categorie.name}</div>;
}
@inject("fetchService")
class CategoryAutocomplete extends React.Component {
  state = {
    categories: [],
  };

  componentDidMount() {
    const { fetchService } = this.props;
    fetchService
      .fetch(getCategories())
      .then(categories => {
        this.setState({
          categories,
        });
      })
      .catch(error => {
        // FIXME notif ?
        console.error(error);
      });
  }

  render() {
    const { field } = this.props;
    const { categories } = this.state;
    if (!categories || !categories.length) {
      return t("user_form.categories.errors.not_available");
    }
    return (
      <Autocomplete
        items={categories}
        placeholder="Sélectionnez une catégorie ..."
        itemToString={item => (item ? `${item.name}` : "")}
        renderItem={renderCategorieItem}
        selectedItem={field.value ? categories.find(categorie=> field.value.id === categorie.id) : null}
        filter={(items, query) =>
          matchSorter(items, query, {
            keys: ["name"],
            threshold: matchSorter.rankings.STARTS_WITH,
          })
        }
        onSelect={item => {
          field.setValue(item ? { id: item.id, name: item.name } : null);
        }}
        error={field.errorMessage}
        disabled={field.disabled}
      />
    );
  }
}

export default CategoryAutocomplete;
