// import createEmojiPlugin from "draft-js-emoji-plugin";
import { themeGet } from "@styled-system/theme-get";
import { isMobile } from "app/layout/responsiveness/mediaQueries";
import {
  Button,
  ErrorContent,
  FilePickerButton,
  OnClickOutside,
  Switch,
  SwitchUserIcon,
} from "components";
import "draft-js-emoji-plugin/lib/plugin.css";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { withTranslation } from "react-i18next";
import { MdClose, MdImage } from "react-icons/md";
import * as weezsApi from "services/weezs";
import styled from "styled-components";
import { cancelEvent } from "utils";
import { USER_TYPES } from "utils/constants";
import { getFileUploadFieldProps, getInputFieldProps, getSwitchProps } from "utils/forms";
import { lightenColor } from "utils/mixins";
import createEmojiPlugin from "vendors/emoji-plugins";
import ProfileImage from "../ProfileImage";
import Weez from "../weez/Weez";
import ChooseUserModal from "./ChooseUserModal";
import newWeezFormDataBuilder from "./newWeezFormDataBuilder";
import UploadingImage from "./UploadingImage";
import WeezEditor from "./WeezEditor";

const ClickOutsideContainer = styled(OnClickOutside)`
  background-color: ${lightenColor(0.3, "colors.primary")};
  padding: 15px;
`;

const TopContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 10px;
`;

const LeftContainer = styled.div`
  width: 50px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`;

const StyledProfileImage = styled(ProfileImage)`
  width: 50px;
`;

const SwitchUserButton = styled(Button)`
  margin-top: 10px;
  width: 30px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-left: ${isMobile ? "10px" : "60px"}; */
  padding: 10px 0 0;
  align-items: center;
  flex-wrap: wrap;
`;

const SponsorContainer = styled.div`
  display: flex;
  align-items: center;
  /* min-width: 300px; */
  & > span {
    margin: 0 10px;
    font-weight: 600;
    white-space: pre;
  }

  & > label {
    transform: scale(0.9);
  }
`;

const WeezContent = styled.div`
  flex: 1;
  padding: 14px;
  border-radius: 4px;
  background: ${themeGet("colors.white")};
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${isMobile ? "100%" : "auto"};
  justify-content: space-between;
  padding: 20px 0 0;
`;

@inject("authStore", "viewStore", "fetchService")
@observer
class NewWeez extends React.Component {
  @observable
  form;

  @observable
  submitting = false;

  @observable
  postWeezRequest;

  @observable
  isFocused;

  @observable
  editorKey;

  @observable
  publishAsModal = false;

  static defaultProps = {
    mode: "new",
  };

  constructor(props) {
    super(props);
    this.resetState();
    this.emojiPlugin = createEmojiPlugin();
    this.plugins = [this.emojiPlugin];
  }

  @action("TOGGLE_PUBLISH_AS")
  togglePublishAsModal = () => {
    this.publishAsModal = !this.publishAsModal;
  };

  @action("SET_PUBLISH_AUTHOR")
  setPublishAuthor = (newAuthor) => {
    this.form.author.setValue(newAuthor);
    this.publishAsModal = false;
  };

  @action
  onSubmit = cancelEvent(() => {
    const { onSuccess, fetchService } = this.props;
    if (!this.form.isValid || this.submitting) {
      this.form.setTouched();
    } else {
      const { weez, mode } = this.props;

      if (mode === "new") {
        this.postWeezRequest = fromPromise(
          fetchService.fetch(
            weezsApi.postWeez({
              text: this.form.text.value.text,
              mentions: this.form.text.value.mentions,
              medias: this.form.image.values ? this.form.image.serialize() : [],
              sponsored: this.form.sponsored.value,
              authorId: this.form.author.value.id,
            })
          )
        );
      } else if (mode === "reweez") {
        this.postWeezRequest = fromPromise(
          weez.reweezIt({
            text: this.form.text.value.text,
            mentions: this.form.text.value.mentions,
            medias: this.form.image.values ? this.form.image.serialize() : [],
            sponsored: this.form.sponsored.value,
            authorId: this.form.author.value.id,
          })
        );
      } else if (mode === "reply") {
        this.postWeezRequest = fromPromise(
          weez.reply({
            text: this.form.text.value.text,
            mentions: this.form.text.value.mentions,
            medias: this.form.image.values ? this.form.image.serialize() : [],
            authorId: this.form.author.value.id,
          })
        );
      } else {
        throw new Error("unexpected");
      }
      this.submitting = true;
      this.postWeezRequest
        .then(() => {
          // viewStore.pushNotification(t(`post.successNotif.${mode}`));
          this.resetState();
          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          this.submitting = false;
          this.viewStore.pushNotification("Weez error.", "error");
        });
    }
  });

  @action("NEW_WEEZ_REST")
  resetState = () => {
    this.form = newWeezFormDataBuilder({ authStore: this.props.authStore });
    this.submitting = false;
    this.isFocused = false;
    this.editorKey = Date.now();
  };

  @action
  onFocus = () => {
    this.isFocused = true;
  };

  @action
  onBlur = (evt) => {
    this.isFocused = false;
  };

  @action
  uploadFiles = (evt, imageId) => {
    evt.stopPropagation();
    this.form.image.setValues(imageId);
  };
  render() {
    const { smallByDefault = false, authStore, weez, mode = "new", t } = this.props;
    const isSmall = !this.isFocused && smallByDefault && !this.form.text.value.text;
    const dismissedOnClickOutside =
      !this.publishAsModal && !isSmall && smallByDefault && !this.form.text.value.text;
    const currentUser = authStore.currentUser;
    const isUser = currentUser.role === USER_TYPES.USER;
    const isPublishingAsCurrentUser = currentUser.id === this.form.author.value.id;
    const { EmojiSelect } = this.emojiPlugin;

    return (
      <React.Fragment>
        <ClickOutsideContainer action={dismissedOnClickOutside ? this.onBlur : null}>
          <form onSubmit={this.onSubmit}>
            <TopContainer>
              <LeftContainer>
                <StyledProfileImage photo={this.form.author.value.photo} />
                {!isSmall && !isUser && (
                  <SwitchUserButton
                    empty
                    onClick={
                      isPublishingAsCurrentUser
                        ? this.togglePublishAsModal
                        : () => this.setPublishAuthor(currentUser)
                    }>
                    {isPublishingAsCurrentUser ? <SwitchUserIcon /> : <MdClose size={30} />}
                  </SwitchUserButton>
                )}
              </LeftContainer>
              <WeezContent>
                <WeezEditor
                  plugins={this.plugins}
                  key={this.editorKey}
                  small={isSmall}
                  placeholder={t(`post.placeholder.${mode}`)}
                  {...getInputFieldProps(this.form.text, {
                    onFocus: this.onFocus,
                  })}
                />
                {this.form.image.values.map((item, index) => {
                  return (
                    <UploadingImage
                      key={index}
                      imageId={item}
                      progress={this.form.image.progress && this.form.image.progress.value}
                      deleteImage={() => this.form.image.removeItem(index)}
                    />
                  );
                })}
              </WeezContent>
            </TopContainer>
            {weez && <Weez weez={weez} showButtons={false} />}
            {!isSmall && (
              <React.Fragment>
                <ButtonsContainer>
                  <OptionsContainer>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {!this.form.image.isFull && (
                        <FilePickerButton
                          {...getFileUploadFieldProps(this.form.image, {
                            onChange: this.uploadFiles,
                          })}
                          metadata={{
                            clientId: currentUser.client.id,
                            exercice: true,
                          }}>
                          <MdImage size={25} />
                        </FilePickerButton>
                      )}
                      <EmojiSelect />
                    </div>
                    {!isUser && (
                      <SponsorContainer>
                        <span>Sponsorisé :</span>
                        <Switch {...getSwitchProps(this.form.sponsored)} />
                      </SponsorContainer>
                    )}
                  </OptionsContainer>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      padding: "10px 10px 0",
                      width: isMobile ? "100%" : "auto",
                    }}>
                    <Button
                      type="submit"
                      full
                      invalid={!this.form.isValid}
                      loading={this.postWeezRequest && this.postWeezRequest.state === "pending"}>
                      Weezer
                    </Button>
                  </div>
                </ButtonsContainer>
                {this.postWeezRequest && this.postWeezRequest.state === "rejected" && (
                  <ErrorContent text={t(this.postWeezRequest.value.code)} />
                )}
              </React.Fragment>
            )}
          </form>
        </ClickOutsideContainer>
        <ChooseUserModal
          isOpen={this.publishAsModal}
          onRequestClose={this.togglePublishAsModal}
          chooseUser={this.setPublishAuthor}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(NewWeez);
