import React from "react";
import styled from "styled-components";
import { marginHorizontal } from "utils/mixins";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { themeGet } from "@styled-system/theme-get";
import ConversationPage from "./ConversationPage";
import ConversationsPage from "./ConversationsPage";
import ComposePage from "./ComposePage";
import { Modal } from "components";
import { cancelEvent } from "utils";
import { action } from "mobx";
import { MobXProviderContext } from "mobx-react";

const pageMaxWidth = 1200;

const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: ${pageMaxWidth + "px"};
  ${marginHorizontal("auto")};
`;

const DesktopLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 450px;
  background-color: ${themeGet("colors.white")};
  border-right: 1px solid ${themeGet("colors.veryLightGrey")};
`;
const DesktopRightSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: ${themeGet("colors.white")};
  max-height: calc(100vh - 50px);
`;

const MessagesRouter = () => {
  const { path } = useRouteMatch();
  const { routingStore } = React.useContext(MobXProviderContext);
  const { authStore } = React.useContext(MobXProviderContext);
  return (
    <Container>
      {!authStore.currentUser.isSuperAdmin && (
        <React.Fragment>
          <DesktopLeftSection>
            <ConversationsPage  />
          </DesktopLeftSection>
          <DesktopRightSection>
            <Switch>
              <Route
                path={`${path}/compose`}
                render={props => {
                  return (
                    <Modal isOpen onRequestClose={routingStore.history.goBack}>
                      <ComposePage {...props} />
                    </Modal>
                  );
                }}
              />
              <Route path={`${path}/:id`} component={ConversationPage} />
            </Switch>
          </DesktopRightSection>
        </React.Fragment>
      )}
    </Container>
  );
};

export default MessagesRouter;
