import { action, computed, observable } from "mobx";
import * as authApi from "services/auth";
import { REFRESH_SESSION_INTERVAL, USER_TYPES } from "utils/constants";

export default class AuthStore {
  @observable isReady = false;

  @observable currentUser = null;

  refreshSessionInterval = null;

  @computed
  get isConnected() {
    return !!this.currentUser && Object.values(USER_TYPES).includes(this.currentUser.role);
  }

  inject({ usersStore, fetchService, routingStore }) {
    this.usersStore = usersStore;
    this.fetchService = fetchService;
    this.routingStore = routingStore;

    fetchService
      .fetch(authApi.session())
      .catch(() => {
        //catch failed session error (user is not authenticated yet)
      })
      .then(this.setCurrentUser)
      .then(this.setIsReady);
  }

  @action("AUTH_SET_IS_READY")
  setIsReady = (ready = true) => {
    this.isReady = ready;
  };

  @action("AUTH_SET_CURRENT_USER")
  setCurrentUser = (userData) => {
    if (!userData) {
      this.currentUser = null;
      if (this.refreshSessionInterval) {
        clearInterval(this.refreshSessionInterval);
        this.refreshSessionInterval = null;
      }
    } else {
      if (this.currentUser && userData.id !== this.currentUser.id) {
        this.currentUser = null;
        throw new Error("Wrong user");
      }

      const { clonePath, clientPath } = this.routingStore;

      if (
        /* eslint-disable eqeqeq */
        clonePath.length > 0 ? userData.clientPath != clonePath : userData.clientPath != clientPath
      ) {
        console.warn("Wrong clientPath, disconnecting user");
        this.logout();
        return;
      }

      if (!this.currentUser) {
        this.currentUser = this.usersStore.deserializeUser(userData);
        this.refreshSessionInterval = setInterval(() => {
          this.refreshSession();
        }, REFRESH_SESSION_INTERVAL);
      } else {
        this.currentUser.updateFromData(userData);
      }
    }
  };

  async login(username, password, clientPath, clonePath) {
    await this.fetchService.fetch(authApi.signin(username, password, clientPath, clonePath));
    await this.refreshSession();
  }

  async logout() {
    this.setCurrentUser(null);
    return this.fetchService.fetch(authApi.signout());
  }

  async refreshSession() {
    const connectedUser = await this.fetchService.fetch(authApi.session());
    this.setCurrentUser(connectedUser);
    this.setIsReady();
  }

  async validateClientPath(clientPath, isClone = false) {
    return this.fetchService.fetch(authApi.checkClientPath(clientPath, isClone));
  }
}
