import React, { Component } from "react";
import { observable, action, computed } from "mobx";
import { observer, inject } from "mobx-react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import Clickable from "components/Clickable";
import { resetList, paddingVertical } from "utils/mixins";
import SequenceBoutonContainer from "./SequenceBoutonContainer";
import SequenceForm from "./SequenceForm";
import sequenceFormDataBuilder from "./sequenceFormDataBuilder";
import SequenceTableDetail from "./SequenceTableDetail";
import { updateSequenceStatus, downloadSequence } from "services/sequence";
import { FORM_MODE_EDIT } from "utils/constants";
import { withTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;
const SequenceContainer = styled.div`
  ${resetList};
  border-top: 1px solid ${themeGet("colors.lightGrey")};
  margin: 0 -15px;
`;
const SequenceContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  ${paddingVertical("20px")};

  &:last-child {
    border: none;
  }
`;

@inject("viewStore", "routingStore", "fetchService")
@observer
class AddSequencePage extends Component {
  @observable
  form = sequenceFormDataBuilder({
    sequence: this.props.sequence,
    mode: this.props.mode,
    t: this.props.t,
  });

  @observable
  sequencesInfo = this.props.sequence;

  @computed
  get metadata() {
    if (!this.form.isValid) {
      return null;
    }
    return {
      name: this.form.name.value,
      goals: this.form.goals.value,
      description: this.form.description.value,
    };
  }

  @action("GET_IMPORTED_SEQUENCE")
  csvHasImported = (e, data) => {
    this.sequencesInfo = data;
    this.props.viewStore.pushNotification(this.props.t("sequence.add.successful"));
  };

  @action("SAVE")
  save = () => {
    const { fetchService, t } = this.props;
    const id = this.sequencesInfo.id;
    if (!id) {
      this.props.viewStore.pushNotification("error");
      return false;
    }
    if (!this.form.isValid) {
      this.form.setTouched();
      return;
    }
    const promiseWithProgress = fetchService.fetch(updateSequenceStatus(id, this.metadata));

    promiseWithProgress
      .then((data) => {
        this.props.viewStore.pushNotification(this.props.t("sequence.alerts.update_success"));
      })
      .catch((e) => {
        this.props.viewStore.pushNotification(this.props.t(e.code), "error");
      });
  };

  cancel = () => {
    this.props.routingStore.history.goBack();
  };

  exportSequence = (id) => (evt) => {
    this.props.fetchService.fetch(downloadSequence(id));
  };

  render() {
    const sequenceId = this.sequencesInfo ? this.sequencesInfo.id : this.props.id;
    const { mode, routingStore, t } = this.props;
    return (
      <Container>
        <Helmet>
          <title>
            {mode === FORM_MODE_EDIT ? t("sequence.edit.title") : t("sequence.add.title")}
          </title>
        </Helmet>
        <h1>
          <Clickable onClick={routingStore.history.goBack}>{t("admin_sequences")}</Clickable> &gt;{" "}
          {mode === FORM_MODE_EDIT ? ` ${this.sequencesInfo.name}` : t("create")}
        </h1>
        <SequenceContainer>
          <SequenceContainerItem>
            <SequenceForm form={this.form} mode={mode} />
            <SequenceBoutonContainer
              csvHasImported={this.csvHasImported}
              save={this.save}
              onCancel={this.cancel}
              metadata={this.metadata}
              form={this.form}
              id={sequenceId}
              mode={mode}
              exportSequence={this.exportSequence}
            />
          </SequenceContainerItem>
          {this.sequencesInfo && (
            <SequenceContainerItem>
              <SequenceTableDetail sequences={this.sequencesInfo} />
            </SequenceContainerItem>
          )}
        </SequenceContainer>
      </Container>
    );
  }
}

export default withTranslation()(AddSequencePage);
