import config from "./config"
import { t } from "./i18n"

/**
 *
 * @param {function} cb - callback function
 */
export function cancelEvent(cb) {
  return function (evt) {
    evt.preventDefault()
    try {
      cb()
    } catch (e) {
      console.error(e)
    }
    return false
  }
}

/**
 * Generates url based on given path.
 * Check if given argument starts with "http", and returns path untouched.
 * If not, add leading "mediaUrl" after clean url path
 * @see cleanUrlPath
 * @param {string} path
 * @returns {string} image url
 */
export function imageUrl(path) {
  if (path && path.startsWith("http")) {
    return path
  }
  return `${config.mediaUrl}/${cleanUrlPath(path)}`
}

export const getEntities = (editorState, entityType = null) => {
  const content = editorState.getCurrentContent()
  const entities = []
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity())
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            }
            return true
          }
        }
        return false
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end })
      }
    )
  })
  return entities
}

/**
 * Check if url contains "drive.google.com"
 * @param {string} url
 * @returns {boolean} True if valid
 */
export function isGoogleDriveUrl(url) {
  return url && url.indexOf("drive.google.com") !== -1
}

/**
 * Extract file id from any form of Google URL to allow generic access to media from anywhere.
 * @param {string} originalUrl
 * @returns {string} Formatted Google drive URL.
 */
export function adaptGoogleDriveUrl(originalUrl) {
  if (originalUrl) {
    // const fileIdRegex = /(?<=\/file\/d\/)(.*?)(?=\/)|(?<=open\?id)(.*?)|(?<=\&id=)(.*?)$/gi
    const fileIdRegex = /(\/file\/d\/)(.*?)(?=\/)|(open\?id)(.*?)|(\&id=)(.*?)$/gi // extract file id from url.
    let fileId
    try {
      const match = fileIdRegex.exec(originalUrl.trim())
      fileId = match.filter((v) => v !== undefined).pop() //removes empty group match and returns last group match.
      fileId = fileId.replace("/", "")
    } catch (err) {
      console.error(err)
    }
    const result = fileId ? `https://drive.google.com/file/d/${fileId}/preview` : null
    return result
  } else {
    return null
  }
}

/**
 * Remove the first leading slash "/" if any
 * @param {string} path the given path
 */
export function cleanUrlPath(path) {
  if (path && path.indexOf("/") === 0) {
    return path.substring(1)
  } else {
    return path
  }
}

export function isPending(observable) {
  return observable && observable.state === "pending"
}

export function isSuccess(observable) {
  return observable && observable.state === "fulfilled"
}

export function isError(observable) {
  return observable && observable.state === "rejected"
}

export function getErrorMessage(error) {
  if (error.data && error.data.code) {
    return t(error.data.code)
  } else {
    return error.userMessage
  }
}

/**
 * Check if user is not undefined and if user has the given role
 * @param {*} user the user
 * @param {string} role the role
 * @returns {boolean} true || false
 */
export function hasRole(user, role) {
  return user && user.role === role
}
/**
 * Check if string is null or empty
 * @param {String} str
 * @return {boolean}
 */
export function isNullOrEmpty(str) {
  return str == null || str.length === 0
}
