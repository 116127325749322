import React, { Component } from "react";
import { hot } from "react-hot-loader";
import { Observer, Provider } from "mobx-react";
import theme from "utils/theme";
import { ThemeProvider } from "styled-components";
import { Router } from "react-router";
import { configure } from "mobx";
// import DevTools from "mobx-react-devtools";

import Layout from "app/layout";
configure({
  enforceActions: "observed",
});

class App extends Component {
  render() {
    const { stores, history } = this.props;
    return (
      <Provider {...stores}>
        <Observer
          render={() => {
            const user = stores.authStore.currentUser;
            return (
              <ThemeProvider
                theme={{
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: (user && user.client && user.client.colorMain) || theme.colors.primary,
                  },
                }}>
                <Router history={history}>
                  <Layout />
                </Router>
              </ThemeProvider>
            );
          }}
        />
      </Provider>
    );
  }
}

export default hot(module)(App);
