import { ErrorContent, Link, LoadingContent } from "components";
import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { resetList } from "utils/mixins";
import { MdGraphicEq } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  background-color: ${themeGet("colors.white")};
  min-height: 200px;
`;

const Title = styled.h2`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 10px;
  height: 50px;
  font-weight: 700;
  font-size: 1.3rem;
  color: ${themeGet("colors.grey")};
  border-bottom: 1px solid ${themeGet("colors.lightGrey")};
`;

const Content = styled.div`
  padding: 10px;
`;

const NoTrendsYet = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 500;
  padding: 0.5rem;
  /* flex: 1; */
  color: ${themeGet("colors.strongGrey")};
`;

const NoTrendsYetText = styled.span`
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0.5rem;
  flex: 1;
  /* color: ${themeGet("colors.strongGrey")}; */
`;

const TrendsList = styled.ul`
  ${resetList};
`;

const TrendsItem = styled.li`
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

const TrendsLink = styled(Link)`
  font-weight: 600;
  text-decoration: none;
`;

function Trends({ className, isPending = true, error = false, trends = [] }) {
  const showTrends = !isPending && !error;
  const noValues = showTrends && (!trends || !trends.length);
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <Title>{t("trends_for_you")}</Title>
      <Content>
        {isPending && <LoadingContent label={t("loading_trends")} />}
        {error && <ErrorContent text={t(error)} />}
        {noValues && (
          <NoTrendsYet>
            <MdGraphicEq />
            <NoTrendsYetText>{t("no_trends")}</NoTrendsYetText>
          </NoTrendsYet>
        )}
        {showTrends && !noValues && (
          <TrendsList>
            {trends.map((item) => (
              <TrendsItem key={item.hashtag}>
                <TrendsLink to={`/hashtag/${item.hashtag}`}>#{item.hashtag}</TrendsLink>
                <span>
                  {item.count} {t("weezes")}
                </span>
              </TrendsItem>
            ))}
          </TrendsList>
        )}
      </Content>
    </Container>
  );
}

export default Trends;
