import Field from "./Field";
import FieldsMap from "./FieldsMap";
import FieldsArray from "./FieldsArray";

export function isValid(values) {
  return values.every(field => field.isValid);
}

export function setTouched(values) {
  values.forEach(field => field.markAsTouch());
}

function mergeHandler(handler, optHandler) {
  if (!optHandler) {
    return handler;
  } else {
    return (...args) => {
      optHandler(...args);
      handler(...args);
    };
  }
}

export function numberParser(onChange) {
  return (evt, value) => {
    return onChange(evt, toNumber(value));
  };
}

export function numberFormater(value) {
  if (value) {
    return value.toString();
  } else {
    return "";
  }
}

export function toNumber(newValue) {
  try {
    const int = parseInt(newValue, 10);
    return isNaN(int) ? 0 : int;
  } catch (e) {
    return 0;
  }
}

export function getInputFieldProps(field, { onChange, onBlur, ...others } = {}) {
  return (
    field && {
      value: field.value,
      onChange: mergeHandler(field.onChange, onChange),
      error: field.errorMessage,
      onBlur: mergeHandler(field.markAsTouch, onBlur),
      disabled: field.disabled,
      ...others,
    }
  );
}

export function getNumberFieldProps(field, { onChange, onBlur, ...others } = {}) {
  return (
    field && {
      value: numberFormater(field.value),
      onChange: numberParser(mergeHandler(field.onChange, onChange)),
      error: field.errorMessage,
      onBlur: mergeHandler(field.markAsTouch, onBlur),
      disabled: field.disabled,
      ...others,
    }
  );
}

export function getFileUploadFieldProps(field, { onChange, onBlur, ...others } = {}) {
  return {
    value: field.value,
    onChange: mergeHandler(field.onChange, onChange),
    error: field.errorMessage,
    onBlur: mergeHandler(field.markAsTouch, onBlur),
    disabled: field.disabled,
    onProgress: field.setProgress,
    ...others,
  };
}

export function getSwitchProps(field) {
  return {
    checked: field.value,
    onChange: field.onChange,
    onBlur: field.markAsTouch,
    disabled: field.disabled,
  };
}

class FieldBuilder {
  field(...args) {
    return new Field(...args);
  }

  map(...args) {
    return new FieldsMap(...args);
  }

  array(...args) {
    return new FieldsArray(...args);
  }
}

export const fb = new FieldBuilder();

export { default as Field } from "./Field";
export { default as FieldsMap } from "./FieldsMap";
export { default as FieldsArray } from "./FieldsArray";
export * from "./validation";
