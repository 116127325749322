import { action, extendObservable, observable } from "mobx";

export function defaultValuesGetter(form, fieldsNames) {
  return fieldsNames.reduce((res, fieldName) => {
    res[fieldName] = form[fieldName].value;
    return res;
  }, {});
}

export function fieldsAggregatorBuilder({
  fields,
  data,
  dataMapper = data => data,
  valuesGetter = defaultValuesGetter,
}) {
  // const obj = {};
  if (typeof fields === "function") {
    fields = fields();
  }
  const fieldsNames = Object.keys(fields);
  const allFields = fieldsNames.map(fieldName => fields[fieldName]);

  const obj = observable(
    {
      ...fields,
      allFields,
      setTouched() {
        this.allFields.forEach(field => field.markAsTouch());
      },
      updateData(newData) {
        if (!newData) return;
        const mappedData = dataMapper(newData);
        fieldsNames.forEach(fieldName => {
          this[fieldName].setValue(mappedData[fieldName], false);
        });
      },
    },
    {
      setTouched: action("FORM_SET_TOUCHED"),
      updateData: action("FORM_UPDATE_DATA"),
    },
    { deep: false }
  );

  extendObservable(obj, {
    get isValid() {
      return obj.allFields.every(field => field.isValid);
    },
    get values() {
      return valuesGetter(obj, fieldsNames);
    },
  });

  const fieldsAggregator = obj;
  if (data) {
    fieldsAggregator.updateData(data);
  }

  return fieldsAggregator;
}
