import React from "react";
import styled, { css } from "styled-components";
import { ifProp } from "utils/mixins";

const ImageRatioContainer = styled.div`
  width: 100%;
  padding-top: calc(${({ ratio }) => ratio} * 100%);
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    ${ifProp(
      "fullHeight",
      css`
        height: 100%;
        width: auto;
      `,
      css`
        width: 100%;
        height: auto;
      `
    )};
    ${ifProp(
      "cropped",
      css`
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    )};
  }
`;

const FixedRatioImage = ({
  maxRatio = 1.53,
  cover = false,
  ratio: fixedRatio = null,
  alt = "",
  ...others
}) => {
  const [computedRatio, setComputedRatio] = React.useState(1);
  const onImageLoader = evt => {
    const { naturalHeight, naturalWidth } = evt.target;
    let ratio = naturalHeight / naturalWidth;
    if (maxRatio && ratio > maxRatio) {
      ratio = maxRatio;
    }
    setComputedRatio(ratio);
  };

  const ratio = fixedRatio || computedRatio || 1;
  let fullHeight = false;
  let cropped = false;
  if (cover && fixedRatio) {
    if (fixedRatio > computedRatio) {
      fullHeight = true;
    }
    if (Math.abs(fixedRatio - computedRatio) > 0.2) {
      cropped = true;
    }
  }
  return (
    <ImageRatioContainer ratio={ratio} fullHeight={fullHeight} cropped={cropped}>
      <img {...others} alt={alt} onLoad={onImageLoader} />
    </ImageRatioContainer>
  );
};

export default React.memo(FixedRatioImage);
