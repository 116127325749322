import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import styled from "styled-components";
import { imageUrl as getImageUrl } from "utils";

const ImagesContainer = styled.div`
  position: relative;
  left: 10%;
  background: dimgrey;
  border-radius: 15px;
  overflow: hidden;
  width: 80%;
  height: 300px;
  cursor: pointer;
`;

// Common styling
const ImageWrapper = styled.div`
  border: 1px solid white;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-size: cover !important;
  background-position: center !important;
  background: url('${props => props.images[props.pos]}');
`;

const FirstImg = styled(ImageWrapper)`
  width: ${props => (props.images.length > 2 ? "75%" : props.images.length > 1 ? "50%" : "100%")};
  height: 100%;
  left: 0;
  top: 0;
`;

const LittleImage = styled(ImageWrapper)`
  width: ${props => (props.images.length > 2 ? "25%" : "50%")};
  height: calc(100% / ${props => props.images.length - 1});
  right: 0;
  top: ${props =>
    props.pos === 1 ? 0 : `calc((100% / ${props.images.length - 1}) * ${props.pos - 1})`};
`;

export default class LightboxContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
    this.images = this.props.images.map(image => {
      return getImageUrl(image.val);
    });
  }

  openModal = evt => {
    this.setState({ isOpen: true });
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  handleMovePrevImage = () => {
    this.setState((state, { images }) => ({
      ...state,
      photoIndex: (state.photoIndex + images.length - 1) % images.length,
    }));
  };

  handleMoveNextImage = () => {
    this.setState((state, { images }) => ({
      ...state,
      photoIndex: (state.photoIndex + 1) % images.length,
    }));
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div>
        <ImagesContainer onClick={this.openModal}>{this.Image(this.images)}</ImagesContainer>

        {isOpen && (
          <Lightbox
            mainSrc={this.images[photoIndex]}
            nextSrc={this.images[(photoIndex + 1) % this.images.length]}
            prevSrc={this.images[(photoIndex + this.images.length - 1) % this.images.length]}
            onCloseRequest={this.closeModal}
            enableZoom={false}
            onMovePrevRequest={this.handleMovePrevImage}
            onMoveNextRequest={this.handleMoveNextImage}
          />
        )}
      </div>
    );
  }

  Image(images) {
    return images.map((image, index) =>
      index === 0 ? (
        <FirstImg key={index} alt="Main thumbnail" images={images} pos={index} />
      ) : (
        <LittleImage key={index} alt="Other thumbnail" images={images} pos={index} />
      )
    );
  }
}
