import Field from "./Field";
import { observable, action, computed } from "mobx";
import { WEEZ_MAX_PHOTO } from "utils/constants";

export default class FieldWithProgress extends Field {
  @observable progress;
  @observable _values;

  @action("FIELD_SET_PROGRESS_MONITOR")
  setProgress = value => {
    this.progress = value;
    if (value) {
      this._value = null;
    }
  };

  @action("FIELD_SET_VALUES")
  setValues = value => {
    if (!this._values) {
      this._values = [];
    }
    if (this._values.length < WEEZ_MAX_PHOTO)
      this._values.push(value);
  }

  @computed
  get isFull() {
    return this._values.length >= WEEZ_MAX_PHOTO
  }

  @action
  removeItem = index => {
    this.values.splice(index, 1);
  }

  serialize = () => {
    return this._values.map(item => {
      return { type: "IMAGE", val: item }
    });
  }

  @computed
  get values() {
    if (!this._values)
      this._values = []
    return this._values;
  }

}


