import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { MdClose, MdArrowBack } from "react-icons/md";
import Modal from "react-modal";
import styledMap from "styled-map";
import Button from "./Button";
import { MobileView, BrowserView, isMobile } from "app/layout/responsiveness/mediaQueries";

Modal.setAppElement("#root");

function getModalStyle({ allowScroll }) {
  const modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.50)",
      zIndex: 999,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: isMobile ? "100%" : "60vw",
      maxWidth: "700px",
      heigth: "fit-content",
      minHeight: isMobile() ? "100%" : "160px",
      maxHeight: isMobile() ? "100%" : "95%",
      marginRight: "-50%",
      padding: 0,
      border: 0,
      borderRadius: isMobile ? 0 : 5,
      display: "flex",
      flexDirection: "column",
      transform: "translate(-50%, -50%)",
      overflow: allowScroll ? "auto" : "initial",
      // "overflow-y": "auto",
    },
  };

  return modalStyle;
}

const ModalTitle = styled.h2`
  font-weight: 600;
  font-size: 1.2;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
  color: ${themeGet("colors.strongGrey")};
`;

const ModalHeader = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  max-height: calc(95vh - 30px);
  overflow-y: ${styledMap({
    allowScroll: "auto",
    default: "initial",
  })};
  overflow-x: ${styledMap({
    allowScroll: "hidden",
    default: "initial",
  })};
`;

const StyledModal = ({
  isOpen,
  onRequestClose,
  title,
  children,
  allowScroll = false,
  closeOnOverlayClick = false,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={getModalStyle({ allowScroll })}
      shouldCloseOnOverlayClick={closeOnOverlayClick}>
      <ModalHeader>
        <MobileView>
          <Button empty onClick={onRequestClose}>
            <MdArrowBack size={30} />
          </Button>
        </MobileView>
        <ModalTitle>{title}</ModalTitle>
        <BrowserView>
          <Button empty onClick={onRequestClose}>
            <MdClose size={30} />
          </Button>
        </BrowserView>
      </ModalHeader>
      <Content allowScroll={allowScroll}>{children}</Content>
    </Modal>
  );
};

export default StyledModal;
