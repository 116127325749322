import { UserCount, WeezFeed, WeezFeedModel } from "app/common";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";
import * as weezsApi from "services/weezs";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { t } from "utils/i18n";

const contentSize = 900;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const HeaderBackground = styled.div`
  height: 64px;
  background-color: ${themeGet("colors.primary")};
  display: flex;
  justify-content: center;

  h1 {
    font-size: 1.7rem;
    font-weight: 700;
    color: ${themeGet("colors.white")};
    margin-left: 20px;
  }
`;

const TopBar = styled.div`
  background-color: ${themeGet("colors.white")};
  width: 100%;
  height: 60px;
  box-shadow: ${themeGet("shadows.default")};
  display: flex;
  justify-content: center;
`;

const TopBarContent = styled.div`
  display: flex;
  flex: 1;
  max-width: ${contentSize}px;
  height: 100%;
`;

const TopBarLinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopBarLeft = styled.div`
  display: flex;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 10px;
`;

const StyledWeezFeed = styled(WeezFeed)`
  max-width: ${contentSize}px;
  background-color: ${themeGet("colors.white")};
`;

@inject("fetchService")
@observer
class HashtagPage extends React.Component {
  @observable
  weezFeed;

  @action("HASHTAGS_INIT_FEED")
  initFeed() {
    const { fetchService, match } = this.props;
    const hashtag = match.params.hashtag;
    this.weezFeed = new WeezFeedModel(weezsApi.getWeezes(hashtag), { fetchService });
  }

  componentDidMount() {
    this.initFeed();
  }

  componentWillUnmount() {
    this.weezFeed.close();
  }

  render() {
    const { match } = this.props;
    const hashtag = match.params.hashtag;
    return (
      <Container>
        <Helmet>
          <title>#{hashtag}</title>
        </Helmet>
        <HeaderContainer>
          <HeaderBackground>
            <TopBarContent>
              <h1>#{hashtag}</h1>
            </TopBarContent>
          </HeaderBackground>
          <TopBar>
            <TopBarContent>
              <TopBarLinksContainer>
                <TopBarLeft>
                  <UserCount
                    selected
                    label={t("weezes")}
                    value={this.weezFeed ? this.weezFeed.count : 0}
                  />
                </TopBarLeft>
              </TopBarLinksContainer>
            </TopBarContent>
          </TopBar>
        </HeaderContainer>
        <Content>{this.weezFeed && <StyledWeezFeed weezsFeed={this.weezFeed} />}</Content>
      </Container>
    );
  }
}

export default HashtagPage;
