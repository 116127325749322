import React from "react";
import styled from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "app/login";
import LinkLoginPage from "app/login/LinkLoginPage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AuthLayout = () => {
  return (
    <Container>
      <Switch>
        <Route path="/auth/login" component={LoginPage} />
        <Route path="/auth/nologin" component={LinkLoginPage} />
        <Redirect to="/auth/login" />
      </Switch>
    </Container>
  );
};

export default AuthLayout;
