import React from "react";
import { observer, inject } from "mobx-react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import BoostSequencesItem from "./BoostSequencesItem";
import BoostSequencesModel from "./BoostSequencesModel";
import { observable, action } from "mobx";
import { withTranslation } from "react-i18next";

const Sidebar = styled.div`
  position: relative;
  box-sizing: border-box;
  background-color: white;
  width: 320px;
  height: 300px;
  padding: 0;
  margin-left: 20px;
`;

const Side = styled.div`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 250px;
  overflow-y: auto;
`;

const SideTitle = styled.h2`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 10px;
  height: 50px;
  font-weight: 700;
  font-size: 1.3rem;
  color: ${themeGet("colors.grey")};
  border-bottom: 1px solid ${themeGet("colors.lightGrey")};
`;

@inject("fetchService", "webSocketService")
@observer
class BoostSequences extends React.Component {
  @observable boostSequencesModel;

  @action
  initModel() {
    const { fetchService, webSocketService } = this.props;
    this.boostSequencesModel = new BoostSequencesModel(fetchService, webSocketService);
    this.boostSequencesModel.init();
  }
  componentDidMount() {
    this.initModel();
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Sidebar>
          <SideTitle>{t("boost.title")}</SideTitle>
          <Side>
            {this.boostSequencesModel &&
              this.boostSequencesModel.sequences &&
              this.boostSequencesModel.sequences.map((seq, index) => (
                <BoostSequencesItem
                  fetchService={this.props.fetchService}
                  sequence={seq}
                  key={index}
                  onChangeStatus={this.boostSequencesModel.updateSequenceData}
                  onClickOpenOriginalWeeze={this.handleClickOpenOriginalWeeze}
                />
              ))}
          </Side>
        </Sidebar>
      </React.Fragment>
    );
  }
}

export default withTranslation()(BoostSequences);
