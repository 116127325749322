import format from "date-fns/format";
import { enUS, fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const locales = { fr: fr, en: enUS };

export default function DateFormat({ children, pattern = "dd/MM/yyyy HH:mm" }) {
  const date = new Date(children);
  const { i18n } = useTranslation();
  return format(date, pattern, { locale: locales[i18n.language] });
}
