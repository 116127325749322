import { requestFactory, requestDownloadFactory, multipartFactory, ERROR_CODES } from "utils/fetch";
import { captureException } from "@sentry/core";

export default class FetchService {
  inject({ authStore, viewStore }) {
    this.authStore = authStore;
    this.viewStore = viewStore;
    const errorInterceptor = err => {
      if (err.code === ERROR_CODES.UNAUTHORIZED_401 && this.authStore.isConnected) {
        // User session is ended, force deconnection
        this.authStore.setCurrentUser();
        this.viewStore.pushNotification("Votre session a expiré", "error");
      } else if (err.code === ERROR_CODES.SERVER_ERROR_500 && this.authStore.isConnected) {
        captureException(err);
        // this.viewStore.pushNotification(t(`errors.${ERROR_CODES.SERVER_ERROR_500}`), "error", 3000);
      }
    };
    this.request = requestFactory({ errorInterceptor });
    this.multipart = multipartFactory({ errorInterceptor });
    this.download = requestDownloadFactory({ errorInterceptor });
  }

  fetch(opts) {
    if (opts.type === "multipart") {
      return this.multipart(opts);
    } else if (opts.type === "download") {
      return this.download(opts);
    } else {
      return this.request(opts);
    }
  }
}
