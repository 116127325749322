import { getServerUrl } from "utils/fetch";

export function getNotifications({ after, before }) {
  const url = getServerUrl(`/notifications`, { after, before });

  return {
    url,
    method: "GET",
  };
}

export function setNotificationsAsRead(at) {
  const url = getServerUrl(`/notifications/read`, { at });

  return {
    url,
    method: "POST",
  };
}
