import differenceInHours from "date-fns/differenceInHours";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import isThisYear from "date-fns/isThisYear";
import { enUS, fr } from "date-fns/locale";
import parseISO from "date-fns/parseISO";
import { useTranslation } from "react-i18next";

const locales = { fr: fr, en: enUS };

export default function AdaptiveDateFormat({ isoDate, strict = false, options }) {
  const { i18n } = useTranslation();
  const date = parseISO(isoDate);
  const now = new Date();
  if (isThisYear(date)) {
    const diffInHours = differenceInHours(isoDate, now);
    if (diffInHours < 24) {
      return strict
        ? formatDistanceStrict(now, date, { ...options, locale: locales[i18n.language] })
        : formatDistanceToNow(date, { ...options, locale: locales[i18n.language] });
    } else {
      return format(date, "d MMMM", { locale: locales[i18n.language] });
    }
  } else {
    return format(date, "d MMMM yyyy", { locale: locales[i18n.language] });
  }
}
