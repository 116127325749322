import React from "react";
import styled from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";
import { paddingHorizontal } from "utils/mixins";

const TopCountContainer = styled.div`
  border-bottom-color: ${styledMap({
    selected: themeGet("colors.primary"),
    default: "transparent",
  })};
  border-bottom-style: solid;
  border-bottom-width: 3px;
  height: 100%;
  ${paddingHorizontal("15px")};
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  span {
    text-align: ${styledMap({
      leftAlign: "left",
      default: "center",
    })};
  }

  & > span:nth-child(1) {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 12px;
    color: ${themeGet("colors.strongGrey")};
  }

  & > span:nth-child(2) {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 18px;
    color: ${themeGet("colors.primary")};
  }
`;

const TopCount = ({ selected, label, value, ...others }) => {
  return (
    <TopCountContainer selected={selected} {...others}>
      <span>{label}</span>
      <span>{value}</span>
    </TopCountContainer>
  );
};

export default TopCount;
