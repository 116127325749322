import { action, observable } from "mobx";
import { observer, inject } from "mobx-react";
import { Circle } from "rc-progress";
import React from "react";
import Dropzone from "react-dropzone";
import { MdAddAPhoto } from "react-icons/md";
import { addMedia } from "services/media";
import styled from "styled-components";
import { imageUrl } from "utils";
import fromPromiseWithProgress from "utils/fromPromiseWithProgress";
import { t } from "utils/i18n";
import { ErrorText } from "../basics";
import { captureException } from "utils/logger";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DropzoneContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    height: 100%;
    width: auto;
  }
`;

@inject("fetchService", "viewStore")
@observer
class ImageDropzone extends React.Component {
  @observable
  imageUpload;

  @action("UPLOAD_FILE")
  uploadFiles = files => {
    const { metadata, field, fetchService } = this.props;
    if (this.imageUpload && this.imageUpload.isPending) {
      return;
    }
    this.imageUpload = fromPromiseWithProgress(
      fetchService.fetch(addMedia(files[0], "/media/upload", metadata))
    );
    this.imageUpload
      .then(data => {
        field.setValue(data.imageId);
      })
      .catch(e => {
        captureException(e);
        this.props.viewStore.pushNotification(t(e.code), "error");
      });
  };

  render() {
    const { field, className } = this.props;

    let childComponent = (
      <DropzoneContentContainer>
        <MdAddAPhoto size={30} />
      </DropzoneContentContainer>
    );

    if (this.imageUpload && this.imageUpload.state === "pending") {
      childComponent = (
        <DropzoneContentContainer>
          {/* <div className={s.backgroundOverlay} /> */}
          <Circle percent={this.imageUpload.progress} strokeWidth="4" strokeColor="#D3D3D3" />
        </DropzoneContentContainer>
      );
    }

    if (field.value) {
      childComponent = (
        <DropzoneContentContainer>
          <img src={imageUrl(field.value)} alt="Profile" />
        </DropzoneContentContainer>
      );
    }

    return (
      <Container className={className}>
        <Dropzone
          accept="image/jpeg, image/png"
          onDropAccepted={this.uploadFiles}
          onDropRejected={files => {
            field.markAsTouch();
            field.setValidation({
              valid: false,
              message: `Vous devez choisir une image de moins de 5Mo`,
            });
          }}
          multiple={false}
          maxSize={5242880}>
          {childComponent}
        </Dropzone>

        {field.errorMessage ? (
          <div>
            <p>{field.errorMessage}</p>
          </div>
        ) : null}

        {this.imageUpload && this.imageUpload.state === "rejected" ? (
          <div>
            <ErrorText>{t(this.imageUpload.value.code)}</ErrorText>
          </div>
        ) : null}
      </Container>
    );
  }
}

export default ImageDropzone;
