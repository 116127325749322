import React from "react";
import styled from "styled-components";
import { MdKeyboardArrowUp } from "react-icons/md";
import { themeGet } from "@styled-system/theme-get";

class ScrollButton extends React.Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0,
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <button
        title="Back to top"
        className={this.props.className}
        onClick={() => {
          this.scrollToTop();
        }}>
        <MdKeyboardArrowUp size={40} color="white" />
      </button>
    );
  }
}

const ScrollButtonStyled = styled(ScrollButton)`
  position: fixed;
  bottom: 50px;
  right: 20px;
  @media (min-width: 1200px) {
    right: calc(((100% - 800px) / 2) + 20px);
  }
  outline: 0;
  opacity: 0.5;
  background-color: ${themeGet("colors.primary")};
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: none;
  z-index: 10;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

export default ScrollButtonStyled;
