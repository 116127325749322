import React from "react";
import styled from "styled-components";
import { MdHome, MdNotifications, MdSearch, MdMessage } from "react-icons/md";
import { themeGet } from "@styled-system/theme-get";
import { NavLink } from "react-router-dom";
import { MobXProviderContext, useObserver } from "mobx-react";
import { lightenColor } from "utils/mixins";

const activeClassName = "header-navlink-active";

const Container = styled.div`
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${themeGet("colors.white")};
  box-shadow: ${themeGet("shadows.upward")};
`;
const ButtonsContainer = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: auto;
  max-width: 450px; /* limit width in landscape */
`;

const HeaderNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 1rem;
  font-weight: 700;
  color: ${themeGet("colors.grey")};
  text-decoration: none;
  &:hover {
    color: ${themeGet("colors.primary")};
  }
  &.${activeClassName} {
    color: ${themeGet("colors.primary")};
    /* text-decoration: underline; */
  }
`;

const NotificationBadge = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  background: ${lightenColor(0.1, "colors.primary")};
  height: 12px;
  width: 12px;
`;

const NotificationsCounterContainer = styled.span`
  position: relative;

  span {
    position: absolute;
    top: -3px;
    right: -3px;
    display: inline-block;
    padding: 1px;
    min-width: 14px;
    min-height: 14px;
    line-height: 14px;
    border-radius: 7px;
    text-align: center;
    background-color: red;
    color: white;
    font-size: 0.9rem;
  }

  .${activeClassName} & {
    & span {
      display: none;
    }
  }
`;

const NotificationsCounter = ({ count }) => {
  return (
    <NotificationsCounterContainer>
      <MdNotifications size={25} />
      {count ? <span>{count}</span> : null}
    </NotificationsCounterContainer>
  );
};

const MobileBottomMenu = () => {
  const { routingStore, notificationsStore, conversationsStore } = React.useContext(
    MobXProviderContext
  );

  return useObserver(() => (
    <Container>
      <ButtonsContainer>
        <HeaderNavLink to="/" exact location={routingStore.location}>
          <MdHome size={25} />
        </HeaderNavLink>
        <HeaderNavLink to="/explore" location={routingStore.location}>
          <MdSearch size={25} />
        </HeaderNavLink>
        <HeaderNavLink to="/notifications" location={routingStore.location}>
          <NotificationsCounter count={notificationsStore.unreadCount} />
        </HeaderNavLink>
        <HeaderNavLink to="/messages" location={routingStore.location}>
          <div style={{ position: "relative" }}>
            <MdMessage size={25} />
            {conversationsStore.hasUnreadConversations && <NotificationBadge />}
          </div>
        </HeaderNavLink>
      </ButtonsContainer>
    </Container>
  ));
};

export default MobileBottomMenu;
