import { createBrowserHistory } from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import pathToRegexp from "path-to-regexp";
import { extendObservable } from "mobx";
import { FORBIDDEN_CLIENT_PATH } from "utils/constants";

export default function routingStoreBuilder() {
  const baseMatch = "/:clientPath/:rest?";
  const keys = [];
  const compiledBase = pathToRegexp(baseMatch, keys, {
    strict: true,
    end: false,
  });
  const match = compiledBase.exec(window.location.pathname);
  const clientPath =
    match && !!match[1] && !FORBIDDEN_CLIENT_PATH.includes(match[1])
      ? match[1].trim().replace("/", "")
      : undefined;
  let isClone = (match && match[2] === "clones") || false;
  let clonePath = "";
  if (isClone) {
    try {
      clonePath = match.input.substring(match[0].length).split("/")[1];
    } catch (e) {
      console.error(e);
      isClone = false;
    }
  }
  const basename = clonePath.length > 0 ? `${clientPath}/clones/${clonePath}` : clientPath || "/"; // this should really do some verification too
  const browserHistory = createBrowserHistory({ basename });
  const routingStore = new RouterStore();
  syncHistoryWithStore(browserHistory, routingStore);
  extendObservable(routingStore, {
    basename,
    clientPath,
    clonePath,
    isClone,
    showReturnArrow: false,
  });
  return routingStore;
}
