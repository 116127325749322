import React from "react";

/**
 * Hook that run an action when user clicks outside of the passed ref
 */
function useOutsideClickActionRunner(ref, action) {
  React.useEffect(() => {
      /**
       * Action run if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          action && action();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref]);
}


const OnClickOutside = ({action, render, children, ...props}) => {
  const containerRef = React.useRef(null);
  useOutsideClickActionRunner(containerRef, action)




    if (render) {
      return render({props, ref: containerRef});
      
    } else {
      return (
        <div ref={containerRef} {...props}>
          {children}
        </div>
      );
    }

}

export default OnClickOutside;
