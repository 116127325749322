import { Button, LoadingContent, Modal } from "components";
import UploadButton from "components/form/UploadButton";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";
import { Redirect } from "react-router-dom";
import { download } from "services/media";
import { importSequence, removeSequence } from "services/sequence";
import styled from "styled-components";
import { FORM_MODE_EDIT } from "utils/constants";
import { getFileUploadFieldProps } from "utils/forms";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px;
  margin-left: 50px;
  & div,
  button {
    margin-bottom: 5px;
  }
`;

const DeleteWeezModal = styled.div`
  display: initial;
`;
const DeleteWeezModalText = styled.div`
  position: absolute;
  top: 40%;
  left: 4%;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #777777;
  font-weight: bold;
`;
const DeleteWeezModalButton = styled.div`
  position: absolute;
  bottom: 3px;
  right: 15px;
  button {
    margin-left: 15px;
    margin-bottom: 10px;
  }
`;

@inject("fetchService", "viewStore")
@observer
class SequenceBoutonContainer extends PureComponent {
  state = {
    showModal: false,
    redirect: false,
  };
  @observable uploadSequenceRequest;

  downloadCsv = () => {
    window.location.href = download("/admin/sequences/template").url;
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  onClose = () => {
    this.setState({ showModal: false });
  };

  _handleCancel = () => {
    return this.props.id && this.props.mode !== FORM_MODE_EDIT
      ? this.openModal()
      : this.props.onCancel();
  };

  uploadRequest = (file) => {
    const { fetchService, metadata } = this.props;
    const promiseWithProgress = fetchService.fetch(importSequence(file, metadata));
    promiseWithProgress.catch((e) => {
      this.props.viewStore.pushNotification(this.props.t(e.code), "error");
    });
    return promiseWithProgress;
  };

  @action("DELETE_SEQUENCE")
  removeSequence = () => {
    const { fetchService, id } = this.props;
    const deleteSequenceRequest = fromPromise(fetchService.fetch(removeSequence(id)));
    deleteSequenceRequest.then(() => {
      this.props.viewStore.pushNotification("Suppression de la séquence effectuée !");
      this.setState({ showModal: false, redirect: true });
    });
  };

  render() {
    const { t } = this.props;
    if (this.state.redirect) return <Redirect to="/admin/sequences" />;
    return (
      <>
        <ButtonContainer>
          <Button admin onClick={this.downloadCsv}>
            {t("sequence.add.form_creation.download_csv")}
          </Button>
          {this.props.mode !== FORM_MODE_EDIT && !this.props.id && (
            <UploadButton
              accept="text/csv"
              {...getFileUploadFieldProps(this.props.form.importCsv, {
                onChange: this.props.csvHasImported,
                onClick: (evt) => {
                  const { form } = this.props;
                  if (!form.isValid) {
                    form.setTouched();
                    evt.preventDefault();
                    return false;
                  }
                },
              })}
              uploadRequest={this.uploadRequest}
              render={(props) => (
                <Button admin loading={this.props.form.importCsv.progress} fake {...props}>
                  {t("sequence.add.form_creation.import_csv")}
                </Button>
              )}
            />
          )}
          <Button
            admin
            onClick={this.props.exportSequence(this.props.id)}
            disabled={!this.props.id}>
            {t("sequence.add.form_creation.download_sequence")}
          </Button>
          <Button admin onClick={this.props.save} disabled={!this.props.id}>
            {t("save")}
          </Button>
          <Button admin onClick={this._handleCancel}>
            {t("cancel")}
          </Button>
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={this.onClose}
            title={"Suppression d'une séquence"}>
            <DeleteWeezModal>
              <DeleteWeezModalText>
                <MdDelete size={30} /> Voulez-vous supprimer la séquence que vous venez de créer ?{" "}
              </DeleteWeezModalText>
              <DeleteWeezModalButton>
                <Button admin onClick={this.removeSequence}>
                  Supprimer
                </Button>
                <Button admin onClick={this.onClose}>
                  Annuler
                </Button>
              </DeleteWeezModalButton>
            </DeleteWeezModal>
          </Modal>
        </ButtonContainer>
        {this.props.form.importCsv.progress && <LoadingContent float={true} />}
      </>
    );
  }
}

export default withTranslation()(SequenceBoutonContainer);
