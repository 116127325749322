import { Autocomplete } from "components";
import matchSorter from "match-sorter";
import React from "react";
import { t } from "utils/i18n";
import { inject, observer } from "mobx-react";

function renderClientItem(user, query) {
  return <div>{user.name}</div>;
}

const MoodSelect = inject("boostStore")(
  observer(function MoodSelect({ value, onChange, boostStore, disabled }) {
    if (!boostStore.moods) {
      return null;
    }
    return (
      <Autocomplete
        items={boostStore.moods}
        placeholder={t("boost.mood")}
        itemToString={item => (item ? `${item.name}` : "")}
        renderItem={renderClientItem}
        filter={(items, query) =>
          matchSorter(items, query, {
            keys: ["name"],
            threshold: matchSorter.rankings.STARTS_WITH,
          })
        }
        selectedItem={value ? boostStore.moods.find(lang => value === lang.id) : ""}
        onSelect={(val, evt) => onChange(evt, val ? val.id : null)}
        disabled={disabled}
      />
    );
  })
);

export default MoodSelect;
