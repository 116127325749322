import React, { Component } from "react";
import RadioButton from "./RadioButton";
import isComponentOfType from "utils/is-component-of-type";

export default class RadioGroup extends Component {
  static defaultProps = {
    className: "",
    disabled: false,
  };

  handleChange = (value, event) => {
    if (this.props.onChange) this.props.onChange(event, value);
  };

  renderRadioButtons() {
    return React.Children.map(
      this.props.children,
      child =>
        !isComponentOfType(RadioButton, child)
          ? child
          : React.cloneElement(child, {
              checked: child.props.value === this.props.value,
              disabled: this.props.disabled || child.props.disabled,
              onChange: this.handleChange.bind(this, child.props.value),
            })
    );
  }

  render() {
    return <div className={this.props.className}>{this.renderRadioButtons()}</div>;
  }
}
