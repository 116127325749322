import { Button, Modal } from "components";

import React, { useState } from "react";
import styled from "styled-components";
// import { t } from "utils/i18n";

export const CloneDeletionModal = ({ onClose, onConfirm }) => {
  const [isConfirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const handleConfirmDeleteClone = () => {
    setConfirmButtonDisabled(true);
    onConfirm();
  };

  return (
    <Modal isOpen={true} onRequestClose={onClose} title={"Supprimer un clone"}>
      <ModalContentTitle>Confirmer la suppression du clone</ModalContentTitle>
      <ActionButtons>
        <Button admin onClick={onClose}>
          Annuler
        </Button>
        <Button admin full onClick={handleConfirmDeleteClone} disabled={isConfirmButtonDisabled}>
          Supprimer
        </Button>
      </ActionButtons>
    </Modal>
  );
};

const ModalContentTitle = styled.div`
  display: flex;
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  > * {
    margin: 0 10px;
  }
`;
