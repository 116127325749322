import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { cancelEvent } from "utils";
import UserForm from "./UserForm";
import userFormDataBuilder from "./userFormDataBuilder";

import { fromPromise } from "mobx-utils";
import * as usersApi from "services/admin";
import { t } from "utils/i18n";
import { FORM_MODE_EDIT } from "utils/constants";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

@inject("authStore", "routingStore", "viewStore", "fetchService")
@observer
class EditUserPage extends React.Component {
  @observable
  user;

  @observable
  form;

  componentDidMount() {
    const { match, fetchService } = this.props;
    const userId = match.params.userId;
    fetchService
      .fetch(usersApi.getUser(userId))
      .then(
        action(user => {
          this.user = user;
          this.form = userFormDataBuilder({
            authStore: this.props.authStore,
            user,
            mode: FORM_MODE_EDIT,
            fetchService: this.props.fetchService,
          });
        })
      )
      .catch(e => {
        // FIXME
      });
  }

  @action
  onSubmit = cancelEvent(evt => {
    if (!this.form.isValid) {
      this.form.setTouched();
    } else {
      const { routingStore, viewStore, fetchService } = this.props;
      const data = this.form.values;
      this.createUserRequest = fromPromise(
        fetchService.fetch(usersApi.updateUser(this.user.id, data))
      );
      this.createUserRequest
        .then(() => {
          routingStore.history.goBack();
          viewStore.pushNotification("Utilisateur modifié avec succès.");
        })
        .catch(e => {
          // FIXME NOTNEEDED
          console.error(e);
        });
    }
  });

  render() {
    const { authStore, routingStore } = this.props;
    const { currentUser } = authStore;

    if (!this.user) {
      return null;
    }

    return (
      <Container>
        <Helmet>
          <title>Édition d'un utilisateur</title>
        </Helmet>
        <h1 onClick={routingStore.history.goBack} style={{ cursor: "pointer" }}>
          {t("admin_user")} &gt; @{this.user.username}
        </h1>
        <UserForm
          form={this.form}
          currentUser={currentUser}
          requestObject={this.createUserRequest}
          onSubmit={this.onSubmit}
          onCancel={routingStore.history.goBack}
          mode={FORM_MODE_EDIT}
        />
      </Container>
    );
  }
}

export default EditUserPage;
