import {
  Button,
  ErrorText,
  FormSection,
  ImageDropzone,
  InputField,
  InputWrapper,
  Modal,
  Switch,
  TextArea,
} from "components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { withTranslation } from "react-i18next";
import modalHoc from "react-modal-hoc";
import styled from "styled-components";
import { cancelEvent } from "utils";
import {
  Field,
  fieldsAggregatorBuilder,
  getInputFieldProps,
  getSwitchProps,
  isEmail,
  isRequired,
} from "utils/forms";

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`;

const StyledImageDropzone = styled(ImageDropzone)`
  align-self: center;
`;

const Form = styled.form`
  margin: 10px;
`;

@inject("viewStore")
@observer
class EditProfileModal extends React.Component {
  @observable
  updateProfileRequest = null;

  form = fieldsAggregatorBuilder({
    fields: {
      photo: new Field(),
      firstname: new Field(),
      lastname: new Field({ validate: [isRequired], label: "nom" }),
      email: new Field({ validate: [isEmail], label: "email" }),
      certified: new Field({ initValue: false }),
      bio: new Field(),
      password: new Field(),
      username: new Field({ disabled: true }),
      location: new Field(),
    },
    data: this.props.user,
  });

  @action("SUBMIT_PROFILE_UPDATE")
  onSubmit = cancelEvent((evt) => {
    if (!this.form.isValid) {
      this.form.setTouched();
    } else {
      const { viewStore, user, onRequestClose } = this.props;

      const data = this.form.values;

      this.updateProfileRequest = fromPromise(
        user.updateProfile(data).then(() => {
          onRequestClose();
          viewStore.pushNotification("Profil modifié avec succès.");
        })
      );
    }
  });

  render() {
    const { onRequestClose, user, t } = this.props;
    const isUpdating = this.updateProfileRequest && this.updateProfileRequest.state === "pending";

    return (
      <Modal allowScroll isOpen title={t("profile.edit.title")} onRequestClose={onRequestClose}>
        <Form autoComplete="off" onSubmit={this.onSubmit}>
          <FormSection title={t("profile.edit.photo")}>
            <StyledImageDropzone
              field={this.form.photo}
              metadata={{ clientId: user.client ? user.client.id : null }}
            />
          </FormSection>
          <FormSection title="Informations générales">
            <InputField
              label={`${t("profile.fields.login")} (@)`}
              type="text"
              field={this.form.username}
            />
            <InputField
              label={`${t("profile.fields.lastname")} (*)`}
              type="text"
              field={this.form.lastname}
            />
            <InputField
              label={t("profile.fields.firstname")}
              type="text"
              field={this.form.firstname}
            />
            <InputField label={t("profile.fields.email")} type="text" field={this.form.email} />
            <InputWrapper label={t("profile.fields.certified")}>
              <Switch {...getSwitchProps(this.form.certified)} />
            </InputWrapper>
            <InputField
              label={t("profile.fields.location")}
              type="text"
              field={this.form.location}
            />
            <InputWrapper label={t("profile.fields.bio")}>
              <TextArea
                maxLength={160}
                rows={3}
                maxRows={10}
                {...getInputFieldProps(this.form.bio)}
              />
            </InputWrapper>
            <InputField
              label={t("profile.fields.password")}
              type="password"
              field={this.form.password}
            />
          </FormSection>
          {this.updateProfileRequest && this.updateProfileRequest.state === "rejected" && (
            <ErrorText>{t(this.updateProfileRequest.value.code)}</ErrorText>
          )}
          <ButtonContainer>
            <Button onClick={onRequestClose} disabled={isUpdating}>
              {t("cancel")}
            </Button>
            <Button full type="submit" invalid={!this.form.isValid} loading={isUpdating}>
              {t("save")}
            </Button>
          </ButtonContainer>
        </Form>
      </Modal>
    );
  }
}

export default withTranslation()(modalHoc(EditProfileModal));
