import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { MdMessage } from "react-icons/md";
import { cancelEvent } from "utils";
import { MobileView, BrowserView, DesktopView } from "app/layout/responsiveness/mediaQueries";
import { PageContent } from "app/layout/MainLayout.mobile";
import MainHeaderMobile from "app/layout/headers/MainHeader.mobile";
import { MobXProviderContext } from "mobx-react";
import { Observer } from "mobx-react-lite";
import ConversationItem from "./ConversationItem";
import TabBar from "./TabBar";
import Tab from "./Tab";
import { t } from "utils/i18n";
import { useTranslation } from "react-i18next";

const Header = styled.div`
  display: flex;
  flex: 1 1 100%;
  height: 60px;
`;

const NewMessageButtonWrapper = styled.div`
  position: fixed;
  bottom: 80px;
  right: 35px;
  background-color: ${themeGet("colors.primary")};
  width: 50px;
  height: 50px;
  z-index: ${themeGet("zIndex.high")};
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  box-shadow: ${themeGet("shadows.elevation1")};
`;

const ItemListWrapper = styled.div`
  max-height: calc(100vh - 160px);
  overflow-y: auto;
`;
function ConversationsListSection({ className, user }) {
  const { conversationsStore, routingStore } = React.useContext(MobXProviderContext);
  const [activeTab, setActiveTab] = React.useState(0);
  const { t } = useTranslation();

  const handleClickConversation = (conversationId) =>
    cancelEvent(() => {
      routingStore.history.push(`/messages/conversations/${conversationId}`, {
        readMode: activeTab === 1,
      });
    });

  React.useEffect(() => {
    activeTab
      ? conversationsStore.fetchOtherConversations()
      : conversationsStore.fetchUserConversations();
  }, [activeTab]);

  return (
    <div className={className}>
      <BrowserView>
        <Header />
      </BrowserView>
      {user.isAnimator && (
        <TabBar>
          <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
            {t("messages.conversations.title_yours")}
          </Tab>
          <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
            {t("messages.conversations.title_all")}
          </Tab>
        </TabBar>
      )}
      <ItemListWrapper>
        {activeTab === 0 && (
          <Observer>
            {() => {
              return (
                <ConversationList
                  conversations={conversationsStore.sortedConversations}
                  onClickConversation={handleClickConversation}
                  user={user}
                  readMode={activeTab === 1}
                />
              );
            }}
          </Observer>
        )}
        {activeTab === 1 && (
          <Observer>
            {() => {
              return (
                <ConversationList
                  conversations={conversationsStore.sortedOtherConversations}
                  onClickConversation={handleClickConversation}
                  user={user}
                />
              );
            }}
          </Observer>
        )}
      </ItemListWrapper>
    </div>
  );
}

const ConversationsPageMobile = styled(ConversationsListSection)`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;
  /* max-width: 600px; */
  margin: 0 auto;
`;

function ConversationsPage() {
  const { authStore, routingStore } = React.useContext(MobXProviderContext);
  const handleClickMessageButton = cancelEvent(() => {
    routingStore.history.push("/messages/compose", {
      readMode: false,
    });
  });

  return (
    <React.Fragment>
      <MobileView>
        <MainHeaderMobile user={authStore.currentUser} />
        <PageContent>
          <ConversationsPageMobile user={authStore.currentUser} />
        </PageContent>
        <NewMessageButtonWrapper onClick={handleClickMessageButton}>
          <MdMessage size={25} />
        </NewMessageButtonWrapper>
      </MobileView>
      <DesktopView>
        <HeaderDesktop>
          <span>{t("messages.title")}</span>
          <IconWrapper onClick={handleClickMessageButton}>
            <MdMessage size={25} />
          </IconWrapper>
        </HeaderDesktop>
        <ConversationsListSection user={authStore.currentUser} />
      </DesktopView>
    </React.Fragment>
  );
}

const IconWrapper = styled.div`
  color: ${themeGet("colors.primary")};
  padding: 5px;
  cursor: pointer;
  z-index: 2;
`;

const HeaderDesktop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  height: 60px;
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
  font-weight: 800;
  font-size: 19px;
  padding: 10px;
  margin-bottom: -60px;
`;

function ConversationList({ className, conversations, onClickConversation, user }) {
  return (
    <div className={className}>
      {conversations &&
        conversations.map((conv) => (
          <ConversationItem
            key={conv.id}
            conversation={conv}
            user={user}
            onClick={onClickConversation(conv.id)}
          />
        ))}
    </div>
  );
}

export default ConversationsPage;
