import { getServerUrl } from "utils/fetch";

export function getConversation(id) {
  const url = getServerUrl(`/conversations/${id}`);

  return {
    url,
    method: "GET",
  };
}
