import { getServerUrl } from "utils/fetch";

export function signin(username, password, clientPath, clonePath) {
  return {
    url: getServerUrl("/signin"),
    method: "POST",
    statusToErrorCode: {
      401: "signin.unauthorized",
    },
    acceptedErrorCodes: [401],
    body: JSON.stringify({
      username,
      password,
      clientPath,
      clonePath,
    }),
  };
}

export function signout() {
  return {
    url: getServerUrl("/signout"),
    method: "POST",
  };
}

export function session() {
  return {
    url: getServerUrl("/session"),
    method: "POST",
    acceptedErrorCodes: [401],
  };
}

export function checkClientPath(clientPath, isClone = false) {
  return {
    url: getServerUrl("/check_path", { path: clientPath, isClone }),
    method: "GET",
    acceptedErrorCodes: [401],
  };
}
