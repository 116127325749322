import { action, observable, computed } from "mobx";
import { getDefaultModelSchema, identifier, object, serializable, update } from "serializr";
import * as usersApi from "services/users";
import * as notificationsApi from "services/notifications";
import Client from "./Client";
import { USER_TYPES } from "utils/constants";

export default class User {
  @serializable(identifier())
  id;

  @serializable username;

  @serializable
  @observable
  lastname;

  @serializable
  @observable
  firstname;

  @serializable
  @observable
  photo;

  @serializable
  @observable
  certified;

  @serializable
  @observable
  email;

  @serializable
  @observable
  bio;

  @serializable
  @observable
  weezsCount;

  @serializable
  @observable
  location;

  @serializable(object(Client))
  @observable
  client;

  @serializable
  @observable
  subscribersCount;

  @serializable
  @observable
  subscriptionsCount;

  @serializable
  @observable
  subscribed;

  @serializable
  @observable
  role;

  @serializable
  @observable
  lastNotificationRead;

  @serializable
  @observable
  locked;

  @serializable
  globalDummy;

  @serializable
  @observable
  lang;

  constructor({ fetchService }) {
    this.fetchService = fetchService;
  }

  @action("USER_UPDATE")
  updateFromData(userData) {
    update(this, userData);
  }

  @action("USER_SUBSCRIBE")
  subscribe = (value) => {
    this.subscribed = value;
    return this.fetchService
      .fetch(usersApi.followProfile(this.username, value))
      .then((data) => this.updateFromData(data))
      .catch(action(() => (this.subscribed = !value)));
  };

  @action("UPDATE_PROFILE")
  updateProfile = (data) => {
    return this.fetchService
      .fetch(usersApi.updateUserProfile(this.username, data))
      .then((profile) => this.updateFromData(profile));
  };

  @action("NOTIFICATIONS_SET_READ")
  setLastNotificationRead = (lastNotificationRead) => {
    const oldValue = this.lastNotificationRead;
    this.lastNotificationRead = lastNotificationRead;
    return this.fetchService
      .fetch(notificationsApi.setNotificationsAsRead(lastNotificationRead))
      .catch(action(() => (this.lastNotificationRead = oldValue)));
  };

  @computed
  get isAnimator() {
    return this.role === USER_TYPES.ANIMATOR;
  }
  @computed
  get isSuperAdmin() {
    return this.role === USER_TYPES.SUPERADMIN;
  }
  @computed
  get isUser() {
    return this.role === USER_TYPES.USER;
  }
  @computed
  get isFakeUser() {
    return this.role === USER_TYPES.FAKE_USER;
  }
}

getDefaultModelSchema(User).factory = (context) => {
  const json = context.json;
  const { usersStore } = context.args;
  return usersStore.getUserById(json.id);
};
