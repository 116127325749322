import config from "utils/config";
import qs from "qs";
import fileDownload from "js-file-download";
import ProgressMonitor from "./ProgressMonitor";
import { captureException } from "utils/logger";

export const ERROR_CODES = {
  BAD_REQUEST_400: "BAD_REQUEST_400",
  UNAUTHORIZED_401: "UNAUTHORIZED_401",
  FORBIDDEN_403: "FORBIDDEN_403",
  NOT_FOUND_404: "NOT_FOUND_404",
  SERVER_ERROR_500: "SERVER_ERROR_500",
  NETWORK_ERROR: "NETWORK_ERROR",
  PARSE_ERROR: "PARSE_ERROR",
};
export class ServiceError extends Error {
  constructor({ code, status, data = null, error = null, body, url, method, userMessage }) {
    const message = `SERVICE ERROR : API_code: ${code}, HTTP_status: ${status}, data: ${JSON.stringify(
      data
    )}`;
    super(message);
    this.code = code;
    this.status = status;
    this.error = error;
    this.request = {
      body,
      url,
      method,
    };
    this.userMessage = userMessage;
    this.data = data;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, ServiceError);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export function networkErrorHandler({ body, url, method }) {
  return error => {
    throw new ServiceError({
      code: ERROR_CODES.NETWORK_ERROR,
      status: null,
      data: error.message,
      error,
      body,
      url,
      method,
    });
  };
}

export const defaultStatusToErrorCode = {
  400: ERROR_CODES.BAD_REQUEST_400,
  401: ERROR_CODES.UNAUTHORIZED_401,
  403: ERROR_CODES.FORBIDDEN_403,
  404: ERROR_CODES.NOT_FOUND_404,
  500: ERROR_CODES.SERVER_ERROR_500,
};

export function responseHandler({ url, body, method, statusToErrorCode = {} }) {
  const statusMapper = {
    ...defaultStatusToErrorCode,
    ...statusToErrorCode,
  };

  return function handleResponse(response) {
    const contentType = response.headers.get("content-type");
    const isJson = contentType && contentType.includes("application/json");

    if (isJson) {
      return response
        .json()
        .catch(e => {
          throw new ServiceError({
            code: statusMapper[response.status] || ERROR_CODES.PARSE_ERROR,
            status: response.status,
            url,
            body,
            method,
          });
        })
        .then(json => {
          if (response.ok) {
            return json;
          } else {
            throw new ServiceError({
              code: statusMapper[response.status],
              status: response.status,
              data: json,
              url,
              body,
              method,
              userMessage: json.message,
            });
          }
        });
    } else {
      return response
        .text()
        .catch(e => {
          throw new ServiceError({
            code: statusMapper[response.status] || ERROR_CODES.PARSE_ERROR,
            status: response.status,
            url,
            body,
            method,
          });
        })
        .then(text => {
          if (response.ok) {
            return true;
          } else {
            throw new ServiceError({
              code: statusMapper[response.status],
              status: response.status,
              data: text,
              url,
              body,
              method,
            });
          }
        });
    }
  };
}

export function addParams(path, params) {
  const paramsString = qs.stringify(params);
  return `${path}?${paramsString}`;
}

export function getServerUrl(path, params) {
  let fullPath = `${config.apiUrl}${path}`;
  if (params) {
    return addParams(fullPath, params);
  } else {
    return fullPath;
  }
}

export function requestFactory({ errorInterceptor }) {
  return function request(opts) {
    const { url, statusToErrorCode, acceptedErrorCodes = [], ...requestOpts } = opts;
    const requestOptions = {
      credentials: config.credentials,
      ...requestOpts,
      headers: {
        ...requestOpts.headers,
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
      },
    };
    return fetch(url, requestOptions)
      .catch(networkErrorHandler(opts))
      .then(responseHandler(opts))
      .catch(err => {
        if (err.status && !acceptedErrorCodes.includes(err.status)) {
          // We don't log Network error and accepted errors
          captureException(err);
        }
        if (errorInterceptor) {
          errorInterceptor(err);
        }

        throw err;
      });
  };
}

export function multipartFactory({ errorInterceptor }) {
  return function multipart({ url, fields, statusToErrorCode = {}, acceptedErrorCodes = [] }) {
    const progressMonitor = new ProgressMonitor();
    const promise = new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        Object.keys(fields).forEach(key => {
          formData.append(key, fields[key]);
        });
        xhr.open("POST", url, true);
        xhr.withCredentials = true;

        if (progressMonitor) {
          xhr.upload.addEventListener(
            "progress",
            evt => {
              progressMonitor.setProgress(Math.round((evt.loaded * 100.0) / evt.total));
            },
            false
          );
        }

        xhr.send(formData);
        xhr.onreadystatechange = function() {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            const statusMapper = {
              ...defaultStatusToErrorCode,
              ...statusToErrorCode,
            };
            if (xhr.status !== 200) {
              reject(
                new ServiceError({
                  status: xhr.status,
                  code: (xhr.status && statusMapper[xhr.status]) || ERROR_CODES.NETWORK_ERROR,
                })
              );
            } else {
              try {
                resolve(JSON.parse(xhr.responseText));
              } catch (e) {
                reject(e);
              }
            }
          }
        };
      } catch (e) {
        reject(e);
      }
    }).catch(err => {
      if (err.status && !acceptedErrorCodes.includes(err.status)) {
        // We don't log Network error and accepted errors
        captureException(err);
      }
      if (errorInterceptor) {
        errorInterceptor(err);
      }

      throw err;
    });
    promise.progressMonitor = progressMonitor;

    return promise;
  };
}

export function requestDownloadFactory({ errorInterceptor }) {
  return function request(opts) {
    const { url, statusToErrorCode, acceptedErrorCodes = [], ...requestOpts } = opts;
    const requestOptions = {
      credentials: config.credentials,
      ...requestOpts,
      headers: {
        ...requestOpts.headers,
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
      },
    };
    return fetch(url, requestOptions)
      .catch(networkErrorHandler(opts))
      .then(response => {
        const fileName = response.headers.get("x-filename");
        response.blob().then(blob => fileDownload(blob, fileName));
      })
      .catch(err => {
        if (err.status && !acceptedErrorCodes.includes(err.status)) {
          // We don't log Network error and accepted errors
          captureException(err);
        }
        if (errorInterceptor) {
          errorInterceptor(err);
        }

        throw err;
      });
  };
}
