import { EditorState } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import "draft-js-mention-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import React from "react";
import * as usersApi from "services/users";
import styled from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";
import { WEEZ_MAX_LENGTH } from "utils/constants";
import { transition } from "utils/mixins";
import SuggestionEntry from "./SuggestionEntry";
import { getEntities } from "utils";
import { inject } from "mobx-react";
import debounce from "lodash/debounce";

const theme = {
  mentionSuggestions: "mentionSuggestions",
  mentionSuggestionsEntry: "mentionSuggestionsEntry",
  mentionSuggestionsEntryFocused: "mentionSuggestionsEntryFocused",
};

const Container = styled.div`
  cursor: text;

  flex: 1;
  position: relative;

  & .public-DraftEditor-content {
    ${transition("min-height")};
    min-height: ${styledMap({
      small: "15px",
      default: "100px",
    })};
  }

  & .public-DraftEditorPlaceholder-root {
    color: ${themeGet("colors.primary")};
    font-weight: 600;
  }

  & .public-DraftEditorPlaceholder-hasFocus {
    color: ${themeGet("colors.grey")};
    font-weight: 400;
  }

  & .${theme.mentionSuggestions} {
    position: absolute;
    border: 1px solid ${themeGet("colors.veryLightGrey")};
    background: #fff;
    border-radius: 2px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    width: 100%;
    left: 0px;
    box-shadow: ${themeGet("shadows.default")};
    z-index: ${themeGet("zIndex.high")};
  }

  & .${theme.mentionSuggestionsEntry} {
    transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  }

  & .${theme.mentionSuggestionsEntry}:active {
    background-color: #cce7ff;
  }

  & .${theme.mentionSuggestionsEntryFocused} {
    transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
    background-color: #e6f3ff;
  }

  span[data-text] {
    word-break: break-all;
  }
`;

const Mention = styled.span`
  color: ${themeGet("colors.primary")};
  font-weight: 600;
`;

const LengthCounter = styled.span`
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-weight: 600;
  color: ${styledMap({
    error: themeGet("colors.error"),
    default: themeGet("colors.black"),
  })};
`;

const positionSuggestions = ({ state, props }) => {
  let transform;
  let transition;

  if (state.isActive && props.suggestions.length > 0) {
    transform = "scaleY(1)";
    transition = "all 0.25s cubic-bezier(.3,1.2,.2,1)";
  } else if (state.isActive) {
    transform = "scaleY(0)";
    transition = "all 0.25s cubic-bezier(.3,1,.2,1)";
  }

  return {
    transform,
    transition,
  };
};

@inject("fetchService")
class WeezEditor extends React.Component {
  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      positionSuggestions,
      theme,
      mentionPrefix: "@",
      mentionComponent: ({ children }) => <Mention>{children}</Mention>,
    });
    this.plugins = [this.mentionPlugin, ...props.plugins];
    this.onSearchChange = debounce(this.onSearchChange, 700);
  }

  state = {
    editorState: EditorState.createEmpty(),
    suggestions: [],
    length: 0,
  };

  onChange = editorState => {
    const { onChange, maxLength } = this.props;
    const text = editorState.getCurrentContent().getPlainText();
    const length = text.length;
    const lastChangeType = editorState.getLastChangeType();
    let selectedTextLength = this._getLengthOfSelectedText();
    const EMOJI_LENGTH = 3;
    selectedTextLength =
      selectedTextLength > 0 ? selectedTextLength + EMOJI_LENGTH : selectedTextLength;
    // Prevent emoji from being inserted when text reached max length
    if (lastChangeType === "insert-emoji" && length - selectedTextLength > maxLength) {
      // No emoji insertion
    } else {
      this.setState({
        editorState,
        length,
      });
    }

    const mentions = getEntities(editorState, "mention").reduce((res, draftEntity) => {
      const userId = draftEntity.entity.data.mention.id;
      const alreadyIncluded = res.find(({ id }) => id === userId);
      if (!alreadyIncluded) {
        res.push({
          ...draftEntity.entity.data.mention,
        });
      }
      return res;
    }, []);
    onChange(null, { text, mentions });
  };

  onSearchChange = ({ value }) => {
    const { fetchService } = this.props;
    if (value) {
      fetchService
        .fetch(usersApi.usersAutocomplete(value))
        .then(users => {
          // Name key mandatory
          users.forEach(user => (user.name = user.username));
          this.setState({
            suggestions: users,
          });
        })
        .catch(e => {
          // FIXME ??
        });
    } else {
      this.setState({
        suggestions: [],
      });
    }
  };

  onAddMention = () => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  /**
   * Copied from : https://stackoverflow.com/questions/46044251/how-to-limit-max-length-of-draft-js
   */
  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }
    return length;
  };

  _handleBeforeInput = () => {
    const { maxLength } = this.props;
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > maxLength - 1) {
      return "handled";
    }
  };

  _handlePastedText = pastedText => {
    const { maxLength } = this.props;
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > maxLength) {
      return "handled";
    }
  };

  /**
   * End of copy
   */

  render() {
    const { onFocus, onBlur, small, placeholder, maxLength } = this.props;
    const { editorState, suggestions, length } = this.state;
    const { MentionSuggestions } = this.mentionPlugin;

    return (
      <Container onClick={this.focus} small={small}>
        <Editor
          editorState={editorState}
          onChange={this.onChange}
          plugins={this.plugins}
          onBlur={onBlur}
          onFocus={onFocus}
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}
          placeholder={placeholder}
          ref={element => {
            this.editor = element;
          }}
        />
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={suggestions}
          onAddMention={this.onAddMention}
          entryComponent={SuggestionEntry}
        />
        {!small && (
          <LengthCounter error={length > maxLength}>
            {length} / {maxLength}
          </LengthCounter>
        )}
      </Container>
    );
  }
}

WeezEditor.defaultProps = {
  maxLength: WEEZ_MAX_LENGTH,
};

export default WeezEditor;
