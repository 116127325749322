import isEmailValidator from "validator/lib/isEmail";
import isHexColorValidator from "validator/lib/isHexColor";
import isValidDate from "date-fns/isValid";
import i18n from "../../i18n";

export function isValid(values) {
  return values.every((field) => field.isValid);
}

export function setTouched(values) {
  values.forEach((field) => field.markAsTouch());
}

function mergeHandler(handler, optHandler) {
  if (!optHandler) {
    return handler;
  } else {
    return (...args) => {
      optHandler(...args);
      handler(...args);
    };
  }
}

function withFormater(value, formater) {
  if (!formater) {
    return value;
  } else {
    return formater(value);
  }
}

export function getInputFieldProps(field, { onChange, onBlur, ...others } = {}, { formater } = {}) {
  return (
    field && {
      value: withFormater(field.value, formater),
      onChange: mergeHandler(field.onChange, onChange),
      error: field.errorMessage,
      onBlur: mergeHandler(field.markAsTouch, onBlur),
      disabled: field.disabled,
      ...others,
    }
  );
}

export function getFileUploadFieldProps(field, { onChange, onBlur, ...others } = {}) {
  return {
    value: field.value,
    onChange: mergeHandler(field.onChange, onChange),
    error: field.errorMessage,
    onBlur: mergeHandler(field.markAsTouch, onBlur),
    disabled: field.disabled,
    onProgress: field.setProgress,
    ...others,
  };
}

export function getSwitchProps(field) {
  return {
    checked: field.value,
    onChange: field.onChange,
    onBlur: field.markAsTouch,
    disabled: field.disabled,
  };
}

export const isRequired = (newValue, label) => {
  let isValid = !!newValue;
  if (isValid && newValue.push) {
    // If is array then length must be > 0
    isValid = !!newValue.length;
  }

  return {
    valid: isValid,
    message: !isValid ? `${label} ${i18n.t("field_required")}` : "",
  };
};

export const maxLength = (maxLength, property) => (newValue, label) => {
  let value = newValue;
  if (newValue && property) {
    value = newValue[property];
  }

  let isValid = !value || value.length <= maxLength;
  return {
    valid: isValid,
    message: !isValid ? `${label} doit faire ${maxLength} caractères maximum` : "",
  };
};

export const isEmail = (newValue, label) => {
  const isValid = !newValue || isEmailValidator(newValue);
  return {
    valid: isValid,
    message: !isValid ? `${label} non valide` : "",
  };
};

export function isHexColor(newValue, label) {
  const isValid = !newValue || isHexColorValidator(newValue);
  return {
    valid: isValid,
    message: !isValid ? `${label} doit être une couleur hexa valide` : "",
  };
}

export function isDate(newValue, label) {
  const isValid = !newValue || isValidDate(newValue);
  return {
    valid: isValid,
    message: !isValid ? `${label} non valide` : "",
  };
}

export function isAlphanumeric(newValue, label) {
  const isValid = !newValue || !!newValue.match(/^[0-9A-Z-]+$/i);
  return {
    valid: isValid,
    message: !isValid ? `${label || "Le champ"} doit être alphanumerique` : "",
  };
}

export const forbiddenChar = (char, label, message) => (newValue) => {
  let value = newValue;
  let isValid = !value || !value.includes(char);
  return {
    valid: isValid,
    message: !isValid
      ? message || `${label || "Le champ"} ne doit pas contenir le caractère ${char}`
      : "",
  };
};

export const forbiddenChars =
  (chars = [], label, message) =>
  (value) => {
    let isValid =
      !value ||
      !chars.filter((char) => {
        return value.includes(char);
      }).length > 0;
    return {
      valid: isValid,
      message: !isValid
        ? message || `${label || "Le champ"} ne doit pas contenir les caractères ${chars.join(" ")}`
        : "",
    };
  };

// export function isPostalCode(newValue, label) {
//   const isValid = isPostalCodeValidator(newValue, "FR");
//   return {
//     valid: isValid,
//     message: !isValid ? `${label} non valide` : "",
//   };
// }

// export function isPhoneNumber(newValue, label) {
//   const isValid = isMobilePhone(newValue, "fr-FR");
//   return {
//     valid: isValid,
//     message: !isValid ? `${label} non valide` : "",
//   };
// }

export { default as Field } from "./Field";
export { default as FieldWithProgress } from "./FieldWithProgress";
export * from "./FieldsAggregator";
