import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { t } from "utils/i18n";
import { getUser } from "services/admin";
import UserForm from "./UserForm";
import userFormDataBuilder from "./userFormDataBuilder";
import { FORM_MODE_CONSULT } from "utils/constants";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

@inject("fetchService", "authStore", "routingStore")
@observer
class ViewUserPage extends Component {
  @observable
  user;

  @observable
  form;

  componentDidMount() {
    const { match, fetchService } = this.props;
    const userId = match.params.userId;
    fetchService
      .fetch(getUser(userId))
      .then(
        action(user => {
          this.user = user;
          this.form = userFormDataBuilder({
            authStore: this.props.authStore,
            user,
            mode: FORM_MODE_CONSULT,
            fetchService: this.props.fetchService,
          });
        })
      )
      .catch(e => {
        // FIXME
      });
  }

  render() {
    const { authStore, routingStore } = this.props;
    const { currentUser } = authStore;
    if (!this.user) {
      return null;
    }
    return (
      <Container>
        <Helmet>
          <title>Consultation d'un utilisateur</title>
        </Helmet>
        <h1 onClick={routingStore.history.goBack} style={{ cursor: "pointer" }}>
          {t("admin_user")} &gt; @{this.user.username}
        </h1>
        <UserForm
          form={this.form}
          currentUser={currentUser}
          requestObject={this.createUserRequest}
          onSubmit={this.onSubmit}
          onCancel={routingStore.history.goBack}
          mode={FORM_MODE_CONSULT}
        />
      </Container>
    );
  }
}

export default ViewUserPage;
