import React from "react";
import { isMobile, isNotMobile } from "app/layout/responsiveness/mediaQueries";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import SearchBar from "components/searchbar";
import { t } from "utils/i18n";
import useSearchStore from "./useSearchStore";
import { runInAction, action } from "mobx";
import { fromPromise } from "mobx-utils";
import { MobXProviderContext } from "mobx-react";
import { MdCheckCircle } from "react-icons/md";
import { UserItem } from "app/common";
import { searchUsers } from "services/users";
import { useObserver } from "mobx-react-lite";
import { resetList } from "utils/mixins";

function NewConversationAsPage({ onSelect }) {
  return (
    <React.Fragment>
      <NewConversationAsView onSelectUser={onSelect} />
    </React.Fragment>
  );
}

const NewConversationAsView = styled(NewConversationAsSection)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: ${isNotMobile ? "300px" : "100%"};
  max-height: 100%;
  flex: 1 1 auto;
  margin: 0 auto;
`;

const SearchBarWrapper = styled.div`
  padding: ${isMobile ? 0 : "10px"};
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
`;

const SquaredSearchBar = styled(SearchBar)`
  border: none;
  border-radius: 0;
  padding: 4px 10px;
  &:focus-within {
    background-color: ${themeGet("colors.inputBackground")};
  }
`;

const ResultsWrapper = styled.ul`
  ${resetList};
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

function NewConversationAsSection({ className, onSelectUser }) {
  const { authStore, fetchService, usersStore } = React.useContext(MobXProviderContext);
  const processResult = values => values.map(value => usersStore.deserializeUser(value));
  const store = useSearchStore(processResult);
  const loadResults = terms => {
    !store.allResultsLoaded &&
      runInAction(() => {
        if (store.searchPage > 0) {
          store.searchQueryRequest = fromPromise(
            // fetchService.fetch(searchUsers({ terms, roles: ["DUMMY"] }, store.searchPage))
            fetchService.fetch(searchUsers(terms, ["DUMMY"]))
          );
          store.searchQueryRequest.then(
            action(values => {
              store.pushResults(values);
              // store.allResultsLoaded = values.totalElements === store.usersCount;
              store.allResultsLoaded = true;
            })
          );
        } else {
          store.searchQueryRequest = fromPromise(
            fetchService.fetch(searchUsers(terms, ["DUMMY"]))
            // fetchService.fetch(searchUsers({ terms, roles: ["DUMMY"] }))
          );
          store.searchQueryRequest.then(
            action(values => {
              store.replaceResults(values);
              // store.allResultsLoaded = values.totalElements === store.resultsCount;
              store.allResultsLoaded = true;
            })
          );
        }
      });
  };

  const handleScroll = () => {
    store.incrementSearchPage();
    loadResults();
  };

  const handleClickUser = user => () => {
    onSelectUser && onSelectUser(user);
  };

  const handleSearchBarPressEnter = action(terms => {
    store.resetSearch();
    store.terms = terms;
    loadResults(terms);
  });

  return useObserver(() => (
    <div className={className}>
      <SearchBarWrapper>
        <SquaredSearchBar
          rounded={false}
          placeholder={t("messages.compose.onbehalf.search.placeholder")}
          inputProps={{ autoFocus: true }}
          onPressEnter={handleSearchBarPressEnter}
        />
      </SearchBarWrapper>
      <ResultsList
        user={authStore.currentUser}
        onClickItem={handleClickUser}
        results={store.results}
        allResultsLoaded={store.allResultsLoaded}
        handleScroll={handleScroll}
      />
    </div>
  ));
}

function ResultsList({ user, onClickItem, results, handleScroll, allResultsLoaded }) {
  return (
    <ResultsWrapper>
      <UserItem
        key={user.id}
        user={user}
        onClick={onClickItem(user)}
        renderRight={() => <MdCheckCircle size={20} color={"#0D5295"} />}
      />
      {results.length === 0 && allResultsLoaded && <div>No results</div>}
      {results.length > 0 &&
        results.map(user => {
          return <UserItem key={user.id} user={user} onClick={onClickItem(user)} />;
        })}
    </ResultsWrapper>
  );
}

export default NewConversationAsPage;
