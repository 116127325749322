import React from "react";
import styled from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";
import TextareaAutosize from "react-autosize-textarea";

const StyledTextarea = styled(TextareaAutosize)`
  outline: 0;
  border: 1px solid ${themeGet("colors.grey")};
  border-color: ${styledMap({
    white: themeGet("colors.white"),
    error: themeGet("colors.error"),
    default: themeGet("colors.grey"),
    disabled: "transparent",
  })};
  padding: ${styledMap({
    large: "10px 10px;",
    default: "5px 7px;",
  })};
  border-radius: 4px;
  background-color: ${styledMap({
    white: themeGet("colors.white"),
    default: "transparent",
  })};
  color: ${styledMap({
    disabled: themeGet("colors.strongGrey"),
    default: themeGet("colors.black"),
  })};
  width: 100%;
  line-height: 1.3rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Counter = styled.span`
  visibility: ${(props) => (props.disabled ? "hidden" : "visible")};
  color: ${styledMap({
    disabled: themeGet("colors.grey"),
    default: "inherit",
  })};
  margin: 2px;
  font-size: 0.9rem;
`;

const TextArea = ({ onChange, maxLength, className, ...props }) => {
  const handleChange = (evt) => {
    let value = evt.target.value;
    if (maxLength && value.length > maxLength) {
      value = value.substring(0, maxLength);
    }
    if (onChange) {
      onChange(evt, value);
    }
  };

  const length = props.value ? props.value.length : 0;
  return (
    <Container className={className}>
      <StyledTextarea {...props} onChange={handleChange} />
      {maxLength ? (
        <Counter disabled={props.disabled}>
          {length}/{maxLength}
        </Counter>
      ) : null}
    </Container>
  );
};

export default React.memo(TextArea);
