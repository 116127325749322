import { themeGet } from "@styled-system/theme-get";
import { Button, ErrorContent, Input, LoadingContent } from "components";
import throttle from "lodash/throttle";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import qs from "qs";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { searchUsers } from "services/search";
import styled from "styled-components";
import { cancelEvent } from "utils";
import { Field, getInputFieldProps } from "utils/forms";
import { t } from "utils/i18n";
import { marginVertical } from "utils/mixins";
import UserItem from "../common/UserItem";

const Container = styled.div`
  max-width: 800px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SearchBarContainer = styled.div`
  display: flex;
  background-color: ${themeGet("colors.primary")};
  margin-top: 20px;
  border-radius: 5px;
  padding: 40px;
  justify-content: flex-start;
  align-items: center;
`;

const SearchFrom = styled.form`
  display: flex;
  flex: 1;
`;

const SearchInput = styled(Input)`
  flex: 1;
  margin-right: 20px;
`;

const SearchButton = styled(Button)`
  background-color: white;
`;

const SearchResults = styled.div`
  ${marginVertical("5px")};
  background-color: ${themeGet("colors.white")};
`;

const SearchUserItem = observer(({ user, onClick, onSubscribe }) => {
  const { t } = useTranslation();
  const isSubscribed = user.subscribed;
  return (
    <UserItem
      user={user}
      onClick={() => onClick(user)}
      displayEmail={false}
      renderRight={(user) => (
        <Button full={isSubscribed} onClick={onSubscribe(user)}>
          {isSubscribed ? t("subscribed") : t("follow")}
        </Button>
      )}
    />
  );
});

const NoResults = styled.span`
  font-size: 1.2rem;
  text-align: center;
  padding: 40px;
  font-weight: 700;
  display: block;
`;

@inject("fetchService", "usersStore", "routingStore", "authStore")
@observer
class SearchPage extends React.Component {
  @observable
  query;

  @observable
  page;

  @observable
  allResulsLoaded;

  @observable
  searchQueryRequest;

  @observable
  users = [];

  constructor(props) {
    super(props);
    const queryParams = qs.parse(this.props.location.search.substring(1));
    this.query = new Field({
      initValue: decodeURIComponent(queryParams.terms),
    });
  }

  setRef = (node) => {
    this.searchInputRef = node;
  };

  onSearch = cancelEvent(() => {
    this.search();
  });

  handleSubscribeToUser = (user) =>
    cancelEvent(() => {
      user.subscribe(!user.subscribed).then(() => {
        this.props.authStore.refreshSession();
      });
    });

  @action("SEARCH")
  search = () => {
    const terms = this.query.value;
    this.page = 0;
    this.props.routingStore.history.replace(`/search?terms=${encodeURIComponent(terms)}`);
    this.searchQueryRequest = fromPromise(this.props.fetchService.fetch(searchUsers(terms)));
    this.searchQueryRequest.then(
      action((values) => {
        this.users = values.data.map((value) => this.props.usersStore.deserializeUser(value));
        this.allResulsLoaded = values.totalElements === this.users.length;
      })
    );
  };

  @action("SEARCH_LOAD_MORE")
  loadMoreSearch = () => {
    const terms = this.query.value;
    this.page++;
    this.searchQueryRequest = fromPromise(
      this.props.fetchService.fetch(searchUsers(terms, this.page))
    );
    this.searchQueryRequest.then(
      action((values) => {
        this.users.push(
          ...values.data.map((value) => this.props.usersStore.deserializeUser(value))
        );
        this.allResulsLoaded = values.totalElements === this.users.length;
      })
    );
  };

  componentDidMount() {
    this.search();
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = throttle(
    (evt) => {
      if (document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        !this.allResulsLoaded && this.loadMoreSearch();
      }
    },
    300,
    { leading: true, trailing: true }
  );

  onClickUser = (user) => {
    this.props.routingStore.history.push({
      pathname: `/${user.username}`,
      search: `?id=${user.id}`,
    });
  };

  render() {
    return (
      <Container>
        <Helmet>
          <title>{t("search.title")}</title>
        </Helmet>
        <SearchBarContainer>
          <SearchFrom onSubmit={this.onSearch}>
            <SearchInput field={this.query} white {...getInputFieldProps(this.query)} />
            <SearchButton type="submit">{t("search.action")}</SearchButton>
          </SearchFrom>
        </SearchBarContainer>
        {this.users && (
          <SearchResults>
            {this.users.map((user) => (
              <SearchUserItem
                onClick={this.onClickUser}
                onSubscribe={this.handleSubscribeToUser}
                key={user.id}
                user={user}
              />
            ))}
            {!this.users.length && <NoResults>{t("search.empty")}</NoResults>}
          </SearchResults>
        )}
        <div>
          {this.searchQueryRequest &&
            this.searchQueryRequest.case({
              pending: () => <LoadingContent label={t("search.loading")} />,
              rejected: (error) => <ErrorContent text={error.code} />,
              fulfilled: () => null,
            })}
        </div>
      </Container>
    );
  }
}

export default SearchPage;
