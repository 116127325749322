import React from "react"
import styled from "styled-components"
import { observer, inject } from "mobx-react"
import { observable, action } from "mobx"
import settingsFormDataBuilder from "./settingsFormDataBuilder"
import { InputWrapper, Switch, FormSection, Button } from "components"
import { getSwitchProps } from "utils/forms"
import { cancelEvent } from "utils"
import { fromPromise } from "mobx-utils"
import * as adminApi from "services/admin"
import { t } from "utils/i18n"

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  max-width: 600px;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 10px;
  }
`

@inject("fetchService", "viewStore")
@observer
class SettingsPage extends React.Component {
  @observable
  form = settingsFormDataBuilder()

  @action
  _handleSubmit = cancelEvent((evt) => {
    if (!this.form.isValid) {
      this.form.setTouched()
    } else {
      const { fetchService, viewStore } = this.props
      const data = this.form.values
      this.saveSettingsRequest = fromPromise(fetchService.fetch(adminApi.saveSettings(data)))
      this.saveSettingsRequest.then(() =>
        viewStore.pushNotification("Paramètres modifié avec succès.")
      )
    }
  })

  componentDidMount() {
    const { fetchService } = this.props
    fetchService
      .fetch(adminApi.fetchSettings())
      .then(
        action((settings) => {
          this.form = settingsFormDataBuilder(settings)
        })
      )
      .catch((e) => {
        console.error(e)
        // FIXME
      })
  }

  render() {
    return (
      <Container>
        <h1>Gestion des exercices</h1>
        <form autoComplete="off" onSubmit={this._handleSubmit}>
          <FormSection title={t("weeze_parameter")}>
            <InputWrapper label={t("visible_all")}>
              <Switch {...getSwitchProps(this.form.allSponsored)} />
            </InputWrapper>
          </FormSection>
          <ButtonContainer>
            <Button
              admin
              to="/admin/users"
              disabled={this.saveSettingsRequest && this.saveSettingsRequest.state === "pending"}>
              {t("cancel")}
            </Button>
            <Button
              admin
              full
              type="submit"
              invalid={!this.form.isValid}
              loading={this.saveSettingsRequest && this.saveSettingsRequest.state === "pending"}>
              {t("save")}
            </Button>
          </ButtonContainer>
        </form>
      </Container>
    )
  }
}

export default SettingsPage
