import React from "react";
import ReactDOM from "react-dom";

/**
 *
 * @param {React.Component} Component
 */
const withInfiniteScroll = Component =>
  class WithInfiniteScroll extends React.Component {
    componentNode;
    scrollTop = 0;
    scrollHeight = 0;

    static defaultProps = {
      isLoading: false,
      list: [],
      loadMore: () => {},
    };

    onScroll = e => {
      const el = this.componentNode;
      const isScrollingDown = el.scrollTop > this.scrollTop;
      const isAboutToReachListBottom = el.scrollTop > el.scrollHeight - 30 - el.offsetHeight;
      const hasUndisplayedElement = el.scrollHeight >= el.offsetHeight;
      const { isLoading, list, loadMore } = this.props;
      if (
        !isLoading &&
        list.length &&
        hasUndisplayedElement &&
        isScrollingDown &&
        isAboutToReachListBottom
      ) {
        this.scrollTop = el.scrollTop;
        loadMore();
      }
    };

    componentDidMount() {
      this.componentNode = ReactDOM.findDOMNode(this); //TODO find alternative to findDOMNode
      this.componentNode.addEventListener("scroll", this.onScroll, false);
    }

    componentWillUnmount() {
      this.componentNode.removeEventListener("scroll", this.onScroll, false);
    }

    render() {
      return <Component {...this.props} />;
    }
  };

export default withInfiniteScroll;
