import React from "react";
import styled, { withTheme } from "styled-components";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { themeGet } from "@styled-system/theme-get";
import { Line } from "rc-progress";
import DurationFormat from "components/DurationFormat";
import { MdMessage, MdStop } from "react-icons/md";
import { boostSequenceAction } from "services/boost";
import OriginalBoostWeezeModal from "../weez/OriginalBoostWeezeModal";
import { withTranslation } from "react-i18next";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 65px;
`;

const InfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-height: 60px;
  position: relative;
  width: 100%;
  background-color: ${(props) => (props.isPlaying || props.isPaused ? "#e9f2f8" : "inherit")};

  :last-child {
    border-bottom: none;
  }
`;
const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
`;
const ItemButton = styled.div`
  float: left;
  cursor: ${(props) => (props.disable ? "initial" : "pointer")};
  color: ${(props) => (props.color ? props.color : "inherit")};
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
`;
const Message = styled.div`
  /* font-style: italic; */
  margin-top: 5px;
  font-size: 12px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 180px;
`;

const ContainerSource = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PLAY_STATUS = "RUNNING";
const PAUSE_STATUS = "PAUSED";
const STOP_STATUS = "STOPPED";

const WAITING_BETWEEN_ACTION = 1000;

@observer
class BoostSequencesItem extends React.Component {
  @observable isPlaying = false;
  @observable isPaused = false;
  @observable disable = false;
  @observable disableStop = true;
  @observable status = null;
  @observable openModal = false;

  constructor(props) {
    super(props);
    this.updateSequenceStatus(props.sequence);
  }

  @action.bound
  updateSequenceStatus(sequence) {
    const { status } = sequence;
    if (status !== this.status) {
      this.status = status;
      switch (status) {
        case PLAY_STATUS:
          this.isPlaying = true;
          this.isPaused = false;
          this.disableStop = false;
          break;
        case PAUSE_STATUS:
          this.isPaused = true;
          this.isPlaying = false;
          this.disableStop = false;
          break;
        case STOP_STATUS:
          this.isPaused = false;
          this.isPlaying = false;
          this.disableStop = true;
          this.elapsed = 0;
          break;
        default:
          // do nothing
          break;
      }
    }
  }

  stop = (id) =>
    !this.disableStop
      ? action("STOP_SEQUENCE", (evt) => {
          if (!this.disable) {
            this.disable = true;
            this.isPlaying = false;
            this.disableStop = true;
            this.elapsed = 0;
            this.postStatus(STOP_STATUS, id).then((data) => {
              this.props.onChangeStatus(data);
            });
            setTimeout(() => {
              this.enableActionButtons();
            }, WAITING_BETWEEN_ACTION);
          }
        })
      : undefined;

  postStatus = (status, id) => {
    const { fetchService } = this.props;
    const data = { id, status };
    return fetchService.fetch(boostSequenceAction(data, id));
  };

  calculateProgression = (elapsed, duration) => {
    return (duration ? elapsed / duration : 0) * 100;
  };

  @action("ENABLE_BUTTON")
  enableActionButtons = () => {
    this.disable = false;
  };

  openOriginalWeeze = (weezeId) => {};

  componentDidUpdate() {
    this.updateSequenceStatus(this.props.sequence);
  }

  render() {
    const { sequence: seq, t } = this.props;
    return (
      <React.Fragment>
        {this.openModal && (
          <OriginalBoostWeezeModal
            isOpen={!!this.openModal}
            weezeId={seq.originalWeezeId}
            onRequestClose={action("CLOSE_ORIGINAL_WEEZE_MODAL", () => (this.openModal = false))}
          />
        )}

        <ItemContainer>
          <InfoContainer isPlaying={this.isPlaying} isPaused={this.isPaused}>
            <MessageContainer>
              <Title>{seq.name}</Title>
              <Message>
                {`${t("scheduler.published_weezes")} 
              ${seq.publishedWeezesCount} / ${seq.weezesCount}`}
              </Message>
              <Message>
                {t("scheduler.duration")} <DurationFormat duration={seq.duration} strict={true} />
              </Message>
            </MessageContainer>
            <ContainerSource onClick={action(() => (this.openModal = true))}>
              <MdMessage size={20} />
            </ContainerSource>
            <ContainerButton>
              <ItemButton
                onClick={this.stop(seq.id)}
                disable={this.disableStop || this.disable}
                color={this.disableStop ? themeGet("colors.grey") : themeGet("colors.red")}>
                <MdStop size={25} />
              </ItemButton>
            </ContainerButton>
          </InfoContainer>
          <Line
            percent={this.calculateProgression(seq.elapsed, seq.duration)}
            strokeWidth={2}
            strokeLinecap="square"
            strokeColor={themeGet("colors.primary")(this.props)}
          />
        </ItemContainer>
      </React.Fragment>
    );
  }
}

export default withTranslation()(withTheme(BoostSequencesItem));
