import { getServerUrl } from "utils/fetch";

export function getFakeUsers({ lang, page, size }) {
  return {
    url: getServerUrl("/boost/users", { lang, page, size }),
    method: "GET",
  };
}

export function getFakeMessages({ lang, page, size, mood }) {
  return {
    url: getServerUrl("/boost/messages", { lang, page, size, mood }),
    method: "GET",
  };
}

export function importFakeUsers(file, metadata = {}) {
  return {
    url: getServerUrl("/boost/users/import"),
    type: "multipart",
    fields: {
      file,
      metadata: new Blob([JSON.stringify(metadata)], { type: "application/json" }),
    },
  };
}

export function importFakeMessages(file, metadata = {}) {
  return {
    url: getServerUrl("/boost/messages/import"),
    type: "multipart",
    fields: {
      file,
      metadata: new Blob([JSON.stringify(metadata)], { type: "application/json" }),
    },
  };
}

export function getLangs() {
  return {
    url: getServerUrl("/boost/langs"),
    method: "GET",
  };
}

export function getMoods() {
  return {
    url: getServerUrl("/boost/moods"),
    method: "GET",
  };
}

export function createBoost(boost) {
  return {
    url: getServerUrl("/boost"),
    method: "POST",
    body: JSON.stringify(boost),
  };
}

export function getBoostSequences() {
  return {
    url: getServerUrl("/boost/sequences"),
    method: "GET",
  };
}

export function boostSequenceAction(body, id) {
  return {
    url: getServerUrl(`/boost/sequences/${id}/status`),
    method: "POST",
    body: JSON.stringify(body),
  };
}
