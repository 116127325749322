// import { observable } from "mobx";
import { identifier, serializable, object, custom } from "serializr"
import Participant from "./Participant"
import parseISO from "date-fns/parseISO"

export default class PrivateMessage {
  @serializable(identifier())
  id

  @serializable
  content

  @serializable(object(Participant))
  author

  @serializable
  conversationId

  @serializable(
    custom(
      (date) => date.toISOString(),
      (value, context, oldValue, callback) => {
        callback(null, value && parseISO(value))
      }
    )
  )
  createdOn

  static initSchema() {
    Participant.initSchema()
  }
}
