import { Button, ErrorText, Spinner } from "components";
import throttle from "lodash/throttle";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { lightenColor, resetButton } from "utils/mixins";
import Weez from "./weez/Weez";
import { MdInbox } from "react-icons/md";
import { withTranslation } from "react-i18next";

const Container = styled.div`
  display: block;
  width: 100%;
`;

const HasNewWeezContainer = styled.div`
  button {
    ${resetButton};
    display: block;
    font-weight: 400;
    background-color: ${lightenColor(0.02, "colors.backgroundGrey")};
    width: 100%;
    padding: 30px;
    text-align: center;
    border: 1px solid ${themeGet("colors.veryLightGrey")};
    color: ${themeGet("colors.primary")};

    &:hover {
      background-color: ${themeGet("colors.backgroundGrey")};
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: ${lightenColor(0.02, "colors.backgroundGrey")};
  text-align: center;
`;

const NoWeez = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  padding-top: 30px;
  color: ${themeGet("colors.strongGrey")};
`;
const NoWeezText = styled.span`
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
  flex: 1;
`;

@observer
class WeezFeed extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("beforeunload", () =>
      window.removeEventListener("scroll", this.handleScroll)
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = throttle(
    (evt) => {
      if (document.body.scrollHeight - window.scrollY - window.innerHeight < 50) {
        this.props.weezsFeed.feedLoad();
      }
    },
    300,
    { leading: true, trailing: true }
  );

  render() {
    const { className, weezsFeed, t } = this.props;

    const { weezs, deleteWeeze } = weezsFeed;

    return (
      <Container className={className}>
        {weezsFeed.hasNewWeezs && (
          <HasNewWeezContainer>
            <button onClick={weezsFeed.showNewElements}>
              Voir {weezsFeed.newElements.length} nouveau(x) weeze(s)
            </button>
          </HasNewWeezContainer>
        )}
        {weezs.length ? (
          weezs.map((weez) => <Weez key={weez.id} weez={weez} onDelete={deleteWeeze} />)
        ) : (
          <NoWeez>
            <MdInbox size={32} />
            <NoWeezText>{t("homepage.feed.empty")}</NoWeezText>
          </NoWeez>
        )}
        {(weezsFeed.error || weezsFeed.isLoading) && (
          <FooterContainer>
            {weezsFeed.error && <ErrorText>{t(weezsFeed.error.code)}</ErrorText>}
            {weezsFeed.isLoading && <Spinner size={30} />}
          </FooterContainer>
        )}
      </Container>
    );
  }
}

export default withTranslation()(WeezFeed);
