import { inject, observer } from "mobx-react";
import styledMap from "styled-map";
import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { isMobile } from "app/layout/responsiveness/mediaQueries";

const Container = styled.div`
  position: fixed;
  bottom: ${isMobile ? "60px" : "10px"};
  left: 10px;
  max-width: 90%;
  z-index: 1001;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex: 1 1 300px;
  align-items: center;
  /* height: 100px; */
  background-color: ${styledMap("type", {
    error: "#f8d7da",
    warning: "#f1c40f",
    default: "#d4edda",
  })};
  color: ${styledMap("type", {
    error: "#721c24",
    warning: "#d35400",
    default: "#155724",
  })};
  white-space: nowrap;
  min-width: 150px;
  border-radius: 4px;
  padding: 15px;
  box-shadow: ${themeGet("shadows.default")};
  span {
    font-weight: 600;
    font-size: 1.3rem;
  }
`;

const Notification = ({ text, type }) => {
  return (
    <NotificationContainer type={type}>
      <span>{text}</span>
    </NotificationContainer>
  );
};

@inject("viewStore")
@observer
class Notifications extends React.Component {
  render() {
    const { viewStore } = this.props;

    return (
      <Container>
        {viewStore.notifications.map((notification, i) => (
          <Notification key={i} {...notification} />
        ))}
      </Container>
    );
  }
}

export default Notifications;
