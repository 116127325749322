import { Field, fieldsAggregatorBuilder, isRequired, maxLength, forbiddenChars } from "utils/forms";
import { t } from "utils/i18n";

export default function builder() {
  const fields = {
    archiveName: new Field({
      validate: [
        isRequired,
        maxLength(100),
        forbiddenChars(
          ["\\", "@", "`", "[", "]", '"', "'", "{", "}", "^", ">", "<", "|", "~", "?", "%"],
          t("archive_name")
        ),
      ],
      label: t("archive_name"),
    }),
  };

  return fieldsAggregatorBuilder({
    fields,
  });
}
