import isEmailValidator from "validator/lib/isEmail"
import isHexColorValidator from "validator/lib/isHexColor"
import isValidDate from "date-fns/isValid"

export function defaultValidationContext(value) {
  return {
    value,
  }
}

export const next = () => ({ valid: true })
export const error = (message) => ({ valid: false, message })
export const stop = () => ({ stop: true, valid: true })

export const isRequired = (message) => (context) => {
  let isValid = !!context.value
  if (isValid && context.value.push) {
    // If is array then length must be > 0
    isValid = !!context.value.length
  }
  return isValid ? next() : error(message)
}

export const maxLength = (message, maxLength) => (context) => {
  const isValid = !context.value || context.value.length <= maxLength
  return isValid ? next() : error(message)
}

export const isEmail = (message) => (context) => {
  const isValid = !context.value || isEmailValidator(context.value)
  return isValid ? next() : error(message)
}

export const isHexColor = (message) => (context) => {
  const isValid = !context.value || isHexColorValidator(context.value)
  return isValid ? next() : error(message)
}

export function isDate(newValue, label) {
  const isValid = !newValue || isValidDate(newValue)
  return {
    valid: isValid,
    message: !isValid ? `${label} non valide` : "",
  }
}

export const isInt =
  (message, { max, min }) =>
  (context) => {
    let isValid = Number.isInteger(context.value)
    if (isValid && max) {
      isValid = context.value <= max
    }
    if (isValid && min) {
      isValid = context.value >= min
    }
    return isValid ? next() : error(message)
  }

// export function isAlphanumeric(newValue, label) {
//   const isValid = !newValue || !!newValue.match(/^[0-9A-Z-]+$/i);
//   return {
//     valid: isValid,
//     message: !isValid ? `${label || "Le champ"} doit être alphanumerique` : "",
//   };
// }

// export const forbiddenChar = (char, label, message) => newValue => {
//   let value = newValue;
//   let isValid = !value || !value.includes(char);
//   return {
//     valid: isValid,
//     message: !isValid
//       ? message || `${label || "Le champ"} ne doit pas contenir le caractère ${char}`
//       : "",
//   };
// };

// export const forbiddenChars = (chars = [], label, message) => value => {
//   let isValid =
//     !value ||
//     !chars.filter(char => {
//       return value.includes(char);
//     }).length > 0;
//   return {
//     valid: isValid,
//     message: !isValid
//       ? message || `${label || "Le champ"} ne doit pas contenir les caractères ${chars.join(" ")}`
//       : "",
//   };
// };
