import { getServerUrl } from "utils/fetch";

export function getUserProfile(userId) {
  return {
    url: getServerUrl(`/users/${userId}/profile`),
    method: "GET",
  };
}

export function updateUserProfile(userId, data) {
  const body = {
    email: data.email,
    lastname: data.lastname,
    firstname: data.firstname,
    photo: data.photo,
    certified: data.certified,
    bio: data.bio,
    password: data.password,
    location: data.location,
  };

  return {
    url: getServerUrl(`/users/${userId}/profile/update`),
    method: "POST",

    body: JSON.stringify(body),
  };
}

export function getUserWeezs(userId) {
  return ({ after, before }) => {
    const url = getServerUrl(`/users/${userId}/weezs`, { after, before });

    return {
      url,
      method: "GET",
    };
  };
}

export function followProfile(username, value) {
  return {
    url: getServerUrl(`/users/${username}/follow`),
    method: "POST",
    body: JSON.stringify(value),
  };
}

export function usersAutocomplete(username) {
  return {
    url: getServerUrl(`/users/autocomplete`, { username }),
    method: "GET",
  };
}

export function searchUsers(query, roles = "", includeSelf = false) {
  return {
    url: getServerUrl("/users/search", { terms: query, roles: roles.join(","), includeSelf }),
    method: "GET",
  };
}

/**
 *
 * @param {Object} query
 * @param {string} query.terms
 * @param {string[]} query.roles
 * @param {string} [query.client]
 * @param {number} [page=0]
 * @param {number} [size=20]
 */
export function searchUsersPaginated({ terms, roles, client }, page = 0, size = 5) {
  return {
    url: getServerUrl("/users/search"),
    method: "POST",
    body: JSON.stringify({ terms, roles, client, page, size }),
  };
}
