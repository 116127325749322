import React from "react";
import styled, { css } from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";

export const inputStyle = css`
  outline: 0;
  border: 1px solid ${themeGet("colors.grey")};
  border-color: ${styledMap({
    white: themeGet("colors.white"),
    error: themeGet("colors.error"),
    disabled: "transparent",
    default: themeGet("colors.grey"),
  })};
  color: ${styledMap({
    disabled: themeGet("colors.strongGrey"),
    default: themeGet("colors.black"),
  })};
  padding: 5px 7px;
  border-radius: 4px;
  background-color: ${styledMap({
    white: themeGet("colors.white"),
    default: themeGet("colors.white"),
  })};
  line-height: 1.3rem;
`;

const StyledInput = styled.input`
  ${inputStyle};
`;

const handleChange = onChangeFn => evt => {
  const value = evt.target.value;
  if (onChangeFn) {
    onChangeFn(evt, value);
  }
};

const handleBlur = onBlurFn => evt => {
  const value = evt.target.value.trim();
  if (onBlurFn) {
    onBlurFn(evt, value);
  }
};

const Input = ({ onChange, ...props }) => {
  return <StyledInput {...props} onChange={handleChange(onChange)} onBlur={handleBlur(onChange)} />;
};

export default React.memo(Input);
