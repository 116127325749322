import { getServerUrl } from "utils/fetch";
/**
 * @param {String} query
 * @param {Number} page
 */
export function searchUsers(query, page = 0) {
  return {
    url: getServerUrl("/search/users", { terms: query, page, size: 10 }),
    method: "GET",
  };
}
