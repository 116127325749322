import React, { Component, Fragment } from "react";
import styled from "styled-components";
import {
  MdAddCircleOutline,
  MdGetApp,
  MdRemoveRedEye,
  MdEdit,
  MdRemoveCircle,
} from "react-icons/md";
import { Button, LoadingContent, ErrorContent, FormSection } from "components";
import { resetList } from "utils/mixins";
import SequenceItem from "./SequenceItem";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import { getSequences, removeSequence, downloadSequence } from "services/sequence";
import { withTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AddButton = styled(Button)`
  align-self: center;
  margin-right: 30px;
`;

const ActionButtonContainer = styled.div`
  padding: 10px;
`;

const SequenceResults = styled.ul`
  ${resetList};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

@inject("fetchService")
@observer
class SequencePage extends Component {
  @observable
  getSequenceRequest = null;

  constructor(props) {
    super(props);
    this.searchSequence();
  }

  exportSequence = (id) => (evt) => {
    this.props.fetchService.fetch(downloadSequence(id));
  };

  @action("ADMIN_CLIENT_SEARCH")
  searchSequence = () => {
    const { fetchService } = this.props;
    this.getSequenceRequest = fromPromise(fetchService.fetch(getSequences()));
  };

  @action("DELETE_SEQUENCE")
  removeSequence = (seq) => {
    const { fetchService } = this.props;
    const deleteSequenceRequest = fromPromise(fetchService.fetch(removeSequence(seq.id)));
    deleteSequenceRequest.then(() => {
      this.searchSequence();
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Header>
          <h1>{t("admin_sequences")}</h1>
          <ActionButtonContainer>
            <AddButton admin full="true" to="/admin/sequences/add">
              <MdAddCircleOutline size={20} />
              {t("sequence.create")}
            </AddButton>
          </ActionButtonContainer>
        </Header>
        <FormSection title={t("sequence.title")} />
        <div>
          {this.getSequenceRequest &&
            this.getSequenceRequest.case({
              pending: () => <LoadingContent label={t("sequence.loading")} />,
              rejected: (error) => <ErrorContent text={t(error.code)} />,
              fulfilled: (value) => (
                <Fragment>
                  <SequenceResults>
                    {value &&
                      value.map((sequence) => (
                        <SequenceItem
                          {...sequence}
                          key={sequence.id}
                          menuOptions={[
                            {
                              label: t("sequence.menu.show"),
                              to: `/admin/sequences/${sequence.id}`,
                              icon: MdRemoveRedEye,
                            },
                            {
                              label: t("sequence.menu.edit"),
                              to: `/admin/sequences/${sequence.id}/edit`,
                              icon: MdEdit,
                            },
                            // {
                            //   label: t("sequence.duplicate"),
                            //   externalUrl: "",
                            //   icon: MdControlPointDuplicate,
                            // },
                            // {
                            //   label: t("sequence.play"),
                            //   icon: MdPlayArrow,
                            // },
                            {
                              label: t("sequence.menu.download"),
                              onClick: this.exportSequence(sequence.id),
                              icon: MdGetApp,
                            },
                            {
                              label: t("sequence.menu.delete"),
                              onClick: this.removeSequence,
                              user: sequence,
                              icon: MdRemoveCircle,
                            },
                          ]}
                        />
                      ))}
                  </SequenceResults>
                </Fragment>
              ),
            })}
        </div>
      </Container>
    );
  }
}

export default withTranslation()(SequencePage);
