import UsersStore from "./UsersStore";
import AuthStore from "./AuthStore";
import ViewStore from "./ViewStore";
import routingStoreBuilder from "./RoutingStore";
import NotificationsStore from "./NotificationsStore";
import FetchService from "./FetchService";
import WebSocketService from "./WebSocketService";
import BoostStore from "./BoostStore";
import PrivateMessageService from "./PrivateMessageService";
import ConversationsStore from "./ConversationsStore";

export default function createStores() {
  const usersStore = new UsersStore();
  const authStore = new AuthStore();
  const viewStore = new ViewStore();
  const routingStore = routingStoreBuilder();
  const notificationsStore = new NotificationsStore();
  const fetchService = new FetchService();
  const webSocketService = new WebSocketService();
  const boostStore = new BoostStore();
  const privateMessageService = PrivateMessageService.getInstance();
  const conversationsStore = new ConversationsStore();

  fetchService.inject({ authStore, viewStore });
  usersStore.inject({ fetchService });
  boostStore.inject({ fetchService });
  authStore.inject({ usersStore, fetchService, routingStore });
  webSocketService.inject({ authStore });
  notificationsStore.inject({ authStore, viewStore, fetchService });
  conversationsStore.inject({ fetchService, authStore });
  privateMessageService.inject({ fetchService, webSocketService, authStore, conversationsStore });

  return {
    authStore,
    viewStore,
    routingStore,
    usersStore,
    notificationsStore,
    fetchService,
    webSocketService,
    boostStore,
    privateMessageService,
    conversationsStore,
  };
}
