import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import Spinner from "./Spinner";

const FloatableWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  padding: 2rem 3rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.95);

  span {
    margin-top: 20px;
    font-weight: 600;
    color: ${themeGet("colors.strongGrey")};
    font-size: 1.2rem;
  }
`;

const content = (label, color = "primary", background = false) => {
  return (
    <Container>
      <Spinner size={50} color={color} />
      <span>{label}</span>
    </Container>
  );
};
export default function LoadingContent({ label, float = false, color }) {
  return float ? (
    <FloatableWrapper>{content(label, color, true)}</FloatableWrapper>
  ) : (
    content(label, color)
  );
}
