import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: ${({ size }) => size}px;
  height: auto;
  will-change: transform;

  & .path {
    stroke: ${({ color }) => themeGet("colors." + color)};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    transform: translateZ(0);
    will-change: stroke-dasharray, stroke-dashoffset;
  }

  @keyframes rotate {
    100% {
      transform: rotateZ(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

/**
 * 
 * @param {*} size: change the size of the spinner (default to 30) 
 * @param {*} color: change the color of the spinner, must come from the theme.colors (default to 'primary') 
 */
const Spinner = ({ size = 30, color = "primary" }) => (
  <StyledSpinner size={size} viewBox="0 0 50 50" color={color}>
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
  </StyledSpinner>
);

export default Spinner;
