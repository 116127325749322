import { themeGet } from "@styled-system/theme-get";
import DurationFormat from "components/DurationFormat";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import { Line } from "rc-progress";
import React from "react";
import { withTranslation } from "react-i18next";
import { MdPause, MdPlayArrow, MdStop } from "react-icons/md";
import { sequenceAction } from "services/sequence";
import styled, { withTheme } from "styled-components";
import styledMap from "styled-map";
import ConfirmationDialog from "../ConfirmationDialog";

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 65px;
`;

const InfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  min-height: 60px;
  position: relative;
  width: 100%;
  background-color: ${styledMap({
    isPlaying: "#e9f2f8",
    isPaused: "#e9f2f8",
    default: "inherit",
  })};
  :last-child {
    border-bottom: none;
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
`;
const Message = styled.div`
  /* font-style: italic; */
  margin-top: 5px;
  font-size: 12px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: 180px;
`;

const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  color: #42c7ef;
`;

const ItemButton = styled.div`
  float: left;
  cursor: ${(props) => (props.disable ? "initial" : "pointer")};
  color: ${(props) => props.$color || "inherit"};
`;

const WAITING_BETWEEN_ACTION = 1000;

const PLAY_STATUS = "RUNNING";
const PAUSE_STATUS = "PAUSED";
const STOP_STATUS = "STOPPED";

@observer
class PlannificateurItem extends React.Component {
  @observable isPlaying = false;
  @observable isPaused = false;
  @observable disable = false;
  @observable disableStop = true;
  @observable status = null;
  @observable displayConfirmation = false;

  constructor(props) {
    super(props);
    this.updateSequenceStatus(props.sequence);
  }

  @action.bound
  updateSequenceStatus(sequence) {
    const { status } = sequence;
    if (status !== this.status) {
      this.status = status;
      switch (status) {
        case PLAY_STATUS:
          this.isPlaying = true;
          this.isPaused = false;
          this.disableStop = false;
          break;
        case PAUSE_STATUS:
          this.isPaused = true;
          this.isPlaying = false;
          this.disableStop = false;
          break;
        case STOP_STATUS:
          this.isPaused = false;
          this.isPlaying = false;
          this.disableStop = true;
          this.elapsed = 0;
          break;
        default:
          // do nothing
          break;
      }
    }
  }

  play = (id) =>
    action("PLAY_SEQUENCE", (evt) => {
      if (!this.disable) {
        this.disable = true;
        this.disableStop = false;
        this.isPlaying = true;
        this.postStatus(PLAY_STATUS, id).then((data) => {
          this.props.onChangeStatus(data);
        });
        setTimeout(() => {
          this.enableActionButtons();
        }, WAITING_BETWEEN_ACTION);
      }
    });

  pause = (id) =>
    action("PAUSE_SEQUENCE", (evt) => {
      if (!this.disable) {
        this.disable = true;
        this.isPlaying = false;
        this.postStatus(PAUSE_STATUS, id).then((data) => {
          this.props.onChangeStatus(data);
        });
        setTimeout(() => {
          this.enableActionButtons();
        }, WAITING_BETWEEN_ACTION);
      }
    });

  stop = (id) =>
    !this.disableStop
      ? action("STOP_SEQUENCE", (evt) => {
          if (!this.disable) {
            this.disable = true;
            this.isPlaying = false;
            this.disableStop = true;
            this.elapsed = 0;
            this.postStatus(STOP_STATUS, id).then((data) => {
              this.props.onChangeStatus(data);
            });
            setTimeout(() => {
              this.enableActionButtons();
            }, WAITING_BETWEEN_ACTION);
          }
        })
      : undefined;

  @action
  askForConfirmation = () => {
    if (!this.disableStop) {
      this.displayConfirmation = true;
    }
  };

  @action
  closeConfirmationDialog = () => {
    this.displayConfirmation = false;
  };

  postStatus = (status, id) => {
    const { fetchService } = this.props;
    const data = { id, status };
    return fetchService.fetch(sequenceAction(data, id));
  };

  calculateProgression = (elapsed, duration) => {
    return (duration ? elapsed / duration : 0) * 100;
  };

  @action("ENABLE_BUTTON")
  enableActionButtons = () => {
    this.disable = false;
  };

  componentDidUpdate() {
    this.updateSequenceStatus(this.props.sequence);
  }

  render() {
    const { sequence: seq, t } = this.props;
    return (
      <ItemContainer>
        <InfoContainer isPlaying={this.isPlaying} isPaused={this.isPaused}>
          <MessageContainer>
            <Title>{seq.name}</Title>
            <Message>
              {`${t("scheduler.published_weezes")} 
              ${seq.publishedWeezesCount} / ${seq.weezesCount}`}
            </Message>
            <Message>
              {t("scheduler.duration")} <DurationFormat duration={seq.duration} strict={true} />
            </Message>
          </MessageContainer>
          <ContainerButton>
            {seq.status !== PLAY_STATUS ? (
              <ItemButton
                onClick={this.play(seq.id)}
                disable={this.disable}
                $color={themeGet("colors.green")}>
                <MdPlayArrow size={25} />
              </ItemButton>
            ) : (
              <ItemButton
                onClick={this.pause(seq.id)}
                disable={this.disable}
                $color={themeGet("colors.yellow")}>
                <MdPause size={25} />
              </ItemButton>
            )}
            <ItemButton
              onClick={this.askForConfirmation}
              disable={this.disableStop || this.disable}
              $color={this.disableStop ? themeGet("colors.grey") : themeGet("colors.red")}>
              <MdStop size={25} />
            </ItemButton>
          </ContainerButton>
        </InfoContainer>
        <Line
          percent={this.calculateProgression(seq.elapsed, seq.duration)}
          strokeWidth={2}
          strokeLinecap="square"
          strokeColor={themeGet("colors.primary")(this.props)}
        />
        {this.displayConfirmation && (
          <ConfirmationDialog
            title={t("scheduler.title")}
            text={t("scheduler.sequence_stop_confirm", { seq })}
            onConfirm={this.stop(seq.id)}
            onRequestClose={this.closeConfirmationDialog}
            isOpen={this.displayConfirmation}
          />
        )}
      </ItemContainer>
    );
  }
}

export default withTranslation()(withTheme(PlannificateurItem));
