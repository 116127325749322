import React from "react";
import TextareaAutosize from "react-textarea-autosize";

const handleChange = onChangeFn => evt => {
  const value = evt.target.value;
  if (onChangeFn) {
    onChangeFn(evt, value);
  }
};

const handleBlur = onBlurFn => evt => {
  const value = evt.target.value.trim();
  if (onBlurFn) {
    onBlurFn(evt, value);
  }
};

const Input = ({ onChange, onBlur = onChange, multiline, ...props }) => {
  if (multiline) {
    return (
      <TextareaAutosize
        {...props}
        onChange={handleChange(onChange)}
        onBlur={handleBlur(onBlur)}
        rows={1}
        maxRows={multiline}
        async={true}
      />
    );
  } else {
    return <input {...props} onChange={handleChange(onChange)} onBlur={handleBlur(onBlur)} />;
  }
};

export default React.memo(Input);
