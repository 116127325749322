import React from "react";
import NewConversationAsPage from "./NewConversationAsPage";
import NewConversationPage from "./NewConversationPage";
import { MobXProviderContext } from "mobx-react";
import MainHeaderMobile from "app/layout/headers/MainHeader.mobile";
import { PageContent } from "app/layout/MainLayout.mobile";
import { t } from "utils/i18n";
import { createConversation } from "services/messages";
import { MobileView, DesktopView } from "app/layout/responsiveness/mediaQueries";

function ComposePage() {
  const { authStore, routingStore, fetchService, conversationsStore: convStore } = React.useContext(
    MobXProviderContext
  );
  const [step, setStep] = React.useState(authStore.currentUser.isAnimator ? 0 : 1);
  const [composeAs, setComposeAs] = React.useState(authStore.currentUser);

  const goNext = () => {
    setStep(step + 1);
  };
  const goPrevious = () => {
    setStep(step - 1);
  };
  const onSelectComposeAs = user => {
    setComposeAs(user);
    goNext();
  };
  const onSelectSendTo = user => {
    fetchService
      .fetch(createConversation({ composeAs: composeAs.id, sendTo: user.id }))
      .then(value => {
        const conv = convStore.deserializeConversation(value);
        const editMode = conv.participants.filter(p => p.endpoint.id === authStore.currentUser.id)
          .length;
        routingStore.history.push(`/messages/conversations/${value.id}`, { readMode: !editMode });
      });
  };
  const setTitle = () => (step === 0 ? t("locations./messages/composeas") : undefined);

  const setReturnTo = () => {
    return step === 1 && authStore.currentUser.isAnimator
      ? goPrevious()
      : routingStore.history.goBack();
  };
  return (
    <React.Fragment>
      <MobileView>
        <MainHeaderMobile user={authStore.currentUser} title={setTitle()} returnTo={setReturnTo} />
        <PageContent>
          {step === 0 && <NewConversationAsPage onSelect={onSelectComposeAs} />}
          {step === 1 && <NewConversationPage onSelect={onSelectSendTo} />}
        </PageContent>
      </MobileView>
      <DesktopView>
        {step === 0 && <NewConversationAsPage onSelect={onSelectComposeAs} />}
        {step === 1 && <NewConversationPage onSelect={onSelectSendTo} />}
      </DesktopView>
    </React.Fragment>
  );
}

export default ComposePage;
