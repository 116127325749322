import React from "react";
import Input from "./Input";
import Datepicker from "./Datepicker";
import styled, { keyframes } from "styled-components";
import styledMap from "styled-map";
import format from "date-fns/format";
import subMinutes from "date-fns/subMinutes";
import OnClickOutside from "../utils/OnClickOutside";
import { observer } from "mobx-react";
import { t } from "utils/i18n";

const Container = styled(OnClickOutside)`
  position: relative;
`;

const openMenuAnimation = keyframes`
  0% {
    transform:scale(0);
  }

  100% {
    transform:scale(1);
  }
`;

const StyledDatepicker = styled(Datepicker)`
  display: ${styledMap({
    active: "block",
    default: "none",
  })};
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform-origin: 0% 100%;
  animation: ${openMenuAnimation} 200ms ease-in-out;
  font-size: 14px;
`;

@observer
class DatePickerInput extends React.Component {
  state = {
    active: false,
  };

  onBlur = () => {
    this.setState({
      active: false,
    });
  };

  onDateSelected = (val) => {
    const { field } = this.props;
    this.setState(
      {
        active: false,
      },
      () => {
        // fix timezone offset.
        const fixedDate = subMinutes(val.date, val.date.getTimezoneOffset());
        field.setValue(fixedDate);
      }
    );
  };

  onFocus = () => {
    this.setState({
      active: true,
    });
  };

  render() {
    const { field, value, minDate, date, ...rest } = this.props;
    const { active } = this.state;
    return (
      <Container action={active ? this.onBlur : null}>
        <Input value={format(field.value, t("format_date"))} onClick={this.onFocus} />
        <StyledDatepicker
          {...rest}
          active={active}
          selected={field.value}
          minDate={minDate}
          date={date}
          onDateSelected={this.onDateSelected}
        />
      </Container>
    );
  }
}

export default DatePickerInput;
