import React from "react";
import styled from "styled-components";

const Container = styled.span`
  display: inline-block;

  svg {
    width: 100%;
  }
`;

export default function SwitchUserIcon() {
  return (
    <Container>
      <svg viewBox="0 0 314.3 314.3">
        <path
          fill="currentColor"
          d="m102.29 77.384-10.608-10.607-25.242 25.242v-60.907h79.347v-15h-94.35v75.907l-25.241-25.242-10.607 10.607 43.348 43.346zm60.336 194.34v15h94.346v-75.906l25.241 25.242 10.607-10.607-43.348-43.348-43.348 43.348 10.606 10.607 25.242-25.242v60.906zm92.32-196.77c20.603 0 37.365-16.762 37.365-37.365s-16.762-37.367-37.365-37.367c-20.604 0-37.366 16.763-37.366 37.366s16.762 37.366 37.366 37.366zm0-59.732c12.332 0 22.365 10.033 22.365 22.366 0 12.332-10.033 22.365-22.365 22.365-12.333 0-22.366-10.033-22.366-22.365 0-12.333 10.033-22.366 22.366-22.366zm30.409 61.146h-3.22l-27.2 25.876-27.19-25.876h-3.214c-15.96 0-28.944 12.984-28.944 28.944v51.711h118.7v-51.711c0-15.96-12.985-28.944-28.945-28.944zm13.945 65.655h-88.708v-36.711c0-6.822 4.925-12.516 11.406-13.713l32.947 31.348 32.948-31.348c6.482 1.196 11.407 6.891 11.407 13.713zm-239.95 89.99c20.604 0 37.366-16.762 37.366-37.365s-16.762-37.365-37.366-37.365c-20.603 0-37.365 16.762-37.365 37.365s16.762 37.365 37.365 37.365zm0-59.73c12.333 0 22.366 10.033 22.366 22.365s-10.033 22.365-22.366 22.365c-12.332 0-22.365-10.033-22.365-22.365s10.033-22.365 22.365-22.365zm30.41 61.145h-3.214l-27.195 25.88-27.195-25.87h-3.214c-15.962 0-28.946 12.98-28.946 28.94v51.711h118.71v-51.711c0-15.96-12.984-28.945-28.944-28.945zm13.944 65.656h-88.71v-36.711c0-6.822 4.925-12.517 11.407-13.713l32.947 31.347 32.947-31.347c6.482 1.196 11.407 6.891 11.407 13.713v36.711z"
        />
      </svg>
    </Container>
  );
}
