import React from "react";
import PropTypes from "prop-types";

function EllipsisText({ text, length, tail = "...", tailClassName = "more", ...others }) {
  if (text.length <= length || length < 0) {
    return <span {...others}>{text}</span>;
  } else {
    const tailStyle = {
      cursor: "auto",
    };

    let displayText;
    if (length - tail.length <= 0) {
      displayText = "";
    } else {
      displayText = text.slice(0, length - tail.length);
    }

    return (
      <span title={text} {...others}>
        {displayText}
        <span style={tailStyle} className={tailClassName}>
          {tail}
        </span>
      </span>
    );
  }
}

export default EllipsisText;

EllipsisText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  tail: PropTypes.string,
  tailClassName: PropTypes.string,
};
