import React, { Component } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ErrorContent } from "components";
import { withTranslation } from "react-i18next";

const TableContainer = styled.table`
  font-size: 12px;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;

  td,
  th {
    border: 1px solid #ddd;
    padding: 5px;
    max-width: 150px;
    text-align: left;
    &.right {
      text-align: right;
    }
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding: 10px 5px;
    background-color: ${themeGet("colors.primary")};
    color: white;
  }
`;

const MediaLink = styled.a`
  text-decoration: none;
  color: ${themeGet("colors.primary")};
  margin-right: 5px;
  & :hover,
  :visited,
  :active,
  :link {
    text-decoration: none;
  }
`;

class SequenceTableDetail extends Component {
  formatInterval(interval) {
    if (interval) {
      if (interval > 1000) {
        return `+${interval / 1000} s`;
      } else if (interval > 0) {
        return `+${interval} ms`;
      } else {
        return interval;
      }
    }
  }

  formatMedias(medias) {
    return medias
      .filter((media) => media && media.length > 0)
      .map((media) => {
        try {
          return JSON.parse(media);
        } catch (err) {
          console.error("JSON parse failed with following string: " + media);
          console.error(err);
          return null;
        }
      })
      .filter((media) => media && media)
      .map((media, index) => (
        <MediaLink key={index} href={media.val} target="_blank" rel="noopener noreferrer">
          {media.type}
        </MediaLink>
      ));
  }

  render() {
    const { sequences, t } = this.props;
    return (
      <TableContainer>
        <thead>
          <tr>
            <th className="right">{t("sequence.details.countdown")}</th>
            <th>{t("sequence.details.login")}</th>
            <th>{t("sequence.details.weeze")}</th>
            <th>{t("sequence.details.external_link")}</th>
            <th className="right">{t("sequence.details.nb_like")}</th>
            <th className="right">{t("sequence.details.nb_reweez")}</th>
          </tr>
        </thead>
        {sequences && sequences.weezes ? (
          <tbody>
            {sequences.weezes.map((item, i) => {
              return (
                <tr key={i}>
                  <td className="right">{this.formatInterval(item.interval)}</td>
                  <td>{item.author.username}</td>
                  <td>{item.text}</td>
                  <td>{item.medias && this.formatMedias(item.medias)}</td>
                  <td className="right">{item.likesCount}</td>
                  <td className="right">{item.reweezsCount}</td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <ErrorContent text={t("sequence.add.details.no_weez")} />
        )}
      </TableContainer>
    );
  }
}

export default withTranslation()(SequenceTableDetail);
