import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { t } from "utils/i18n";
import { MdSearch, MdCancel } from "react-icons/md";
import { getInputFieldProps, Field } from "utils/forms";
import { useObserver } from "mobx-react";
import { useLocation } from "react-router";
import { useLocalStore } from "mobx-react-lite";
import qs from "qs";
import { runInAction } from "mobx";
import { Input } from "components";

const SearchBarContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${themeGet("colors.inputBackground")};
  border-radius: ${({ isRounded }) => (isRounded ? "9999px" : "0")};
  padding: 1px 5px;
  overflow: hidden;
  color: ${themeGet("colors.grey")};
  &:focus-within {
    border-color: ${themeGet("colors.primary")};
    background-color: transparent;
    color: ${themeGet("colors.primary")};
  }
`;

const SearchBarIconWrapper = styled.div`
  display: flex;
  flex: 0 0;
  align-items: center;
  justify-content: center;
  width: 30px;
  color: inherit;
`;
const SearchInputWrapper = styled.div`
  display: flex;
  flex: 1 1 50px;
  align-items: center;
`;

const StyledInput = styled(Input)`
  outline: 0;
  background-color: inherit;
  display: flex;
  flex: 1 1;
  width: 100%;
  border: none;
  line-height: 1.5rem;
  font-size: 1rem;
  &::-webkit-search-cancel-button {
    display: none;
  }
  &::-ms-clear {
    display: none;
  }
`;

/**
 *
 * @param {object} props
 * @param {boolean} props.rounded
 */
const SearchBar = ({
  className,
  placeholder = t("header.search.placeholder"),
  onCancel,
  onPressEnter,
  rounded = true,
  inputProps = {},
}) => {
  const store = useLocalStore(() => ({
    query: new Field(),
  }));
  const location = useLocation();

  React.useEffect(() => {
    const terms = qs.parse(location.search.substring(1)).terms || "";
    runInAction(() => {
      store.query.setValue(terms);
    });
  }, [location]);

  const handleCancel = () => {
    store.query.setValue("");
    onCancel && onCancel();
  };
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      const terms = store.query.value;
      onPressEnter && onPressEnter(terms);
    }
  };
  return useObserver(() => (
    <SearchBarContainer isRounded={rounded} className={className}>
      <SearchBarIconWrapper>
        <MdSearch size={25} />
      </SearchBarIconWrapper>
      <SearchInputWrapper>
        <StyledInput
          type="search"
          name="search"
          autocomplete="false"
          placeholder={placeholder}
          {...getInputFieldProps(store.query, { onKeyPress: handleKeyPress })}
          {...inputProps}
        />
      </SearchInputWrapper>

      {store.query && store.query.value.length > 0 && (
        <SearchBarIconWrapper onClick={handleCancel}>
          <MdCancel size={25} />
        </SearchBarIconWrapper>
      )}
    </SearchBarContainer>
  ));
};

export default React.memo(SearchBar);
