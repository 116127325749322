import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import "sanitize.css";
import "./index.css";
import App from "./App";
import createStores from "stores";
// import { AppContainer } from "react-hot-loader";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const stores = createStores();

if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
  window.globalStore = stores;
}

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App stores={stores} history={stores.routingStore.history} />,
  </I18nextProvider>,
  document.getElementById("root")
);
