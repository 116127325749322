import { MenuButton } from "components";
import React from "react";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ifProp, marginVertical } from "utils/mixins";
import ProfileImage from "./ProfileImage";
import DisplayName from "./DisplayName";

const UserItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  flex: 0 1;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  max-width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${themeGet("colors.lightGrey")};
  &:last-child {
    border: none;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-start;
  ${ifProp(
    "onClick",
    css`
      background-color: ${themeGet("colors.white")};
      cursor: pointer;

      &:hover {
        background-color: ${themeGet("colors.backgroundGrey")};
      }
    `
  )};
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
`;

const userInfoTextStyle = css`
  ${marginVertical("3px")};
  &:first-child {
    margin-top: 0px;
  }
`;

const StyledDisplayName = styled(DisplayName)`
  ${userInfoTextStyle};
`;

const EmailText = styled.span`
  ${userInfoTextStyle};
`;

const UsernameText = styled.span`
  ${userInfoTextStyle};
  color: ${themeGet("colors.grey")};
  font-style: italic;
`;

const ImageContainer = styled.div`
  margin-right: 20px;
  width: 50px;
`;

const UserItem = ({ user, menuOptions, onClick, renderRight, displayEmail = false }) => {
  return (
    <UserItemContainer>
      <LeftContainer onClick={onClick}>
        <ImageContainer>
          <ProfileImage photo={user.photo} />
        </ImageContainer>
        <UserInfoContainer>
          <StyledDisplayName user={user} showGlobalDummyIcon={true} />
          {displayEmail && <EmailText>{user.email}</EmailText>}
          <UsernameText>@{user.username}</UsernameText>
        </UserInfoContainer>
      </LeftContainer>
      {menuOptions && <MenuButton options={menuOptions} />}
      {renderRight && renderRight(user)}
    </UserItemContainer>
  );
};

export default React.memo(UserItem);
