import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { runInAction, action } from "mobx";
import { MobXProviderContext } from "mobx-react";

const Body = styled.div`
  font-family: 16px/26px "Roboto", "Open Sans", sans-serif;
  font-size: 16px;
`;

const Main = styled.div`
  padding: 30px 0;
  width: 90%;
  margin: 0 5%;
  *zoom: 1;
  @media only screen and (min-width: 1140px) {
    width: 1026px; /* 1140px - 10% for margins */
    margin: 0 auto;
  }
  &&::after,
  &&::before {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  footer {
    text-align: center;
    background-color: rgba(52, 152, 219, 0.2);
    border: 3px solid #3498db;
    width: 80%;
    max-width: 600px;
    margin: 50px auto;
    color: black;
    padding: 10px 20px;
  }
`;

const Section = styled.section`
  margin-bottom: 30px;
`;

const H2 = styled.h2`
  color: #3498db;
  font-size: 1.1em;
  margin: 0;
  display: block;
`;

const HeaderLogo = styled.div`
  width: 90%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2em;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.title`
  text-align: center;
  display: block;
  color: #3498db;
  font-size: 36px;
  line-height: 1.2em;
  padding: 10px 0;
`;

const Article = styled.article`
  float: left;
  width: 85%;
`;
const Disclaimer = () => {
  const { routingStore } = React.useContext(MobXProviderContext);
  React.useEffect(() => {
    runInAction(() => {
      routingStore.showReturnArrow = true;
    });
    return action(() => {
      routingStore.showReturnArrow = false;
    });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Disclaimer</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1.0" />
      </Helmet>
      <Body>
        <div className="main-container">
          <Main>
            <Article>
              <header>
                <HeaderLogo>
                  <img
                    src="https://weezer.fr/logo-full.png"
                    alt="Logo Weezer"
                    height="auto"
                    width="100%"
                  />
                </HeaderLogo>
                <Title>Informations importantes</Title>
                <Title>Règles d’utilisation du service</Title>
              </header>
              <Section>
                <p>
                  Le texte qui suit contient des informations importantes concernant le service
                  WEEZER, et des règles à respecter dans le cadre de son utilisation. Merci de le
                  lire attentivement avant de cliquer sur le bouton de confirmation qui suit.
                </p>
              </Section>
              <Section>
                <H2>1. Usage professionnel</H2>
                <p>
                  WEEZER est un service réservé à un usage strictement professionnel, dans le cadre
                  d’exercices de simulation de crise organisés par des entreprises et organisations
                  ayant souscrit une licence d’utilisation valide de la solution WEEZER.{" "}
                  <strong>
                    Vous n’avez pas le droit d’utiliser le service si vous n’y avez pas été
                    expressément invité(e) et autorisé(e) par une telle entreprise ou organisation.
                  </strong>
                </p>
              </Section>
              <Section>
                <H2>2. Caractère fictif</H2>
                <p>
                  Le service fonctionne selon les règles de la pression médiatique simulée (PMS), et
                  utilise des éléments réalistes (identités d’emprunt, marques existantes, etc.)
                  pour augmenter la sensation d’immersion et la qualité de l’exercice.{" "}
                  <strong>
                    Vous reconnaissez le caractère purement fictif de ces éléments et des scénarios
                    utilisés dans le cadre des exercices.
                  </strong>
                </p>
              </Section>
              <Section>
                <H2>3. Confidentialité</H2>
                <p>
                  Le service vous permet d’intégrer des contenus de toute nature (textes, images,
                  vidéos) en totale autonomie. Ces contenus sont diffusés aux autres utilisateurs
                  participant à la simulation, ainsi qu’aux personnes chargées de la superviser.{" "}
                  <strong>
                    Les contenus injurieux, diffamatoires, contrefaisants ou autrement illicites, de
                    même que les usurpations d’identité, sont strictement interdits, et vous
                    exposent à des poursuites et sanctions judiciaires.
                  </strong>
                </p>
              </Section>
              <Section>
                <H2>4. Contenus utilisateur</H2>
                <p>
                  . Les exercices réalisés via le service WEEZER, et plus généralement l’ensemble
                  des éléments constituant ce service, sont strictement confidentiels.{" "}
                  <strong>
                    Il vous est interdit de diffuser l’un quelconque de ces éléments, par exemple au
                    moyen d’une capture d’écran, en-dehors du cadre du service.
                  </strong>{" "}
                  Toute violation de cette règle vous expose à des poursuites et sanctions
                  judiciaires.
                </p>
              </Section>
              <Section>
                <H2>5. Sécurité</H2>
                <p>
                  <strong>
                    Vous êtes seul(e) responsable de la sécurité des identifiants de votre compte
                    utilisateur, qui vous permettent d’accéder au service WEEZER.
                  </strong>{" "}
                  Toute action effectuée sur le service à travers votre compte utilisateur sera donc
                  réputée avoir été effectuée par vous-même. En cas de perte ou de vol de ces
                  identifiants, vous êtes tenu(e) d’en informer immédiatement l’entreprise ou
                  l’organisation dont vous dépendez, ainsi que la société TECHLYDE, chargée de la
                  fourniture et de la maintenance du service (
                  <a href="mailto:contact@techlyde.com">contact@techlyde.com</a>).{" "}
                </p>
              </Section>
              <Section>
                <H2>6. Données personnelles</H2>
                <p>
                  L’utilisation du service implique la collecte d’un certain nombre de données vous
                  concernant. Vous trouverez toutes les informations utiles à ce sujet dans la{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://weezer.fr/weezer-privacy_fr.pdf">
                    note d’information dédiée.
                  </a>
                </p>
              </Section>
            </Article>
          </Main>
        </div>

        <FooterContainer>
          <footer>
            <p>J’ai bien pris note de ces informations, et j’accepte les règles du service.</p>
          </footer>
        </FooterContainer>
      </Body>
    </React.Fragment>
  );
};

export default Disclaimer;
