import { observable } from "mobx";
import { identifier, serializable, list, primitive } from "serializr";

export default class Client {
  @serializable(identifier())
  id;

  @observable
  @serializable
  clientPath;

  @observable
  @serializable
  name;

  @observable
  @serializable
  logo;

  @observable
  @serializable
  applicationName;

  @observable
  @serializable
  colorMain;

  @observable
  @serializable
  clone;

  @observable
  @serializable(list())
  authorizations = [];

  @serializable
  lang;
}
