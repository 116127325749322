import React, { Component } from "react";
import styled, { css } from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";
import { transition } from "utils/mixins";

const RadioContainer = styled.div`
  border-style: solid;
  border-width: 2px;
  border-color: ${styledMap({
    disabled: themeGet("colors.grey"),
    checked: themeGet("colors.primary"),
    default: themeGet("colors.veryStrongGrey"),
  })};

  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  vertical-align: top;
  width: 20px;

  &::before {
    background-color: ${styledMap({
      disabled: themeGet("colors.grey"),
      default: themeGet("colors.primary"),
    })};
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0;
    transform: ${styledMap({
      checked: "scale(0.65)",
      default: "scale(0)",
    })};

    transition: ${transition("transform")};
    width: 100%;
  }
`;

const Radio = ({ checked, onMouseDown, theme, ...other }) => (
  <RadioContainer checked={checked} onMouseDown={onMouseDown} {...other} />
);

const Input = styled.input`
  appearance: none;
  border: 0;
  height: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 0;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;

  ${styledMap({
    disabled: css``,
    default: "",
  })};
`;

// TODO disabled label
// .disabled {
//     composes: field;

//     & .text {
//       color: var(--radio-disabled-color);
//     }

//     & .radio {
//       border-color: var(--radio-disabled-color);
//       cursor: auto;
//     }

//     & .radioChecked {
//       border-color: var(--radio-disabled-color);
//       cursor: auto;

//       &::before {
//         background-color: var(--radio-disabled-color);
//       }
//     }
//   }

const LabelText = styled.span`
  color: ${styledMap({
    disabled: themeGet("colors.grey"),
    default: themeGet("colors.veryStrongGrey"),
  })};
  display: inline-block;
  font-size: 1rem;
  line-height: 20px;
  padding-left: 5px;
  vertical-align: top;
  white-space: nowrap;
`;

export default class RadioButton extends Component {
  static defaultProps = {
    checked: false,
    className: "",
    disabled: false,
  };

  handleClick = event => {
    const { checked, disabled, onChange } = this.props;
    if (event.pageX !== 0 && event.pageY !== 0) this.blur();
    if (!disabled && !checked && onChange) onChange(event, this);
  };

  blur() {
    if (this.inputNode) {
      this.inputNode.blur();
    }
  }

  focus() {
    if (this.inputNode) {
      this.inputNode.focus();
    }
  }

  setRef = node => {
    this.inputNode = node;
  };

  render() {
    const {
      checked,
      children,
      className,
      disabled,
      label,
      name,
      onChange, // eslint-disable-line
      onMouseEnter,
      onMouseLeave,
      ...others
    } = this.props;
    // const _className = classnames(theme[this.props.disabled ? "disabled" : "field"], className);
    return (
      <Label
        data-react-toolbox="radio-button"
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <Input
          {...others}
          checked={checked}
          disabled={disabled}
          name={name}
          onChange={() => {}}
          onClick={this.handleClick}
          ref={this.setRef}
          type="radio"
        />
        <Radio checked={checked} disabled={disabled} />
        {label ? <LabelText disabled={disabled}>{label}</LabelText> : null}
        {children}
      </Label>
    );
  }
}
