import React from "react";
import styled from "styled-components";
import { marginHorizontal } from "utils/mixins";
import { WeezFeed } from "app/common";
import { LoadingContent } from "components";
import { t } from "utils/i18n";
import { themeGet } from "@styled-system/theme-get";
import { useHistory } from "react-router-dom";
import { MdEdit } from "react-icons/md";

import ClickableIcon from "app/admin/users/ClickableIcon";
import { Helmet } from "react-helmet";
import CommunicationButton from "./CommunicationButton";

const Container = styled.div`
  display: flex;
  flex: 1;
  max-width: 100%;
  ${marginHorizontal("auto")};
`;

const WriteWeezeIcon = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  right: 15px;
  bottom: 70px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${themeGet("colors.primary")};
  border-radius: 50%;
  box-shadow: ${themeGet("shadows.elevation1")};
`;

const handleButtonClick = history => () => {
  history.push("/contacts");
};

const MobileHomePage = ({ currentUser, weezeFeed, toggleNewWeezeModal }) => {
  const history = useHistory();
  return (
    <Container>
      <Helmet>
        <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0" />
      </Helmet>
      {weezeFeed ? (
        <WeezFeed weezsFeed={weezeFeed} />
      ) : (
        <LoadingContent label={t("loading_feed")} />
      )}
      <CommunicationButton handleClick={handleButtonClick(history)} />
      <WriteWeezeIcon onClick={toggleNewWeezeModal}>
        <ClickableIcon icon={MdEdit} size={30} />
      </WriteWeezeIcon>
    </Container>
  );
};

export default MobileHomePage;
