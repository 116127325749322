import React from "react";
import styled, { css } from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";
import { imageUrl, isNullOrEmpty } from "utils";
import { FixedRatioImage } from "components";
import defaultAvatar from "assets/default_avatar.jpg";

const Container = styled.div`
  border-radius: 50%;
  border: ${styledMap({
    border: css`3px solid ${themeGet("colors.white")}`,
    default: "none",
  })};
  overflow: hidden;
  user-select: none;
  width: ${props => props.size ? `${props.size}px` : '100%'};
  
`;

const ProfileImage = ({ photo, size, border = false, className }) => {
  const src = !isNullOrEmpty(photo) ? imageUrl(photo) : defaultAvatar;
  return (
    <Container border={border} className={className} size={size}>
      <FixedRatioImage src={src} ratio={1} cover alt="user profile image" />
    </Container>
  );
};

export default ProfileImage;
