import { LoadingContent, Modal, ErrorContent } from "components";
import { action, observable } from "mobx";
import { observer, inject } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import modalHoc from "react-modal-hoc";
import styled from "styled-components";
import Weez from "./Weez";
import { weezFromData } from "./WeezModel";
import { t } from "utils/i18n";
import { getWeeze } from "services/weezs";

const Container = styled.div``;

@inject("fetchService")
@observer
class OriginalBoostWeezeModal extends React.Component {
  @observable getWeezeRequest;

  componentDidMount() {
    this.loadWeeze();
  }

  @action("LOAD_WEEZE")
  loadWeeze = () => {
    const { weezeId, fetchService } = this.props;
    this.getWeezeRequest = fromPromise(fetchService.fetch(getWeeze(weezeId)));
  };

  render() {
    const { fetchService, onRequestClose } = this.props;
    if (!this.getWeezeRequest) {
      return null;
    }
    return (
      <Modal
        allowScroll={false}
        isOpen
        title={t("boost.sequences.originalWeezeModal.title")}
        onRequestClose={onRequestClose}>
        <Container>
          {this.getWeezeRequest.case({
            pending: () => (
              <LoadingContent label={t("boost.sequences.originalWeezeModal.loading")} />
            ),
            rejected: error => <ErrorContent text={t(error.code)} />,
            fulfilled: data => {
              const weeze = weezFromData(data, { fetchService });
              return <Weez showButtons={true} key={weeze.id} weez={weeze} />;
            },
          })}
        </Container>
      </Modal>
    );
  }
}

export default modalHoc(OriginalBoostWeezeModal);
