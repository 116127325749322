import { themeGet } from "@styled-system/theme-get";
import { MenuButton } from "components";
import DateFormat from "components/DateFormat";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { MdDateRange } from "react-icons/md";
import styled from "styled-components";
import { paddingVertical } from "utils/mixins";

const ItemContainer = styled.li`
  display: flex;
  flex-direction: row;
  ${paddingVertical("20px")};
  border-bottom: 1px solid ${themeGet("colors.lightGrey")};
  &:last-child {
    border: none;
  }
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
`;
const ActionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
const Title = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;
const Description = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const StyledDiv = styled.div`
  display: flex;
  align-items: flex-end;
  color: #000;
`;
const Label = styled.span`
  color: #7f8c8d;
  font-weight: 600;
  margin-right: 10px;
`;

const ArchiveItem = memo(({ name, dateStart, dateEnd, menuOptions }) => {
  const { t } = useTranslation();
  return (
    <ItemContainer>
      <DescriptionContainer>
        <Title>{name}</Title>
        <Description>
          <StyledDiv>
            <MdDateRange size={25} color="grey" />
            <Label>{t("start")}</Label>
            <DateFormat>{dateStart}</DateFormat>
          </StyledDiv>
          <StyledDiv>
            <MdDateRange size={25} color="grey" /> <Label>{t("end")}</Label>
            <DateFormat>{dateEnd}</DateFormat>
          </StyledDiv>
        </Description>
      </DescriptionContainer>
      <ActionContainer>{menuOptions && <MenuButton options={menuOptions} />}</ActionContainer>
    </ItemContainer>
  );
});

export default ArchiveItem;
