import {
  Field,
  fieldsAggregatorBuilder,
  isDate,
  isHexColor,
  isRequired,
  isAlphanumeric,
  maxLength,
} from "utils/forms"
import { t } from "utils/i18n"
import { FORM_MODE_CREATE } from "utils/constants"
import parseISO from "date-fns/parseISO"

export default function builder({ authStore, mode = FORM_MODE_CREATE, client = null }) {
  const fields = {
    clientName: new Field({ validate: [isRequired, maxLength(50)], label: t("field") }),
    applicationName: new Field({ validate: [isRequired, maxLength(50)], label: t("field") }),
    clientPath: new Field({
      validate: [isRequired, isAlphanumeric, maxLength(50)],
      label: t("field"),
    }),
    logo: new Field(),
    colorMain: new Field({ validate: [isHexColor], label: t("the_color") }),
    endDate: new Field({ initValue: new Date(), validate: [isRequired, isDate] }),
  }

  return fieldsAggregatorBuilder({
    fields,
    data: client,
    dataMapper: (data) => ({
      ...data,
      endDate: parseISO(data.endDate),
      clientName: data.name,
    }),
  })
}
