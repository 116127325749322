import { observable, action } from "mobx";

export default class ProgressMonitor {
  @observable value = 0;

  @action("PROGRESS_MONITOR_SET")
  setProgress = val => {
    this.value = val;
  };
}
