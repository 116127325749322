import { action, observable, runInAction } from "mobx";

export default class ViewStore {
  @observable notifications = observable.array([], { deep: false });

  @observable newWeezModalOpened = false;

  @action("VIEW_TOGGLE_NEW_WEEZ_MODAL")
  toggleNewWeezModal = () => {
    this.newWeezModalOpened = !this.newWeezModalOpened;
  };

  @action("VIEW_CLOSE_NEW_WEEZ_MODAL")
  closeNewWeezModal = () => {
    this.newWeezModalOpened = false;
  };

  @action("PUSH_APP_NOTIF")
  pushNotification(text, type = "success", duration = 2500) {
    const notification = { text, type };
    this.notifications.push(notification);
    setTimeout(() => {
      runInAction(() => {
        this.notifications.remove(notification);
      });
    }, duration);
  }
}
