import React, { Component } from "react";
import Checkbox from "./Checkbox";
import isComponentOfType from "utils/is-component-of-type";

export default class CheckboxGroup extends Component {
  static defaultProps = {
    className: "",
    disabled: false,
  };

  handleChange = (checkboxValue, event) => {
    const { value, onChange } = this.props;
    const isChecked = event.target.checked;

    const newValue = value.slice();
    if (isChecked && !newValue.includes(checkboxValue)) {
      newValue.push(checkboxValue);
    } else if (!isChecked && newValue.includes(checkboxValue)) {
      newValue.splice(newValue.indexOf(checkboxValue), 1);
    }

    if (onChange) {
      onChange(event, newValue);
    }
  };

  renderCheckboxes() {
    return React.Children.map(this.props.children, child =>
      !isComponentOfType(Checkbox, child)
        ? child
        : React.cloneElement(child, {
            checked: this.props.value.includes(child.props.value),
            disabled: this.props.disabled || child.props.disabled,
            onChange: this.handleChange.bind(this, child.props.value),
          })
    );
  }

  render() {
    return <div className={this.props.className}>{this.renderCheckboxes()}</div>;
  }
}
