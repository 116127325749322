import {
  Field,
  isRequired,
  maxLength,
  fieldsAggregatorBuilder,
  FieldWithProgress,
} from "utils/forms";
import { FORM_MODE_CONSULT, FORM_MODE_CREATE } from "utils/constants";

export default function builder({ sequence = null, mode = FORM_MODE_CREATE, t }) {
  const fields = {};

  fields.name = new Field({
    validate: [maxLength(100), isRequired],
    label: t("sequence.add.form_creation.title"),
    disabled: mode === FORM_MODE_CONSULT,
  });
  fields.goals = new Field({
    validate: [maxLength(255)],
    label: t("sequence.add.form_creation.goals"),
    disabled: mode === FORM_MODE_CONSULT,
  });
  fields.description = new Field({
    validate: [maxLength(1000)],
    label: t("sequence.add.form_creation.description"),
    disabled: mode === FORM_MODE_CONSULT,
  });
  fields.importCsv = new FieldWithProgress();

  return fieldsAggregatorBuilder({
    fields,
    data: sequence,
  });
}
