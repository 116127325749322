import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddClientPage from "./AddClientPage";
import ClientsPage from "./ClientsPage";
import EditClientPage from "./EditClientPage";

export default class ClientsLayout extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/clients" exact component={ClientsPage} />
        <Route path="/admin/clients/add" component={AddClientPage} />
        <Route path="/admin/clients/:clientId/edit" component={EditClientPage} />
        <Redirect to="/admin/clients" />
      </Switch>
    );
  }
}
