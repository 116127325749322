import { addMilliseconds, formatDistanceStrict, formatDistanceToNow } from "date-fns";
import { enUS, fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const locales = { fr: fr, en: enUS };

export default function DurationFormat({ duration, strict = false, options }) {
  const now = new Date();
  const date = addMilliseconds(now, duration);
  const { i18n } = useTranslation();

  return strict
    ? formatDistanceStrict(now, date, {
        ...options,
        locale: locales[i18n.language],
      })
    : formatDistanceToNow(date, { ...options, locale: locales[i18n.language] });
}
