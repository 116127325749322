import React, { memo, useRef, useState } from "react"
import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const Label = styled.label`
  cursor: pointer;
  &:hover {
    color: ${themeGet("colors.primary")};
  }
`

const FileInput = styled.input.attrs({
  type: "file",
})`
  display: none;
`

const UploadButton = ({
  children,
  className,
  value,
  render,
  uploadRequest,
  onChange,
  onProgress,
  ...others
}) => {
  const [loading, setLoading] = useState(false)
  const inputFieldRef = useRef(null)

  const uploadFile = (evt) => {
    let files = evt.target.files
    if (files && files.length) {
      const file = files[0]
      const promiseWithProgress = uploadRequest(file)
      if (!promiseWithProgress) {
        return
      }
      onProgress(promiseWithProgress.progressMonitor)
      setLoading(true)
      promiseWithProgress.then((data) => {
        onChange(evt, data)
        onProgress()
        setLoading(false)
      })
      return promiseWithProgress
    }
  }
  return (
    <Label style={{ display: "flex" }} className={className}>
      <FileInput ref={inputFieldRef} {...others} onChange={uploadFile} disabled={loading} />
      {(render &&
        render({
          onClick: (evt) => {
            inputFieldRef.current.click()
          },
        })) ||
        children}
    </Label>
  )
}

export default memo(UploadButton)
