import { lighten } from "polished";
import { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import theme from "./theme";

export const transition = (
  property,
  fonction = theme.transitions.default.function,
  duration = theme.transitions.default.duration
) => {
  return css`
    transition-property: ${property};
    transition-timing-function: ${fonction};
    transition-duration: ${duration};
  `;
};

export const resetList = () => css`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const resetButton = () => css`
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  text-align: left;
  background: transparent;
  cursor: pointer;
`;

export const resetLink = () => css`
  text-decoration: none;
  &:visited {
    color: inherit;
  }
`;

export const paddingHorizontal = padding => css`
  padding-left: ${padding};
  padding-right: ${padding};
`;

export const paddingVertical = padding => css`
  padding-top: ${padding};
  padding-bottom: ${padding};
`;

export const marginHorizontal = margin => css`
  margin-left: ${margin};
  margin-right: ${margin};
`;

export const marginVertical = margin => css`
  margin-top: ${margin};
  margin-bottom: ${margin};
`;

export function ifProp(propName, value, elseValue = null) {
  return props => (props[propName] && value) || elseValue;
}

export function ifNoProp(propName, value, elseValue = null) {
  return props => (!props[propName] && value) || elseValue;
}

export const lightenColor = (amount, colorKey) => props =>
  lighten(amount, themeGet(colorKey)(props));
