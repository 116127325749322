import { getServerUrl } from "utils/fetch";

export function getFeed({ after, before }) {
  const url = getServerUrl("/feed", { after, before });

  return {
    url,
    method: "GET",
  };
}
