import { EditorState, Modifier, SelectionState } from "draft-js";
import findWithRegex from "find-with-regex";

import { re as unicodeRegex } from "../utils/emojiRegex";

/*
 * Attaches Immutable DraftJS Entities to the Emoji text.
 *
 * This is necessary as emojis consist of 2 characters (unicode). By making them
 * immutable the whole Emoji is removed when hitting backspace.
 */
export default function attachImmutableEntitiesToEmojis(editorState) {
  const currentContentState = editorState.getCurrentContent();
  const blocks = currentContentState.getBlockMap();
  let newContentState = currentContentState;

  blocks.forEach(block => {
    const plainText = block.getText();

    const addEntityToEmoji = (start, end) => {
      const existingEntityKey = block.getEntityAt(start);
      if (existingEntityKey) {
        // avoid manipulation in case the emoji already has an entity
        const entity = newContentState.getEntity(existingEntityKey);
        if (entity && entity.get("type") === "emoji") {
          return;
        }
      }

      const selectionState = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: start,
        focusKey: block.getKey(),
        focusOffset: end + 1,
      });
      const emojiText = plainText.substring(start, end);

      const contentStateWithEntity = newContentState.createEntity("emoji", "IMMUTABLE", {
        emojiUnicode: emojiText,
      });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      newContentState = Modifier.replaceText(
        newContentState,
        selectionState,
        emojiText,
        null,
        entityKey
      );
    };
    findWithRegex(unicodeRegex, block, addEntityToEmoji);
  });

  if (!newContentState.equals(currentContentState)) {
    return EditorState.push(editorState, newContentState, "convert-to-immutable-emojis");
  }

  return editorState;
}
