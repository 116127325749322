import React from "react";
import { Route, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LoadingContent from "./LoadingContent";

@inject("authStore")
@observer
class AuthorizedRoute extends React.Component {
  render() {
    const {
      component: Component,
      render,
      redirectTo = "/auth/login",
      roles = null,
      authStore,
      ...rest
    } = this.props;
    const { isReady, isConnected } = authStore;
    let isAuthorized = isConnected;
    if (isConnected) {
      const userRole = authStore.currentUser.role;
      if (roles && !!userRole) {
        isAuthorized = roles.includes(userRole);
      }
    }

    return (
      <Route
        {...rest}
        render={(props) => {
          if (!isReady) return <LoadingContent label="Authenticating" />;
          return isAuthorized ? (
            this.props.render ? (
              this.props.render(props)
            ) : (
              <Component {...props} />
            )
          ) : (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: props.location },
              }}
            />
          );
        }}
      />
    );
  }
}

export default AuthorizedRoute;
