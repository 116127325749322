import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { themeGet } from "@styled-system/theme-get";
import ProfileImage from "app/common/ProfileImage";
import MobileDrawer from "../menus/MobileDrawer";
import { t } from "utils/i18n";
import { MobXProviderContext } from "mobx-react";
import { useObserver } from "mobx-react-lite";
import { MdArrowBack } from "react-icons/md";

const Container = styled.div`
  display: flex;
  position: fixed;
  height: 65px;
  width: 100%;
  padding: 0 5px;
  align-items: center;
  background-color: ${themeGet("colors.white")};
  z-index: ${themeGet("zIndex.higher")};
  box-shadow: ${themeGet("shadows.default")};
`;

const Title = styled.div`
  display: flex;
  flex: 1;
  font-size: 19px;
  font-weight: 600;
  padding-left: 10px;
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
`;
const ArrowBackContainer = ProfileContainer;

function MainHeaderMobile({ user, photo = user.photo, title, returnTo }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const history = useHistory();
  const { routingStore } = React.useContext(MobXProviderContext);
  const handleOpenMenu = (isOpen = true) => () => {
    setMenuOpen(isOpen);
  };

  return useObserver(() => (
    <React.Fragment>
      <Container>
        {!routingStore.showReturnArrow && !returnTo && (
          <ProfileContainer onClick={handleOpenMenu()}>
            <ProfileImage photo={photo} border />
          </ProfileContainer>
        )}
        {(routingStore.showReturnArrow || returnTo != null) && (
          <ArrowBackContainer onClick={returnTo || history.goBack}>
            <MdArrowBack size={25} />
          </ArrowBackContainer>
        )}
        <Title>{title || t(`locations.${history.location.pathname}`)}</Title>
      </Container>

      <MobileDrawer isOpen={menuOpen} onCloseMenu={handleOpenMenu(false)} user={user} />
    </React.Fragment>
  ));
}

export default MainHeaderMobile;
