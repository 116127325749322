import { action, observable } from "mobx";
import { getSequences } from "services/sequence";

export default class PlannificateurModel {
  @observable sequences = observable.array([], { deep: true });

  constructor(fetchService, webSocketService) {
    this.webSocketService = webSocketService;
    this.fetchService = fetchService;
    if (this.webSocketService) {
      webSocketService.subscribe("/topic/sequences", async (message) => {
        const { clientId, ...sequenceItem } = await this.webSocketService.processMessage(message);
        if (webSocketService.checkClientMatch(clientId)) {
          this.updateSequenceData(sequenceItem);
        }
        // this.webSocketService.connect();
      });
    }
  }

  @action("SEQUENCES_UPDATE")
  updateSequenceData = (data) => {
    const { id, status, publishedWeezesCount, weezesCount, elapsed, duration } = data;
    const seq = this.sequences.find((item) => item.id === id);
    if (seq) {
      seq.status = status;
      seq.publishedWeezesCount = publishedWeezesCount;
      seq.weezesCount = weezesCount;
      seq.elapsed = elapsed;
      seq.duration = duration;
    }
  };

  @action("INIT_SEQUENCES")
  initSequences() {
    this.fetchService.fetch(getSequences()).then((data) => {
      this.setData(data);
    });
  }

  @action("SET_SEQUENCES")
  setData(data) {
    this.sequences = data;
  }
}
