import { WeezFeedModel } from "app/common";
import { action, computed, observable } from "mobx";
import * as usersApi from "services/users";
export default class ProfileModel {
  @observable user;

  @observable isLoading = true;
  @observable error;

  @computed
  get weezsFeed() {
    return new WeezFeedModel(this.feedFetcher, { fetchService: this.fetchService });
  }

  constructor(username, userId, { usersStore, fetchService }) {
    this.username = username;
    this.userId = userId;
    this.usersStore = usersStore;
    this.fetchService = fetchService;
    this.loadUserProfile();
    this.feedFetcher = usersApi.getUserWeezs(userId);
  }

  @action("LOAD_USER_PROFILE")
  loadUserProfile = () => {
    this.isLoading = true;
    return this.fetchService
      .fetch(usersApi.getUserProfile(this.userId))
      .then(
        action((profile) => {
          this.user = this.usersStore.deserializeUser(profile);
          this.isLoading = false;
        })
      )
      .catch(
        action((e) => {
          this.error = e;
          this.isLoading = false;
        })
      );
  };
}
