import { themeGet } from "@styled-system/theme-get";
import Input from "components/searchbar/Input";
import { runInAction } from "mobx";
import { useLocalStore, useObserver } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdSend } from "react-icons/md";
import styled from "styled-components";
import { Field, getInputFieldProps } from "utils/forms";

const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  padding: 10px;
  border-top: 1px solid ${themeGet("colors.lightGrey")};
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  background-color: ${themeGet("colors.inputBackground")};
  border-radius: 15px;
  padding: 5px 5px;
  overflow: hidden;
  color: ${themeGet("colors.grey")};
  &:focus-within {
    border-color: ${themeGet("colors.primary")};
    background-color: transparent;
    color: ${themeGet("colors.primary")};
  }
  margin: 0 10px;
`;

const StyledInput = styled(Input)`
  outline: 0;
  background-color: inherit;
  display: flex;
  flex: 1 1;
  width: 100%;
  border: none;
  line-height: 1.5rem;
  font-size: 1rem;
  &::-webkit-search-cancel-button {
    display: none;
  }
  &::-ms-clear {
    display: none;
  }
  resize: none;
  /* height: fit-content; */
  overflow-wrap: break-word;
  flex-direction: column;
  cursor: text;
  white-space: pre-wrap;
`;
const SubmitButtonWrapper = styled.button`
  outline: 0;
  border: none;
  color: ${themeGet("colors.primary")};
  background-color: transparent;
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const MessageInput = ({ onSubmit }) => {
  const store = useLocalStore(() => ({
    message: new Field(),
  }));
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    runInAction(() => {
      onSubmit && onSubmit(store.message.value)();
      store.message = new Field();
    });
  };

  return useObserver(() => (
    <MessageInputContainer>
      <InputWrapper>
        <StyledInput
          placeholder={t("messages.compose.send")}
          multiline={3}
          {...getInputFieldProps(store.message)}
        />
      </InputWrapper>
      <SubmitButtonWrapper onClick={handleSubmit} disabled={!store.message.value}>
        <MdSend size={25} />
      </SubmitButtonWrapper>
    </MessageInputContainer>
  ));
};

export default MessageInput;
