import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { Helmet } from "react-helmet";
import * as usersApi from "services/admin";
import styled from "styled-components";
import { cancelEvent } from "utils";
import UserForm from "./UserForm";
import userFormDataBuilder from "./userFormDataBuilder";
import { t } from "utils/i18n";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

@inject("authStore", "routingStore", "viewStore", "fetchService")
@observer
class AddUserPage extends React.Component {
  @observable
  createUserRequest = null;

  form = userFormDataBuilder({
    authStore: this.props.authStore,
    fetchService: this.props.fetchService,
  });

  @action
  onSubmit = cancelEvent(evt => {
    if (!this.form.isValid) {
      this.form.setTouched();
    } else {
      const { routingStore, viewStore, fetchService } = this.props;

      const data = this.form.values;

      this.createUserRequest = fromPromise(fetchService.fetch(usersApi.createUser(data)));
      this.createUserRequest
        .then(() => {
          routingStore.history.push("/admin/users");
          viewStore.pushNotification("Utilisateur créé avec succès.");
        })
        .catch(e => {
          // FIXME NOTNEEDED
          console.error(e);
        });
    }
  });

  render() {
    const { authStore, routingStore } = this.props;
    const { currentUser } = authStore;

    return (
      <Container>
        <Helmet>
          <title>{t("admin_user_title")}</title>
        </Helmet>
        <h1 onClick={routingStore.history.goBack} style={{ cursor: "pointer" }}>
          {t("admin_user")} &gt; {t("create")}
        </h1>
        <UserForm
          form={this.form}
          currentUser={currentUser}
          requestObject={this.createUserRequest}
          onSubmit={this.onSubmit}
          onCancel={routingStore.history.goBack}
        />
      </Container>
    );
  }
}

export default AddUserPage;
