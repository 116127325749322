import React from "react";
import styled, { css } from "styled-components";
import styledMap from "styled-map";
import { themeGet } from "@styled-system/theme-get";

export const inputStyle = css`
  outline: 0;
  color: ${styledMap({
    disabled: themeGet("colors.strongGrey"),
    default: themeGet("colors.black"),
  })};
  padding: 0 2px;
  border: none;
  margin: 0;
  width: 80%;
`;

const StyledInput = styled.input`
  ${inputStyle}
`;

const InputContainer = styled.div`
  border: 1px solid ${themeGet("colors.grey")};
  border-color: ${styledMap({
    white: themeGet("colors.white"),
    error: themeGet("colors.error"),
    disabled: "transparent",
    default: themeGet("colors.grey"),
  })};
  border-radius: 4px;
  background-color: ${styledMap({
    white: themeGet("colors.white"),
    default: themeGet("colors.white"),
  })};
  line-height: 1.3rem;
  padding: 5px;
  color: ${themeGet("colors.grey")};
  display: flex;
  width: ${props => (props.width ? props.width : "auto")};
`;

// const InputOrned = (onChange, ornament, ...props) => {
//   const handleChange = evt => {
//     const value = evt.target.value;
//     if (onChange) {
//       onChange(evt, value);
//     }
//   };

//   const handleBlur = evt => {
//     const value = evt.target.value.trim();
//     if (onChange) {
//       onChange(evt, value);
//     }
//   };
//   return (
//     <InputContainer {...props}>
//       {ornament}
//       <StyledInput {...props} onChange={this.handleChange} onBlur={this.handleBlur} />
//     </InputContainer>
//   );
// };

// export default React.memo(InputOrned);

export default class InputOrned extends React.PureComponent {
  handleChange = evt => {
    const { onChange } = this.props;
    const value = evt.target.value;
    if (onChange) {
      onChange(evt, value);
    }
  };

  handleBlur = evt => {
    const { onChange } = this.props;
    const value = evt.target.value.trim();
    if (onChange) {
      onChange(evt, value);
    }
  };

  render() {
    const { onChange, ornamentStart, ornamentEnd, ...props } = this.props;
    const { className, white, error, disabled, width, ...oProps } = props;
    return (
      <InputContainer
        className={className}
        white={white}
        error={error}
        disabled={disabled}
        width={width}>
        {ornamentStart && ornamentStart}
        <StyledInput
          white={white}
          error={error}
          disabled={disabled}
          {...oProps}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        {ornamentEnd && ornamentEnd}
      </InputContainer>
    );
  }
}
