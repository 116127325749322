import { themeGet } from "@styled-system/theme-get";
import { Button, Input, LoadingContent } from "components";
import DateFormat from "components/DateFormat";
import { action, observable } from "mobx";
import { inject } from "mobx-react";
import { Observer } from "mobx-react-lite";
import React from "react";
import { withTranslation } from "react-i18next";
import { MdArchive, MdGetApp } from "react-icons/md";
import * as adminApi from "services/admin";
import styled from "styled-components";
import { cancelEvent, cleanUrlPath } from "utils";
import config from "utils/config";
import { getInputFieldProps } from "utils/forms";
import { marginVertical, resetList } from "utils/mixins";
import archiveFormDataBuilder from "./archiveFormDataBuilder";
import ArchiveItem from "./ArchiveItem";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ArchiveForm = styled.form`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const ArchiveInput = styled(Input)`
  flex: 1;
  margin-right: 10px;
  max-width: 500px;
`;

const ErrorText = styled.span`
  color: ${themeGet("colors.error")};
  font-weight: 600;
`;
const ErrorTextContainer = styled.div`
  padding-top: 5px;
`;

const ArchiveResults = styled.ul`
  ${resetList};
`;

const ArchiveResultsTitle = styled.span`
  ${marginVertical("20px")};
  font-weight: 600;
  font-size: 1.1rem;
  color: ${themeGet("colors.grey")};
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
`;

@inject("authStore", "viewStore", "fetchService", "notificationsStore", "conversationsStore")
class ArchivesPage extends React.Component {
  @observable form = archiveFormDataBuilder();
  @observable archivedExercices = [];
  @observable currentExercice;
  @observable archiveButtonDisabled = false;
  @observable totalArchivedExercices = 0;
  @observable loading = false;
  totalPages = 0;
  currentPage = 0;

  /**
   * Retrieve archived exercices and refresh displayed data
   */
  loadArchivedExercices = (requestPage = this.currentPage) => {
    const { fetchService } = this.props;
    const pageSize = 5;
    return fetchService
      .fetch(adminApi.getArchivedExercices(requestPage, pageSize))
      .then(
        action((values) => {
          const { data: archivedExercices, page, totalElements, totalPages } = values;
          this.totalArchivedExercices = totalElements;
          this.currentPage = page;
          this.totalPages = totalPages;
          this.archivedExercices.push(
            ...archivedExercices.filter((exercice) => exercice.dateEnd != null)
          );
        })
      )
      .finally(
        action(() => {
          this.loading = false;
        })
      );
  };

  /**
   * Retrieve currently running exercice and refresh displayed data
   */
  refreshCurrentExercice = () => {
    const { fetchService } = this.props;
    fetchService.fetch(adminApi.getCurrentExercice()).then(
      action((value) => {
        this.currentExercice = value;
      })
    );
  };

  @action
  loadMoreArchivedExercices = () => {
    this.currentPage += 1;
    this.loading = true;
    this.loadArchivedExercices();
  };

  /**
   * Initial loading of exercices data
   */
  @action
  loadExercices = () => {
    this.loading = true;
    this.refreshCurrentExercice();
    this.loadArchivedExercices();
  };

  @action
  handleSubmit = cancelEvent((evt) => {
    if (!this.form.isValid) {
      this.form.setTouched();
    } else {
      this.archiveButtonDisabled = true;
      const { authStore, viewStore, fetchService, notificationsStore, conversationsStore } =
        this.props;
      fetchService
        .fetch(adminApi.terminateCurrentExercice(this.form.archiveName.value))
        .then(
          action((exercice) => {
            this.initializeForm(); // Reset form value
            this.archivedExercices.unshift(exercice);
            this.refreshCurrentExercice();
            viewStore.pushNotification(this.props.t("archive_exercice_savec"));
            authStore.refreshSession(); // Current user can now post weez in the new exercice
            notificationsStore.clearNotifications();
            conversationsStore.clearConversations();
          })
        )
        .catch((e) => {
          // FIXME NOTNEEDED
          viewStore.pushNotification("Echec de l'archivage", "error");
          console.error(e);
        })
        .finally(() => {
          setTimeout(
            action(() => {
              this.archiveButtonDisabled = false;
            }),
            5000
          );
        });
    }
  });

  /**
   * Reset archive input form
   */
  @action
  initializeForm = () => {
    this.form = archiveFormDataBuilder();
  };

  componentDidMount() {
    this.loadExercices();
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <h1>{t("manager_exercice")}</h1>
        <Observer>
          {() => (
            <div>
              {t("start_exercice")}
              {this.currentExercice && <DateFormat>{this.currentExercice.dateStart}</DateFormat>}
            </div>
          )}
        </Observer>
        <Observer>
          {() => {
            const inputError = this.form.archiveName.errorMessage;

            return (
              <ArchiveForm onSubmit={this.handleSubmit}>
                <div style={{ display: "flex" }}>
                  <ArchiveInput
                    placeholder={t("archive_name")}
                    {...getInputFieldProps(this.form.archiveName)}
                  />
                  <Button
                    admin
                    type="submit"
                    full
                    loading={this.loading || this.archiveButtonDisabled}>
                    <MdArchive size={20} />
                    {t("archive")}
                  </Button>
                </div>
                <ErrorTextContainer>
                  {inputError && <ErrorText>{inputError}</ErrorText>}
                </ErrorTextContainer>
              </ArchiveForm>
            );
          }}
        </Observer>
        <Observer>
          {() => {
            return this.loading && <LoadingContent label={t("loading")} float={true} />;
          }}
        </Observer>
        <Observer>
          {() => {
            return this.loading && <LoadingContent label={t("loading")} float={true} />;
          }}
        </Observer>
        <Observer>
          {() => {
            return (
              this.archivedExercices && (
                <React.Fragment>
                  <ArchiveResultsTitle>
                    {`${this.totalArchivedExercices} ${t("archived_exercices")}`}{" "}
                  </ArchiveResultsTitle>
                  <ArchiveResults>
                    {this.archivedExercices.map((exercice) => (
                      <ArchiveItem
                        {...exercice}
                        key={exercice.id}
                        menuOptions={[
                          {
                            label: t("download"),
                            externalUrl: `${config.mediaUrl}/${cleanUrlPath(exercice.archivePath)}`,
                            icon: MdGetApp,
                            disabled: !exercice.archivePath || exercice.archivePath.length === 0,
                          },
                        ]}
                      />
                    ))}
                  </ArchiveResults>
                  {this.currentPage < this.totalPages - 1 && (
                    <FlexDiv>
                      <Button admin onClick={this.loadMoreArchivedExercices}>
                        {t("upload_previous_exercice")}
                      </Button>
                    </FlexDiv>
                  )}
                </React.Fragment>
              )
            );
          }}
        </Observer>
      </Container>
    );
  }
}

export default withTranslation()(ArchivesPage);
