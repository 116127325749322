import { themeGet } from "@styled-system/theme-get";
import { ProfileImage } from "app/common";
import logoCrisalyde from "assets/logo_crisalyde.png";
import { Button, Input } from "components";
import get from "lodash/get";
import { action, reaction } from "mobx";
import { MobXProviderContext } from "mobx-react";
import { Observer, useLocalStore } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdEmail, MdHelp, MdHome, MdNotifications, MdSearch, MdSettings } from "react-icons/md";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { imageUrl, isNullOrEmpty } from "utils";
import { Field, getInputFieldProps } from "utils/forms";
import { lightenColor, marginHorizontal } from "utils/mixins";
import { media } from "utils/styles";
import UserMenu from "../menus/UserMenu";

const headerHeight = 50;

const Container = styled.div`
  position: fixed;
  height: ${headerHeight}px;
  background-color: ${themeGet("colors.white")};
  border-bottom: 1px solid ${themeGet("colors.grey")};
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  z-index: ${themeGet("zIndex.higher")};
  box-shadow: ${themeGet("shadows.default")};
`;

const Spacer = styled.div`
  display: block;
  height: ${headerHeight}px;
  width: 100%;
`;

const ProfileMenuContainer = styled.div`
  position: relative;
  ${marginHorizontal("10px")};
`;

const ProfileMenuButton = styled(Button)`
  width: 35px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const activeClassName = "header-navlink-active";

const HeaderNavLinkLabel = styled.span``;

const HeaderNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  font-size: 1rem;
  font-weight: 700;
  color: ${themeGet("colors.grey")};
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: 20px;

  &:hover {
    color: ${themeGet("colors.primary")};
  }
  &.${activeClassName} {
    color: ${themeGet("colors.primary")};
    /* text-decoration: underline; */
  }

  ${HeaderNavLinkLabel} {
    display: none;
  }
  ${media.desktop} {
    ${HeaderNavLinkLabel} {
      display: initial;
    }
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  display: none;

  img {
    width: auto;
    height: 30px;
    margin-right: 5px;
  }

  ${media.tablet} {
    display: initial;
  }
`;

const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  input {
    display: none;
  }

  ${media.desktop} {
    input {
      display: initial;
    }
  }
`;

const SearchBarButton = styled(Button)`
  ${media.desktop} {
    position: absolute;
    right: 0;
  }
`;

const NotificationsCounterContainer = styled.span`
  position: relative;

  span {
    position: absolute;
    top: -3px;
    right: -3px;
    display: inline-block;
    padding: 1px;
    min-width: 14px;
    min-height: 14px;
    line-height: 14px;
    border-radius: 7px;
    text-align: center;
    background-color: red;
    color: white;
    font-size: 0.9rem;
  }

  .${activeClassName} & {
    & span {
      display: none;
    }
  }
`;

const NotificationsCounter = ({ count }) => {
  return (
    <NotificationsCounterContainer>
      <MdNotifications size={25} />
      {count ? <span>{count}</span> : null}
    </NotificationsCounterContainer>
  );
};

const NotificationBadge = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  background: ${lightenColor(0.1, "colors.primary")};
  height: 10px;
  width: 10px;
`;
const IconWrapper = styled.div`
  position: relative;
`;

const Header = () => {
  const { authStore, routingStore, viewStore, notificationsStore, conversationsStore } =
    React.useContext(MobXProviderContext);
  const { t } = useTranslation();
  const store = useLocalStore(() => ({
    logo: logoCrisalyde,
    applicationName: "Weezer",
    query: new Field(),
    menuVisible: false,
    toggleMenu() {
      action(() => (store.menuVisible = !store.menuVisible))();
    },
  }));

  React.useEffect(() => {
    const reactionDisposer = reaction(
      () => get(authStore, ["currentUser", "client"]),
      (client) => {
        store.logo = client && !isNullOrEmpty(client.logo) ? imageUrl(client.logo) : logoCrisalyde;
        store.applicationName =
          client && client.applicationName ? client.applicationName : "Weezer";
      },
      {
        fireImmediately: true,
      }
    );
    return reactionDisposer;
  }, []);

  const onSearch = () => {
    const query = encodeURIComponent(store.query.value);
    store.query.setValue("");
    routingStore.history.push(`/search?terms=${query}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  return (
    <React.Fragment>
      <Container>
        <div
          style={{
            margin: "auto",
            width: "1200px",
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Observer>
            {() => {
              return (
                <ButtonsContainer>
                  {!authStore.currentUser.isSuperAdmin && (
                    <HeaderNavLink to="/" exact location={routingStore.location}>
                      <MdHome size={25} />
                      <HeaderNavLinkLabel>{t("home")}</HeaderNavLinkLabel>
                    </HeaderNavLink>
                  )}
                  {!authStore.currentUser.isSuperAdmin && (
                    <HeaderNavLink to="/notifications" location={routingStore.location}>
                      <NotificationsCounter count={notificationsStore.unreadCount} />
                      <HeaderNavLinkLabel>{t("notifications.title")}</HeaderNavLinkLabel>
                    </HeaderNavLink>
                  )}
                  {!authStore.currentUser.isSuperAdmin && (
                    <HeaderNavLink to="/messages" location={routingStore.location}>
                      <IconWrapper>
                        <MdEmail size={20} style={{ margin: "2px" }} />
                        {conversationsStore.hasUnreadConversations && <NotificationBadge />}
                      </IconWrapper>
                      <HeaderNavLinkLabel>{t("messages.title")}</HeaderNavLinkLabel>
                    </HeaderNavLink>
                  )}

                  {!authStore.currentUser.isUser && (
                    <HeaderNavLink to="/admin" location={routingStore.location}>
                      <MdSettings size={25} />
                      <HeaderNavLinkLabel>{t("admin")}</HeaderNavLinkLabel>
                    </HeaderNavLink>
                  )}
                  {!authStore.currentUser.isSuperAdmin && (
                    <HeaderNavLink to="/help" location={routingStore.location}>
                      <MdHelp size={25} />
                      <HeaderNavLinkLabel>{t("help")}</HeaderNavLinkLabel>
                    </HeaderNavLink>
                  )}
                </ButtonsContainer>
              );
            }}
          </Observer>
          <Observer>
            {() => (
              <LogoContainer>
                <img alt="crisalyde" src={store.logo} />
                {store.applicationName}
              </LogoContainer>
            )}
          </Observer>
          <Observer>
            {() => (
              <ButtonsContainer>
                {!authStore.currentUser.isSuperAdmin && (
                  <SearchBarContainer>
                    <Input
                      name="search"
                      {...getInputFieldProps(store.query, { onKeyPress: handleKeyPress })}
                    />
                    <SearchBarButton empty onClick={onSearch}>
                      <MdSearch size={25} />
                    </SearchBarButton>
                  </SearchBarContainer>
                )}
                <ProfileMenuContainer>
                  <ProfileMenuButton empty onClick={store.toggleMenu}>
                    <ProfileImage photo={authStore.currentUser.photo} border />
                  </ProfileMenuButton>
                  {store.menuVisible && <UserMenu closeMenu={store.toggleMenu} />}
                </ProfileMenuContainer>
                {!authStore.currentUser.isSuperAdmin && (
                  <Button full onClick={viewStore.toggleNewWeezModal}>
                    Weezer
                  </Button>
                )}
              </ButtonsContainer>
            )}
          </Observer>
        </div>
      </Container>
      <Spacer />
    </React.Fragment>
  );
};

export default React.memo(Header);
