import isAfter from "date-fns/isAfter";
import isValid from "date-fns/isValid";
import parseISO from "date-fns/parseISO";
import get from "lodash/get";
import { action, computed, observable, reaction } from "mobx";
import * as notificationsApi from "services/notifications";
import { REFRESH_NOTIFICATIONS_INTERVAL } from "utils/constants";
import { t } from "utils/i18n";

export default class NotificationsStore {
  checkNotificationsInterval;

  @observable
  notifications = [];

  @observable
  loading;

  @observable
  endOfFeed = false;

  @observable
  error = null;

  @computed
  get lastNotificationReadDate() {
    const { lastNotificationRead } = this.authStore.currentUser;
    return parseISO(lastNotificationRead);
  }

  @computed
  get unreadCount() {
    const initialValue = 0;
    return this.notifications.reduce((res, notification) => {
      if (this.isNotificationUnread(notification)) {
        return res + 1;
      }
      return res;
    }, initialValue);
  }

  inject({ viewStore, authStore, fetchService }) {
    this.viewStore = viewStore;
    this.authStore = authStore;
    this.fetchService = fetchService;
    reaction(
      () => authStore.isConnected,
      (isAuthentified) => {
        if (isAuthentified) {
          //"Super admin" do not get notifications
          if (!get(authStore.currentUser, "isSuperAdmin", false)) {
            this.startInterval();
          }
        } else {
          this.stopInterval();
        }
      }
    );
  }

  isNotificationUnread(notification) {
    if (!isValid(this.lastNotificationReadDate)) {
      return true;
    }
    const createdOnDate = parseISO(notification.createdOn);
    return isAfter(createdOnDate, this.lastNotificationReadDate);
  }

  startInterval() {
    this.stopInterval();
    this.loadNewNotifications().then(() => {
      this.checkNotificationsInterval = setInterval(
        this.loadNewNotifications,
        REFRESH_NOTIFICATIONS_INTERVAL
      );
    });
  }

  stopInterval() {
    if (this.checkNotificationsInterval) {
      clearInterval(this.checkNotificationsInterval);
    }
  }

  @action("NOTIFICATION_LOAD")
  loadNewNotifications = () => {
    this.loading = true;
    const lastNotification = this.notifications.length && this.notifications[0];
    const afterTimestamp = lastNotification ? lastNotification.createdOn : null;
    return this.fetchService
      .fetch(notificationsApi.getNotifications({ after: afterTimestamp }))
      .then(
        action((notifications) => {
          this.notifications.unshift(...notifications);
        })
      )
      .catch((e) => {
        this.viewStore.pushNotification(t(`errors.${e.code}`), "error");
      })
      .finally(action(() => (this.loading = false)));
  };

  @action
  loadOldNotifications() {
    if (this.endOfFeed) {
      return;
    }
    this.loading = true;
    this.error = null;
    const beforeNotification =
      this.notifications.length && this.notifications[this.notifications.length - 1];
    const beforeTimestamp = beforeNotification ? beforeNotification.createdOn : null;
    return this.fetchService
      .fetch(notificationsApi.getNotifications({ before: beforeTimestamp }))
      .then(
        action((notifications) => {
          this.loading = false;
          if (!notifications || !notifications.length) {
            this.endOfFeed = true;
          } else {
            this.notifications.push(...notifications);
          }
        })
      )
      .catch(
        action((e) => {
          this.loading = false;
          this.error = e;
        })
      );
  }

  @action
  clearNotifications() {
    this.loading = false;
    this.error = null;
    this.endOfFeed = false;
    this.notifications = [];
  }
}
