import { Modal } from "components";
import React from "react";
import modalHoc from "react-modal-hoc";
import NewWeez from "./NewWeez";
import { t } from "utils/i18n";

class NewWeezModal extends React.Component {
  render() {
    const { onRequestClose, weez, mode = "new" } = this.props;
    return (
      <Modal isOpen allowScroll title={t(`post.title.${mode}`)} onRequestClose={onRequestClose}>
        <NewWeez weez={weez} mode={mode} onSuccess={onRequestClose} />
      </Modal>
    );
  }
}

export default modalHoc(NewWeezModal);
