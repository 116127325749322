import { observable } from "mobx";

export default class BoostStore {
  @observable _langs;

  @observable _moods;

  inject({ fetchService }) {
    this.fetchService = fetchService;
  }

  // @computed
  get langs() {
    return ["fr", "en"];
    // if (!this._langs) {
    //   this._langs = fromPromise(this.fetchService.fetch(getLangs()));
    // }
    // return this._langs.state === "fulfilled" ? this._langs.value : [];
  }

  // @computed
  get moods() {
    return ["ENTHUSIASM", "FUN", "ANGER", "SADNESS", "FEAR", "TROLL", "NONE"];
    //   if (!this._moods) {
    //     this._moods = fromPromise(this.fetchService.fetch(getMoods()));
    //   }
    //   return this._moods.state === "fulfilled" ? this._moods.value : [];
  }
}
