import { AuthorizedRoute } from "components";
import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router-dom";
import { inject } from "mobx-react";
import AuthLayout from "./AuthLayout";
import MainLayout from "./MainLayout";
import { imageUrl, isNullOrEmpty } from "utils";
// import Disclaimer from "app/disclaimer";

const Layout = props => {
  const { currentUser } = props.authStore;
  const companyName =
    currentUser && currentUser.client ? currentUser.client.applicationName : "Weezer";
  const favicon =
    currentUser && currentUser.client && !isNullOrEmpty(currentUser.client.logo)
      ? imageUrl(currentUser.client.logo)
      : "/favicons/favicon-32x32.png";
  return (
    <React.Fragment>
      <Helmet defaultTitle="Weezer" titleTemplate={`${companyName} | %s`}>
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
      </Helmet>
      <Switch>
        {/* <Route exact path="/disclaimer" component={Disclaimer} /> */}
        <Route path="/auth" component={AuthLayout} />
        <AuthorizedRoute component={MainLayout} />
      </Switch>
    </React.Fragment>
  );
};

export default inject("authStore")(Layout);
