import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
// import { lightenColor } from "utils/mixins";

const StyledBar = styled.div`
  display: flex;
  width: 100%;
  min-height: 50px;
  height: 50px;
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
`;
function TabBar({ className, children }) {
  return <StyledBar className={className}>{children}</StyledBar>;
}

export default TabBar;
