import { Button, Divider, ErrorContent, LoadingContent } from "components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { fromPromise } from "mobx-utils";
import React from "react";
import { MdEdit, MdPersonAdd } from "react-icons/md";
import { getClients } from "services/clients";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { t } from "utils/i18n";
import { marginVertical, resetList } from "utils/mixins";
import ClientItem from "./ClientItem";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const AddButton = styled(Button)`
  align-self: center;
`;

const SearchResults = styled.ul`
  ${resetList};
`;

const SearchResultsTitle = styled.span`
  ${marginVertical("20px")};
  font-weight: 600;
  font-size: 1.1rem;
  color: ${themeGet("colors.grey")};
`;

const ActionButtonContainer = styled.div`
  margin: 10px;
`;

@inject("fetchService")
@observer
class ClientsPage extends React.Component {
  @observable
  getClientsRequest = null;

  @action("ADMIN_CLIENT_SEARCH")
  searchClients = () => {
    const { fetchService } = this.props;
    this.getClientsRequest = fromPromise(fetchService.fetch(getClients()));
  };

  componentDidMount() {
    this.searchClients();
  }

  render() {
    return (
      <Container>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <h1>Administration des clients</h1>
          <ActionButtonContainer>
            <AddButton admin full to="/admin/clients/add">
              <MdPersonAdd size={20} />
              {t("add_customer")}
            </AddButton>
          </ActionButtonContainer>
        </div>
        <Divider />
        {this.getClientsRequest &&
          this.getClientsRequest.case({
            pending: () => <LoadingContent label={t("loading_customers")} />,
            rejected: (error) => <ErrorContent text={t(error.code)} />,
            fulfilled: (value) => (
              <>
                <SearchResultsTitle>{`${value.length} ${t("found_customers")}`}</SearchResultsTitle>
                <SearchResults>
                  {value.map((client) => (
                    <ClientItem
                      key={client.id}
                      client={client}
                      menuOptions={[
                        {
                          label: t("edit"),
                          to: `/admin/clients/${client.id}/edit`,
                          icon: MdEdit,
                        },
                      ]}
                    />
                  ))}
                </SearchResults>
              </>
            ),
          })}
      </Container>
    );
  }
}

export default ClientsPage;
