import { computed, observable } from "mobx";

export default class FieldsArray {
  @observable fields;

  initValue;

  constructor(fields, { validate } = {}) {
    this.initValue = fields;
    this.validate = validate;
    this.init(fields);
  }

  init(fields) {
    this.fields = observable.array(fields, { deep: false });
  }

  reset(pristine = false) {
    this.init(this.initValue);
    this.fields.forEach(field => field.reset(pristine));
  }

  markAsTouched() {
    this.fields.forEach(field => field.markAsTouched());
  }

  setValue(data) {
    if (!data) {
      return;
    }
    this.fields.forEach((field, i) => field.setValue(data[i], false));
  }

  get(index) {
    return this.fields[index];
  }

  @computed
  get _validation() {
    if (this.validate) {
      const validation = this.validate(this.fields);
      if (!validation.valid) {
        return validation;
      }
    }

    const invalidField = this.fields.find(field => !field.isValid);
    if (invalidField) {
      return invalidField._validation;
    }

    return {
      valid: true,
    };
  }

  @computed
  get isValid() {
    return this._validation.valid;
  }

  @computed
  get value() {
    return this.fields.map(field => field.value);
  }
}
