import isToday from "date-fns/isToday"
import format from "date-fns/format"
import frLocale from "date-fns/locale/fr"

export default function DateFormatConversation({ children, pattern = "HH'h'mm" }) {
  const date = new Date(children)
  if (!isToday(date)) {
    pattern = "dd MMM"
  }
  return format(date, pattern, { locale: frLocale })
}
