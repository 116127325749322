import { action, autorun, computed, observable, toJS } from "mobx";
import { defaultValidationContext } from "./validation";

export default class Field {
  @observable _value;
  @observable _interacted;
  @observable _validation;
  @observable disabled = false;
  opts = {};
  _isValidFn = [];

  reset(pristine = false) {
    this._value = this.initValue;
    if (pristine) {
      this.markAsUntouched();
    }
  }

  @action("FIELD_SET_DISABLED")
  setDisabled(value) {
    this.disabled = value;
  }

  @action("FIELD_MARK_AS_TOUCH")
  markAsTouched = () => {
    if (!this._interacted) {
      this._interacted = true;
    }
  };

  @action("FIELD_MARK_AS_UNTOUCHED")
  markAsUntouched = () => {
    if (this._interacted) {
      this._interacted = false;
    }
  };

  @computed
  get value() {
    if (this.computeValue) {
      return this.computeValue(this._value);
    }
    return this._value;
  }

  @action("SET_FIELD_VALUE")
  setValue = (val, interract = true) => {
    if (interract && !this._interacted) {
      this._interacted = true;
    }
    this._value = val;
  };

  onChange = (evt, value) => {
    if (value !== this._value) {
      this.setValue(value);
    }
  };

  @action("SET_FIELD_VALIDATION")
  setValidation(validation) {
    this._validation = validation;
  }

  @computed
  get errorMessage() {
    if (!this._interacted) {
      return "";
    }
    return this._validation.message;
  }

  @computed
  get isValid() {
    return this._validation.valid;
  }

  jsValue() {
    return toJS(this._value);
  }

  validate = async () => {
    const validationContext = this.validationContext(this._value);
    let lastRes;

    for (let i = 0; i < this._isValidFn.length; i++) {
      const isValidFn = this._isValidFn[i];

      let res = await isValidFn(validationContext);

      if (res === null || res === "undefined" || !(typeof res === "object")) {
        throw new Error("Invalid validator result");
      }
      lastRes = res;
      if (!res.valid || res.stop) {
        break;
      }
    }

    if (lastRes) {
      this.setValidation(lastRes);
    }
  };

  constructor(
    initValue,
    {
      validate,
      debounce = false,
      disabled = false,
      computeValue,
      validationContext = defaultValidationContext,
    } = {}
  ) {
    this.disabled = disabled;
    this.computeValue = computeValue;
    this.validationContext = validationContext;
    this.initValue = initValue;
    this.setValue(initValue, false);
    if (validate) {
      if (validate instanceof Array) {
        this._isValidFn = validate;
      } else {
        this._isValidFn = [validate];
      }
    }

    if (this._isValidFn.length) {
      this._validation = { valid: false };
      autorun(this.validate, { delay: debounce ? 300 : 0 });
    } else {
      this._validation = { valid: true };
    }
  }
}
