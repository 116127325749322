import React from "react";
import Dayzed from "dayzed";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import styledMap from "styled-map";
import { resetButton, ifProp } from "utils/mixins";

const monthNamesShort = [
  "Jan",
  "Févr",
  "Mar",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Aou",
  "Sep",
  "Oct",
  "Nov",
  "Déc",
];
const weekdayNamesShort = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

const Container = styled.div`
  display: flex;
  flex-direction:column;
  background-color: ${themeGet("colors.white")};
  width: 250px;
  height: 190px;
  border: 1px solid ${themeGet("colors.grey")},
  border-radius: 3px;
  box-shadow: ${themeGet("shadows.default")};
  z-index: ${themeGet("zIndex.high")};
  padding: 10px;
`;

const TopButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    ${resetButton};
    font-weight: 600;
    color: ${themeGet("colors.black")};
    &:hover {
      color: ${themeGet("colors.primary")};
    }
  }
`;

const CalendarContainer = styled.div``;

const Month = styled.div`
  text-align: center;
  font-weight: 700;
  color: ${themeGet("colors.primary")};
  margin: 5px;
`;

const WeekDay = styled.div`
  display: inline-block;
  width: calc(100% / 7);
  border: none;
  background: transparent;
  font-weight: 300;
`;

const Day = styled.button.attrs({ type: "button" })`
  display: inline-block;
  width: calc(100% / 7);
  border: none;
  cursor: ${styledMap({
    selectable: "pointer",
    default: "initial",
  })};
  font-weight: 400;
  color: ${styledMap({
    selectable: themeGet("colors.veryStronggrey"),
    default: themeGet("colors.veryLightGrey"),
  })};
  background-color: ${styledMap({
    today: "green",
    selected: themeGet("colors.veryLightGrey"),
    default: "white",
  })};

  ${ifProp(
    "selectable",
    css`
      &:hover {
        background-color: ${themeGet("colors.primary")};
        color: ${themeGet("colors.white")};
      }
    `
  )};
`;

export default class Datepicker extends React.Component {
  render() {
    const { onDateSelected, selected, className, minDate, date, ...rest } = this.props;
    return (
      <Dayzed
        {...rest}
        onDateSelected={onDateSelected}
        selected={selected}
        minDate={minDate}
        date={date}
        firstDayOfWeek={1}
        render={({ calendars, getBackProps, getForwardProps, getDateProps }) => {
          if (!calendars.length) {
            return null;
          }
          return (
            <Container className={className}>
              <TopButtonContainer>
                <button type="button" {...getBackProps({ calendars })}>
                  Préc.
                </button>
                <button type="button" {...getForwardProps({ calendars })}>
                  Suiv.
                </button>
              </TopButtonContainer>
              {calendars.map((calendar) => (
                <CalendarContainer key={`${calendar.month}${calendar.year}`}>
                  <Month>
                    {monthNamesShort[calendar.month]} {calendar.year}
                  </Month>
                  {weekdayNamesShort.map((weekday) => (
                    <WeekDay key={`${calendar.month}${calendar.year}${weekday}`}>{weekday}</WeekDay>
                  ))}
                  {calendar.weeks.map((week) =>
                    week.map((dateObj, index) => {
                      if (!dateObj) {
                        return (
                          <div
                            key={`${calendar.year}${calendar.month}${index}`}
                            style={{
                              display: "inline-block",
                              width: "calc(100% / 7)",
                              border: "none",
                              background: "transparent",
                            }}
                          />
                        );
                      }
                      let { date, selected, selectable, today } = dateObj;
                      return (
                        <Day
                          isToday={today}
                          selected={selected}
                          selectable={selectable}
                          key={`${calendar.year}${calendar.month}${index}`}
                          {...getDateProps({ dateObj })}>
                          {date.getDate()}
                        </Day>
                      );
                    })
                  )}
                </CalendarContainer>
              ))}
            </Container>
          );
        }}
      />
    );
  }
}
