import React, { Component } from "react";
import AddSequencePage from "./AddSequencePage";
import { fromPromise } from "mobx-utils";
import { getSequence } from "services/sequence";
import { observer, inject } from "mobx-react";
import { observable, action } from "mobx";
import { LoadingContent, ErrorContent } from "components";
import { FORM_MODE_EDIT } from "utils/constants";
import { withTranslation } from "react-i18next";

@inject("fetchService")
@observer
class EditSequence extends Component {
  @observable
  sequenceDetailsRequest = null;

  constructor(props) {
    super(props);
    this.showDetailSequence();
  }

  @action("GET_SEQUENCE")
  showDetailSequence = () => {
    const { fetchService } = this.props;
    if (this.props.match.params.id)
      this.sequenceDetailsRequest = fromPromise(
        fetchService.fetch(getSequence(this.props.match.params.id))
      );
  };

  render() {
    const { t } = this.props;
    return this.sequenceDetailsRequest.case({
      pending: () => <LoadingContent label={t("sequence.loading")} />,
      rejected: (error) => <ErrorContent text={t(error.code)} />,
      fulfilled: (sequence) => (
        <AddSequencePage
          sequence={sequence}
          mode={FORM_MODE_EDIT}
          id={this.props.match.params.id}
        />
      ),
    });
  }
}

export default withTranslation()(EditSequence);
