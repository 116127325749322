import React from "react";
import { Document, Page } from "react-pdf";
import { runInAction, action } from "mobx";
import { MobXProviderContext } from "mobx-react";

function PrivacyPage() {
  const [numPages, setNumPages] = React.useState(null);
  const { routingStore } = React.useContext(MobXProviderContext);
  React.useEffect(() => {
    runInAction(() => {
      routingStore.showReturnArrow = true;
    });
    return action(() => {
      routingStore.showReturnArrow = false;
    });
  }, []);
  const onDocumentLoadSuccess = document => {
    const { numPages } = document;
    setNumPages(numPages);
  };
  return (
    <div>
      <Document
        file="https://weezer.fr/weezer-privacy_fr.pdf"
        onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={window.innerWidth}/>
        ))}
      </Document>
    </div>
  );
}

export default PrivacyPage;
