import { deserialize } from "serializr";
import User from "./models/User";

export default class UsersStore {
  users = {};

  inject({ fetchService }) {
    this.fetchService = fetchService;
  }

  deserializeUser(userData) {
    const user = deserialize(User, userData, () => {}, { usersStore: this });
    this.users[user.id] = user;
    return user;
  }

  getUserById(userId) {
    if (this.users[userId]) {
      return this.users[userId];
    } else {
      return (this.users[userId] = new User({ fetchService: this.fetchService }));
    }
  }
}
