const transitions = {
  default: {
    duration: "250ms",
    function: "cubic-bezier(0.8, 0.2, 0.2, 1)",
  },
}

const colors = {
  primary: "#0D5295",
  secondary: "#FFBC0D",
  white: "#ffffff",
  black: "#000000",
  text: "#3b3b3b",
  veryLightGrey: "#DDDDDD",
  lightGrey: "#BBBBBB",
  grey: "#999999",
  strongGrey: "#777777",
  veryStrongGrey: "#555555",
  backgroundGrey: "#EBEBEB",
  error: "red",
  dummy: "green",
  success: "green",
  red: "#e74c3c",
  green: "#27ae60",
  yellow: "#fdcb6e",
  inputBackground: "rgb(230, 236, 240)",
}

const zIndex = {
  highest: 300,
  higher: 200,
  high: 100,
  normal: 1,
  low: -100,
  lower: -200,
}

const defaultFont = "'Roboto', sans-serif"

const shadows = {
  default: "0 1px 3px 0 rgba(0, 0, 0, 0.2)",
  other1: "0 1px 10px 0 rgba(0, 0, 0, 0.35)",
  other2: `0 1px 4px 0 ${colors.text}`,
  upward: "0 -1px 3px 0 rgba(0, 0, 0, 0.1)",
  elevation1: "0px 1px 1px 1px rgba(0, 0, 0, 0.25)",
}

const theme = { colors, transitions, zIndex, defaultFont, shadows }

export default theme
