import camelCase from "lodash/camelCase";
const config = {};

/**
 * This is to extract REACT_APP_* values to camelcase object
 */
Object.keys(process.env).forEach((key) => {
  const configName = key.substring(10);
  config[camelCase(configName)] = process.env[key];
});

export default config;
