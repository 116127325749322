import React from "react";
import { DrawerSubtitle, DrawerContent } from "@material/react-drawer";
import { MdPermIdentity, MdInfoOutline, MdExitToApp, MdPowerSettingsNew } from "react-icons/md";
import styled from "styled-components";

import "@material/react-drawer/dist/drawer.css";
import { ProfileImage } from "app/common";
import Droower, {
  DroowerLay,
  DroowerHeader,
  DroowerTitle,
  DroowerListItem,
  DroowerListIcon,
  DroowerList,
} from "app/common/Droower";
import { MobXProviderContext } from "mobx-react";

const Menus = [
  {
    text: "Profil",
    icon: <MdPermIdentity />,
    onClick: ({ routingStore }, user) => routingStore.history.push(`/${user.username}`),
  },
  {
    text: "Politique de confidentialité",
    icon: <MdInfoOutline />,
    onClick: ({ routingStore }) => routingStore.history.push("/privacy"),
  },
  {
    text: "Disclaimer",
    icon: <MdInfoOutline />,
    onClick: ({ routingStore }) => routingStore.history.push("/disclaimer"),
  },
  {
    text: "Se déconnecter",
    icon: <MdExitToApp />,
    onClick: ({ authStore, routingStore }) => {
      routingStore.history.push("/logout");
    },
  },
  {
    text: "Quitter l'application",
    icon: <MdPowerSettingsNew />,
    onClick: ({ authStore, routingStore }) => {
      routingStore.history.push("/exitApp");
    },
  },
];

const ProfilContainer = styled.div`
  display: block;
  width: 50px;
`;

function MobileDrawer({ isOpen = false, onCloseMenu, user }) {
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const stores = React.useContext(MobXProviderContext);
  return (
    <div>
      <DroowerLay open={isOpen} onClick={onCloseMenu} />
      <Droower open={isOpen} style={{ zIndex: 999 }} onClose={onCloseMenu}>
        <DroowerHeader>
          <ProfilContainer>
            <ProfileImage photo={user.photo} border />
          </ProfilContainer>
          <DroowerTitle tag="h1">{`${user.firstname} ${user.lastname}`}</DroowerTitle>
          <DrawerSubtitle>@{user.username}</DrawerSubtitle>
        </DroowerHeader>

        <DrawerContent>
          <DroowerList singleSelection selectedIndex={selectedIndex}>
            {Menus.map((menu, index) => (
              <DroowerListItem
                key={index}
                onClick={() => {
                  setSelectedIndex(index);
                  onCloseMenu();
                  menu.onClick(stores, user);
                }}>
                {/* <MdPermIdentity size={40} color="grey" /> */}
                <DroowerListIcon graphic={menu.icon} size={40} />
                {menu.text}
              </DroowerListItem>
            ))}
          </DroowerList>
        </DrawerContent>
      </Droower>
    </div>
  );
}

export default MobileDrawer;
