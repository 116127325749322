import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ProfileImage } from "app/common";
import EllipsisText from "./EllipsisText";
import DateFormatConversation from "./DateFormatConversation";
import { useObserver } from "mobx-react-lite";

const ItemContainer = styled.div`
  display: flex;
  max-height: 100px;
  flex-wrap: nowrap;
  padding: 8px 10px;
  border-bottom: 1px solid ${themeGet("colors.veryLightGrey")};
  background-color: ${(props) => (props.unread ? "rgba(236, 240, 241, 0.5)" : "transparent")};
`;
const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 1 0px;
  border: 1px solid transparent;
  padding: 1px;
`;
const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 0px;
  padding: 5px;
  flex-wrap: nowrap;
`;
const ConversationContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex: 1 1 0px;
  /* height: 100%; */
  flex-direction: column;
  justify-content: stretch;
`;
const Title = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: auto;
  max-width: 100%;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  overflow: hidden;
  font-size: 1.1rem;
  font-weight: 700;
`;
const LastMessage = styled.div`
  display: flex;
  width: 100%;
  color: ${themeGet("colors.grey")};
  overflow: hidden;
`;
const ConversationDate = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  color: ${themeGet("colors.grey")};
`;
const ImageContainer = styled.div`
  margin-left: ${({ position }) => `-${position * 20}px`};
  width: 40px;
`;
const AvatarSplitter = styled.div`
  width: ${({ position }) => `${position * 2 + 20}px`};
  overflow: hidden;
  box-sizing: border-box;
  border-left: ${({ position }) => `${position * 2}px solid white`};
  z-index: ${({ position }) => position + 1};
`;

function ConversationItem({ className, conversation, onClick, user }) {
  const [interlocutors, setInterlocutors] = React.useState([]);
  React.useEffect(() => {
    setInterlocutors(conversation.participants.filter((part) => part.identity.id !== user.id));
  }, [conversation, user]);
  return useObserver(() => (
    <ItemContainer className={className} unread={conversation.hasUnreadMessage} onClick={onClick}>
      <AvatarContainer>
        {interlocutors.length === 1 && (
          <ImageContainer position={0}>
            <ProfileImage photo={interlocutors[0].identity.photo} />
          </ImageContainer>
        )}
        {interlocutors.length > 1 &&
          interlocutors.map((part, index) => (
            <AvatarSplitter key={part.id} position={index}>
              <ImageContainer position={index}>
                <ProfileImage photo={part.identity.photo} />
              </ImageContainer>
            </AvatarSplitter>
          ))}
      </AvatarContainer>
      <ContentContainer>
        <ConversationContent>
          <Title>
            <EllipsisText
              text={interlocutors
                .map((part) => {
                  return part.identity.displayName;
                })
                .join(", ")}
              length={30}
            />
          </Title>
          <LastMessage>
            {conversation.lastMessage && (
              <EllipsisText text={conversation.lastMessage.content} length={20} />
            )}
          </LastMessage>
        </ConversationContent>
        <ConversationDate>
          {conversation.lastMessage && (
            <DateFormatConversation>{conversation.lastMessage.createdOn}</DateFormatConversation>
          )}
        </ConversationDate>
      </ContentContainer>
    </ItemContainer>
  ));
}

export default ConversationItem;
