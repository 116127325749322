import { themeGet } from "@styled-system/theme-get";
import { DisplayName, ProfileImage, UserCount, WeezFeed } from "app/common";
import { BrowserView, MobileView } from "app/layout/responsiveness/mediaQueries";
import { Button, ErrorText, LoadingContent } from "components";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import qs from "qs";
import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { MdDoNotDisturb, MdLocationOn } from "react-icons/md";
import styled, { css } from "styled-components";
import { cancelEvent } from "utils";
import { USER_TYPES } from "utils/constants";
// import { t } from "utils/i18n";
import EditProfileModal from "./EditProfileModal";
import ProfileModel from "./ProfileModel";
import ProfilePageMobile from "./ProfilePage.mobile";

const leftContentSize = 300;
const maxWidth = 1200;
const maxFeedWidth = 880;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
`;

const HeaderBackground = styled.div`
  height: 175px;
  background-color: ${themeGet("colors.primary")};
`;

const TopBar = styled.div`
  background-color: ${themeGet("colors.white")};
  width: 100%;
  height: 60px;
  box-shadow: ${themeGet("shadows.default")};
  display: flex;
  justify-content: center;
`;

const TopBarContent = styled.div`
  display: flex;
  flex: 1;
  max-width: ${maxWidth}px;
  height: 100%;
`;

const TopBarLinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopBarLeft = styled.div`
  display: flex;
  height: 100%;
`;

const TopBarRight = styled.div`
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: center;
`;

const UserPhoto = styled.div`
  width: ${leftContentSize}px;
  margin-right: 20px;
  position: relative;
`;

const StyledProfileImage = styled(ProfileImage)`
  position: absolute;
  z-index: ${themeGet("zIndex.high")};
  left: 0px;
  bottom: -40px;
  width: 220px;
  border-width: 6px;
  border-color: ${themeGet("colors.white")};
`;

const UserInformation = styled.div`
  width: ${leftContentSize}px;
  margin-top: 2px;
  margin-right: 2px;
  display: flex;
  flex: 1 1 content;
  flex-direction: column;
  padding: 10px;
  padding-top: 50px;
  background-color: white;
`;

const userInformationTextStyle = css`
  margin-bottom: 10px;
`;

const StyledDisplayName = styled(DisplayName)`
  ${userInformationTextStyle};
`;

const Username = styled.span`
  ${userInformationTextStyle};
  font-size: 1rem;
  font-weight: 500;
  color: ${themeGet("colors.grey")};
`;

const Bio = styled.span`
  ${userInformationTextStyle};
`;

const StyledUserFeed = styled(WeezFeed)`
  flex: 1;
  background-color: ${themeGet("colors.white")};
  margin-top: 2px;
  max-width: ${maxFeedWidth}px;
`;

@inject("authStore", "usersStore", "fetchService")
@observer
class ProfilePage extends React.Component {
  @observable
  profile = null;

  @observable
  editProfileActive = false;

  @action("TOGGLE_EDIT_MODAL")
  toggleModal = () => {
    this.editProfileActive = !this.editProfileActive;
  };

  @action("LOAD_USER_PROFILE")
  initProfileModel = () => {
    const { match, location } = this.props;

    const username = match.params.username;
    const searchParamsWithoutQuestionMark = location.search.substring(1).trim();
    const userId = qs.parse(searchParamsWithoutQuestionMark).id;

    this.profile = new ProfileModel(username, userId, {
      usersStore: this.props.usersStore,
      fetchService: this.props.fetchService,
    });
  };

  handleClickSubscribeButton = (user) =>
    cancelEvent(() => {
      user.subscribe(!user.subscribed).then(() => {
        this.props.authStore.refreshSession();
      });
    });

  componentDidMount() {
    this.initProfileModel();
  }

  componentWillUnmount() {
    this.profile.weezsFeed.close();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.username !== this.props.match.params.username) {
      this.initProfileModel();
    }
  }

  render() {
    const { t } = this.props;
    if (!this.profile || this.profile.isLoading) {
      // Loading indicator
      return <LoadingContent label="Profile loading" />;
    }

    if (this.profile.error) {
      // Loading indicator
      return <ErrorText>{t(this.profile.error.code)}</ErrorText>;
    }

    const { currentUser } = this.props.authStore;
    const user = this.profile.user;

    if (user.locked) {
      return (
        <Container>
          <Helmet>
            <title>{`${t("profile.title")} ?`}</title>
          </Helmet>
          <HeaderContainer>
            <HeaderBackground />
            <TopBar>
              <TopBarContent>
                <UserPhoto>
                  <StyledProfileImage photo={user.photo} border />
                </UserPhoto>
                <TopBarLinksContainer />
              </TopBarContent>
            </TopBar>
          </HeaderContainer>
          <Content>
            <h1>
              <MdDoNotDisturb size={35} /> {t("profile_locked")}
            </h1>
          </Content>
        </Container>
      );
    }

    return (
      <React.Fragment>
        <BrowserView>
          <Container>
            <Helmet>
              <title>{`${t("profile.title")} @${this.profile.username}`}</title>
            </Helmet>
            <HeaderContainer>
              <HeaderBackground />
              <TopBar>
                <TopBarContent>
                  <UserPhoto>
                    <StyledProfileImage photo={user.photo} border />
                  </UserPhoto>
                  <TopBarLinksContainer>
                    <TopBarLeft>
                      <UserCount selected label="weezes" value={user.weezsCount} />
                      <UserCount label={t("subscriptions")} value={user.subscriptionsCount} />
                      <UserCount label={t("subscribers")} value={user.subscribersCount} />
                    </TopBarLeft>
                    <TopBarRight>
                      {currentUser.id !== user.id && (
                        <Button
                          full={user.subscribed}
                          onClick={this.handleClickSubscribeButton(user)}>
                          {user.subscribed ? t("subscribed_plural") : t("follow_plural")}
                        </Button>
                      )}
                      {(currentUser.id === user.id || currentUser.role === USER_TYPES.ANIMATOR) && (
                        <Button onClick={this.toggleModal}>{t("profile.actions.edit")}</Button>
                      )}
                    </TopBarRight>
                  </TopBarLinksContainer>
                </TopBarContent>
              </TopBar>
            </HeaderContainer>
            <Content>
              <div style={{ paddingBottom: "3px" }}>
                <UserInformation>
                  <StyledDisplayName user={user} size="big" />
                  <Username>@{user.username}</Username>
                  {user.location && (
                    <Username>
                      <MdLocationOn siz={20} />
                      {user.location}
                    </Username>
                  )}
                  <Bio>{user.bio}</Bio>
                </UserInformation>
              </div>
              <StyledUserFeed weezsFeed={this.profile.weezsFeed} />
            </Content>
            <EditProfileModal
              user={user}
              isOpen={this.editProfileActive}
              onRequestClose={this.toggleModal}
            />
          </Container>
        </BrowserView>
        <MobileView>
          <ProfilePageMobile
            user={user}
            username={this.profile.username}
            currentUser={currentUser}
            toggleModal={this.toggleModal}
            weezsFeed={this.profile.weezsFeed}
            onClickSubscribeButton={this.handleClickSubscribeButton}
            editProfileActive={this.editProfileActive}
          />
        </MobileView>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ProfilePage);
