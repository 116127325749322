import { ProfileImage } from "app/common";
import { MenuButton } from "components";
import React from "react";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ifProp, paddingVertical } from "utils/mixins";

const ClientItemContainer = styled.li`
  display: flex;
  justify-content: space-between;
  ${paddingVertical("10px")};
  border-bottom: 1px solid ${themeGet("colors.lightGrey")};
  &:last-child {
    border: none;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  ${ifProp(
    "onClick",
    css`
      background-color: ${themeGet("colors.white")};
      cursor: pointer;

      &:hover {
        background-color: ${themeGet("colors.backgroundGrey")};
      }
    `
  )};
`;

const ClientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ImageContainer = styled.div`
  margin-right: 20px;
  width: 50px;
`;

const ClientItem = ({ client, menuOptions, onClick, renderRight, displayEmail = true }) => {
  return (
    <ClientItemContainer>
      <LeftContainer onClick={onClick}>
        <ImageContainer>
          <ProfileImage photo={client.logo} />
        </ImageContainer>
        <ClientInfoContainer>
          <span>{client.name}</span>
        </ClientInfoContainer>
      </LeftContainer>
      {menuOptions && <MenuButton options={menuOptions} />}
    </ClientItemContainer>
  );
};

export default ClientItem;
