import React, { Component } from "react";
import { MdMoreVert } from "react-icons/md";
import Toggle from "react-toggled";
import styled, { keyframes } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { paddingHorizontal, paddingVertical, resetList } from "utils/mixins";
import Button from "./Button";
import OnClickOutside from "./utils/OnClickOutside";

const Container = styled.div`
  position: relative;
`;

const openMenuAnimation = keyframes`
  0% {
    transform:scale(0);
  }

  100% {
    transform:scale(1);
  }
`;

const MenuItems = styled.ul`
  ${resetList};
  background: ${themeGet("colors.white")};
  box-shadow: ${themeGet("shadows.default")};
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 0px;
  z-index: ${themeGet("zIndex.high")};
  transform-origin: 100% 0%;
  animation: ${openMenuAnimation} 200ms ease-in-out;
  white-space: nowrap;
`;

const MenuItemContainer = styled.li``;

const MenuItemButton = styled(Button)`
  ${paddingHorizontal("10px")};
  ${paddingVertical("20px")};
  width: 100%;
  text-align: left;

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: ${themeGet("colors.backgroundGrey")};
  }
`;

const MenuItem = ({ label, onClick, icon: IconElement, ...props }) => (
  <MenuItemContainer>
    <MenuItemButton empty="true" onClick={onClick} {...props}>
      {IconElement && <IconElement size={20} />}
      {label}
    </MenuItemButton>
  </MenuItemContainer>
);

class Menu extends Component {
  onClickRequest = (onClick, params) => e => {
    if (onClick) {
      onClick(params);
    }
    this.props.setOff();
  };

  render() {
    const { options } = this.props;
    return (
      <OnClickOutside action={this.props.setOff} >
        <MenuItems>
          {options.map(({ label, icon, onClick, user, ...others }, index) => {
            return (
              <MenuItem
                key={index}
                onClick={this.onClickRequest(onClick, user)}
                label={label}
                icon={icon}
                {...others}
              />
            );
          })}
        </MenuItems>
      </OnClickOutside> 
    );
  }
}

export default class MenuButton extends Component {
  render() {
    const { options, className } = this.props;
    return (
      <Toggle>
        {({ on, toggle, setOff }) => (
          <Container className={className}>
            <Button empty="true" onClick={toggle}>
              <MdMoreVert size={20} />
            </Button>
            {on && <Menu options={options} setOff={setOff} />}
          </Container>
        )}
      </Toggle>
    );
  }
}
