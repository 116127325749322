import { inject, observer } from "mobx-react";
import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  MdBuild,
  MdBusiness,
  // MdInsertChart,
  MdGroup,
  MdSave,
  MdPlayCircleFilled,
} from "react-icons/md";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { t } from "utils/i18n";
import { lightenColor, resetLink, resetList, transition } from "utils/mixins";
import ArchivesPage from "./archives/ArchivesPage";
import StatisticsPage from "./StatisticsPage";
import ClientsLayout from "./clients/ClientsLayout";
import UsersLayout from "./users/UsersLayout";
import SettingsPage from "./settings";

import { media } from "utils/styles";
import SequenceLayout from "./sequence/SequenceLayout";
import { ClonesPage } from "./clones";
import { IoCopySharp } from "react-icons/io5";
import { has } from "lodash";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";

const AdminLayout = observer(({ authStore }) => {
  const user = authStore.currentUser;
  const { t } = useTranslation();
  return (
    <Container>
      <Helmet>
        <title>{t("admin_page.title_page")}</title>
      </Helmet>
      <AdminMenu>
        <ul>
          <li>
            <AdminNavLink to="/admin/users">
              <MdGroup size={20} /> {t("user_plural")}
            </AdminNavLink>
          </li>
          {user.isSuperAdmin && (
            <li>
              <AdminNavLink to="/admin/clients">
                <MdBusiness /> {t("client_plural")}
              </AdminNavLink>
            </li>
          )}
          {user.isAnimator && (
            <li>
              <AdminNavLink to="/admin/sequences">
                <MdPlayCircleFilled size={20} /> {t("sequence.name")}
              </AdminNavLink>
            </li>
          )}
          {/* <li>
              <AdminNavLink to="/admin/boost">
                <MdRecordVoiceOver size={20} /> {t("admin_page.boost_launch")}
              </AdminNavLink>
            </li> */}
          <li>
            {!user.isSuperAdmin && (
              <AdminNavLink to="/admin/settings">
                <MdBuild size={18} /> {t("setting_plural")}
              </AdminNavLink>
            )}
          </li>
          {/* <li>
              <AdminNavLink to="/admin/statistics">
                <MdInsertChart size={20} /> {t("statistic_plural")}
              </AdminNavLink>
            </li> */}
          <li>
            {!user.isSuperAdmin &&
              user.client &&
              !user.client.clone &&
              user.client.authorizations &&
              toJS(user.client.authorizations).includes("CLONE_GENERATOR") && (
                <AdminNavLink to="/admin/clones">
                  <span>
                    <IoCopySharp size={20} /> {t("clone_plural")}
                  </span>
                </AdminNavLink>
              )}
          </li>
          <li>
            {!user.isSuperAdmin && (
              <AdminNavLink to="/admin/archives">
                <span>
                  <MdSave size={20} /> {t("archiving")}
                </span>
              </AdminNavLink>
            )}
          </li>
        </ul>
      </AdminMenu>
      <PageContent>
        <Switch>
          <Route path="/admin/clients" component={ClientsLayout} />
          <Route path="/admin/users" component={UsersLayout} />
          <Route path="/admin/archives" component={ArchivesPage} />
          <Route path="/admin/statistics" component={StatisticsPage} />
          {/* <Route path="/admin/boost" component={BoostLayoutPage} /> */}
          <Route path="/admin/settings" component={SettingsPage} />
          <Route path="/admin/clones" component={ClonesPage} />
          {user.isAnimator && <Route path="/admin/sequences" component={SequenceLayout} />}
          <Redirect to="/admin/users" />
        </Switch>
      </PageContent>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  background-color: #fafafa;
  min-width: 1200px;
`;

const AdminMenu = styled.div`
  width: 250px;
  margin: 20px;
  margin-right: 0;
  padding: 10px 0;
  ul {
    ${resetList};

    li {
      position: relative;
    }
  }
`;

const activeClassName = "admin-navlink-active";

const AdminNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${themeGet("colors.strongGrey")};
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  padding: 5px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: ${transition("background-color")};
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  &.${activeClassName} {
    color: ${themeGet("colors.black")};
    color: ${lightenColor(0.05, "colors.primary")};
  }

  & svg {
    margin-right: 5px;
  }
`;

export const PageContent = styled.div`
  margin: 20px 10px;
  background-color: ${themeGet("colors.white")};
  border-radius: 2px;
  min-height: 400px;
  padding: 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 750px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  h1 {
    font-size: 1.8rem;
    color: ${themeGet("colors.grey")};

    a {
      ${resetLink()};

      &:hover {
        color: ${themeGet("colors.primary")};
      }
    }
  }
  max-width: 1000px;
  /* ${media.desktop} {
    max-width: 1100px;
  } */
`;

export default inject("authStore")(AdminLayout);
