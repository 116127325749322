import { DisplayName, ProfileImage, UserCount, WeezFeed } from "app/common";
import React from "react";
import { MdLocationOn } from "react-icons/md";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import EditProfileModal from "./EditProfileModal";
import { t } from "utils/i18n";
import { Helmet } from "react-helmet";
import { MobXProviderContext } from "mobx-react";
import { action, runInAction } from "mobx";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  position: relative;
`;

const HeaderBackground = styled.div`
  height: 120px;
  background-color: ${themeGet("colors.primary")};
`;

const TopBar = styled.div`
  background-color: ${themeGet("colors.white")};
  width: 100%;
  box-shadow: ${themeGet("shadows.default")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const TopBarContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const TopBarLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TopBarLeft = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const StyledProfileImage = styled(ProfileImage)`
  position: absolute;
  z-index: ${themeGet("zIndex.high")};
  left: 10px;
  top: 80px;
  width: 70px;
  border-width: 6px;
  border-color: ${themeGet("colors.white")};
`;

const UserInformation = styled.div`
  width: 100%;
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`;

const userInformationTextStyle = css`
  margin-bottom: 10px;
`;

const StyledDisplayName = styled(DisplayName)`
  ${userInformationTextStyle};
`;

const Username = styled.span`
  ${userInformationTextStyle};
  font-size: 1rem;
  font-weight: 500;
  color: ${themeGet("colors.grey")};
`;

const Bio = styled.span`
  ${userInformationTextStyle};
`;

const StyledUserFeed = styled(WeezFeed)`
  flex: 1;
  background-color: ${themeGet("colors.white")};
  margin-top: 2px;
  max-width: 100%;
`;

function ProfilePageMobile({
  editProfileActive,
  user,
  username,
  currentUser,
  toggleModal,
  weezsFeed,
  onClickSubscribeButton,
}) {
  const { routingStore } = React.useContext(MobXProviderContext);
  React.useEffect(() => {
    runInAction(() => {
      routingStore.showReturnArrow = true;
    });
    return action(() => {
      routingStore.showReturnArrow = false;
    });
  }, []);
  return (
    <Container>
      <Helmet>
        <title>{`${t("profile.title")} @${username}`}</title>
      </Helmet>
      <HeaderContainer>
        <StyledProfileImage photo={user.photo} border />
        <HeaderBackground />
        <TopBar>
          <UserInformation>
            <StyledDisplayName user={user} size="big" />
            <Username>@{user.username}</Username>
            {user.location && (
              <Username>
                <MdLocationOn siz={20} />
                {user.location}
              </Username>
            )}
            <Bio>{user.bio}</Bio>
          </UserInformation>
          <TopBarContent>
            {/* <UserPhoto> */}
            {/* </UserPhoto> */}
            <TopBarLinksContainer>
              <TopBarLeft>
                <UserCount selected label="weezes" value={user.weezsCount} />
                <UserCount label="abonnements" value={user.subscriptionsCount} />
                <UserCount label="abonnés" value={user.subscribersCount} />
              </TopBarLeft>
              {/* <TopBarRight>
                {currentUser.id !== user.id && (
                  <Button full={user.subscribed} onClick={onClickSubscribeButton(user)}>
                    {user.subscribed ? "Abonné" : "Suivre"}
                  </Button>
                )}
                {(currentUser.id === user.id || currentUser.role === USER_TYPES.ANIMATOR) && (
                  <Button onClick={toggleModal}>Editer le profil</Button>
                )}
              </TopBarRight> */}
            </TopBarLinksContainer>
          </TopBarContent>
        </TopBar>
      </HeaderContainer>
      <Content>
        <StyledUserFeed weezsFeed={weezsFeed} />
      </Content>
      <EditProfileModal user={user} isOpen={editProfileActive} onRequestClose={toggleModal} />
    </Container>
  );
}

export default ProfilePageMobile;
